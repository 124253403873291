import React, { Component } from 'react';
import Loader from './Loader';
import "./Styles/ContributionsPopup.css"
/**
 * @typedef Transaction
 * @prop {string} type
 * @prop {string} createdAt
 * @prop {float} amount
 */
/**
 * Responsible for  displaying contributions by referrals,ppvs,subscriptions,tips
 * @extends Component<Props,State>
 */
class ContributionsPopup extends Component {
    constructor(props){
        super(props)
        this.state={
            total:"",
            renewed_subscriptions:null,
            ppv:null,
            tips:null,
            referrals:null,
            moreInfo:null,
            chevron:false,
            loadingData:true
        }
        
    }
    highestTipper = (allTransactions)=>{
        //
    }

    /**
     * Organises transactions for a given user  by type and sets the state 
     * @param {Transaction} array
     */
    calculateTotal_organised = (array)=>{
        if(this.state.ppv){
            this.setState({ppv:null,tips:null,renewed_subscriptions:null,moreInfo:null,total:null})
            return
        }
        let tips = 0
        let ppv = 0
        let renewed_subscriptions = 0
        let referrals = 0
        for(let b in array){
            if(array[b].type == "payperview"){
                ppv = parseInt(array[b].amount) + ppv
            }
            if(array[b].type == "tip"){
                tips = parseInt(array[b].amount) + tips
            }
            if(array[b].type == "subscription"){
                renewed_subscriptions = parseInt(array[b].amount) + renewed_subscriptions
            }
            if(array[b].type == "referral"){
                referrals = parseFloat(array[b].amount) + referrals

            }
        }
console.log(renewed_subscriptions,tips,ppv)
this.props.storeStatData({ppv,tips,renewed_subscriptions},this.props.currentid)
        this.setState({ppv:ppv,tips:tips,referrals:referrals,renewed_subscriptions:renewed_subscriptions,moreInfo:true,loadingData:false,total:parseFloat(ppv+tips+renewed_subscriptions+referrals)})
    }
    /**
     * Returns the total contribution for a given user    
     * @param {Transaction} array
     * 
     */
    calculateTotal = (contrib_array)=>{
        if(this.state.moreInfo){
           // this.setState({ppv:null,tips:null,renewed_subscriptions:null,moreInfo:null,total:null})
            return this.state.total
        }
        let num = 0
        for(let a in contrib_array){
         num = parseFloat(contrib_array[a].amount) + num
        }
        //this.setState({total:num})
        console.log(num)
        return num
    }
    render() {
        if(!this.props.displayTrans) return null
        return (
            <div className="boxxxx_">
        
            <div className="cenceph_">

            <div onClick={()=>this.calculateTotal_organised(this.props.transactions)}>
            {(this.state.moreInfo)?<i className="fa fa-chevron-circle-up" aria-hidden="true"></i>:<i className="fa fa-chevron-circle-down" aria-hidden="true"></i>}
            </div>
            {this.state.moreInfo && <div>
            <div style={{ fontSze:"12px", color: "#3def88"}}>
                <i className='fa fa-dollar'> Tips: GHC {this.state.tips}</i>
                    </div>
                    <div style={{ fontSze:"12px", color: "#00000"}}>
                <i className='fa fa-user'> Referrals: GHC {this.state.referrals}</i>
                    </div>
                    <div style={{  color: "rgb(110 33 108)"}}> <i className="fa fa-eye"> PPvs: GHC

 {this.state.ppv}</i>
                    </div>
                    <div style={{  color: "#e9d83d"}}><i className='fa fa-money'> Subs: GHC {this.state.renewed_subscriptions}</i></div>
                </div>}
                <div className='wrapp'>

<p style={{color:"#d10e0e"}}>  Total GHC {this.calculateTotal(this.props.transactions)}</p>
                </div>
                </div>
                </div>
        );
    }
}

export default ContributionsPopup;
