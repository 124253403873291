import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { CopyToClipboard } from 'react-copy-to-clipboard';
const link = createHttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

class Error extends Component {
  constructor(props){
    super(props);
  this.state={
    amount:'',
    date:'',
    open:this.props.isOpen 
  
  }
    this.numberchosen =this.numberchosen.bind(this)
    this.datechosen =this.datechosen.bind(this)
    this.submitnumber = this.submitnumber.bind(this)
  }


  submitnumber = async (event)=>{
    event.preventDefault()
    //apollo client createbundle price...  await user.update({ password: password });
    const {amount,date}= this.state
    console.log(amount,date)
    const createBundle = gql`
    mutation newBundle($endDate: Int!, $price: Int!) {
      newBundle(endDate: $endDate, price: $price) {
        price
        endDate
        id
      }
    }
  `;
 let b = await client
    .mutate({
      mutation: createBundle,
      variables: { endDate: parseInt(date), price:parseInt(amount) },
    })
    .then((result) => {
      console.log(result);
      let bundle = result.data.newBundle
      //set state in profile showing created bundle
      console.log("bunlde added")
      //this.setState({ redirect: !this.state.redirect });
      //this.props.updateBundle(bundle)
      return bundle
    })
    .catch((err) => {
      console.log("catch", err);
    });
 
    
   
    this.props.updateBundle(b)

    // axios.post('/api/paywall' ,{quantity:amount}).then(response =>{
    //   console.log(response)
    //   this.props.onClose()})
}


Copy = () =>{
 console.log("foo")
}
// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

  numberchosen = (event)=>{
    this.setState({amount:event.target.value});
    
}

datechosen = (event)=>{
  this.setState({date:event.target.value});
  
}
 


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
   
    
    render() {
     
      if (!this.props.isOpen )
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div style={{backgroundColor:"#86a79f"}} className="cenceph_pure_black">
       {/* {!this.props.OTP && <p><h1 style={{fontSize:"18px",fontWeight:"2000"}}>{this.props.error}</h1></p>} */}
        
        { <p ><h1 style={{fontSize:"23px",fontWeight:"2000"}}>Error</h1> 
        <br/>  <span className="tooltip" style={{color:"#4a7ea3",position:"static"}}><p  aria-hidden="true" className='tooltiptext'>file size less than 150mb <br/>should be less than 6 files </p>something</span> went wrong <br/>  </p>}
        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />
      <input onChange={this.datechosen}  value={this.state.date} type="number" min="1" max="100" /> */}
      {this.props.OTP && <CopyToClipboard text={this.props.error}><div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={this.Copy()}>copy</button> </div></CopyToClipboard>}
  
  
  
  
  
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default Error;