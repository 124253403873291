//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import routes from '../storage_apollo_routes';
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery, resetApolloContext} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import '../UI/Styles/ReactToastify.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 } from 'uuid';
import ComposeFormReply from '../UI/ComposeFormReply';

const ath = localStorage.getItem("userID")


const httpLink = new HttpLink({
  uri: routes.apollo,
});

const wsLink = new GraphQLWsLink(createClient({
  url: routes.apollo_ws,
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const ALERTS = gql`
  subscription Alerted($toUser:Int!) {
    achievementUnlocked(toUser:$toUser){
      type
      id
      message
      postId
      commentId
    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});


const Alerts3 = (props)=>{
//   let renders = 0
//   console.log(ath,renders)




const [alerts,setAlerts] = useState([])
const { data, loading} = useSubscription(
  ALERTS,{ variables: { toUser:parseInt(ath) },client:client });
  //toast.configure();
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }
const mapType = (param)=>{
  let types = {
    message:"fa-message",
    favorited:"fa-heart",
    payment:"fa-usd",
    withrawal:"fa-check",
    comment:"fa-reply"
  }
  return types[param]
}



  

  // setAlerts(prevdata =>[...prevdata,data])
if(data){
//   renders++
  //props.increment()
  console.log(data)
    //props.append(data)
    
toast(<div>Achievement Unlocked: 100 Likes
  <i style={{fontSize:"11px",paddingLeft:"5px",paddingRight:"5px",paddingTop:"5px",color:"#f1c40f"}} className='fa fa-heart'></i>
</div>,{containerId:`1${data.achievementUnlocked.type}:data.${v4()}`,
position:"top-center",
autoClose:6000,
hideProgressBar:false,
newestOnTop:false,
// closeOnClick:data.alertAdded.type !== 'comment',
rtl:false,
pauseOnFocusLoss:true,
draggable:false,
pauseOnHover:true,
theme:"dark"})
    

   
    
  

  

// return <div style={{maxWidth:"120px"}}>
// { <ToastContainer
// containerId={`1${data.achievementUnlocked.type}:data.${v4()}`}
// position="top-center"
// autoClose={5000}
// hideProgressBar={false}
// newestOnTop={false}
// closeOnClick
// rtl={false}
// pauseOnFocusLoss
// draggable
// pauseOnHover
// theme="dark"
// transition={Bounce}
// // limit={1}
// />}
// </div>            

}
else {
  return <small className="notification-badge">
  {/* {data && data.alertAdded.message}  */}
  
  </small>

}

  
// }

}
export default Alerts3