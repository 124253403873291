import React, { Component } from 'react';
import Popup5 from './Popup5';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

// """m-verified m-lg-size

class Profiledetail extends Component {
    constructor(props){
        super(props)
        this.state={
          isOpen:false,
          iMO:false,
          errorMsg:""
        }
    }

    closeModal() {
      
      //this.setState({box:!this.state.box})
      this.setState({ isOpen: false ,iMO:false})
      
      //this.update();
    }

open =()=>{
  this.setState({isOpen:!this.state.isOpen})
}
    componentDidMount(){
      console.log(this.props)
    }
    render() {
        return (
            <div style={{display:"flex"}}>
              <Popup5 id={this.props.profileStat.profile.id}  userID={this.props.profileStat.profile.UserId}onClose={() => this.closeModal()} isOpen={this.state.isOpen}/>
  <div className="crdr" >
    <div className="img">
      <img src={`storage/${this.props.profileStat.profile.photo}`}/>
    </div>
    <div className="infos">
      <div className="name">
        <h2>{this.props.profileStat.profile.name}</h2>
        <h4>{this.props.profileStat.profile.email}</h4>
      </div>
      <p className="text">
        {this.props.profileStat.profile.description}
      </p>
      <ul className="stats">
        <li>
          <h3>{this.props.profileStat.posts}</h3>
          <h4>Media</h4>
        </li>
        <li>
          <h3>82</h3>
          <h4>Projects</h4>
        </li>
        <li>
          <h3>{this.props.profileStat.subscribers}</h3>
          <h4>Subscribers</h4>
        </li>
      </ul>
      <div className="links">
        
        <button onClick={()=>this.open()} className="follow">Subscribe</button>
        {/* <button className="view">View profile</button> */}
      </div>
    </div>
  </div>
  <div className="card" style={{marginRight:"auto"}}>
                  <p>Full access to this user's content for {`${this.props.price}`} cedis per month</p>
                  <p>Direct message with this user</p>
                  <p>Cancel your subscription at any time</p>
                  <h2>Or </h2>
                  <p>Subscribe to any of the BUNDLES below at a discount :</p>
                  
                </div>
                
            </div>
        );
    }
}

export default Profiledetail;