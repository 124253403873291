import React, { Component } from 'react';
import "../UI/Styles/ReferralInputField.css"

class ReferralInputField extends Component {
    constructor(props){
        super(props)
        this.state={

        }

        this.inputField = React.createRef()
    }


    copyHandler = ()=>{
        let inputvalue = this.inputField.current.value 
        navigator.clipboard.writeText(inputvalue);
  
    }   
    render() {
        if(!this.props.referral_id) return null
        return (
            <div>
                <div className="copy-link">
  <input ref={this.inputField}type="text" className="copy-link-input" value={`https://uhsoka.com/register?__referrerid=${this.props.referral_id}`} readonly/>
  <button onClick={()=>{this.copyHandler()}} type="button" className="copy-link-button">
    <span className="material-icons">content_copy</span>
  </button>
</div>
            </div>
        );
    }
}

export default ReferralInputField;