import React, { Component } from "react";
//import axios from "axios";
import { createFFmpeg, fetchFile } from "@ffmpeg/ffmpeg";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from "apollo-upload-client";
const logo = "iooio"
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
     'Apollo-Require-Preflight': 'true'
    }
  }
});
// const ffmpeg = createFFmpeg({
//   //   corePath: "http://localhost:3000/public/ffmpeg-core.js",
//   // Use public address if you don't want to host your own.
//   corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
//   log: true,
// });

const link = createUploadLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class Upload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      file1: false,
      file2: false,
      display: false,
      img: "",
      imgPreview: "",
      video: "",
      style: "",
      trim: "",
      src: "",
      min: -50,
      max: 50,
      a: -50,
      b: 50,
    };

    this.img = React.createRef();
  }
  handleChangeA = (event) => {
    //console.log(event.target.id, event.target.value);
    let _t = event.target;
    let viet = event.target.value;
    _t.parentNode.style.setProperty(`--${_t.id}`, +_t.value);
    this.setState({ a: viet });
    let video = document.getElementById("vid");
    let half = video.duration / 2;
    let seekVal = -((half * viet) / 50);
    video.currentTime = half - seekVal;
    console.log(video.currentTime, video.duration, seekVal);
  };
  handleChangeB = (event) => {
    console.log(event.target.id, event.target.value);
    let _t = event.target;
    let viet = event.target.value;
    _t.parentNode.style.setProperty(`--${_t.id}`, +_t.value);
    this.setState({ b: viet });
  };
  // componentDidMount(){
  //  axios.post('http://localhost:4000/isolateOrigin').then(response=>{
  //    console.log(response)
  //  }).catch(error=>{console.log(error)})
  // }

  // toggleDisplay = () => {
  //   const { file, imgPreview } = this.state;
  //   let f = this.validateFile(file)
  //   if(f.length === 0){
  //     console.log("NO")
  //     return
  //   }
  //   this.setState({ display: !this.state.display, style: "block" });

  //   //const previewImage = this.img
  //   //previewImage.current.style.display = 'block';
  //   //console.log(previewImage)
  //   //     URL.revokeObjectURL(imgPreview)
  //   //     let src = URL.createObjectURL(file)
  //   //     previewImage.setAttribute('src', src);
  //   //     console.log(this.state.imgPreview)
  // };

  // load = async (video) => {
  //   try {
  //     await ffmpeg.load();
  //     return true;
  //   } catch (error) {
  //     console.log(error);
  //     const previewImage = this.img;
  //     previewImage.current.style.display = "block";
  //     let src2 = URL.createObjectURL(video);
  //     //previewImage.current.setAttribute('src', src);
  //     this.setState({ video: src2, imgPreview: logo });
  //   }
  // };
  validateFile = (file) => {
    //console.log(file.type)
    let allowedMimes = [
      "video/mp4",
      "video/avi",
      "video/ogg",
      "video/mov",
      "video/wmg",
      "video/3gp",
      "video/ts",
      "video/webm",
      "audio/mpeg",
    ];
    return allowedMimes.filter((a) => {
      return a === file.type;
    });
  };
  moduleRun = async (file) => {
    // ffmpeg.isLoaded() || await this.load()
    this.setState({ img: file, file1: file });
    // let arr = this.validateFile(file);
    // if (arr.length === 0) {
    //   //upload logic without thumbnail generator
    //   const previewImage = this.img;
    //   previewImage.current.style.display = "block";
    //   let src = URL.createObjectURL(file);
    //   //previewImage.current.setAttribute('src', src);
    //   this.setState({ imgPreview: src });

    //   console.log("file not a video", file.type);
    // }
    // if (arr.length === 1) {
    //   if (ffmpeg.isLoaded() || (await this.load(file))) {
    //     if (arr[0] === "audio/mpeg") {
    //       console.log("audio file do sth here");
    //     } else {
    //       this.setState({file:file})
    //       await this.convertToGif(file);
    //       console.log(`file is a video of the type:${arr[0]}`);
    //     }
    //   }
    // }
  };
  // convertToGif = async (video) => {
  //   try {
  //     // Write the file to memory
  //     ffmpeg.FS("writeFile", "test.mp4", await fetchFile(video));
  //     // Run the FFMpeg command
  //     await ffmpeg.run(
  //       "-i",
  //       "test.mp4",
  //       "-t",
  //       "2.5",
  //       "-ss",
  //       "2.0",
  //       "-f",
  //       "gif",
  //       "out.gif"
  //     );
  //     // Read the result
  //     const data = ffmpeg.FS("readFile", "out.gif");
  //     const imag = new Blob([data.buffer], { type: "image/gif" });
  //     const image = new File([imag],`${video.name}.gif`,{type:"image/gif"})
  //     console.log(image)
  //     const previewImage = this.img;
  //     previewImage.current.style.display = "block";
  //     let src = URL.createObjectURL(image);
  //     let src2 = URL.createObjectURL(video);
  //     //previewImage.current.setAttribute('src', src);
  //     this.setState({ imgPreview: src, video: src2, file2: image });
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  fileUploadHandler = () => {
    const { file1, file2 } = this.state;
    console.log(file1,"fileflelelelel")
    // console.log(file2)
    if (file1) {
      const newPost = gql`
        mutation newPost(
          $title: String!
          $caption: String!
          $image: [Upload]
         
        ) {
          newPost(
            title: $title
            caption: $caption
            image: $image
            
          ) {
            caption
            title
          }
        }
      `;
      client
        .mutate({
          mutation: newPost,
          variables: {
            title: "Default",
            caption: "default caption",
            image: [file1],
            
          },
        })
        .then((result) => {
          console.log(result);
        })
        .catch((err) => {
          console.log("catch", err);
        });
    } else {
      console.log("no file selected");
    }
  };
  fileSelectHandler = (event) => {
    let selected = event.target.files[0];
    console.log(selected);
    this.moduleRun(selected);
  };
  render() {
    const { display, imgPreview, style, trim, a, b, min, max, video } =
      this.state;
    return (
      <div class="main-content">
        {/* <div class="main-header">
          <p>Upload Area</p>
        </div> */}
        <div class="main-container">
          {/* //<div class="main-header"></div> */}
          <section className="feed">
          <ul class="feed-list">
          {/* <li class="feed-item">
          <article class="tweet-item">
            <img class="profile-image" src="https://s.cdpn.io/profiles/user/65740/80.jpg?1576332014" alt="i"/>
            <header class="tweet-item-info u-flex">
              <a class="tweet-item-info-user" href="https://twitter.com/eladsc" target="_blank">
                <span class="common-title">Sheg Earlyson</span>
                <span class="usercode">.referralID</span>
              </a>
              <time datetime="">Jun 7</time>
              <button class="icon-button u-flex u-margin-start-auto" style={{"--icon-button-size":`${27}px`}}><span class="icon icon-arrow-down u-margin-auto"></span></button>
            </header>
            <div class="common-content">
              <p>
               Generic text goes here blah blah blah css trick.

              </p>
                            <a class="embed-content" href="https://medium.com/@elad/reverse-engineering-twitters-css-289c91040008" target="_blank">
                <img class="embed-content-image" src="https://miro.medium.com/max/1104/1*bWhVp9YmZbYyGshS2hpNPw.jpeg" alt=""/>
                <div class="embed-content-text">
                  <h2 class="embed-content-title">Reverse  CSS</h2>
                  <p class="embed-content-paragraph">As someone who loves CSS and appreciates its importance in this day and age, it is very unclear to me how the web industry’s most…</p>
                  <footer class="embed-content-info"><span class="icon">🔗</span><span class="text">medium.com</span></footer>
                </div>
              </a>
            </div>
            <ul class="message-options u-flex u-space-between">
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">💬</span></button>
                  <span class="text"></span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">🔃</span></button>
                  <span class="text">0</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">❤</span></button>
                  <span class="text">2</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">📤</span></button>
                  <span class="text"></span>
                </label>
              </li>
            </ul>
          </article>
        </li> */}





        {/* <li class="feed-item">
          <article class="tweet-item">
            <img class="profile-image" src="https://s.cdpn.io/profiles/user/65740/80.jpg?1576332014" alt="i"/>
            <header class="tweet-item-info u-flex">
              <a class="tweet-item-info-user" href="https://twitter.com/eladsc" target="_blank">
                <span class="common-title">Sheg Earlyson</span>
                <span class="usercode">.referralID</span>
              </a>
              <time datetime="">Jun 7</time>
              <button class="icon-button u-flex u-margin-start-auto" style={{"--icon-button-size":`${27}px`}}><span class="icon icon-arrow-down u-margin-auto"></span></button>
            </header>
            <div class="common-content">
              <p>
               Generic text goes here blah blah blah css trick.

              </p>
                            <a class="embed-content" href="https://medium.com/@elad/reverse-engineering-twitters-css-289c91040008" target="_blank">
                <img class="embed-content-image" src="https://miro.medium.com/max/1104/1*bWhVp9YmZbYyGshS2hpNPw.jpeg" alt=""/>
                <div class="embed-content-text">
                  <h2 class="embed-content-title">Reverse  CSS</h2>
                  <p class="embed-content-paragraph">As someone who loves CSS and appreciates its importance in this day and age, it is very unclear to me how the web industry’s most…</p>
                  <footer class="embed-content-info"><span class="icon">🔗</span><span class="text">medium.com</span></footer>
                </div>
              </a>
            </div>
            <ul class="message-options u-flex u-space-between">
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">💬</span></button>
                  <span class="text"></span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">🔃</span></button>
                  <span class="text">0</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">❤</span></button>
                  <span class="text">2</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">📤</span></button>
                  <span class="text"></span>
                </label>
              </li>
            </ul>
          </article>
        </li> */}

        <li class="feed-item">
          <article class="tweet-item">
            <img class="profile-image" src="https://s.cdpn.io/profiles/user/65740/80.jpg?1576332014" alt="i"/>
            <header class="tweet-item-info u-flex">
              <a class="tweet-item-info-user" href="https://twitter.com/eladsc" target="_blank">
                <span class="common-title">Sheg Earlyson</span>
                <span class="usercode"></span>
              </a>
              <time datetime="">Jun 7</time>
              <button class="icon-button u-flex u-margin-start-auto" style={{"--icon-button-size":`${27}px`}}><span class="icon icon-arrow-down u-margin-auto"></span></button>
            </header>
            <div class="common-content">
              <p>
               Generic text goes here blah blah blah css trick.

              </p>
                            <div class="embed-content" href="https://medium.com/@elad/reverse-engineering-twitters-css-289c91040008" target="_blank">
                            <div className="upload-buttons">
                {/* <div className="dropzone">
                {!display && <img className="preview-image"ref={this.img} src=
                {imgPreview} style={{display:style}} />}
                  {display && <div className="styleBox">
                  <div className="flexible-containe">
        <video id="vid" width="100%" height="100%" controls>
    <source src={trim} type="video/webm">
    </source>
  </video>
  </div>
                  </div>}
       
                 
                </div> */}
              </div>
                {!display &&  <img className="embed-content-image"src={imgPreview} ref={this.img} alt=""/>}
                {display && <div className="styleBox">
                  <div className="flexible-containe">
        <video id="vid" width="100%" height="100%" controls><source src={video} type="video/webm"></source></video></div></div>}

                <div class="embed-content-text">
                  <h2 class="embed-content-title">caption here</h2>
                  <p class="embed-content-paragraph">   {!display && <div className="file-input">
                    <input
                      type="file"
                      className="file-input"
                      onChange={(event) => {
                        this.fileSelectHandler(event);
                      }}
                    />
                  </div> }</p>
                  
                  <footer   class="embed-content-info"><span class="icon">🔗</span><span class="text">preview</span></footer>
                </div>
              </div>
            </div>
            <ul class="message-options u-flex u-space-between">
              <li  class="message-options-item">
                <label>
                  <button  class="icon-button"><span class="icon">💬</span></button>
                  <span class="text"></span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span class="icon">🔃</span></button>
                  <span class="text">0</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button class="icon-button"><span  onClick={() => {

this.toggleDisplay();
}}class="icon">❤</span></button>
                  <span class="text">2</span>
                </label>
              </li>
              <li class="message-options-item">
                <label>
                  <button onClick={() => {
                      this.fileUploadHandler();
                    }} class="icon-button"><span class="icon">📤</span></button>
                  <span class="text"></span>
                </label>
              </li>
            </ul>
          </article>
        </li>


            {/* <div className="feed-item">
              <div className="drop-or-click">
                <p className="upload-area__title">add a file</p>
              </div>

              <div className="upload-buttons">
                <div className="common-content">
                  {!display && (
                    <img
                      className="embed-content-image "
                      ref={this.img}
                      src={imgPreview}
                      style={{ display: style }}
                    />
                  )}
                  {display && (
                    <div className="styleBox">
                      <div className="flexible-containe">
                        <video id="vid" width="100%" height="100%" controls>
                          <source src={video} type="video/webm"></source>
                        </video>
                      </div>
                    </div>
                  )}

                  {!display && (
                    <div className="file-input">
                      <input
                        type="file"
                        className="file-input"
                        onChange={(event) => {
                          this.fileSelectHandler(event);
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="upload-buttons-container">
                <p class="sqccircle grey">
                  <svg height="13px" width="13px" viewBox="0 0 606.193 606.193">
                    <g>
                      <path
                        d="M61.318,187.65h70.87c32.058,0,58.14-26.082,58.14-58.14V58.64c0-32.059-26.082-58.14-58.14-58.14h-70.87
				c-32.059,0-58.14,26.082-58.14,58.14v70.87C3.178,161.569,29.259,187.65,61.318,187.65z M46.018,58.64
				c0-8.45,6.85-15.3,15.3-15.3h70.87c8.45,0,15.3,6.85,15.3,15.3v70.87c0,8.45-6.85,15.3-15.3,15.3h-70.87
				c-8.45,0-15.3-6.85-15.3-15.3V58.64z"
                      />
                      <path
                        d="M132.188,188.15h-70.87c-32.334,0-58.64-26.306-58.64-58.64V58.64C2.678,26.306,28.983,0,61.318,0h70.87
				c32.334,0,58.64,26.306,58.64,58.64v70.87C190.828,161.845,164.522,188.15,132.188,188.15z M61.318,1
				C29.535,1,3.678,26.857,3.678,58.64v70.87c0,31.783,25.857,57.64,57.64,57.64h70.87c31.783,0,57.64-25.857,57.64-57.64V58.64
				c0-31.783-25.857-57.64-57.64-57.64H61.318z M132.188,145.311h-70.87c-8.712,0-15.8-7.088-15.8-15.8V58.64
				c0-8.712,7.088-15.8,15.8-15.8h70.87c8.712,0,15.8,7.088,15.8,15.8v70.87C147.988,138.223,140.9,145.311,132.188,145.311z
				 M61.318,43.84c-8.161,0-14.8,6.639-14.8,14.8v70.87c0,8.161,6.639,14.8,14.8,14.8h70.87c8.161,0,14.8-6.639,14.8-14.8V58.64
				c0-8.161-6.639-14.8-14.8-14.8H61.318z"
                      />
                    </g>
                    <g>
                      <path
                        d="M267.662,187.65h70.87c32.059,0,58.141-26.082,58.141-58.14V58.64c0-32.059-26.082-58.14-58.141-58.14h-70.87
				c-32.059,0-58.14,26.082-58.14,58.14v70.87C209.521,161.569,235.603,187.65,267.662,187.65z M252.361,58.64
				c0-8.45,6.85-15.3,15.3-15.3h70.87c8.451,0,15.301,6.85,15.301,15.3v70.87c0,8.45-6.85,15.3-15.301,15.3h-70.87
				c-8.45,0-15.3-6.85-15.3-15.3V58.64z"
                      />
                      <path
                        d="M338.531,188.15h-70.87c-32.334,0-58.64-26.306-58.64-58.64V58.64c0-32.334,26.306-58.64,58.64-58.64h70.87
				c32.335,0,58.641,26.306,58.641,58.64v70.87C397.172,161.845,370.866,188.15,338.531,188.15z M267.662,1
				c-31.783,0-57.64,25.857-57.64,57.64v70.87c0,31.783,25.857,57.64,57.64,57.64h70.87c31.783,0,57.641-25.857,57.641-57.64V58.64
				c0-31.783-25.857-57.64-57.641-57.64H267.662z M338.531,145.311h-70.87c-8.712,0-15.8-7.088-15.8-15.8V58.64
				c0-8.712,7.088-15.8,15.8-15.8h70.87c8.713,0,15.801,7.088,15.801,15.8v70.87C354.332,138.223,347.244,145.311,338.531,145.311z
				 M267.662,43.84c-8.161,0-14.8,6.639-14.8,14.8v70.87c0,8.161,6.639,14.8,14.8,14.8h70.87c8.161,0,14.801-6.639,14.801-14.8
				V58.64c0-8.161-6.64-14.8-14.801-14.8H267.662z"
                      />
                    </g>
                    <g>
                      <path
                        d="M544.875,0.5h-70.869c-32.059,0-58.141,26.082-58.141,58.14v70.87c0,32.059,26.082,58.14,58.141,58.14h70.869
				c32.059,0,58.141-26.082,58.141-58.14V58.64C603.016,26.582,576.934,0.5,544.875,0.5z M560.176,129.51
				c0,8.45-6.85,15.3-15.301,15.3h-70.869c-8.451,0-15.301-6.85-15.301-15.3V58.64c0-8.45,6.85-15.3,15.301-15.3h70.869
				c8.451,0,15.301,6.85,15.301,15.3V129.51z"
                      />
                      <path
                        d="M544.875,188.15h-70.869c-32.335,0-58.641-26.306-58.641-58.64V58.64c0-32.334,26.306-58.64,58.641-58.64h70.869
				c32.335,0,58.641,26.306,58.641,58.64v70.87C603.516,161.845,577.21,188.15,544.875,188.15z M474.006,1
				c-31.783,0-57.641,25.857-57.641,57.64v70.87c0,31.783,25.857,57.64,57.641,57.64h70.869c31.783,0,57.641-25.857,57.641-57.64
				V58.64c0-31.783-25.857-57.64-57.641-57.64H474.006z M544.875,145.311h-70.869c-8.713,0-15.801-7.088-15.801-15.8V58.64
				c0-8.712,7.088-15.8,15.801-15.8h70.869c8.713,0,15.801,7.088,15.801,15.8v70.87
				C560.676,138.223,553.588,145.311,544.875,145.311z M474.006,43.84c-8.161,0-14.801,6.639-14.801,14.8v70.87
				c0,8.161,6.64,14.8,14.801,14.8h70.869c8.161,0,14.801-6.639,14.801-14.8V58.64c0-8.161-6.64-14.8-14.801-14.8H474.006z"
                      />
                    </g>
                    <g>
                      <path
                        d="M3.178,338.531c0,32.059,26.082,58.141,58.14,58.141h70.87c32.058,0,58.14-26.082,58.14-58.141v-70.871
				c0-32.058-26.082-58.14-58.14-58.14h-70.87c-32.059,0-58.14,26.082-58.14,58.14V338.531z M46.018,267.661
				c0-8.45,6.85-15.3,15.3-15.3h70.87c8.45,0,15.3,6.85,15.3,15.3v70.871c0,8.449-6.85,15.299-15.3,15.299h-70.87
				c-8.45,0-15.3-6.85-15.3-15.299V267.661z"
                      />
                      <path
                        d="M132.188,397.172h-70.87c-32.334,0-58.64-26.306-58.64-58.641v-70.871c0-32.334,26.306-58.64,58.64-58.64h70.87
				c32.334,0,58.64,26.306,58.64,58.64v70.871C190.828,370.866,164.522,397.172,132.188,397.172z M61.318,210.021
				c-31.783,0-57.64,25.857-57.64,57.64v70.871c0,31.783,25.857,57.641,57.64,57.641h70.87c31.783,0,57.64-25.857,57.64-57.641
				v-70.871c0-31.783-25.857-57.64-57.64-57.64H61.318z M132.188,354.33h-70.87c-8.712,0-15.8-7.087-15.8-15.799v-70.871
				c0-8.712,7.088-15.8,15.8-15.8h70.87c8.712,0,15.8,7.088,15.8,15.8v70.871C147.988,347.243,140.9,354.33,132.188,354.33z
				 M61.318,252.861c-8.161,0-14.8,6.639-14.8,14.8v70.871c0,8.16,6.639,14.799,14.8,14.799h70.87c8.161,0,14.8-6.639,14.8-14.799
				v-70.871c0-8.161-6.639-14.8-14.8-14.8H61.318z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.521,338.531c0,32.059,26.082,58.141,58.14,58.141h70.87c32.059,0,58.141-26.082,58.141-58.141v-70.871
				c0-32.058-26.082-58.14-58.141-58.14h-70.87c-32.059,0-58.14,26.082-58.14,58.14V338.531z M252.361,267.661
				c0-8.45,6.85-15.3,15.3-15.3h70.87c8.451,0,15.301,6.85,15.301,15.3v70.871c0,8.449-6.85,15.299-15.301,15.299h-70.87
				c-8.45,0-15.3-6.85-15.3-15.299V267.661z"
                      />
                      <path
                        d="M338.531,397.172h-70.87c-32.334,0-58.64-26.306-58.64-58.641v-70.871c0-32.334,26.306-58.64,58.64-58.64h70.87
				c32.335,0,58.641,26.306,58.641,58.64v70.871C397.172,370.866,370.866,397.172,338.531,397.172z M267.662,210.021
				c-31.783,0-57.64,25.857-57.64,57.64v70.871c0,31.783,25.857,57.641,57.64,57.641h70.87c31.783,0,57.641-25.857,57.641-57.641
				v-70.871c0-31.783-25.857-57.64-57.641-57.64H267.662z M338.531,354.33h-70.87c-8.712,0-15.8-7.087-15.8-15.799v-70.871
				c0-8.712,7.088-15.8,15.8-15.8h70.87c8.713,0,15.801,7.088,15.801,15.8v70.871C354.332,347.243,347.244,354.33,338.531,354.33z
				 M267.662,252.861c-8.161,0-14.8,6.639-14.8,14.8v70.871c0,8.16,6.639,14.799,14.8,14.799h70.87
				c8.161,0,14.801-6.639,14.801-14.799v-70.871c0-8.161-6.64-14.8-14.801-14.8H267.662z"
                      />
                    </g>
                    <g>
                      <path
                        d="M544.875,209.521h-70.869c-32.059,0-58.141,26.082-58.141,58.14v70.871c0,32.059,26.082,58.141,58.141,58.141h70.869
				c32.059,0,58.141-26.082,58.141-58.141v-70.871C603.016,235.603,576.934,209.521,544.875,209.521z M560.176,338.531
				c0,8.451-6.85,15.301-15.301,15.301h-70.869c-8.451,0-15.301-6.85-15.301-15.301v-70.871c0-8.45,6.85-15.3,15.301-15.3h70.869
				c8.451,0,15.301,6.85,15.301,15.3V338.531z"
                      />
                      <path
                        d="M544.875,397.172h-70.869c-32.335,0-58.641-26.306-58.641-58.641v-70.871c0-32.334,26.306-58.64,58.641-58.64h70.869
				c32.335,0,58.641,26.306,58.641,58.64v70.871C603.516,370.866,577.21,397.172,544.875,397.172z M474.006,210.021
				c-31.783,0-57.641,25.857-57.641,57.64v70.871c0,31.783,25.857,57.641,57.641,57.641h70.869
				c31.783,0,57.641-25.857,57.641-57.641v-70.871c0-31.783-25.857-57.64-57.641-57.64H474.006z M544.875,354.332h-70.869
				c-8.713,0-15.801-7.088-15.801-15.801v-70.871c0-8.712,7.088-15.8,15.801-15.8h70.869c8.713,0,15.801,7.088,15.801,15.8v70.871
				C560.676,347.244,553.588,354.332,544.875,354.332z M474.006,252.861c-8.161,0-14.801,6.639-14.801,14.8v70.871
				c0,8.161,6.64,14.801,14.801,14.801h70.869c8.161,0,14.801-6.64,14.801-14.801v-70.871c0-8.161-6.64-14.8-14.801-14.8H474.006z"
                      />
                    </g>
                    <g>
                      <path
                        d="M61.318,605.691h70.87c32.058,0,58.14-26.08,58.14-58.139v-70.869c0-32.059-26.082-58.141-58.14-58.141h-70.87
				c-32.059,0-58.14,26.082-58.14,58.141v70.869C3.178,579.611,29.259,605.691,61.318,605.691z M46.018,476.684
				c0-8.451,6.85-15.301,15.3-15.301h70.87c8.45,0,15.3,6.85,15.3,15.301v70.869c0,8.449-6.85,15.301-15.3,15.301h-70.87
				c-8.45,0-15.3-6.852-15.3-15.301V476.684z"
                      />
                      <path
                        d="M132.188,606.191h-70.87c-32.334,0-58.64-26.306-58.64-58.639v-70.869c0-32.335,26.306-58.641,58.64-58.641h70.87
				c32.334,0,58.64,26.306,58.64,58.641v70.869C190.828,579.886,164.522,606.191,132.188,606.191z M61.318,419.043
				c-31.783,0-57.64,25.857-57.64,57.641v70.869c0,31.782,25.857,57.639,57.64,57.639h70.87c31.783,0,57.64-25.856,57.64-57.639
				v-70.869c0-31.783-25.857-57.641-57.64-57.641H61.318z M132.188,563.354h-70.87c-8.712,0-15.8-7.088-15.8-15.801v-70.869
				c0-8.713,7.088-15.801,15.8-15.801h70.87c8.712,0,15.8,7.088,15.8,15.801v70.869C147.988,556.266,140.9,563.354,132.188,563.354z
				 M61.318,461.883c-8.161,0-14.8,6.64-14.8,14.801v70.869c0,8.161,6.639,14.801,14.8,14.801h70.87c8.161,0,14.8-6.64,14.8-14.801
				v-70.869c0-8.161-6.639-14.801-14.8-14.801H61.318z"
                      />
                    </g>
                    <g>
                      <path
                        d="M209.521,547.553c0,32.059,26.082,58.139,58.14,58.139h70.87c32.059,0,58.141-26.08,58.141-58.139v-70.869
				c0-32.059-26.082-58.141-58.141-58.141h-70.87c-32.059,0-58.14,26.082-58.14,58.141V547.553z M252.361,476.684
				c0-8.451,6.85-15.301,15.3-15.301h70.87c8.451,0,15.301,6.85,15.301,15.301v70.869c0,8.449-6.85,15.301-15.301,15.301h-70.87
				c-8.45,0-15.3-6.852-15.3-15.301V476.684z"
                      />
                      <path
                        d="M338.531,606.191h-70.87c-32.334,0-58.64-26.306-58.64-58.639v-70.869c0-32.335,26.306-58.641,58.64-58.641h70.87
				c32.335,0,58.641,26.306,58.641,58.641v70.869C397.172,579.886,370.866,606.191,338.531,606.191z M267.662,419.043
				c-31.783,0-57.64,25.857-57.64,57.641v70.869c0,31.782,25.857,57.639,57.64,57.639h70.87c31.783,0,57.641-25.856,57.641-57.639
				v-70.869c0-31.783-25.857-57.641-57.641-57.641H267.662z M338.531,563.354h-70.87c-8.712,0-15.8-7.088-15.8-15.801v-70.869
				c0-8.713,7.088-15.801,15.8-15.801h70.87c8.713,0,15.801,7.088,15.801,15.801v70.869
				C354.332,556.266,347.244,563.354,338.531,563.354z M267.662,461.883c-8.161,0-14.8,6.64-14.8,14.801v70.869
				c0,8.161,6.639,14.801,14.8,14.801h70.87c8.161,0,14.801-6.64,14.801-14.801v-70.869c0-8.161-6.64-14.801-14.801-14.801H267.662z
				"
                      />
                    </g>
                    <g>
                      <path
                        d="M544.875,418.543h-70.869c-32.059,0-58.141,26.082-58.141,58.141v70.869c0,32.059,26.082,58.141,58.141,58.141h70.869
				c32.059,0,58.141-26.082,58.141-58.141v-70.869C603.016,444.623,576.934,418.543,544.875,418.543z M560.176,547.553
				c0,8.449-6.85,15.299-15.301,15.299h-70.869c-8.451,0-15.301-6.85-15.301-15.299v-70.869c0-8.451,6.85-15.301,15.301-15.301
				h70.869c8.451,0,15.301,6.85,15.301,15.301V547.553z"
                      />
                      <path
                        d="M544.875,606.193h-70.869c-32.335,0-58.641-26.306-58.641-58.641v-70.869c0-32.335,26.306-58.641,58.641-58.641h70.869
				c32.335,0,58.641,26.306,58.641,58.641v70.869C603.516,579.888,577.21,606.193,544.875,606.193z M474.006,419.043
				c-31.783,0-57.641,25.857-57.641,57.641v70.869c0,31.783,25.857,57.641,57.641,57.641h70.869
				c31.783,0,57.641-25.857,57.641-57.641v-70.869c0-31.783-25.857-57.641-57.641-57.641H474.006z M544.875,563.352h-70.869
				c-8.713,0-15.801-7.087-15.801-15.799v-70.869c0-8.713,7.088-15.801,15.801-15.801h70.869c8.713,0,15.801,7.088,15.801,15.801
				v70.869C560.676,556.265,553.588,563.352,544.875,563.352z M474.006,461.883c-8.161,0-14.801,6.64-14.801,14.801v70.869
				c0,8.16,6.64,14.799,14.801,14.799h70.869c8.161,0,14.801-6.639,14.801-14.799v-70.869c0-8.161-6.64-14.801-14.801-14.801
				H474.006z"
                      />
                    </g>
                  </svg>
                  <div class="popper">
                    <h4 class="popper-text"></h4>
                  </div>
                </p>
                <p class="sqccircle green">
                  <svg width="13px" height="13px" viewBox="0 0 488.728 488.728">
                    <g>
                      {" "}
                      <path
                        d="M487.147,462.52l-36.4-167.6c0-4.2-2.1-7.3-5.2-10.4l-261.3-261.3c-20-22.9-74.3-38.1-112.4,0l-47.9,47.9
		c-31,31-31,81.4,0,112.4l261.3,261.3c2.1,2.1,5.2,4.2,9.4,5.2l168.6,38.5C473.347,490.02,492.347,483.62,487.147,462.52z
		 M53.047,154.42c-15.6-15.6-15.6-39.6,0-55.2l47.9-47.9c15.2-15.2,40-15.2,55.2,0l238.4,238.4h-27.1c-11.4,0-20.8,9.4-20.8,20.8
		v34.3h-34.3c-11.4,0-20.8,9.4-20.8,20.8v26.1L53.047,154.42z M333.047,415.72v-29.2h34.3c18,1.7,20.8-16.5,20.8-20.8v-34.4h29.2
		l24,109.3L333.047,415.72z"
                      />
                    </g>
                  </svg>

                  <div class="popper">
                    <h4 class="popper-text"></h4>
                  </div>
                </p>
                <p class="sqccircle orange">
                  <svg
                    onClick={() => {

                      this.toggleDisplay();
                    }}
                    height="13px"
                    width="13px"
                    viewBox="0 0 499.995 499.995"
                  >
                    <g>
                      <path
                        d="M362.601,320.387c-12.648,0-25.079,2.644-36.487,7.708l-32.028-65.17c3.332-6.697,5.079-14.073,5.079-21.639
				c0-5.148-0.837-10.27-2.429-15.143c2.464-2.075,4.93-4.235,7.377-6.481c14.119-12.958,26.64-27.893,37.217-44.388
				c10.887-16.979,19.642-34.991,26.022-53.538c6.592-19.165,10.563-38.719,11.802-58.117c0.642-10.049,0.567-19.721-0.221-28.746
				c-0.685-7.831-1.648-16.21-4.059-24.471l-0.061-0.233c-0.339-1.315-1.044-4.052-3.352-6.455c-2.592-2.698-6.181-4.032-9.852-3.65
				c-3.747,0.386-7.211,2.573-9.265,5.851c-1.562,2.49-2.865,4.992-4.126,7.412c-0.391,0.749-0.78,1.496-1.173,2.233
				c-2.011,3.773-4.03,7.541-6.049,11.31c-4.265,7.96-8.471,16.08-12.577,24.009c-9.026,17.425-17.713,34.714-25.758,50.802
				c-8.545,17.092-16.807,34.152-25.948,53.082c-6.284,13.016-12.388,25.923-18.227,38.515c-2.631-0.437-5.301-0.667-7.998-0.667
				c-2.707,0-5.387,0.232-8.03,0.673c-0.479-1.031-0.956-2.064-1.436-3.095c-8.294-17.817-16.782-35.479-24.718-51.911
				c-8.752-18.124-18.007-36.628-26.172-52.954c-8.69-17.377-17.318-34.196-25.646-49.992c-3.641-6.905-7.885-14.93-12.183-22.777
				l-2.52-4.579l-0.125-0.244c-0.792-1.563-3.201-6.318-9.037-7.49c-1.977-0.397-11.983-1.708-14.946,11.7
				c-0.035,0.158-0.066,0.307-0.099,0.439c-4.005,16.14-5.117,34.891-3.306,55.731c1.717,19.759,6.131,39.388,13.121,58.341
				c7.036,19.081,16.08,36.904,26.882,52.975c10.966,16.315,23.669,30.858,37.756,43.223c1.378,1.21,2.778,2.399,4.186,3.572
				c-1.576,4.85-2.404,9.946-2.404,15.066c0,7.566,1.747,14.942,5.08,21.639l-32.196,65.511
				c-11.611-5.289-24.318-8.049-37.298-8.049c-49.516,0-89.8,40.284-89.8,89.8c0,49.517,40.284,89.801,89.8,89.801
				c0.391,0,0.778-0.008,1.188-0.021c10.986-0.257,20.838-6.505,25.756-16.329c0.032-0.062,0.063-0.123,0.094-0.185l86.052-175.097
				l86.051,175.096c5.014,10.202,15.192,16.54,26.564,16.54c0.614,0,1.251-0.023,1.942-0.071
				c23.365-0.632,45.275-10.236,61.724-27.062c16.528-16.907,25.63-39.164,25.63-62.672
				C452.401,360.672,412.117,320.387,362.601,320.387z M277.15,200.556c5.633-12.132,11.527-24.574,17.575-37.097
				c9.108-18.863,17.338-35.858,25.826-52.835c8.011-16.021,16.66-33.233,25.667-50.625c4.036-7.791,8.208-15.848,12.407-23.685
				c0.102-0.19,0.204-0.381,0.306-0.571c0.026,0.291,0.052,0.582,0.078,0.871c0.701,8.025,0.764,16.683,0.186,25.73
				c-1.125,17.616-4.744,35.409-10.755,52.886c-5.862,17.041-13.918,33.61-23.946,49.248c-9.648,15.046-21.055,28.655-33.904,40.448
				c-1.352,1.24-2.708,2.454-4.064,3.637l-0.001,0.017c-2.776-3.06-5.924-5.758-9.381-8.022L277.15,200.556z M178.873,168.269
				c-9.914-14.751-18.23-31.149-24.716-48.738c-6.374-17.283-10.397-35.166-11.96-53.152c-0.958-11.024-1.021-21.316-0.194-30.781
				c1.674,3.148,3.286,6.201,4.793,9.06c8.261,15.67,16.823,32.361,25.45,49.61c8.136,16.269,17.357,34.707,26.05,52.706
				c7.904,16.366,16.354,33.95,24.596,51.654c0.302,0.65,0.604,1.3,0.907,1.949h0.009c-3.472,2.277-6.632,4.992-9.415,8.072
				l-0.001-0.016c-0.39-0.337-0.779-0.676-1.166-1.016C200.434,196.391,188.876,183.152,178.873,168.269z M238.037,288.35
				l-91.493,186.166c-0.021,0.042-0.042,0.084-0.063,0.126c-1.59,3.213-4.803,5.256-8.386,5.33
				c-0.044,0.001-0.089,0.002-0.133,0.004c-0.195,0.007-0.378,0.012-0.563,0.012c-38.488,0-69.8-31.313-69.8-69.801
				c0-38.487,31.313-69.8,69.8-69.8c12.986,0,25.603,3.538,36.485,10.232c2.399,1.475,5.307,1.87,8.015,1.09
				c2.706-0.782,4.957-2.669,6.199-5.196l32.74-66.618l-0.024-0.044c5.126,3.951,10.988,6.848,17.268,8.499H238.037z
				 M226.547,257.065c-3.098-4.687-4.735-10.143-4.735-15.778c0-4.754,1.127-9.279,3.351-13.453
				c3.295-6.187,8.713-10.946,15.254-13.401c3.224-1.209,6.612-1.822,10.071-1.822c3.457-0.002,6.84,0.609,10.056,1.815
				c6.542,2.451,11.961,7.205,15.26,13.386c2.23,4.179,3.36,8.712,3.36,13.475c0,5.635-1.637,11.092-4.735,15.778
				c-5.337,8.076-14.287,12.897-23.941,12.897C240.834,269.962,231.884,265.141,226.547,257.065z M364.313,479.933
				c-0.173,0.004-0.347,0.013-0.52,0.026c-3.929,0.3-7.577-1.815-9.303-5.329l-91.548-186.28h-0.045
				c6.28-1.651,12.141-4.548,17.268-8.499l-0.024,0.044l32.524,66.18c1.233,2.511,3.462,4.39,6.145,5.181
				c2.684,0.794,5.574,0.423,7.973-1.015c10.75-6.446,23.136-9.854,35.818-9.854c38.488,0,69.8,31.313,69.8,69.8
				C432.401,447.73,401.857,479.018,364.313,479.933z"
                      />
                      <path
                        d="M362.601,359.553c-10.626,0-20.784,3.246-29.373,9.387c-3.981,2.846-5.317,8.154-3.159,12.546l35.374,71.977
				c1.717,3.495,5.246,5.591,8.974,5.591c0.97,0,1.955-0.142,2.924-0.438c21.135-6.461,35.896-26.376,35.896-48.429
				C413.235,382.267,390.52,359.553,362.601,359.553z M379.114,435.938l-26.839-54.611c3.275-1.17,6.755-1.773,10.326-1.773
				c16.892,0,30.635,13.742,30.635,30.634C393.235,420.679,387.705,430.373,379.114,435.938z"
                      />
                      <path
                        d="M167.679,369.586c-8.76-6.563-19.23-10.032-30.28-10.032c-27.92,0-50.635,22.714-50.635,50.634
				c0,22.6,15.201,42.65,36.965,48.759c0.898,0.252,1.807,0.373,2.704,0.373c3.734,0,7.259-2.102,8.973-5.59l35.252-71.729
				C172.778,377.683,171.527,372.469,167.679,369.586z M121.619,436.437c-9.024-5.453-14.855-15.362-14.855-26.25
				c0-16.892,13.743-30.634,30.635-30.634c3.888,0,7.653,0.705,11.159,2.07L121.619,436.437z"
                      />
                    </g>
                  </svg>
                  <div class="popper">
                    <h4 class="popper-text"></h4>
                  </div>
                </p>
                <div className="upload-button">
                  <button
                    className="btn btn-danger btn-round-2"
                    onClick={() => {
                      this.fileUploadHandler();
                    }}
                  >
                    upload
                  </button>
                </div>
              </div>
              {display && (
                <div
                  class="wrap"
                  role="group"
                  aria-labelledby="multi-lbl"
                  style={{
                    "--a": `${a}`,
                    "--b": `${b}`,
                    "--min": `${min}`,
                    "--max": `${max}`,
                  }}
                >
                  <div id="multi-lbl">trim video:</div>
                  <label class="sr-only" for="a">
                    Value A:
                  </label>
                  <input
                    onChange={(event) => {
                      this.handleChangeA(event);
                    }}
                    id="a"
                    type="range"
                    min="-50"
                    value={`${a}`}
                    max="50"
                  />
                  <output for="a" style={{ "--c": `${a}` }}></output>
                  <label class="sr-only" for="b">
                    Value B:
                  </label>
                  <input
                    onChange={(event) => {
                      this.handleChangeB(event);
                    }}
                    id="b"
                    type="range"
                    min="-50"
                    value={`${b}`}
                    max="50"
                  />
                  <output for="b" style={{ "--c": `${b}` }}></output>
                </div>
              )}
            </div> */}
          </ul>
          </section>
        </div>
      </div>
    );
  }
}

export default Upload;