import React, { Component } from "react";
import { BrowserRouter, Switch, Route, Link } from "react-router-dom";
import routes from "../storage_apollo_routes";
import PostImage from "./PostImage";
import Favorites from "./Favorites";
import SkeletonLI from "./SkeletonLI";
import SkeletonPost from "./SkeletonPost";


class Searchv2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      display: false,
    };
  }

  toggleDisplayPreview = (id) => {
    this.setState({ display: id });
  };
  render() {
    let results = this.props.results || [];
    let posts = this.props.posts || [];
    //if (results === null || posts === null) return null;

    return (
      <div>
        <div style={{ maxHeight: "300px", overflowY: "auto" }}>
          {" "}
          {this.props.loading && <SkeletonLI/>}
          {!this.props.loading && results.map((dat) => (
            <li
              ng-repeat="user in ctrl.users"
              style={{
                backgroundImage: `linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3)),url(${routes.storage_1}${dat.user.photo})`,
              }}
              className="list2-item"
            >
              <div
                key={dat.user.id}
                style={{ height: "100px", width: "70px", position: "relative" }}
              >
                <Link
                  to={{
                    pathname: `/profile/${dat.user.id}`,
                    subbed: dat.subbed,
                    id: dat.user.id,
                  }}
                >
                  <PostImage
                    postSrc={dat.user.user_profile.photo}
                    alt="profilepic"
                    className="list2-item-image"
                  />
                </Link>
              </div>
              <div className="list2-item-content">
                <h4>{dat.user.name}</h4>
                {/* <p>{dat.user.email}</p> */}
                <p  style={{"font-size": "16px", color: "#707070"}}>{dat.count} subscribers</p>
                {dat.user.isVerified == 1 && (
                  
                    // {" "}
                   <div style={{borderRadius:"11px",backgroundColor:"red",color:"#ffff"}}className="discount skleleton-loader">
                   <i style={{paddingRight:"10px"}} className="fa fa-star" aria-hidden="true"></i>
                    verified</div> 
                 
                )}

                {/* <p>{dat.user.email}</p> */}
                {/* <p>{this.getTimeLeft(dat.user_profile.createdAt)} days elapsed</p> */}
              </div>
            </li>
          ))}
        </div>
        <div style={{ maxWidth: "900px" }}>
        <p class="logo" style={{"border-right": "0px", padding: "0px"}}>Related Posts</p> 
          <div className="container">
            <div className="gallery">
              {this.props.loading_ && <SkeletonPost/>}
              {!this.props.loading_ && posts.map((post, i) => (
                <div key={post.id} className="gallery-item">
                  {/* <img className="gallery-image"
                   
                   src={`storage/${post.post.thumbnail == ""?post.post.file:post.post.thumbnail}`}></img> */}
                  <PostImage
                    displayHandler={
                      post.thumbnail == ""
                        ? () => {}
                        : this.toggleDisplayPreview
                    }
                    isVideoPreview={this.state.display == post.id}
                    previewSrc={post.preview}
                    postId={post.id}
                    postSrc={post.thumbnail == "" ? post.file : post.thumbnail}
                  />

                  <div className="gallery-item-info">
                    <ul>
                      {false && (
                        <div
                          style={{ paddingTop: "1px" }}
                          className="gallery-item-check"
                        >
                          {" "}
                          <input
                            type="checkbox"
                            id="topping"
                            name="bulkSelect"
                            value="pi"
                            checked={false}
                            onChange={() => {}}
                          />
                        </div>
                      )}

                      {/* <button onClick={()=>getcurrentRef()}>test</button> */}
                      <div className="gallery-item-type">
                        <div onClick={() => {}}>
                          <i className="fa fa-trash-o" aria-hidden="true"></i>
                        </div>
                      </div>
                      {post.duration && post.duration != 'None' && (
                        <div
                          onClick={() => {
                            //this.toggleDisplayPreview(false)
                            if (this.state.display !== post.id) {
                              this.toggleDisplayPreview(post.id);
                            } else {
                              this.toggleDisplayPreview(false);
                            }
                          }}
                          style={{
                            opacity: "0.5",
                            color: "orange",
                            fontSize: "14px",
                            position: "relative",
                            textAlign: "center",
                            justifyContent: "center",
                          }}
                          className="gallery-item-duration"
                        >
                          <div>
                            <div
                              style={{
                                backgroundColor: "black",
                                borderBottom: "solid",
                                borderBottomColor: "brown",
                                borderRadius: "12px",
                                display: "flex",
                                height: "25px",
                              }}
                              className="duration_opaque"
                            >
                              {post.duration && post.duration != 'None'? (
                                <i
                                  style={{
                                    fontSize: "11px",
                                    paddingLeft: "5px",
                                    paddingRight: "5px",
                                    paddingTop: "5px",
                                  }}
                                  class="fa fa-stop"
                                  aria-hidden="true"
                                ></i>
                              ) : (
                                <i
                                  style={{
                                    fontSize: "11px",
                                    paddingRight: "5px",
                                    paddingLeft: "5px",
                                    paddingTop: "5px",
                                  }}
                                  class="fa fa-play-circle"
                                  aria-hidden="true"
                                ></i>
                              )}
                              <p>{post.duration}</p>
                            </div>
                          </div>
                        </div>
                      )}
                      {/* change link to onclick */}
                      <Favorites
                        changefav={() => {}}
                        queryApi={() => {}}
                        post={post.id}
                        posts={posts}
                        postCount={post.count}
                        favorited={false}
                      />
                      <li className="gallery-item-comments">
                        <span className="visually-hidden">Comments:</span>
                        <div>
                          <Link
                            onClick={() => {}}
                            //paras={{changefav:changefav,}}
                            to={{
                              pathname: `/post/${post.id.split(":")[2]}`,
                              //state:{changefav},
                              duration:
                                post.duration == 'None' ? null : post.duration,
                              // album:post.album.Posts.length > 1?post.album.Posts:false,
                              // profile: profile,
                              id: post.id.split(":")[2],
                              UserId: post.UserId,
                              src: post.file,
                              // changefav:changefav,
                              fromSearch: true,
                              preview: post.thumbnail == 'None' ? null : post.thumbnail,
                              thumbnail: post.preview,
                              isAudio: post.isAudio,
                              count: post.count,
                              isPhoto: post.thumbnail == "" ? true : false,
                              favorited: post.favorited ? true : false,
                              caption: post.caption,
                              title: post.title,
                            }}
                          >
                            <i
                              className="fa fa-comments-o"
                              aria-hidden="true"
                            ></i>
                          </Link>{" "}
                        </div>
                        <Link
                          onClick={() => {}}
                          to={{
                            pathname: `/post/${post.id.split(":")[2]}`,
                          }}
                        >{`${post.commentscount}`}</Link>
                      </li>
                    </ul>
                    {true && (
                      <div
                        className="textos"
                        style={{
                          position: "absolute",
                          textAlign: "center",
                          top: "4.3rem",
                          fontSize: "15px",
                        }}
                      >
                        <p>{post.caption}</p>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Searchv2;
