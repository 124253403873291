import React, { Component, useEffect, useRef, useState ,forwardRef} from 'react'
import Loader from './Loader';
 const PostImagePreviewCrop = (props) =>{
    //const [loading, setLoading] = useState(true);
    const child = React.createRef();
    // const children = ref

    const [val, setVal] = useState(child);
    const [openState,setOpen] = useState(true)
    const [durations,setDurations] = useState([])
    const [mimeTypes,setMimeTypes] = useState([])
    const [hasRun,setHasRun] = useState(false)








      
    



    const onLoadedHandler =  ()=>{
      
      //let type = await getMimeType(props.fileObject[i],i,isImage)
    //   props.setOpenMaster()
      setOpen(false)
    
    }

    console.log(props)
    // <PostImagePreview fileObject={processedImage} setOpenMaster={()=>{}} setFileDetails={()=>{}} type={"image"} postSrc={processedImageURL} alt="profilepic" className="list2-item-image"/>
  
    return <div >
         <div>
      <Loader isOpen={openState}/>
     
     {(props.type == "video")? <video onLoadedData={() => {
            //runDuration()
             onLoadedHandler()}} style={{display: openState ? "none" : "block"}} muted={true} autoPlay={true} width="100%" height="100%" controls>
            <source src={props.postSrc}  type="video/mp4">
            </source>
          </video>: <img
        style={{display: openState ? "none" : "block"}}
         className="gallery-image"
         src={props.postSrc}
         
         onLoad={() => {
          //runDuration()
           onLoadedHandler()

            }} />
         }
    </div>
    

          </div>
    ;
  }

  export default PostImagePreviewCrop
  