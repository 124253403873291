import React, { Component, useEffect, useRef, useState } from 'react'
import Loader from './Loader';
import routes from '../storage_apollo_routes';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
 
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});
function ProfilePic(props) {
    //const [loading, setLoading] = useState(true);
   console.log(props.id)
    const [openState,setOpen] = useState(true)
    const [pic,setPic] = useState('')

    useEffect(()=>{
      getProfilePic(props.id)
    },[props.id])

    const getProfilePic = (id) => {

           client
          .query({
            query: gql`
              query Tips {
                getProfilePic(id:${id})
              }
            `,
          }).then((result)=>{
            console.log(result)
             setPic(result.data.getProfilePic)
        
          }).catch((err)=>{
      
           
          })
        
          //clear apollo cache
        
          
    }


    return <>
     {/* <Loader isOpen={openState}/> */}
     
          <img 
          
          //'/storage/${pic}
            src={`${routes.storage_1}${pic}`}
            
           />
      
    </>;
  }
  //https://b754-154-160-9-4.ngrok-free.app${props.postSrc}

  export default ProfilePic
  