import React, { Component } from 'react';
import routes from '../storage_apollo_routes';
import format from 'date-fns/format';
import StatsControllerList from './StatsControllerList';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import Earningsgraph from '../UI/Earningsgraph';
import { roundToNearestMinutes } from 'date-fns';
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

class StatsController extends Component {
    constructor(props){
        super(props)
        this.state ={
         data:null,
         filteredData:null,
         options:null,
         ismonthly:false,
         isYearly:true,
         currentYear:null,
         monthFilterTypeData:null,
         m:null,
         dateObj:null,
         show:false,
         postTrans:[],
         stat_transactions:[],
         months:["January","February","March","April","May",
         "June","July","August","September","October","November","December"
         ]   
        }
       this.chartRef = React.createRef()
    }

    componentDidMount(){
      this.getTransactionHistory()
    }

    //getAssociatedPost =()=> x&ygetValueForPixel() ...getPostWhere id = map[id]///getPosts bought during that time period...you can get highest performing post on inspection or sor the resulting array as a popup of posts

    monthMap = (index) =>{
    if(this.state.months[index]) return this.state.months[index]
    return null
    }

    monthlyEarningTotal = (m,data,year,allowedType)=>{
        let total = 0
        for(let i in data){
        
            let convert = data[i].createdAt
            let dataYear = new Date(convert).getFullYear()
            let month = new Date(convert).getMonth()
            let monthString = this.monthMap(month)
            if(monthString == m  && dataYear == year && allowedType[data[i].type]){
                total = parseFloat(data[i].amount) + total
            }
        }
        return total
    }

    dayEarningTotal = (data,day) =>{
      let sum = 0
      for(let i in data){
        if(data[i].createdAt == day)
       sum =  parseFloat(data[i].amount) + sum 
      }
      return sum
    }
    concatDays = (data)=>{
    let map = {}
    let newDataSum = []
    let newData = []
    for(let j in data){
      if(!map[data[j].createdAt]){
       map[data[j].createdAt] = true
       let date = new Date(data[j].createdAt)
       let fmt = format(date,"yyyy-MM-dd")
       newData = newData.concat([fmt])
      }

    }
    console.log(map)
    for (let key of Object.keys(map)){
      newDataSum.push(this.dayEarningTotal(data,key))
    }
    return [newData,newDataSum]
    }

    getPostsByTrans = (transactions)=>{
      console.log("reeeeeeeeeeeee")
      client.query({
        query: gql`
        query getPostDetails($ids:[Int]){
          getPostsByTrans(ids:$ids){
            file
          }
        }
      `,
      variables:{
      ids:transactions.map((t)=>t.postID)
      }
        
      }).then((results)=>{console.log(results)
      this.setState({postTrans:results.data.getPostsByTrans})
      }).catch((e)=>console.log(e))
    }

    getAssociatedPosts = (dateString,raw)=>{
      let isSpecificDate = dateString.includes("-")

      if(isSpecificDate){
        console.log("getPostsbyDate")
        client.query({
          query: gql`
          query TransactionPosts{
            getPostTransactions(type:"day",dateString:"${dateString}"){
             createdAt
             amount
             postID
            }
          }
        `,
          
        }).then(async (result)=>{
          //console.log(data)
          this.setState({show:true,stat_transactions:result.data.getPostTransactions})
       await   this.getPostsByTrans(result.data.getPostTransactions)
        }).catch((e)=>console.log(e))
        return
      }
      console.log("getpostsbymonth")
      client.query({
        query: gql`
        query TransactionPosts{
          getPostTransactions(type:"date",dateString:"${raw+1}"){
           createdAt
           amount
           postID
          }
        }
      `,
        
      }).then(async(result)=>{
        //console.log(data)
        this.setState({show:true,stat_transactions:result.data.getPostTransactions})
        await this.getPostsByTrans(result.data.getPostTransactions)
      }).catch((e)=>console.log(e))
    }

    yearEarningTotal = (year,data,allowedType,include)=>{
     let map = allowedType
     let lbl = include
      if(!allowedType){
        map = {
          subscription:true,
          tip:true,
          referral:true,
          payperview:true
        }
      
      }
        //if year is current year split array stop time to current month
        let months = this.state.months
        let earningsArr = []
       
        let date = new Date()
        let compare = date.getFullYear()
        if(year == compare ){
            let stopAt = date.getMonth()
            months = months.slice(0,(stopAt+1))
        }
        for(let i in months){
            ///monthly array does not take the year param 
          let m = this.monthlyEarningTotal(months[i],data,year,map)
          earningsArr[i] = m
        }
//'rgb(255, 99, 132)'
        const dat = {
          labels:months,
          datasets: [
            {
              label:lbl,
              data: earningsArr,
              borderColor: "#000",
              backgroundColor: "#000",
              fill:true
            }
          ],
        };
        
        this.setState({filteredData :dat})
        
    
    }

    monthFilterType = (type)=>{

      if(this.state.monthFilterTypeData){
        let map = {}
        if(type){
        
        map[type] = true
      }
       else {
      
        map = {
          subscription:true,
          tip:true,
          referral:true,
          payperview:true
        }
      
      
      }
      
        let dat = this.state.monthFilterTypeData
        let filteredByType = dat.filter((f)=>{
           let year = new Date(f.createdAt).getFullYear()
           if(map[f.type] && year == this.state.currentYear) return true
           return false
        })

        let res = this.concatDays(filteredByType)
             const newDat = {
      labels:res[0],
      datasets: [
        {
          label:this.state.months[this.state.m],
          data: res[1],
          borderColor: "#000",
          backgroundColor: "#000",
          fill:true
        }
      ],
    };
    this.setState({filteredData:newDat})
      }
    
    }

    reset = () =>{
      const options = {
        responsive: true,
        onClick: (evt, element)=>{
          if(element.length > 0) {
            this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
            //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
            // you can also get dataset of your selected element
            // console.log(data.datasets[element[0]._datasetIndex])
          }
        },
        scales:{
          y:{
             ticks: {
                  // Include a dollar sign in the ticks
                  callback: function(value,index,ticks){
                      return `GHC ${value}`;
                  }
              }
          }
        },
        // plugins: {
        //   legend: {
        //     position: 'top'
        //   },
        //   title: {
        //     display: true,
        //     text: 'Chart.js Line Chart',
        //   },
        // },
      };
      let date = new Date()
      let currentYear = date.getFullYear(date)
      this.setState({m:null,isYearly:true,ismonthly:false,monthFilterTypeData:null,dateObj:null,options:options})
      this.yearEarningTotal(currentYear,this.state.data,false,"All")

   

      
    }

    monthFilter =(currentYear,m,dateObj)=>{
      let data = this.state.data
     let newData = data.filter((d)=>{
      let convert = d.createdAt
      let dataYear = new Date(convert).getFullYear()
      let month = new Date(convert).getMonth()
      let monthString = this.monthMap(month)
      console.log(monthString,this.monthMap(m),currentYear,dataYear)
      if(monthString == this.monthMap(m) && currentYear == dataYear) return true
      return false
     })

    //  let date = new Date(e.target.value)
    //  let currentYear = date.getFullYear()
    //  let m = date.getMonth()
    //  this.props.monthFilter(currentYear,m,e.target.value)
     let getParsedDate = (a,b)=>new Date(a,b,0).getDate()
     let dateObjM = dateObj.substring(5,7)
     let startDate = `${dateObj}-01`
     let endDate = `${dateObj}-${getParsedDate(currentYear,dateObjM,0)}`
     console.log(startDate,endDate)
     const options = {
      onClick: (evt, element) =>{
        if(element.length > 0) {
          this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
          //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
          // you can also get dataset of your selected element
          //console.log(data.datasets[element[0]._datasetIndex])
        }
      },
      responsive: true,
      scales:{
        y:{
           ticks: {
                // Include a dollar sign in the ticks
                callback: function(value,index,ticks){
                    return `GHC ${value}`;
                }
            }
        },
        x:{
          type:"time",
          min:startDate,
          max:endDate,
          time:{
            unit:"day"
          }
        }
      },
      // plugins: {
      //   legend: {
      //     position: 'top'
      //   },
      //   title: {
      //     display: true,
      //     text: 'Chart.js Line Chart',
      //   },
      // },
    };

    let forLabel = this.concatDays(newData)
    console.log(forLabel)
     const dat = {
      labels:forLabel[0],
      datasets: [
        {
          label:this.state.months[m],
          data: forLabel[1],
          borderColor: "#000",
          backgroundColor: "#000",
          fill:true
        }
      ],
    };
   
    this.setState({filteredData:dat,options:options,isYearly:false,ismonthly:true,m:m,dateObj:dateObj,monthFilterTypeData:newData})
      }
    

    filterEarningsByType =(type)=>{
   //check if monthly or yearly
      let date = new Date()
      let currentYear = date.getFullYear(date)
      if(this.state.ismonthly){
        this.monthFilterType(type)
        return
      }

      if(type){
        let map = {}
        map[type] = true

      
        this.yearEarningTotal(currentYear,this.state.data,map,type)
        return
      }

      this.yearEarningTotal(currentYear,this.state.data,false,"All")
    }
    fixOptions =()=>{
        const options = {
            responsive: true,
            onClick: (evt, element) =>{
              if(element.length > 0) {
                this.getAssociatedPosts(evt.chart.data.labels[element[0].element.$context.index],element[0].element.$context.index)
                //console.log(element[0].element.$context,element[0].element.$context.datasetIndex,element[0].element.$context.parsed,evt.chart.data.labels[element[0].element.$context.index])
                // you can also get dataset of your selected element
                // console.log(data.datasets[element[0]._datasetIndex])
              }
            },
            scales:{
              y:{
                 ticks: {
                      // Include a dollar sign in the ticks
                      callback: function(value,index,ticks){
                          return `GHC ${value}`;
                      }
                  }
              }
            },
            // plugins: {
            //   legend: {
            //     position: 'top'
            //   },
            //   title: {
            //     display: true,
            //     text: 'Chart.js Line Chart',
            //   },
            // },
          };

          this.setState({options:options})
    }



    getTransactionHistory = ()=>{
        //add post id to transaction
        client.query({
            query: gql`
            query TransactionHistory{
              getTransactionHistory{
               createdAt
               amount
               type
              }
            }
          `,
            
          }).then((result)=>{
            console.log(result.data.getTransactionHistory)
            let date = new Date()
            let currentYear = date.getFullYear(date)
            this.setState({data:result.data.getTransactionHistory,currentYear:currentYear})
            
            this.yearEarningTotal(currentYear,result.data.getTransactionHistory,false,"All")
            this.fixOptions()
          }).catch((error)=>{
            console.log(error)
          })
    }

    render() {
        if(!this.props.displayStatsController) return null
        return (
            <div style={{zIndex:"99",position:"relative"}}>
                                  <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
                                    <StatsControllerList getPostsByTrans={this.getPostsByTrans} postTrans={this.state.postTrans} show={this.state.show} transactions={this.state.stat_transactions}/>
                               {this.state.filteredData && <Earningsgraph  toggleEarnings={this.props.toggleEarnings} reset={this.reset}cleanData={this.state.data} monthFilter ={this.monthFilter} filterEarningsByType={this.filterEarningsByType} options={this.state.options}  data={this.state.filteredData}/>}
                               {/* <ul className="list2">
                                </ul> */}
                                </div>
            </div>
        );
    }
}

export default StatsController;