//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery, resetApolloContext} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import Playlist from './Playlist';
const ath = localStorage.getItem("userID")


const httpLink = new HttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
});

const wsLink = new GraphQLWsLink(createClient({
  url: "ws://localhost:4000/graphql",
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const ALERTS = gql`
  subscription AudioGet($toUser:Int!) {
    audioList(toUser:$toUser){
   songs{
    id
    title
    file
    thumbnail
   }


    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});


const Audiomaster = (props)=>{
//   let renders = 0
//   console.log(ath,renders)




const [alerts,setAlerts] = useState([])
const { data, loading} = useSubscription(
  ALERTS,{ variables: { toUser:parseInt(ath) },client:client });
  //toast.configure();
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }
const mapType = (param)=>{
  let types = {
    message:"fa-message",
    favorited:"fa-heart",
    payment:"fa-usd",
    withrawal:"fa-check",
    comment:"fa-reply"
  }
  return types[param]
}
// useEffect(()=>{

//   if(data){
//     console.log(data)
//     props.append(data)
//     //setAlerts(alerts.concat([data]))
    
//   }
//   console.log("alerts",data)

// },[]



// const alert =()=> {
  console.log("alerts",data,props)

  

  // setAlerts(prevdata =>[...prevdata,data])
if(data){
 return (  <div>

  <Playlist songList={data.audioList.songs}/> 
  </div> )    

}
else {
  return <small className="notification-badge">
  {/* {data && data.alertAdded.message}  */}
  
  </small>

}

  
// }

}
export default Audiomaster