import { methodOf } from 'lodash';
import routes from '../storage_apollo_routes';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3'
import Loader from './Loader';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import RealPay from '../Controllers/RealPay';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const link = createHttpLink({
  uri:routes.apollo ,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class OTPPopup extends Component {
  constructor(props){
    super(props);
  this.state={
   contact:"",
    completed:false,
    open:this.props.isOpen,
    errorMsg:"",
    iMO:false,
    loaderState:false,
    message:null,
    price:null,
    
  
  }
    //this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    //this.setPrice = this.setPrice.bind(this)
  }

  


  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  unlockOTP = (id,method)=>{
    const {contact,message,price} = this.state
    let msg = (!message)?"":message
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(pattern && this.props.price){
      this.setState({loaderState:true})
      client
    .mutate({
      mutation: gql`
        mutation Tips {
          unlockOTP(postID:${id},contact:"${contact}",method:"${method}")
        }
      `,
    }).then((result)=>{
      
      // if(result.data.unlockOTP.includes("SUCCESSFUL")){
      //   this.setState({completed:true,loaderState:false})
      //   let iii = result.data.unlockOTP.split(" ")
      //   console.log(iii[2],"booooom")
      //   this.props.complete(iii[2])
      //   this.createError(iii[1])
      //   //
      //    }
      if(result.data.unlockOTP.SUCCESSFUL){
//{SUCCESSFUL:true,externalId,file}
this.setState({completed:true,loaderState:false})
      this.props.complete(result.data.unlockOTP.files)
        this.createError(result.data.unlockOTP.externalId)
      }
         else{
          
          // this.props.updateProfileParams(amount)
        
          this.createError(result.data.unlockOTP.error)
          this.setState({loaderState:false})
         }
  
    }).catch((err)=>{
      this.createError("Internal Server Error")
      this.setState({loaderState:false})
    })
    }
   
  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}


closeModal() {
  this.setState({iMO:false})
  
  //this.update();
}


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
        this.setState({loaderState:false})
      }
    }
   
    
    render() {
     const {completed,amount,errorMsg} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div  className="boxxxx">
        
        <div style={{zIndex:"11"}}className="cenceph_pure_black">
       
        
        <p> Unlock this Post for Ghc {this.props.price} {(this.props.UserId == localStorage.getItem("userID") &&<span><br/>(You own this Post!)</span>)} </p>
        <div className="tooltip">?
<i className="tooltiptext">Only MTN numbers in the format 233XXXXXXXXX allowed<br/>Check your Approvals on *170#</i>
</div>

       {!this.state.loaderState &&  <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>}
      <div style={{display:"block"}}className="wrapp">
          {/* change to create subscription bundle */}
          <Popup3 OTP={true} isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
          <Loader isOpen={this.state.loaderState}/>
<div> 
  


</div>
{/*<div>
<input onChange={(e)=>this.contactChosen(e)} placeholder="eg. 233XXXXXXXXX"  type="text" />
</div>

 
      {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.unlockOTP(this.props.id,"mtn")}>unlock</button></div>}*/}
          
          {!completed && <RealPay price={this.props.price} deposit={()=>this.unlockOTP(this.props.id,"paystack")} className={"wpc-btn wpc-btn-default-rounded"}/>}
  
  
  
 
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div>  //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default OTPPopup;