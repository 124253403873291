import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter,Route,Switch,Layout} from 'react-router-dom'
import Request from './UI/Request';
import Analytics from './Controllers/AnalyticsController';
import Audiomaster from './Controllers/Audiomaster';
import SearchPage from './UI/SearchPageFFC';
// import AudioPlayer from './Controllers/AudioPlayer';
import Register from './Register_final';
import Landing from './Landing';
//import './index.css';
import LeCropper from './Controllers/LeCropper';
import ImageResize from './Controllers/ImageResize';
import Playlist from './Controllers/Playlist';
import './App.css'
import Payment from './UI/Payment';
import Feed from './UI/Feed';
import App from './App';
import Homepage from './UI/Homepage';
import Posts from './UI/Posts';
import Posts_tags_replaced from './UI/Posts_tags_replaced';
import Post from './UI/Post';
import Upload2 from './UI/Upload2';
import Profile from './UI/Profile'
import Chatwith from './Controllers/Chatwith';
import AudioPlayer from './Controllers/AudioPlayer';
import SignUp from './SignUp';
import Dashboard from './Controllers/dashboard';
import reportWebVitals from './reportWebVitals';
import Minimenu from './Controllers/Minimenu';
import Alerts from './Controllers/Alerts';
import Alertswithpagination from './Controllers/Alertswithpagination';
import Profile2 from './UI/Profile2';
// import Register from './Register';
// import Register from './RegisterProgressive';
import Alerts3 from './Controllers/Alerts3';
//import SearchPage from './UI/SearchPage';
import Upload3 from './UI/Upload3';
import Statements from './Controllers/Statements';
import OTPPost from './UI/OTPPost';
import Alerts2 from './Controllers/Alerts2';
import TextPost from './UI/TextPost';
import { ToastContainer } from 'react-toastify';
// import StatsControllerList from './Controllers/StatsControllerList';
import { useEventTracker, EventTrackerContext } from './Controllers/EventTrackerContext';
import trackEvent from './Controllers/trackEvent';
const root = ReactDOM.createRoot(document.getElementById('root'));
// const trackEvent = (event, component, state) => {
//   console.log('Event:', event);
//   console.log('Component:', component);
//   console.log('State:', state);
// };
root.render(
  // <React.StrictMode>
  //   <App />
  // </React.StrictMode>
  
  <BrowserRouter> 
 <ToastContainer/>

  <Switch>

  <Route render ={(props)=><SignUp{...props}/>} exact path = "/login"/>
  <Route render ={(props)=><Register{...props}/>} exact path = "/register"/>
  <Route render ={(props)=><Landing{...props}/>} exact path = "/"/>
    <Route render={(props)=><Payment{...props}/>} exact path="/pay"/> 
    <Route render={(props)=><Request{...props}/>} exact path="/request/:id"/> 
  
    <>
    <EventTrackerContext.Provider value={trackEvent}>
    <Audiomaster/>
    <Alerts3/>
    <Alerts2/>
     <Dashboard/> 
      <Minimenu/>
      

      {/* <Alerts /> */}
      <Route render={(props)=><Chatwith{...props}/>} exact path="/chatwith/:id"/>
      <Route render={(props)=><Playlist{...props}/>} exact path="/playlist"/>
      <Route render={(props)=><Alertswithpagination{...props}/>} exact path="/alerts"/>
      <Route render={(props)=><TextPost{...props}/>} exact path="/proto"/>
      <Route render={(props)=><SearchPage{...props}/>} exact path="/search"/>
      <Route render={(props)=><Statements{...props}/>} exact path="/statements"/>
      <Route render={(props)=><ImageResize{...props}/>} exact path="/cropper"/>
      <Route render={(props)=><LeCropper{...props}/>} exact path="/cropper2"/>
      <Route render={(props)=><Homepage{...props}/>} exact path="/homepage"/>
      <Route render={(props)=><Profile{...props}/>} exact path="/profile/:id"/>
    <Route render={(props)=><Upload3{...props}/>} exact path="/upload"/>
    {/* <Route render={(props)=><StatsControllerList{...props}/>} exact path="/scl"/> */}
    <Route render={(props)=><Post{...props}/>} exact path="/post/:id"/>
    <Route render={(props)=><Analytics{...props}/>} exact path="/anal"/>
    <Route render={(props)=><OTPPost{...props}/>} exact path="/OTpost/:id"/>
    {/* <Route render={(props)=><Dashboard{...props}/>} exact path="/dashboard">

    </Route> */}
     {/* <Homepage/> */}
    <Route render={(props)=><Posts_tags_replaced{...props}/>} exact path="/posts"/>
    <Route render={(props)=><Feed{...props}/>} exact path="/feed"/>
    <Route render={(props)=><Profile2{...props}/>} exact path="/userdat/:id"/>
    </EventTrackerContext.Provider>
    </>

  </Switch>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
