import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import routes from '../storage_apollo_routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setContext } from '@apollo/client/link/context';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
    //  "Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class PostlinkDropdown extends Component {
  constructor(props){
    super(props);
  this.state={
    amount:'',
    date:'',
    open:this.props.isOpen,
    copied:false,
     
  
  }
    // this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    // this.submitnumber = this.submitnumber.bind(this)
  }


  
  trackEvent = async () =>{
    let saveShare = gql`
mutation trackMaster{
    trackShares
}`

    await client.mutate({
        mutation:saveShare,
    
    }).then((response)=>{
        console.log(response.data.saveShare)
    }).catch((error)=>{
        console.log(error)
    })
            
        }





    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.setState({copied:false})
        this.props.onClose()
      }
    }
   
    
    render() {
     
      if (!this.props.isOpen )
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div style={{backgroundColor:"#86a79f"}} className="cenceph_pure_black">
       {!this.props.OTP && <p><h1 style={{fontSize:"18px",fontWeight:"2000"}}>{this.props.error}</h1></p>}
        
        {<CopyToClipboard text={`${routes.uhsoka}Otpost/${this.props.postID}`}><p  onClick={()=>{
          this.trackEvent()
          this.setState({copied:true})
          
          }} ><h1 style={{color:"#4a7ea3",fontSize:"23px",fontWeight:"2000",cursor:"pointer"}}>{(this.state.copied)?"Copied!":"Copy Link"}</h1> <br/><span style={{color:"#4a7ea3"}}></span></p></CopyToClipboard>}
        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div style={{display:"block"}} className="wrapp">
      <span style={{color:"#4a7ea3",fontSize:"17px"}}>

      <p style={{color:"#ffff"}}>OR</p>
      </span>
        
          {/* change to create subscription bundle */}
      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />
      <input onChange={this.datechosen}  value={this.state.date} type="number" min="1" max="100" /> */}
      {<Link to={{pathname:`/Otpost/${this.props.postID}`}}><div className="cont"><button className="wpc-btn wpc-btn-default-rounded" >View Locked Post</button> </div></Link>}
  
  
  
  
  
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default PostlinkDropdown;