import "../UI/Styles/OTDropdown.css"
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import React,{useState,useEffect} from "react";
import { setContext } from '@apollo/client/link/context';
import Popup3 from "./Popup3";
import CopyToClipboard from "react-copy-to-clipboard";
import routes from "../storage_apollo_routes";
const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    
    return {
      headers: {
       // "Apollo-Require-Preflight": "true",
        "X-Pinggy-No-Screen":"true"
      },
    };
  });
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink.concat(link),
  });

let saveShare = gql`
mutation trackMaster{
    trackShares
}`
const OTDropdown = ({postID,UserId,onCopy}) => {

    const [dropdown,showDropdown] = useState(false)
    const [copy,setCopy] = useState(false)
    const [openState,setOpenState] = useState(false)
    const [error,setError] = useState(null)

    const trackEvent = async () =>{
        await client.mutate({
            mutation:saveShare,
        
        }).then((response)=>{
            console.log(response.data.saveShare)
        }).catch((error)=>{
            console.log(error)
        })
                
            }

    
    if(UserId == parseInt(localStorage.getItem("userID"))){
        return ( <div>
           
            <div className="container">
            {/* <Popup3 onClose={()=>setOpenState(!openState)} error={error} isOpen={openState} /> */}
       <div className={`more ${(dropdown)?"show-more-menu":""}`}>
           <button onClick={()=>showDropdown(!dropdown)} id="more-btn" className="more-btn">
               <span className="more-dot"></span>
               <span className="more-dot"></span>
               <span className="more-dot"></span>
           </button>
           <div aria-hidden={dropdown} className="more-menu">
               <div className="more-menu-caret">
                   <div className="more-menu-caret-outer"></div>
                   <div className="more-menu-caret-inner"></div>
               </div>
               <ul className="more-menu-items" tabindex="-1" role="menu" aria-labelledby="more-btn" aria-hidden="true">
                   {/* <li className="more-menu-item" role="presentation">
                       <button type="button" className="more-menu-btn" role="menuitem">Share</button>
                   </li> */}
                   <li className="more-menu-item" role="presentation">
                   <CopyToClipboard text={`${routes.uhsoka}Otpost/${postID}`}>
                    <button style={{"font-size": "9px"}} className="more-menu-btn" onClick={()=>{
                    console.log("REFN")
                    setCopy(true)
                    onCopy("You have copied the link")
                    
                    trackEvent()
                    // setError("You can share the link now!")
                    // setOpenState(true)
                    }}>{(copy)?"Copied Link!":"Copy Link"}</button></CopyToClipboard>
                   </li>
                   {/* <li className="more-menu-item" role="presentation">
                       <button type="button" className="more-menu-btn" role="menuitem">Embed</button>
                   </li>
                   <li className="more-menu-item" role="presentation">
                       <button type="button" className="more-menu-btn" role="menuitem">Block</button>
                   </li>
                   <li className="more-menu-item" role="presentation">
                       <button type="button" className="more-menu-btn" role="menuitem">Report</button>
                   </li> */}
               </ul>
           </div>
       </div>
   </div>
       </div> );
   }
}
   

export default OTDropdown;