import React, { Component } from 'react';
import  {Line,Ar}   from 'react-chartjs-2'
import 'chartjs-adapter-date-fns';
import "./Styles/Earningsgraph.css"
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    Filler
  } from 'chart.js/auto';
  
ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    TimeScale,
    Tooltip,
    Legend,
    Filler
  );

  //works w statscontroller

class Earningsgraph extends Component {
    constructor(props){
        super(props)
        this.state ={
            options:null,
            active:[true,false,false,false,false],
            monthState:false,
            currentMonth:""
        }
      this.buttonRefs = this.state.active.map((a)=>React.createRef())


    }
    
    componentDidMount(){

    }

    handleDataPointClick = (i,event) =>{
        console.log("yuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuuu")
        // if (this.chartRef.current) {
        //     const chart = ChartJS.getChart(this.chartRef.current)
        //     const clickedElements = chart.getElementsAtEventForMode(event, 'y',{axis: 'x', intersect: false}, true)
        //     if (clickedElements.length > 0) {
        //       console.log(clickedElements[0].index,clickedElements[0]._model.datasetLabel) // Here clicked label | data index
        //     }
        //    }
    }

    resetEarnings = ()=>{
        if(this.state.monthState){
            this.props.reset()
            this.setState({monthState:false,active:[true,false,false,false,false]})
        }
       
    }
changeMonth(e){
    console.log(e.target.value)
    let date = new Date(e.target.value)
    let currentYear = date.getFullYear()
    let m = parseInt(e.target.value.substring(5,7))-1
    this.setState({active:[true,false,false,false,false],monthState:true})
    this.props.monthFilter(currentYear,m,e.target.value)
}
  isActive =(type) =>{
    let newState = [false,false,false,false,false]
   let typeMap = {
    all:0,
    tip:1,
    payperview:2,
    referral:3,
    subscription:4
   }
   console.log(typeMap[type] )
newState[typeMap[type]] = true
this.setState({active:newState})
  }

    render() {
        return (
            <div style={{backgroundColor:"purple",borderRadius:"12px"}}>
                <div style={{position:"relative"}}>
                          {<i style={{fontSize:"13px"}}onClick={()=> this.props.toggleEarnings()} className="fa fa-times" aria-hidden="true"></i>}
                          </div>
                <button ref={this.buttonRefs[0]} className={`stat-btn-hover ${(this.state.active[0])?"color-2":"color-1"}`} onClick={()=>{
                    this.isActive("all")
                    this.props.filterEarningsByType(false)}}>all</button>
                <button ref={this.buttonRefs[1]} className={`stat-btn-hover ${(this.state.active[1])?"color-2":"color-1"}`} onClick={()=>{
                    this.isActive("tip")
                    this.props.filterEarningsByType("tip")}}>tips</button>
                <button ref={this.buttonRefs[2]} className={`stat-btn-hover ${(this.state.active[2])?"color-2":"color-1"}`} onClick={()=>{
                    this.isActive("payperview")
                    this.props.filterEarningsByType("payperview")}}>ppvs</button>
                <button ref={this.buttonRefs[3]} className={`stat-btn-hover ${(this.state.active[3])?"color-2":"color-1"}`} onClick={()=>{
                    this.isActive("referral")
                    this.props.filterEarningsByType("referral")}}>referrals</button>
                <button ref={this.buttonRefs[4]}  className={`stat-btn-hover ${(this.state.active[4])?"color-2":"color-1"}`} onClick={()=>{
                    this.isActive("subscription")
                    this.props.filterEarningsByType("subscription")}}>subs</button>
                    <input onChange={(e)=>this.changeMonth(e)} value={this.state.currentMonth} type="month"/>
                    {this.state.monthState &&<button onClick={()=>this.resetEarnings()}>reset</button>}
<Line ref={this.props.chartRef} getElementAtEvent={
    (i,event) =>{
        // if (this.props.chartRef.current) {
        //     const chart = ChartJS.getChart(this.props.chartRef.current)
        //     const clickedElements = chart.getElementsAtEventForMode(event, 'y',{axis: 'x', intersect: false}, true)
        //     if (clickedElements.length > 0) {
        //       console.log(clickedElements[0].index,clickedElements[0]._model.datasetLabel) // Here clicked label | data index
        //     }
        //    }
    }
} options={this.props.options} data={this.props.data} />;
            </div>
        );
    }
}

export default Earningsgraph;