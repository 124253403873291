import React from "react";
const SelectAndCropControls = ({
    isLeftDisabled, // enable/disable to left button
    isRightDisabled, // enable/disable to right button
    isTopDisabled, // enable/disable to top button
    isBottomDisabled, // enable/disable to bottom button
    flipImageVertically, // allows user to flip image verically
    flipImageHorizontally, // allows user to flip image horizontally
    pushResizableToLeft, // method to push resizable div to left
    pushResizableToRight, // method to push resizable div to right
    pushResizableToTop, // method to push resizable div to top
    pushResizableToBottom, // method to push resizable div to bottom
    resetData, // method to reset all data
    onRatioChange, // method to handle ratio change
    ratioValue // used to add active styles to the checked ratio button
}) => {
return (
<div className="controls-wrapper">
<div className="controls">
<div className="control-col">
<div className="btn-group">
<input
type="radio"
name="aspect-ratio"
value="1:1"
id="firstRatio"
checked={ratioValue === "1:1"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Ratio 1 / 1"
htmlFor="firstRatio"
>
1:1
</label>
<input
type="radio"
name="aspect-ratio"
value="3:2"
id="secondRatio"
checked={ratioValue === "3:2"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Ratio 3 / 2"
htmlFor="secondRatio"
>
3:2
</label>
<input
type="radio"
name="aspect-ratio"
value="4:3"
id="thirdRatio"
checked={ratioValue === "4:3"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Ratio 4 / 3"
htmlFor="thirdRatio"
>
4:3
</label>
<input
type="radio"
name="aspect-ratio"
value="16:9"
id="forthRatio"
checked={ratioValue === "16:9"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Ratio 16 / 9"
htmlFor="forthRatio"
>
16:9
</label>
<input
type="radio"
name="aspect-ratio"
value="16:10"
id="fifthRatio"
checked={ratioValue === "16:10"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Ratio 16 / 10"
htmlFor="fifthRatio"
>
16:10
</label>
<input
type="radio"
name="aspect-ratio"
value="free"
id="sixthRatio"
checked={ratioValue === "free"}
onChange={onRatioChange}
/>
<label
className="btn btn-primary on-top"
data-tool-tip="Free ratio"
htmlFor="sixthRatio"
>
Free
</label>
</div>
</div>
<div className="control-col">
<div className="btn-group">
<button
className="btn btn-primary on-top"
data-tool-tip="To left"
disabled={isLeftDisabled}
onClick={pushResizableToLeft}
>
<i className="fas fa-arrow-left"/>
</button>
<button
className="btn btn-primary on-top"
data-tool-tip="To right"
disabled={isRightDisabled}
onClick={pushResizableToRight}
>
<i className="fas fa-arrow-right"/>
</button>
<button
className="btn btn-primary on-top"
data-tool-tip="To top"
disabled={isTopDisabled}
onClick={pushResizableToTop}
>
<i className="fas fa-arrow-up"/>
</button>
<button
className="btn btn-primary on-top"
data-tool-tip="To bottom"
disabled={isBottomDisabled}
onClick={pushResizableToBottom}
>
<i className="fas fa-arrow-down"/>
</button>
</div>
</div>
<div className="control-col">
<div className="btn-group">
<button
className="btn btn-primary on-top"
data-tool-tip="Flip horizontally"
onClick={flipImageHorizontally}
>
<i className="fas fa-arrows-alt-h"/>
</button>
<button
className="btn btn-primary on-top"
data-tool-tip="Flip vertically"
onClick={flipImageVertically}
>
<i className="fas fa-arrows-alt-v"/>
</button>
</div>
</div>
<div className="control-col">
<div className="btn-group">
<button
className="btn btn-primary on-top"
data-tool-tip="Reset"
onClick={resetData}
>
<i className="fas fa-sync-alt"/>
</button>
</div>
</div>
</div>
</div>
);
};

export default SelectAndCropControls