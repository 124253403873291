import React from "react";
import "./Styles/Skeleton.css";
function SkeletonPost() {
  return (
    <>
     {/* <p class="logo" style={{"border-right": "0px", padding: "0px"}}>Related Posts</p> */}
    
      {Array(8)
        .fill()
        .map((item,index)=>   <div className="gallery-item">       <div 
        //           onMouseOver={ ()=>{if(props.displayHandler){
        //             console.log("yeah")
        // props.displayHandler(props.postId)
        //           } }}
        //           onTouchStart={ ()=>{if(props.displayHandler){
        //             console.log("yeah")
        // props.displayHandler(props.postId)
        //           } }}
              
                   style={{display: "block",height:"100%"}}
                    // className="gallery-image skeleton-loader"
                    
                    
                     >
                      <img  className="gallery-image skeleton-loader" style={{width:"100%",height:"300px"}}></img>
                        <p></p>
                     </div></div>)}</>
   
  );
}

export default SkeletonPost;
