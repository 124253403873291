import {React,useState,useEffect,createRef,forwardRef,useImperativeHandle} from "react"
import Loader from "./Loader";
import ComposeFormReply from "./ComposeFormReply";
import routes from "../storage_apollo_routes";
import { setContext } from "@apollo/client/link/context";
import './Styles/Comments.css'
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    gql,
    useQuery,
    updateQuery,
    useMutation
  } from "@apollo/client";
  import { offsetLimitPagination } from "@apollo/client/utilities";
  const authLink_1 = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        "X-Pinggy-No-Screen": "true",
      },
    };
  });
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache({
        
    //   typePolicies: {
    //     Query: {
    //       fields: {
    //         getComments: offsetLimitPagination(),
    //         //  keyArgs:false,
    //         //  merge(existing=[],incoming){
    //         //    return [...existing,...incoming]
    //         //  },
    //       },
    //     },
    //   },
    }),
    link:authLink_1.concat(link),
    defaultOptions : {
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'ignore',
        },
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
      }
  });
  const fetch = gql`
  query getComments($id:Int!,$referenceID:Int,$limit:Int,$offset:Int) {
    getComments(id:$id,referenceID:$referenceID,limit:$limit,offset:$offset) {
      text
      UserId
      PostId
      id
      createdAt
    }
  }`
const delete_ = gql`
mutation deleteComment($CommentId:Int!){
  deleteComment(CommentId:$CommentId)
}`
const Comments = forwardRef((props,ref)=>{
    // comments:[],
    // postId:this.props.post,
    // counter:false,
    // replies:{},
    // selected:false,
    // isLoading:true

    const [selected,setSelected] = useState(false)
    const [postId,setPostId] = useState(props.post)
    const [counter,setCounter] = useState(false)
    const [replies,setReplies] = useState({})
    const [isLoading,setLoading] = useState(true)
    const [isLoadingBottom,setLoadingBottom] = useState(false)
    const [comments,setComments]= useState([])
    const [limit,setLimit] = useState(4)
    const [offset,setOffset] = useState(0)
const [isBeingDeleted,setIsBeingDeleted] = useState(false)
    const selectorRef = createRef();
    let checkem = client.readQuery({query:fetch,variables:{
        id:props.post,
        limit:limit,
        offset:offset,
       referenceID:null
        }})
    console.log(checkem)
    if(checkem){

      if(checkem.getComments[0]){
        if(checkem.getComments[0].PostId != props.post ){
            console.log(checkem.getComments[0].PostId != props.post,props.post,checkem.getComments[0].PostId)
          //console.log(checkem.getPosts[0].post.UserId,user)
        
         // queryApi()
         client.cache.reset()
      }
      }
      else{
        client.cache.reset()
      }
      
       }

    // const scrollRef = React.createRef(null);
    useEffect(() => {
            if(props.highlight){
          getSingleComment(props.highlight)
        
        }
        updateTable(null)
    
      }, []);


const { fetchMore, data, error } = useQuery(fetch, {
    variables: {
      offset: offset,
      limit: limit,
      id: postId,
      referenceID:null
    },
    client: client,
  });


  const loadNew = async()=>{
    setLoadingBottom(true)
    await fetchMore({
        variables: {
          offset: offset,
          limit: limit,
          id: postId,
        },
      });
      if(data){
        if(data.getComments.length > 0){
            setLimit(data.getComments.length*2)
            setOffset(comments.length+data.getComments.length)
            let newComments = comments.concat(data.getComments)
            setComments(newComments)
        }
       
        setLoadingBottom(false)
      }
  }

  const newCommentHandler = async(newComment)=>{

    //       setLimit(data.getComments.length*2)
    //setOffset(data.getComments.length)
  //  setOffset(offset)
  //  setLimit(limit)
let prevComments = comments
let replace = [...prevComments,newComment]
console.log(replace,replace.length)
  await client.cache.reset()

    client.cache.writeQuery({ query: fetch,data:{getComments:replace},variables: {
      offset: 0,
      limit:replace.length ,
      id: postId,
      referenceID:null
    } } );   



    // let cdc =  client.readQuery({query:fetch,variables:{
    //   id:postId,
    //   limit:limit,
    //   offset:offset,
    //  referenceID:null
    //   }})
    
    // console.log(cdc)
    setComments(replace)


    // client.cache.updateQuery({ query: fetch }, (data) => ({
    //   getComments: [...data.getComments,newComment],
    // }));
  }
 
  const deleteHandler = (id,index)=>{
     //0,4
  

    let newComments = comments.filter((comment)=>comment.id != id)
    console.log(newComments)

    client.cache.reset()

    client.cache.writeQuery({ query: fetch,data:{getComments:newComments},variables: {
      offset: 0,
      limit:newComments.length ,
      id: postId,
      referenceID:null
    } } );   

    // let cdc =  client.readQuery({query:fetch,variables:{
    //   id:postId,
    //   limit:limit,
    //   offset:offset,
    //  referenceID:null
    //   }})
    
    // console.log(cdc)
    setIsBeingDeleted(false)
    setComments(newComments)

   
    //cacheHandler
    // client.resetStore()
    // setLimit(limit)
    // setOffset(offset)
    // client.cache.writeQuery({ query: fetch,data:{getComments:cdc },variables: {
    //   offset: index+1,
    //   // limit: limit*2,
    //   id: postId,
    //   referenceID:null
    // } } );

    // client.cache.modify({id:client.cache.identify(fetch),fields:{
    //   getComments(){
    //     return newComments
    //   }
    // }})
    // setComments(newComments)
   
  }

    const bottomHandler = ()=>{
       loadNew()
       console.log(limit,offset,postId)
    }

   const reply = (param) =>{
        if(param == selected){
          setSelected(false)
          return
        }
        setSelected(param)
      }
          const getSingleComment = (param) =>{
            let singleComment = client
            .query({
              query: gql`
                query getComment {
                  getSingleComment(id:${param}) {
                    text
                    UserId
                    id
                    createdAt
                    PostId
                  }
                }
              `,
            }).then((result)=>{
              console.log("state changed updatecomments",result.data.getSingleComment)
              setComments([result.data.getSingleComment])
            //   this.setState({comments:[result.data.getSingleComment]})
            }).catch((error)=>{
              //error popup here
              console.log(error)
            })
    
        }
    
        /**
         * Gets the replies for a specified comment
         * @param {integer} postId - The id of the post that contains the comment
         * @param {integer} referenceID - The id of the comment ehose replies are requested
         */
    const getReplies = (postId,referenceID)=>{
        //have to separate function in resolver
        console.log("referenceID")
     let query = client
      .query({
        query: gql`
          query getCommentsRpl {
            getReplies(id:${postId},referenceID:${referenceID}) {
              text
              UserId
              id
              createdAt
              PostId
              
            }
          }
        `,
      })
      .then((result) => {//console.log(result)
        console.log("state changed updatecomments",result.data.getReplies)
        if(result.data.getReplies.length > 0){
          let newState = {...replies}
          newState[referenceID] = result.data.getReplies
          setReplies(newState)
         //this.setState({replies:newState})
        // console.log(this.state.replies)
        }
    //console.log(this.selectorRef.current)
      //console.log(document.getElementById(`${referenceID}`))
    
    })
      .catch((err) => {
        console.log(err);
        //this.setState({ redirec: !this.state.redirect });
      });
    }
        const updateComment=(param)=>{
         //this.setState({comments:this.state.comments.concat(param)})
         updateTable()
        }
    
        /**
         * A callback for ComposeFormReply that handles replying to comments
         */

        const updateReplies =(repl,id)=>{
         let prevReplies = replies[id]
         let newReplies
         if(prevReplies.length >0){
          newReplies = [...prevReplies,repl]
         }
         else{
          newReplies = [repl]
         }
        
         let replace ={...replies}
         replace[id] = newReplies


    // client.cache.writeQuery({ query:gql`
    //   query getCommentsRpl($id:Int!,$referenceID:Int) {
    //     getReplies(id:$id,referenceID:$referenceID) {
    //       text
    //       UserId
    //       id
    //       createdAt
    //       PostId
          
    //     }
    //   }
    // `,data:{getReplies:[repl]},variables: {
    //   id: id,
    //   referenceID:id
    // } } );   

         setReplies(replace)

        }
       const handler = (reply,comment_id) =>{
    console.log("fgfgfgfghfvigfiufihfijfhofhofjofj")
        updateReplies(reply,comment_id)
          // updateTable()
          // setSelected(false)
         // this.setState({selected:false})
        } 
    
      /**
       * Gets the rest of the comments
       */
    
     
       const applyHighlight = (param) =>{
    
         return (param == props.reply )?"-highlighted":""
        }
    
        /**
         * Refreshes the comments
         */
        const updateTable=(newcomment)=> {
        //   const {postId} = this.state
          // console.log("updatetable was called",postId)
   // const increment = (newcomment)?1:0
  // setLimit(increment+limit)
  console.log("calling")
            client
            .query({
              query: gql`
                query getComments {
                  getComments(id:${postId},referenceID:${null},offset:${offset},limit:${limit}) {

                    text
                    UserId
                    PostId
                    id
                    createdAt
                  }
                }
              `,
            })
            .then((result) => {//console.log(result)
              if(newcomment){
                setLoading(false)
                setComments(comments.concat([newcomment]))
                setOffset(offset+1)
//newCommentHandler(newcomment)
              }
              else{
                setLoading(false)
                if(result.data.getComments.length >0){
                    let newcomments = comments.concat(result.data.getComments) 
                    setComments(result.data.getComments)
                    setLimit(result.data.getComments.length*2)
                    setOffset(comments.length+result.data.getComments.length)
                }
                
              }
                
               
              //this.setState({comments:result.data.getComments,isLoading:false})
        
        })
            .catch((err) => {
              console.log(err);
              //this.setState({ redirec: !this.state.redirect });
            });
        
        //console.log(this.state.comments)
    
         
          // axios.post(`/api/getcomment/${postId}`).then(response => {
          //   this.setState({
          //     comments: response.data
          //   })
            
          // })
         }

         useImperativeHandle(ref, () => ({
            updateTable
          }));
      //  getDate = (raw)=>{
      //   console.log(raw)
      //   return new Date(raw)
      //  }
     const deleteComment = async (CommentId,reply,index,comment,data_) =>{
    setIsBeingDeleted(CommentId)
    // let newDelete = useMutation(gql`
    // mutation delete{
    //   deleteComment(CommentId:${CommentId})
    // }`)
    // newDelete({
    //   update: (cache, { data }) => {
       
    //   },
    // });
       await client
        .mutate({
          mutation: gql`
            mutation delete {
              deleteComment(CommentId:${CommentId})
            }
          `,
        }).then((result)=>{
          console.log(result.data.deleteComment)
          if(reply){
          
            let replyMap = replies
            let replyArray = replyMap[reply]
            let filteredReplyArray = replyArray.filter(r=> r.id != CommentId)
           
            replyMap[reply] = filteredReplyArray
            //console.log(replies,replyMap)
            //setReplies({})
            // this.setState({replies:replyMap})
          //   client.cache.updateQuery({ query:  gql`
          //   query getCommentsRpl($id:Int!,$referenceID:Int) {
          //     getReplies(id:$id,referenceID:$referenceID) {
          //       text
          //       UserId
          //       id
          //       createdAt
          //       PostId
                
          //     }
          //   }
          // `,variables:{id:reply,referenceID:reply} }, (data) => ({
          //     getReplies: data.getReplies.filter((todo) => todo.id != CommentId),
          //   }));
          setIsBeingDeleted(false)
          // setComments(comments)
            setReplies(replyMap)
            loadNew()
            
            //setSelected(false)
    
          }
          else{
            // client.cache.updateQuery({ query: fetch ,variables:{id:postId}}, (data) => ({
            //   getComments: data.getComments.filter((todo) => {
             
            //     return todo.id !== CommentId;
            //   }),
            // }));
            setIsBeingDeleted(false)
             setComments(comments.filter((f)=>f.id !== CommentId))
            //deleteHandler(CommentId,index)
            ///updateTable(null)
          }
          
        }).catch((error)=>{
          console.log(error)
          setIsBeingDeleted(false)
        })
    
      }
    return  (
        <div onScroll={()=>bottomHandler()}  ref={selectorRef}
        className="conta">  <Loader onClose={()=>{}} isOpen={isLoading}/> {comments.map((comment,index) =>  (<div id={comment.id} key={comment.id}className="comment">
          <div className="comment-header">
            <span className="comment-user">@{comment.UserId}</span> ·{' '}
            <span className="comment-created-on">{comment.createdAt}
           </span>
           <div onClick={()=>reply(comment.id)} className="comment-content"><i class="fa fa-reply" aria-hidden="true"></i>
          </div>
          {comment.UserId == parseInt(localStorage.getItem("userID")) &&<div onClick={()=>deleteComment(comment.id,null,index,comment)} className="comment-content"><i class="fa fa-trash" aria-hidden="true"></i>
          </div>}
          </div>
          {(isBeingDeleted == comment.id)? <Loader onClose={()=>{}}  isOpen={isBeingDeleted}/>:<div onClick={()=>getReplies(comment.id,comment.id)} className="comment-content">{comment.text}
          </div>}
          {comment.id == selected && <ComposeFormReply displayComment={selected} action = {handler} referenceID={comment.id} id={postId}/>}
          {replies[comment.id] && replies[comment.id].map(reply =>  (<div id={reply.id} key={reply.id}className={`comment_reply${applyHighlight(reply.id)}`}>
          <div className="comment-header">
            <span className="comment-user">@{reply.UserId}</span> ·{' '}
            <span className="comment-created-on">{reply.createdAt}
           </span>
           {reply.UserId == parseInt(localStorage.getItem("userID")) &&<div onClick={()=>deleteComment(reply.id,comment.id,index,comment)} className="comment-content"><i class="fa fa-trash" aria-hidden="true"></i>
          </div>}
          </div>
          <div onClick={()=>getReplies(reply.id,reply.id)} className="comment-content">{reply.text}
          </div>
          
          
        </div>))}
          
        </div>))}</div>
        // {false && <div className="comment"> 
        // <div className="comment-header">
        //     <span className="comment-user">@xxx</span> ·{' '}
        //     <span className="comment-created-on">xxx</span>
           
        //     </div>
        //     <div  className="comment-content">
        //         <Loader isOpen={false}/>
        //   </div></div>}
      );
})

export default Comments