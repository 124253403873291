import React, {Component,useEffect,useState} from 'react';
import routes from '../storage_apollo_routes';
import OTDropdown from './OTDropdown';
import AudioPlayer from '../Controllers/AudioPlayer';
import Popuptip from './Popuptip';
import PostImage from './PostImage';
import AlbumSlider from './AlbumSlider';
//import apiClient from './api';
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import axios from 'axios';
import {useHistory,Redirect, Switch, BrowserRouter,Route} from 'react-router-dom';
//import Dashboard from './Dashboard';
import Composeform from './Composeform';
import Comments from './Comments3';
import Favorites from './Favorites';
import ProfilePic from './ProfilePic';
import VideoPlayer from '../Controllers/VideoPlayer';
import Ads from './AdVid';
import Popup3 from './Popup3';
import Postdropdown from './Postdropdown';
import { setContext } from '@apollo/client/link/context';
// import routes from '../storage_apollo_routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});
/**
 * Renders a single Post component with comments
 */
class Post extends Component {
  constructor(props){
    super(props);
    this.state = {
        postId:this.props.location.id,
        count:this.props.location.count,
        favorited:this.props.location.favorited,
        postsrc:this.props.location.src,
        isPhoto:this.props.location.isPhoto,
        display:false,
        title:this.props.location.title,
        caption:this.props.location.caption,
        profile:this.props.location.profile,
        UserId:this.props.location.UserId,
        isAudio:this.props.location.isAudio,
        someVar: true,
        album:this.props.location.album,
        isOpen:false,
        preview:this.props.location.preview,
        thumbnail:this.props.location.thumbnail,
        user:parseInt(localStorage.getItem("userID")),
        highlighted:false,
        reply:false,
        duration:this.props.location.duration,
        showPopup:false,
        error:null,
        isOpen_:false
      };
     
    this.handler = this.handler.bind(this)
    this.childTwo = React.createRef();
    
    
  }

  /**
   * gets the post data from the Database
   */
  getSinglePost = async () =>{
    console.log(this.props.match.params.id)
    let pst = await  client
    .query({
      query: gql`
        query Single {
          getSinglePost_m(id:${this.props.match.params.id}) {
             post{
              caption
              price
              file
              id
              thumbnail
              UserId
              duration
              preview
              isAudio
              title

             } 
             count
             favorited
            
          }
        }
      `,
    }).then((result)=>{
      let photoStatus = (result.data.getSinglePost_m.post.thumbnail == "")?true:false

        let isViewable = !(result.data.getSinglePost_m.post.file == "pngsus.png")
        // console.log(photoStatus,isViewable,api_call.post.file,133)
      let highlighted = (this.props.location.highlight_comment)?this.props.location.highlight_comment:false
      let reply = (this.props.location.reply)?this.props.location.reply:false
      const isPhotoFn = (res)=>{
        if(res.data.getSinglePost_m.post.isAudio){
          return false
        }
        if(!res.data.getSinglePost_m.post.duration){
          return true
        }
        if(res.data.getSinglePost_m.post.preview ==""){
          return true
        }
        if(res.data.getSinglePost_m.post.thumbnail ==""){
          return true
        }
        return false
      }

      //thumbnail == preview == vice v
      this.setState({
        postId:result.data.getSinglePost_m.post.id,
        favorited:result.data.getSinglePost_m.favorited,
        caption:result.data.getSinglePost_m.post.caption,
        duration:result.data.getSinglePost_m.post.duration,
        UserId:result.data.getSinglePost_m.post.UserId,
        preview:result.data.getSinglePost_m.post.thumbnail,
        thumbnail:result.data.getSinglePost_m.post.preview,
        isPhoto:isPhotoFn(result),
        postsrc:result.data.getSinglePost_m.post.file,
        count:result.data.getSinglePost_m.count,
        isAudio:result.data.getSinglePost_m.post.isAudio,
        title:result.data.getSinglePost_m.post.title,
        highlighted:highlighted,
        reply:reply
        
//"https://vmcloudinstancefr-qd5g7ivkga-wm.a.run.app/graphql"

      })
      // this.props.location.isPhoto = (result.data.getSinglePost_m.post.duration||result.data.getSinglePost_m.post.preview||!result.data.getSinglePost_m.post.thumbnail)?false:true
      // this.props.location.src = result.data.getSinglePost_m.post.file
    //return result.data.getSinglePost_m
    }).catch((error   
    )=>{
      console.log(error)
      return false
    })
    //return pst
  }

  createError = (message)=>{
    this.setState({error:message,isOpen_:true})
  }
  
   changefav = (id, mutcount, favorited) => {
    const filteredPosts = this.props.location.posts.map((mp) => {
   

      if (mp.post.id == id) {
        console.log(id, mutcount, favorited);
        let newpost = { ...mp, favorited: favorited, count: mutcount };
        mp = newpost;
        
      }

      return mp;

      return mp;
    });
    console.log(filteredPosts);

    client.cache.updateQuery({ query: fetch }, (data) => ({
      getPosts: data.getPosts.map((todo) => {
        if (todo.post.id == id) {
          let newpost = { ...todo, favorited: favorited, count: mutcount };
          todo = newpost;
        }
        
        return todo;
      }),
    }));
    //setPosts(filteredPosts);
  };
  
showPopup = ()=>{
this.setState({showPopup:!this.state.showPopup})
}

   async componentDidMount(){
    console.log(this.props)
    
      if(!this.props.location.id || this.props.fromSearch){
        let highlighted = (this.props.location.highlight_comment)?this.props.location.highlight_comment:false
        let reply = (this.props.location.reply)?this.props.location.reply:false
          this.getSinglePost()
        
        // let photoStatus = await (api_call.post.thumbnail == "")?true:false

        // let isViewable = !(api_call.post.file == "pngsus.png")
        // console.log(photoStatus,isViewable,api_call.post.file,133)
//         this.setState({
//           postId:api_call.post.id,
//           favorited:api_call.favorited,
//           caption:api_call.post.caption,
//           duration:api_call.post.duration,
//           UserId:api_call.post.UserId,
//           preview:api_call.post.preview,
//           isPhoto:(api_call.post.duration||api_call.post.preview||!api_call.post.thumbnail)?false:true,
//           postsrc:api_call.post.file,
//           count:api_call.count,
//           highlighted:highlighted,
//           reply:reply
          
// //"https://vmcloudinstancefr-qd5g7ivkga-wm.a.run.app/graphql"

//         })
      }
      console.log(this.state.profile,"profileprops yo",this.props)
      window.scrollTo(0, 0)
      //console.log("mounted",this.state.caption,this.props)
    }
/**
 * Handles rendering the image , video (or locked png if the post hasn't been paid for)
 * @param {boolean} isPhoto a Boolean that represents if the post is a photo
 * @param {string} src the Post name
 *
 */
    mediaStatus = (isPhoto,src) =>{
      console.log(this.state.preview)
      console.log(src,"source")
      console.log(isPhoto)
      if(isPhoto){
        console.log(src,"here")
        return <div><img  src={`${routes.storage_1}${src}`} ></img></div>
        //<PostImage postSrc={`/${src}`}/>
      }
      else if(!isPhoto && src !=="pngsus.png"){
  return     <Ads isAudio={this.state.isAudio} Src={src} caption={this.state.caption}  preview={this.state.preview} duration={this.state.duration}/>  
      {/* <div ><video width="100%" height="100%" controls>
          <source src={`/storage/${src}`}  type="video/mp4">
          </source>
        </video></div> */}
       
       
      
      }
      else if(!isPhoto && src =="pngsus.png"){
        return  <div><img src={`${routes.storage_1}${src}`}  ></img></div>
            }

            
    

 
    }

    /**
     * increases the likes count on the Post UI(called from the Favorites component as a callback)
     */
   increasePostCount=()=>{
    console.log(this.state.count,this.props.count)
    this.setState({count:this.state.count+1,favorited:true})
   }
   decreasePostCount=()=>{
    console.log(this.state.count,this.props.count)
    
    this.setState({count:this.state.count-1,favorited:false})
   }

   /**
    * Renders a Tip Popup
    */
    openModal =()=>{
      window.scrollTo(0, 0)
     this.setState({isOpen:true})
    }
    complete = ()=>{
      
    }
    /**
     * Closes the Tip Popup
     */
    close = () =>{
      this.setState({ isOpen: false })
    }
 
    handler(param) {
      this.setState({someVar:!this.state.someVar})
      // this.childTwo.current.updateComment(param);
      this.childTwo.current.updateTable(param);
      
    }

    // componentWillUnmount(){
    //  // this.props.scrollTo(this.state.postId)
    // }
        
 

    render(){
      const {postId,postsrc, email,count,someVar,favorited,isPhoto,profile,caption}= this.state

      if(this.state.redirect==true){return <Redirect to ="/dashboard"/>
      }
    return (<div className="post">
      {/* <AudioPlayer  ref={this.thumbInput} thumbnail={this.state.thumbnail} isEditor={true} isAudio={this.state.isAudio} details={this.state.details} audio={this.state.media} /> */}
            {this.state.isAudio && <AudioPlayer audiomap={this.state.album} title={this.state.title} thumbnail={this.state.thumbnail} audio={this.state.postsrc} isEditor={false}/>}
         
        <blockquote   className="style1">

<div className="style2">
  <div className="style3">

    
  <div className="style4">
    <Link   to={{pathname: `/userdat/${this.state.UserId}`,}}> 
    <ProfilePic id={this.state.UserId}/>
    </Link>
 
    </div>

    <div className="style5">
      {/* <p className= "style6">postcard</p> */}
       <div className="style7"></div>
    </div>

<Popup3 onClose={()=>this.setState({isOpen_:false})} error={this.state.error} isOpen={this.state.isOpen_} />
    {<div className="style8" style={{zIndex:"12",position:"absolute"}}><OTDropdown onCopy={this.createError} UserId={this.state.UserId} isPhoto={this.state.isPhoto} postID={this.state.postId}/></div> }
    
  
    {/* <div onClick={()=>this.showPopup()} className="style8">
      <div className="style9"></div>
      <div className="style9"></div>
      <div className="style9"></div>
    </div> */}
  

  </div>
  
</div>


<div className="style10">

  {this.state.album && <AlbumSlider  mediaStatus={this.mediaStatus} posts={this.state.album}/>}
  {postsrc && !this.state.album && this.mediaStatus(this.state.isPhoto,postsrc)}
</div>


<div className="style11">

 
  <div className="style12">
   

      <div className="bigger">

        <ul style={{"list-style-type": "none","left":"-0px","margin-top":"1px","position":"relative"
}}>

      
    {this.state.postId &&<Favorites post={this.state.postId} decreasePostCount={this.decreasePostCount} increasePostCount={this.increasePostCount} isPost={true} changefav={this.props.location.changefav} postCount={this.state.count} favorited={favorited? true:false}/> }
    </ul>
      </div>
    
   
    {/* <div className="style16">
    <div className="style17 "></div>
      <div className="style18"></div>
    </div> */}

    {/* <div className="style19">
      <div className="style20"></div>
      <div className="style21"></div>
      <div className="style21"></div>
      <div className="style21"></div>
    </div> */}
      {this.state.postId &&<div ><Popuptip onClose={()=>{this.close()}}  id={this.state.postId} isOpen={this.state.isOpen}/></div>}
    <div className="style22" onClick={()=>{this.openModal()}}>
      {/* <div className="style23"></div>
      <div className="style24"></div>
      <div className="style25"></div> */}
      <ul>
      <i  style={{fontSize:"22px",cursor:"pointer"}} class="fa fa-usd" aria-hidden="true"></i>
      </ul>
     
    </div>

  </div>
 
  <div className="style26">
    <div>
      {this.state.postId && <p className="style27"> <ProfilePic id={this.state.UserId}/>
        <span className="style28">   Caption:</span> {`${this.state.caption}`} </p>}
    </div>
 
    <div className="style29">
      <div className="style30"><span className="style30a">Comments</span>

      {this.state.postId && <Comments ref={this.childTwo} value={someVar} reply={this.state.reply} highlight={this.state.highlighted} post={this.state.postId}/>}

       </div>
    </div>
  </div>


  

   <div  >{this.state.postId && <Composeform action = {this.handler} id={this.state.postId}/>}</div> 
</div>
</blockquote>

      </div>
    );
    }
}

export default Post;