import React from "react";

const UploadProgress = (props)=>{
    return (
        <div style={{zIndex:"1232"}}>
              <div id="feedback">
    <span>{Math.round(props.percent)}% complete</span>
    </div>
               <label id="progress-label" for="progress"></label>
        <progress id="progress" value={props.percent} max="100"> </progress>
 
        </div>
    )
}

export default UploadProgress