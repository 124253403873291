import React, { Component } from "react";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import Loader from "./Loader";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
const logo = "iooio";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "Apollo-Require-Preflight": "true",
    },
  };
});
// const ffmpeg = createFFmpeg({
//   //   corePath: "http://localhost:3000/public/ffmpeg-core.js",
//   // Use public address if you don't want to host your own.
//   corePath: "https://unpkg.com/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
//   log: true,
// });

const link = createUploadLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link),
});

class Upload2 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: "",
      file1: false,
      file2: false,
      display: false,
      img: "",
      imgPreview: "",
      video: "",
      style: "",
      trim: "",
      src: "",
      min: -50,
      max: 50,
      a: -50,
      b: 50,
      caption: "",
      price: 0,
      spinState:false
    };
  }
  handleChangeA = (event) => {
    //console.log(event.target.id, event.target.value);
    let _t = event.target;
    let viet = event.target.value;
    _t.parentNode.style.setProperty(`--${_t.id}`, +_t.value);
    this.setState({ a: viet });
    let video = document.getElementById("vid");
    let half = video.duration / 2;
    let seekVal = -((half * viet) / 50);
    video.currentTime = half - seekVal;
    console.log(video.currentTime, video.duration, seekVal);
  };
  handleChangeB = (event) => {
    console.log(event.target.id, event.target.value);
    let _t = event.target;
    let viet = event.target.value;
    _t.parentNode.style.setProperty(`--${_t.id}`, +_t.value);
    this.setState({ b: viet });
  };

  contactChosen = (e) => {
    console.log(e.target.value);
    this.setState({ caption: e.target.value });
  };

  priceChosen = (e) => {
    this.setState({ price: e.target.value });
  };

  validateFile = (file) => {
    let allowedMimes = [
      "video/mp4",
      "video/avi",
      "video/ogg",
      "video/mov",
      "video/wmg",
      "video/3gp",
      "video/ts",
      "video/webm",
      "audio/mpeg",
    ];
    return allowedMimes.filter((a) => {
      return a === file.type;
    });
  };
  moduleRun = async (file) => {
    this.setState({ img: file, file1: file });
  };

  fileUploadHandler = () => {
    const { file1, file2, caption, price } = this.state;
    console.log(file1, "fileflelelelel");
    // console.log(file2)
    if (file1) {
      this.setState({spinState:true})
      const newPost = gql`
        mutation newPost(
          $title: String!
          $caption: String!
          $image: [Upload]
          $price: Int!
        ) {
          newPost(
            title: $title
            caption: $caption
            image: $image
            price: $price
          ) {
            caption
            title
          }
        }
      `;
      client
        .mutate({
          mutation: newPost,
          variables: {
            title: "Default",
            caption: caption,
            image: [file1],
            price: parseInt(price),
          },
        })
        .then((result) => {
          console.log(result);
          this.setState({spinState:false})
        })
        .catch((err) => {
          console.log("catch", err);
          this.setState({spinState:false})
        });
    } else {
      console.log("no file selected");
    }
  };
  fileSelectHandler = (event) => {
    let selected = event.target.files[0];
    console.log(selected);
    this.moduleRun(selected);
  };

  render() {
    return (
      <div className="container">
        {/* <div class="upload_container">
    <div class="fileUploadInput_v1"><label>"" Upload File</label><input type="file" multiple accept="image/*,audio/*,video/*"  onChange={(event) => {
                        this.fileSelectHandler(event);
                      }} /><button>+</button></div>
</div> */}
        <div className="boxxxx">
          <div className="cenceph_pure_black" style={{ top: "28%" }}>
            <p>Price and Caption Here</p>
            <div className="wrapp_block">
              {this.state.spinState && <Loader isOpen={this.state.spinState}/>}
             {!this.state.spinState && <div className="copy-link">
                <input
                  ref={this.inputField}
                  type="file"
                  onChange={(event) => {
                    this.fileSelectHandler(event);
                  }}
                  multiple
                  accept="image/*,audio/*,video/*"
                  className="copy-link-input"
                />
                <button
                  onClick={() => {
                    this.fileUploadHandler();
                  }}
                  type="button"
                  className="copy-link-button"
                >
                  <span className="material-icons">file_upload</span>
                </button>
              </div>}

              <div>
                {" "}
                <input
                  onChange={(e) => this.priceChosen(e)}
                  placeholder="eg. 10"
                  value={this.state.amount}
                  type="number"
                  min="1"
                  max="700"
                />
              </div>

              <div>
                {" "}
                <input
                  onChange={(e) => this.contactChosen(e)}
                  placeholder="default caption"
                  type="text"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <label>
                  <button onClick={() => {
                      this.fileUploadHandler();
                    }} class="icon-button"><span class="icon">📤</span></button>
                  <span class="text"></span>
                </label> */}
        {/* <input
                      type="file"
                      className="file-input"
                      onChange={(event) => {
                        this.fileSelectHandler(event);
                      }}
                    /> */}
        {/* <a class="reference" href="http://bit.ly/2JPuBjx" target="_blank">🔗 Ali Soueidan</a> */}
      </div>
    );
  }
}

export default Upload2;
