import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3'
import Loader from './Loader';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import routes from '../storage_apollo_routes';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

class Withdraw extends Component {
  constructor(props){
    super(props);
  this.state={
   contact:"",
    completed:false,
    open:this.props.isOpen,
    errorMsg:"",
    iMO:false,
    loaderState:false,
    message:null,
    amount:null
  
  }
    //this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    //this.setPrice = this.setPrice.bind(this)
  }


  componentDidMount(){

    
  }



  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  payView = ()=>{
    // client
    // .query({
    //   query: gql`
    //     query getPrice{
    //       getPpvPrice(id:${this.props.id})
    //     }
    //   `,
    // }).then((result)=>{
    //   console.log(result.data.getPpvPrice)
    //   this.setState({price:result.data.getPpvPrice})
    // }).catch((error)=>{
    //   this.createError("An error occured")
    // })

    const {contact,message,amount} = this.state
    let msg = (!message)?"":message
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(!this.props.isVerified){
      this.createError("You have to verify to withdraw money")
      return
    }
    if(pattern && amount > 5 && amount <= this.props.earnings){
      this.setState({loaderState:true})
      client
    .query({
      query: gql`
        query Tips {
          Withdraw(amount:${amount},contact:"${contact}")
        }
      `,
    }).then((result)=>{
      console.log(result.data.Withdraw)
      if(result.data.Withdraw == "SUCCESSFUL"){
        //modify earnings
        this.props.modify()
        this.setState({completed:true,loaderState:false})
        //
         }
         else{
          
          // this.props.updateProfileParams(amount)
          
          this.createError(result.data.Withdraw)
          //modify post clear it after testing
          
          this.setState({loaderState:false})
         }
  
    }).catch((err)=>{
      this.createError(`Internal Server Error:${err}`)
      this.setState({loaderState:false})
    }) 
    }
  
  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}
amountChosen = (event)=>{
  this.setState({amount:event.target.value});
  
}
// numberchosen = (event)=>{
//   this.setState({price:event.target.value})
// }

closeModal() {
  this.setState({iMO:false})
  
  //this.update();
}


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
        this.setState({loaderState:false})
      }
    }
   
    
    render() {
     const {completed,amount,errorMsg,price} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div className="cenceph_pure_black">
       
        
        <p> Enter your number and amount to be withdrawn {this.props.price}  </p>
       <div className="tooltip">?
       <i className="fa fa-question tooltiptext" aria-hidden="true"> Only MTN numbers are supported</i>
       </div>
        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp_block">
          {/* change to create subscription bundle */}
          <Popup3 isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
          <Loader isOpen={this.state.loaderState}/>

      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />  */}
{!completed &&<input onChange={(e)=>this.contactChosen(e)} placeholder="eg. 233XXXXXXXXX" type="text" />}
      {!completed &&<div><input onChange={(e)=>this.amountChosen(e)} placeholder="10 or more"  type="text" /></div>}
      {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.payView()}>withraw</button>
  
  
  
  
  </div>}
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default Withdraw;