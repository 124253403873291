import React, { Component } from 'react';
import "./Styles/TypingIndicator.css"

class TypingIndicator extends Component {
    render() {
        return (
            <div>
               <div className="typing">
  <span></span>
  <span></span>
  <span></span>
</div> 
            </div>
        );
    }
}

export default TypingIndicator;