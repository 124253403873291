import Favorites from "./Favorites";
import PostImage from "./PostImage";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import SkeletonPost from "./SkeletonPost";
import { useState } from "react";
import Discount from "./Discount";
import Payperview from './Payperview';
function Suggestions({posts,loading,changefav,modifyPost}) {
    const [displayPreview,toggleDisplayPreview] = useState(false)
    const [discount,setDiscount] = useState(null)
    const [amount,setAmount] = useState(null)
    const [selected,setSelected] = useState(null)
    const [display,setDisplay] = useState(false)



    const getTimeLeft = (dateString) =>{
      let d2= new Date(dateString)
    let d1 = Date.now()
    
    let dif= Math.abs(d2-d1);
    let d = Math.round((dif/(1000 * 3600 * 24)))
    //bundle or regular subscription
    // let days_left = 30 - d
    // console.log(d)
    return d
    }
    const togglePost = (id,price,discount) =>{
      setSelected(id)
      setDiscount(discount)
     setAmount(price)
      setDisplay(!display)
     }
    
    const calculateExpiry = (createdAt,expiry)=>{
    let timeLeft = getTimeLeft(createdAt)
    console.log(timeLeft >= expiry,expiry,timeLeft,"brehhhhhhhhhhhhhhhhhhh")
      return timeLeft  >= expiry
    }
    console.log(posts,loading)
    return (     <>     
                  <p class="logo" style={{"border-right": "0px", padding: "0px"}}>You might like</p> 
                  
         <div className="gallery">
         <Payperview discount={discount} onClose={togglePost} modify={modifyPost} id={selected} price={amount} isOpen={display}/>
    {loading && <SkeletonPost/>}
    {!loading && posts.map((post, i) => (
             
      <div key={post.post.id} className="gallery-item">

        {/* <img className="gallery-image"
         
         src={`storage/${post.post.thumbnail == ""?post.post.file:post.post.thumbnail}`}></img> */}
        <PostImage
          displayHandler={
            post.post.thumbnail == ""
              ? () => {}
              : toggleDisplayPreview
          }
          isVideoPreview={displayPreview == post.post.id}
          previewSrc={post.post.preview}
          postId={post.post.id}
          postSrc={post.post.thumbnail == "" ? post.post.file : post.post.thumbnail}
        />

        <div className="gallery-item-info">
        {post.post.Discount && <Discount timeElapsed={getTimeLeft} price={post.post.price} calculateExpiry={calculateExpiry} discount={post.post.Discount}/>}
          <ul>
            {false && (
              <div
                style={{ paddingTop: "1px" }}
                className="gallery-item-check"
              >
                {" "}
                <input
                  type="checkbox"
                  id="topping"
                  name="bulkSelect"
                  value="pi"
                  checked={false}
                  onChange={() => {}}
                />
              </div>
            )}

            {/* <button onClick={()=>getcurrentRef()}>test</button> */}
            <div className="gallery-item-type">
            <div onClick={()=>(post.post.file != "pngsus.png")?"":togglePost(post.post.id,post.post.price,post.post.Discount)}>{post.post.thumbnail == ""?<i className="fa fa-picture-o" aria-hidden="true"></i>:
(post.post.isAudio||post.post.isAudio)?<i className="fa fa-music" aria-hidden="true"></i>:<i  className="fa fa-video-camera" aria-hidden="true"></i>
}</div>
            </div>
            {post.post.duration && post.post.duration != 'None' && (
              <div
                onClick={() => {
                  //this.toggleDisplayPreview(false)
                  if (displayPreview !== post.post.id) {
                    toggleDisplayPreview(post.post.id);
                  } else {
                    toggleDisplayPreview(false);
                  }
                }}
                style={{
                  opacity: "0.5",
                  color: "orange",
                  fontSize: "14px",
                  position: "relative",
                  textAlign: "center",
                  justifyContent: "center",
                }}
                className="gallery-item-duration"
              >
                <div>
                  <div
                    style={{
                      backgroundColor: "black",
                      borderBottom: "solid",
                      borderBottomColor: "brown",
                      borderRadius: "12px",
                      display: "flex",
                      height: "25px",
                    }}
                    className="duration_opaque"
                  >
                    {post.post.duration && post.post.duration != 'None'? (
                      <i
                        style={{
                          fontSize: "11px",
                          paddingLeft: "5px",
                          paddingRight: "5px",
                          paddingTop: "5px",
                        }}
                        class="fa fa-stop"
                        aria-hidden="true"
                      ></i>
                    ) : (
                      <i
                        style={{
                          fontSize: "11px",
                          paddingRight: "5px",
                          paddingLeft: "5px",
                          paddingTop: "5px",
                        }}
                        class="fa fa-play-circle"
                        aria-hidden="true"
                      ></i>
                    )}
                    <p>{post.post.duration}</p>
                  </div>
                </div>
              </div>
            )}
            {/* change link to onclick */}
            <Favorites
              changefav={changefav}
              queryApi={() => {}}
              post={post.post.id}
              posts={posts}
              postCount={post.count}
              favorited={post.favorited}
            />
            <li className="gallery-item-comments">
              <span className="visually-hidden">Comments:</span>
              <div>
                <Link
                  onClick={() => {}}
                  //paras={{changefav:changefav,}}
                  to={{
                    pathname: `/post/${post.post.id}`,
                    //state:{changefav},
                    duration:
                      post.duration == 'None' ? null : post.duration,
                    // album:post.album.Posts.length > 1?post.album.Posts:false,
                    // profile: profile,
                    id: post.post.id,
                    UserId: post.post.UserId,
                    src: post.post.file,
                    // changefav:changefav,
                    fromSearch: true,
                    preview: post.post.thumbnail == 'None' ? null : post.post.thumbnail,
                    thumbnail: post.post.preview,
                    isAudio: post.post.isAudio,
                    count: post.post.count,
                    isPhoto: post.post.thumbnail == "" ? true : false,
                    favorited: post.post.favorited ? true : false,
                    caption: post.post.caption,
                    title: post.post.title,
                  }}
                >
                  <i
                    className="fa fa-comments-o"
                    aria-hidden="true"
                  ></i>
                </Link>{" "}
              </div>
              <Link
                onClick={() => {}}
                to={{
                  pathname: `/post/${post.post.id}`,
                }}
              >{`${post.commentscount}`}</Link>
            </li>
          </ul>
          {true && (
            <div
              className="textos"
              style={{
                position: "absolute",
                textAlign: "center",
                top: "4.3rem",
                fontSize: "15px",
              }}
            >
              <p>{post.post.caption}</p>
            </div>
          )}
        </div>
      </div>
    ))}
  </div></> );
}

export default Suggestions;