//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery, resetApolloContext} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';

const ath = localStorage.getItem("userID")


const httpLink = new HttpLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
});

const wsLink = new GraphQLWsLink(createClient({
  url: "wss://apolloserver.uhsoka.com/graphql",
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const UNREADS = gql`
  subscription Unreads($toUser:Int!,$fromUser:Int!) {
    unreadMesage(toUser:$toUser,fromUser:$fromUser){
      type
      message
      from
      UserId
    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

let renders = false
const UnreadsSingle = (props)=>{

  console.log(props.id,renders)




const [unreads,setUnreads] = useState([])
const { data, loading} = useSubscription(
  UNREADS,{ variables: { toUser:parseInt(ath),fromUser:props.id },client:client });
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }
const mapType = (param)=>{
  let types = {
    message:"fa-message",
image:"fa-picture",
video:"fa-video"
  }
  return types[param]
}
// useEffect(()=>{

//   if(data){
//     console.log(data)
//     props.append(data)
//     //setUnreads(unreads.concat([data]))
    
//   }
//   console.log("unreads",data)

// },[]



// const alert =()=> {
 // index={j} type={"subscription"}changeActive={this.changeActive} active={this.state.subscriptionArr[j]}



  // setUnreads(prevdata =>[...prevdata,data])
if(data && data.unreadMesage.UserId == props.id || props.unreadMessage ){

console.log("COUNT INCREMENTED",renders)
  //props.increment()
    //props.append(data)
  


return <span style={{
  position: "absolute",
  right:"-0px",
  borderRadius:"100%",
  height:"17px",
  width:"17px",
  top:"1px",
  background:"blue",
  textAlign: "center",
  padding:"5px 10px"}} className="notify-badge"></span>
                 

}

 
else {
  return null

}

  
// }

}
export default UnreadsSingle