import React, { Component, useEffect, useRef, useState } from 'react'
import Loader from './Loader';
import routes from '../storage_apollo_routes';
function PostImage_2(props) {
    //const [loading, setLoading] = useState(true);
   
    const [openState,setOpen] = useState(true)
  if(props.isVideoPreview){
    return <><video   autoPlay muted loop onLoadedData={() => {
      setOpen(false)}} style={{display: openState ? "none" : "block"}} width="100%" height="100%" controls={false}>
     <source src={`${routes.storage_1}${props.postSrc}`}  type="video/mp4">
     </source>
   </video></>
  }
    return <>
     <Loader isOpen={openState}/>
     
          <img 
//           onMouseOver={ ()=>{if(props.displayHandler){
//             console.log("yeah")
// props.displayHandler(props.postId)
//           } }}
//           onTouchStart={ ()=>{if(props.displayHandler){
//             console.log("yeah")
// props.displayHandler(props.postId)
//           } }}
      
           style={{display: openState ? "none" : "block",}}
            className="gallery-image"
            src={`${routes.storage_1}${props.postSrc}`}
            
            onLoad={() => {
                setOpen(false)}} />
      
    </>;
  }

  export default PostImage_2
  