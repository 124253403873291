import axios from 'axios'
import React, { Component } from 'react'
import { Link,Redirect ,useLocation} from 'react-router-dom'
import Profile2 from './Profile2';
//import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Favorites from './Favorites';
import Payment from './Payment'
//import Loader from './Loader';
//import { Trail, animated } from "react-spring";
class Profile extends Component {
  constructor (props) {
    super(props)
    this.state = {
      posts: [],
      subbed:this.props.location.subbed,
      display:true,
       id :this.props.location.id,
       name:null,
       subscribers:null,
       postcount:null,
       profilephoto:null,
       isLoaderOpen:false
    }
    
  }
 componentDidMount(){
   if(!this.state.id){
    return window.location.href = "/posts"
   }
    // this.setState({
    //   id:this.props.location.id,
    //   subbed:this.props.location.subbed
    // })
  console.log("testing",this.state.id)
 
  }

//classify this component
  toggle(){
  this.setState({isLoaderOpen:!this.state.isLoaderOpen})

  }





  componentDidUpdate(prevprops){
    console.log(prevprops,"yoooo",this.state.id,prevprops.location.id,this.props.location.id)
     if(prevprops.location.id == this.props.location.id){
      return
     }
    this.setState({subbed:this.props.location.subbed,id:this.props.location.id})
  }
    //  const {id} = this.state
    // console.log(prevprops.math.params.subbed)
    // this.setState({subbed:prevprops.subbed})
    // if(prevprops.match.params.id!== this.props.match.params.id ){
    //   axios.post(`http://localhost:8000/api/profilecontent1/${this.props.match.params.id}`).then(response =>{

    //     console.log(response.data)
    //     if( response.data.subscription =='true' ||response.data.subscription==null&&response.data.fee ==null) this.setState({
    //      display: false,
    //      posts:response.data,
    //      id:id,
    //      name:response.data.name,
    //      subscribers:response.data.subscribers,
    //      postcount:response.data.postcount,
    //      profilephoto:response.data.profilephoto
         
    //    });else{
    //     this.setState({
    //       posts:response.data,
    //       display:true,
    //       id:id,
    //       name:response.data.name,
    //       subscribers:response.data.subscribers,
    //       postcount:response.data.postcount,
    //       profilephoto:response.data.profilephoto
          
          
    //     })
    //    }
    //  })
      
      
      
    // }
  // }

 update(){
  //const{id}=this.state
  // axios.post(`http://localhost:8000/api/profilecontent1/${id}`).then(response =>{

    
  //   if( response.data.subscription =='true' ||response.data.subscription==null&&response.data.fee ==null) this.setState({
  //    display: false,
  //    posts:response.data,
  //    id:id,
  //    name:response.data.name,
  //    subscribers:response.data.subscribers,
  //    postcount:response.data.postcount,
  //    profilephoto:response.data.profilephoto
     
  //  });else{
  //   this.setState({
  //     display:true,
  //     posts:response.data,
  //     id:id,
  //     name:response.data.name,
  //     subscribers:response.data.subscribers,
  //     postcount:response.data.postcount,
  //     profilephoto:response.data.profilephoto
  //   })
//    }
//  })
 }



  

  
    
   
  

  render () {
    const { posts,display,name,subscribers,postcount,profilephoto,subbed,id} = this.state
    // const id = this.props.match.params.id
    //redirect if a bundle exists and user isnt subbed  and 
    if(!this.state.subbed)
    { return <Redirect to ={{pathname:`/pay`,prevstate:{subbed:subbed,id:id}}}/>}
    return (
      





       <Redirect to ={{pathname:`/userdat/${id}`,prevstate:{subbed:subbed,id:id}}}/>
      
    
    
    
   
      
    )
  }
}

export default Profile