import React, { Component } from 'react';
import "../UI/Styles/CascadingProfiles.css"
import ProfilePic from '../UI/ProfilePic';

class CascadingProfiles extends Component {
    renderProfilePics = () =>{
        if(this.props.users){
            if(this.props.users.length > 0 && this.props.users.length <= 3) {

                return <div className="avatars_">{this.props.users.map((u)=>
                 <span key={u.id} className="avatar_">
                  <ProfilePic id={u.id}/>
              </span>
              )}</div>
             }
             if(this.props.users.length > 0 && this.props.users.length > 3) {
     
               return  (<div className="avatars_"><span key={this.props.users[0].id} className="avatar_">
              <ProfilePic id={this.props.users[0].id}/>
           </span>
            <span key={this.props.users[0].id} className="avatar_">
            <ProfilePic id={this.props.users[1].id}/>
        </span>
         <span key={this.props.users[0].id} className="avatar_">
         <ProfilePic id={this.props.users[2].id}/>
     </span></div>)
               
              }

        }

   
  
    }
    render() {
        return (
          this.renderProfilePics()
        );
    }
}

export default CascadingProfiles;