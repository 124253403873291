import "../UI/Styles/AlbumSlider.css"
import Ads from "./AdVid";
import React, { Component } from 'react';
import routes from "../storage_apollo_routes";

class AlbumSlider extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    
    this.state = {
      left: 0,
      originalOffset: 0,
      timeOfLastDrag:0,
      width:0,
      velocity:0,
      X:0,
      startPos:0,
      prevTranslate: 0,
      isDragging:false,
      animationID:0,
      domItems:[],
       index:0
      
    };
    this.myRef = React.createRef()
  }
  componentDidMount(){
    const imagesTypes = [
      "jpeg",
      "png",
      "svg",
      "gif"
    ];

    console.log(this.props)
    const slideitems = Array.from(document.querySelectorAll('.slide-images'))
    let slider = this.myRef.current
    
    //console.log(`items:${slideitems[0].height}:${Date.now()}`)
    this.setState({domItems:slideitems})
    // const endMaster = (event)=>{
    //   console.log("endmaster triggered")
    //   this.handleTouchEnd(event)
    //   slider.removeEventListener('mouseup', endMaster);
    //   slider.removeEventListener('touchend', endMaster);
    //   slider.removeEventListener('mousemove', (e)=>this.handleTouchMove(e));
    //   slider.removeEventListener('touchmove', (e)=>this.handleTouchMove(e));
    // }
    // const startMaster = (event)=>{
    //   console.log("ayo")
    //   //this.setState({isDragging:true,startPos:this.getPositionX(event), timeOfLastDrag: Date.now()})
    //   this.handleTouchStart(event)
    //   slider.addEventListener("mousemove",(e)=>this.handleTouchMove(e))
    //   slider.addEventListener("touchmove",(e)=>this.handleTouchMove(e))
    //   slider.addEventListener("mouseup",endMaster)
    //   slider.addEventListener("touchend",endMaster)
    // }
    // //setInterval(()=>{this.setPosition(-300,0)},1000)
   
    // //this.renderlist(4)

    // slider.addEventListener("touchstart",startMaster)
    // //slider.addEventListener("touchmove",this.handleTouchMove)
    // //slider.addEventListener("touchend",this.handleTouchEnd)
    // //slider.addEventListener("mouseup",this.handleTouchEnd)
    // //slider.addEventListener("mousemove",this.handleTouchMove)
    // slider.addEventListener("mousedown",startMaster)

  }
  mediaStatus = (isPhoto,src,post) =>{
    console.log(this.state.preview)
    console.log(src,"source")
    console.log(isPhoto)
    if(isPhoto){
      console.log(src,"here")
      return <div className="container slide-images">
        <div className="video">
          <div style={{width:"300px"}}className="video-player">
          <img style={{width:"300px"}}  draggable={false}  src={`${routes.storage_1}${src}`}  ></img>
          </div>
      
        </div>
        </div>
      //<PostImage postSrc={`/${src}`}/>
    }
    else if(!isPhoto && src !=="pngsus.png"){
return    <Ads isAlbum={true} isAudio={post.isAudio} Src={post.file} caption={post.caption}  preview={post.thumbnail} duration={post.duration}/> 
    {/* <div ><video width="100%" height="100%" controls>
        <source src={`/storage/${src}`}  type="video/mp4">
        </source>
      </video></div> */}
     
     
    
    }
    else if(!isPhoto && src =="pngsus.png"){
      return  <div>
        <img style={{width:"340px"}}draggable={false} className="slide-images" src={`${routes.storage_1}${src}`}  ></img></div>
          }

          
  


  }
   isPhotoFn = (res)=>{
    if(res.isAudio){
      return false
    }
    if(!res.duration){
      return true
    }
    if(res.preview ==""){
      return true
    }
    if(res.thumbnail ==""){
      return true
    }
    return false
  }
 
  renderlist(num) {
    let brando = [];
    for(let i= 0; i < num; i++) {
      brando.push(<img key={i} className="slide-images" src="../storage/brando.jpg" alt="Img 1"/>);
    }
     
    this.setState({domItems:brando})
    
  };

  getPositionX(event) {
    return event.type.includes('mouse') ? event.clientX : event.touches[0].clientX
    //pageX v clientX
    
  }
  
  animation(animationID) {
    
    if (this.state.isDragging) requestAnimationFrame(this.animation)
  }

  setPosition(currentTranslate,prev) {
    
    this.setState({X:currentTranslate,prevTranslate:prev})
  }

  handleTouchStart=(event)=>{
     console.log(this.getPositionX(event))
     
    this.setState({isDragging:true,startPos:this.getPositionX(event), timeOfLastDrag: Date.now()})
     
    
   }
   
  setPositionByIndex(){
      const {index,X} = this.state
      const slide = this.myRef.current.getBoundingClientRect().width
      // const currentTranslate = index * - (slide-20)
      const currentTranslate = index * - (slide)
      this.setState({prevTranslate:currentTranslate})
      this.setPosition(currentTranslate,X)
      console.log(`${index}:${currentTranslate}:${slide}`)
    }

   handleTouchMove(event){
    const {isDragging,X,startPos,timeOfLastDrag} = this.state
    if (isDragging) {
     
      const currentPosition = this.getPositionX(event)
      if(startPos == this.getPositionX(event)) return
      const currentTranslate = X + currentPosition - startPos
      this.setPosition(currentTranslate,X)
    }
    
   }

   handleTouchEnd(event){
    this.setState({isDragging:false})
     const {X,prevTranslate,domItems,index} = this.state
   
     const length = domItems.length 
     const movedBy = X - prevTranslate
    // console.log(domItems.length,index,"eeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeennnnnnnnnnnnnnddddddddddd",movedBy,this.myRef.current.getBoundingClientRect().width,index * - (384-20))
    if (movedBy < -100 && index < length-1) {
     
      this.setState({index:this.state.index+=1})
      this.setPositionByIndex() 
   }
    else if (movedBy > 100 && index > 0) {
      
      this.setState({index:this.state.index-=1})
      this.setPositionByIndex()
  
  }
  else if(movedBy > 500){
  this.setState({index:this.state.index-=1})
      this.setPositionByIndex()

  }

    
   }

    render() {
      const {X,domItems,width} = this.state
        return (
//             target.addEventListener('mousedown',onMouseDown)
// target.addEventListener('mouseup',onMouseUp)
// contain.addEventListener('mousemove',onMouseMove)
// contain.addEventListener('mouseleave',onMouseUp)
<div>
            <div ref={this.myRef} className="slider-container" >
            <div     onTouchStart={touchStartEvent => this.handleTouchStart(touchStartEvent)}
         onMouseDown={mouseDownEvent => this.handleTouchStart(mouseDownEvent)}
         onTouchMove={touchMoveEvent => this.handleTouchMove(touchMoveEvent)}
          onMouseMove={(mouseMoveEvent)=>{this.handleTouchMove(mouseMoveEvent)}}
         onTouchEnd={() => this.handleTouchEnd()} 
          
         onMouseUp={() => this.handleTouchEnd()}
         onMouseOut={() => this.handleTouchEnd()}
         onMouseLeave={() => this.handleTouchEnd()} className="slide">
    <div 

         className="slide-items" style={{transform: `translateX(${X}px)`}}
         >{
            //this.mediaStatus(this.state.isPhoto,postsrc)
            this.props.posts.map((post)=>  this.mediaStatus(this.isPhotoFn(post),post.file,post)
            
          
            )
         }
           {/* <img  className="slide-images" src="../storage/brando.jpg" alt="Img 1"/>
           <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video>
  <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video>
   <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video>
  <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video>
  <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video>
  <video controls={false}
    autoPlay muted class="slide-images" id="5"width="100%" height="100%"  >
    <source src="../storage/moriah.mp4" type="video/mp4">
    </source>
  </video> */}

  
 
 
 

  
          
    </div>
    
  </div>

            </div>
            </div>
        );
    }
}

export default AlbumSlider;