import React, { Component } from 'react';
import "./Styles/SocialLinks.css"
import SocialLinkForm from './SocialLinkForm';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

class SocialLinks extends Component {
    constructor(props){
        super(props)
        this.state ={
            sites:null,
            formState:false,
            name:null,
            link:null
        }
    }

    componentDidMount(){
        client
        .query({
          query: gql`
        query Social{
              getSocials{
                name
                link
              }
                          }`}).then((results)=>{
                            console.log(results)
                            this.setState({sites:results.data.getSocials})
                          }).catch((error)=>{
                            console.log(error)
                          })
    }

    modifiedLinkHandler = (social)=>{
      let copy = this.state.sites
      for(let i in copy){
        if(social.name == copy[i].name){
          copy[i] = social

        }
      }
      this.setState({sites:copy,link:social.link})
    }
    newLinkHandler = (name)=>{
        let link = false
        for(let i in this.state.sites){
          if(name == this.state.sites[i].name){
            link = this.state.sites[i].link

          }
        }
        
        this.setState({formState:true,name:name,link:link})

    }
    render() {
        return (
            <div style={{paddingLeft:"39px"}}>
                 {this.state.formState && <SocialLinkForm modifiedLinkHandler={this.modifiedLinkHandler}link={this.state.link} name={this.state.name}/>}
            <div style={{borderRadius:"20px"}}className="social_body" >
               
                <div  style={{left:"-19px",display:"block",position:"relative"}} className="icons">
        <ul className="ul_social">
            <li>
                <div onClick={()=>this.newLinkHandler("facebook")} className="social" ><i className="fa fa-facebook" aria-hidden="true"></i></div>
            </li>
            <li>
                <div onClick={()=>this.newLinkHandler("twitter")} className="social" ><i className="fa fa-twitter" aria-hidden="true"></i></div>
            </li>
            
            <li>
                <div onClick={()=>this.newLinkHandler("snapchat")} className="social" ><i className="fa fa-snapchat-ghost" aria-hidden="true"></i></div>
            </li>
            <li>
                <div onClick={()=>this.newLinkHandler("whatsapp")}  className="social" ><i className="fa fa-whatsapp" aria-hidden="true"></i></div>
            </li>
        </ul>
        <ul className="ul_social">
            <li>
                <div onClick={()=>this.newLinkHandler("youtube")}  className="social" ><i className="fa fa-youtube-play" aria-hidden="true"></i></div>
            </li>
            <li>
                <div  onClick={()=>this.newLinkHandler("reddit")} className="social" ><i className="fa fa-reddit" aria-hidden="true"></i></div>
            </li>
            <li>
                <div  className="social" ><i className="fa fa-reddit" aria-hidden="true"></i></div>
            </li>
            <li>
                <div  className="social" ><i className="fa fa-discord" aria-hidden="true"></i></div>
            </li>
        </ul>
        <ul className="ul_social">
            <li>
                <div  className="social" ><ion-icon name="logo-discord"></ion-icon></div>
            </li>
            <li>
                <div  className="social" ><ion-icon name="logo-reddit"></ion-icon></div>
            </li>
            <li>
                <div  className="social" ><ion-icon name="logo-xbox"></ion-icon></div>
            </li>
            <li>
                <div  className="social" ><ion-icon name="logo-behance"></ion-icon></div>
            </li>
        </ul>
    </div>

  </div>
            </div>
        );
    }
}

export default SocialLinks;