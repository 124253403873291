import React, { Component } from 'react';
import Popup3 from './Popup3'
import RealPay from '../Controllers/RealPay';
import Loader from './Loader';
import routes from '../storage_apollo_routes';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
 // uri: "https://apolloserver.uhsoka.com/graphql",
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

/**
 * A popup that promps a user to deposit some amount for transactions
 */
class Deposit extends Component {
    constructor(props){
        super(props)
        this.state = {
            contact:"",
            completed:false,
            open:this.props.displayDeposit,
            errorMsg:"",
            iMO:false,
            loaderState:false,
            message:null,
            amount:null
        }
    }

  componentDidMount(){

    
  }


/**
 * An error popup with a text message (can be repurposed for any info popup).It renders Popup3.
 * @param {string} message 
 */
  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  /**
   * Deposits some amount into a user's account
   */
  deposit = (method)=>{
    const methodMap={
      momo:"MTN_MOMO",
      paystack:"PAYSTACK"


    }
    // client
    // .query({
    //   query: gql`
    //     query getPrice{
    //       getPpvPrice(id:${this.props.id})
    //     }
    //   `,
    // }).then((result)=>{
    //   console.log(result.data.getPpvPrice)
    //   this.setState({price:result.data.getPpvPrice})
    // }).catch((error)=>{
    //   this.createError("An error occured")
    // })

    const {contact,message,amount} = this.state
    let msg = (!message)?"":message
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(!this.props.isVerified){
      this.createError("You must verify your account  in order to deposit")
      return
    }
    if(pattern && amount >= 10 || method ){
      this.setState({loaderState:true})
      client
    .query({
      query: gql`
        query depositMaster {
          Deposit(amount:${amount},contact:"${contact}",method:"${methodMap[method]}")
        }
      `,
    }).then((result)=>{
      console.log(result.data.Deposit)
      if(result.data.Deposit == "SUCCESSFUL"){
        //modify earnings
        this.props.modify()
        this.setState({completed:true,loaderState:false})
        //
         }
         else{
          
          // this.props.updateProfileParams(amount)
          
          this.createError(result.data.Deposit)
          //modify post clear it after testing
          
          this.setState({loaderState:false})
         }
  
    }).catch((err)=>{
      this.createError(`Internal Server Error:${err}`)
      this.setState({loaderState:false})
    }) 
    }
  
  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

/**
 * Sets the user's number in the state
 * @param {string} event 
 */
contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}
/**
 * Sets the amount in the state
 * @param {float} event 
 */
amountChosen = (event)=>{
  this.setState({amount:event.target.value});
  
}
// numberchosen = (event)=>{
//   this.setState({price:event.target.value})
// }

/**
 * Closes the popup
 */
closeModal() {
  this.setState({iMO:false})
  
  //this.update();
}


/**
 * Closes the Deposit Component
 * @param {} e 
 */
    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
        this.setState({loaderState:false,completed:false,amount:null,errorMsg:""})
      }
    }
    render() {
        const {completed,amount,errorMsg,price} = this.state
        if (this.props.displayDeposit == false)
          return null
        return (
            <div className="boxxxx">
        
            <div className="cenceph_pure_black">
           
            
            <p> Enter your number and amount to be deposited   </p>
           <div className="tooltip">?
           <i className="fa fa-question tooltiptext" aria-hidden="true"> Enter an amount and your number(Only MTN numbers are supported).Or
           Enter an amount and choose another option.
           </i>
           </div>
            <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
          <div className="wrapp_block">
              {/* change to create subscription bundle */}
              <Popup3 isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
              <Loader isOpen={this.state.loaderState}/>
    
          {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />  */}
    {!completed &&<input onChange={(e)=>this.contactChosen(e)}style={{color:"#fffff"}}placeholder="eg. 233XXXXXXXXX" type="text" />}
   
          {!completed &&<div><input style={{color:"#fffff"}}onChange={(e)=>this.amountChosen(e)} placeholder="10 or more"  type="text" /></div>}
          {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.deposit("momo")}>deposit</button>
      
      or
      <div>
      {!completed  && <RealPay price={this.state.amount} deposit={this.deposit} className={"wpc-btn wpc-btn-default-rounded"}/>}
      </div>
      
      </div>}
        
      </div>
      
      
          
          
          
      
      
      
      
      
     
      
      
      
      </div>
      
      
      
      
      
      
      </div> //<div className="bg" onClick={e => this.close(e)}/>
        );
    }
}

export default Deposit;