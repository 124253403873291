import React, { Component } from "react";
import Media from "../Controllers/Media";
import ProfilePic from "./ProfilePic";
import TypingIndicator from "./TypingIndicator";
/**
 * @typedef Message
 * @prop {string} from
 * @prop {string} to
 * @prop {string} content
 */

/**
 * @typedef Props
 * @prop {integer} id -The id of the user
 * @prop {integer} rcpt - The id of the recipient
 * @prop {Array.<Message>} messages - An array of received messages.
 * @prop {Boolean} activate - A boolean that controls the isTyping Indicator
 */

/**
 * @typedef State
 * 
 */

/**
 * @extends Component<Props,State>
 */
class ChatUI extends Component {
  /**
   * 
   * @param {Props} props 
   */
  constructor(props) {
    super(props);
    this.state = {
      //messages:this.props.messages
    };
  }

  /**
   * Checks if the file contains a video extension
   * @param {*} string - possible video file extension
   * @returns {Boolean} boolean that represents the presence of a video file
   */
 includesMap = (string)=>{
   if(string.includes("mp4")||string.includes("webm")||string.includes("avi")||string.includes("ogg")||string.includes("m4v")||string.includes("mkv")){
    return true
  }
    return false
 }
/**
 * Handles displaying images and videos 
 * @param {Message.content} obj 
 * @returns {Component} - Media display element
 */
  showImg =  (obj) => {
    if(!obj) return <p>null</p>
//  console.log(this.props.profile_data.photo,this.props.profile_data)
    return (
      // <div className="embed-content-image">
        <Media src={obj} />
        //<div></div>
      
    );
  };
  render() {
    //const {messages} = this.state
    return (
      <div>
        {/* <div className="menu">
             <div className="back"><i className="fa fa-chevron-left"></i> <img src="https://i.imgur.com/DY6gND0.png" draggable="false"/></div> 
            <div className="name">{this.props.to}</div>
            <div className="last">18:09</div>
        </div> */}
        <div className= "post">
        <ol className="chat">
          {this.props.messages.map((msg,i) => (
            <li className={msg.from == this.props.id ? "self" : "other"}>
              <div className="avatar">
              {(msg.from == this.props.id)?<ProfilePic id={parseInt(localStorage.getItem("userID"))}/>:<ProfilePic id={this.props.rcpt}/>}
              </div>
              <div className="msg">
                {/* <p></p> */}
                {msg.from == this.props.id && <i style={{
                      float: "right",
                      fontSize: "1.35rem",
                      fontWeight: "700",
                      lineHeight: "1",
                      color: "#000",
                      textShadow: "0 1px 0 #fff",
                      opacity: "0.5",
                }} onClick={()=>this.props.deleteMessage(msg.index,i,msg.to)} className="fa fa-trash"></i>}
                {typeof msg.content == "string" ? (msg.content.includes("/chat")||msg.content.includes("/storage")) ? (this.includesMap(msg.content))?
                 <div ><video width="100%" height="100%" controls>
                 <source src={`${msg.content}`}  type="video/mp4">
                 </source>
               </video></div>:<div className="embed-content-image">
                <img src={`${msg.content}`}/>
            </div>:  <p>
                    {msg.content}
                    {/* <emoji className="pizza" /> */}
                  </p> :(
                   this.showImg(msg.content)
                )
                 
                }
                <time>{msg.dateFmt && msg.dateFmt}</time>
           
                {msg.price && msg.from !== this.props.id  && <i
                style={{
                  fontSize: "0.7rem",
                  paddingLeft:"12px",
                  cursor: "pointer"
            }} onClick={()=>this.props.buyPost(msg.price,msg.index,msg)} className="fa fa-eye"></i>}
              </div>
            </li>
          ))}
          {this.props.activate && <li className="other"><div className="ms">
          <TypingIndicator/></div></li>}
        </ol>
        {/* <input className="textarea" type="text" placeholder="Type here!"/> */}
        <div className="emojis"></div>
      </div>
      </div>
    );
  }
}

export default ChatUI;