import React, { Component } from 'react';
import "../UI/Styles/HomeOptions.css"

class HomeOptions extends Component {
    constructor(props){
        super(props)
        this.state ={

        }
    }
    render() {
        return (
            
            <div className="c__ example-one">
  <div className="title"></div>
  <header className="example-one-header scroll">
    <span className="logo">Subs</span>
    <nav className="vertical-align-middle">
      <span onClick={()=>this.props.listController()}className="homepage_nav-item">Lists</span>
      <span onClick={()=>this.props.earningsGraphShow()}className="homepage_nav-item">Stats</span>
      <span className="homepage_nav-item" onClick={()=>this.props.toggleBulkMessages()}>Message</span>
      <span onClick={()=>this.props.toggleDeposit()}className="homepage_nav-item">Deposit</span>
      <span onClick={()=>this.props.toggleSettings()}className="homepage_nav-item">Settings</span>
    </nav>
  </header>
</div>

        );
    }
}

export default HomeOptions;