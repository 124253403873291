import React, { Component } from 'react';
import ContributionsPopup from '../UI/ContributionsPopup';
import { Link } from 'react-router-dom'
import ProfilePic from '../UI/ProfilePic';
import SocialLinks from '../UI/SocialLinks';
import BulkMessages from './BulkMessages';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});


class List extends Component {
    constructor(props){
        super(props)
        this.state = {
            displayTrans:false,
            statData:{},
            selectedTrans:null,
            displayTrans:false,
            transactions:null,
            showBulkMessages:true,

        }

    }   
    removeListUser =(id)=>{
      client
      .mutate({
        mutation: gql`
          mutation delete{
            removeListUser(id:${id},list:${this.props.activeListId})
                        }`}).then((result)=>{
                          console.log(result.data.removeListUser)
                          //modify list users
                          this.props.modifyListUsers(this.props.activeListId,id)
                        }).catch((error)=>{
                          console.log(error)
                        })
    }
  toggleBulkMessages=()=>{
this.setState({showBulkMessages:!this.state.showBulkMessages})
    }
    getContributions = (id)=>{
      this.setState({selectedTrans:id})
      client
      .query({
        query: gql`
          query myQ {
            getContributions(id:${id}){
              from
              amount
              type
            }
          }`}).then((result)=>{
            console.log(result.data.getContributions)
            this.setState({transactions:result.data.getContributions,displayTrans:true})
            }).catch((err)=>{
              console.log(err)
            })
     }
        storeStatData=(data,id)=>{
      let newState = this.state.statData

    if(newState[id]){
      return
    }
    newState[id] = data
    console.log(newState)
    this.setState({statData:newState})
    }
    
    render() {
        if(!this.props.openList) return null

        return (
            <div>
              
                              <div style={{position:"relative"}}>
                          {<i style={{fontSize:"13px"}}onClick={()=> this.props.closeList()} className="fa fa-times" aria-hidden="true"></i>}
                          <BulkMessages selectState={this.props.listUsers.map(a=>false)} toggleBulkMessages={this.toggleBulkMessages} listUsers={this.props.listUsers} showBulkMessages={this.state.showBulkMessages}/>
                          </div>{ this.props.listUsers.map(dat=>(<div>

<li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3)),url(storage/${dat.photo})`}} class="list2-item">

<div className="avatar_">
<Link to={{pathname:`/profile/${dat.id}`, subbed:dat.subbed,id: dat.id,
}}><ProfilePic id={dat.id}
alt="profilepic" className="list2-item-image"/></Link>
</div>

<div class="list2-item-content">
  
<i style={{fontSize:"13px"}}onClick={()=> this.removeListUser(dat.id)} className="fa fa-trash-o" aria-hidden="true"></i>
<Link to={{pathname:`/chatwith/${dat.id}`,to_id:dat.id,profile_data:null}}><h4>{dat.name}</h4></Link>
  <p>{dat.email}</p>
  
{/*   
  <p>{this.getTimeLeft(dat.user_profile.createdAt)} days elapsed</p> */}
  <p style={{cursor:"pointer"}}onClick={()=>this.getContributions(dat.id)}>more</p>
 
</div>
{/* { this.state.selectedTrans == dat.id  &&<div style={{position:"relative",right:"0px"}}className="list2-item-content">
<ContributionsPopup storeStatData={this.storeStatData} currentid={dat.id} displayTrans={this.state.displayTrans} transactions={this.state.transactions}/>


</div>} */}
  { this.state.selectedTrans == dat.id  &&<div style={{position:"relative",right:"0px"}}className="list2-item-content">
                <ContributionsPopup storeStatData={this.storeStatData} currentid={dat.id} displayTrans={this.state.displayTrans} transactions={this.state.transactions}/>
               
                
                </div>}

</li>
</div>))}
            </div>
        );
    }
}

export default List;