import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ProfilePic from '../UI/ProfilePic';
import { io } from "socket.io-client";
import Popup3 from '../UI/Popup3'
import ContributionsPopup from '../UI/ContributionsPopup';
import  '../UI/Styles/BulkMessages.css';
import PostImagePreview from '../UI/PostImagePreview';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { resultKeyNameFromField } from "@apollo/client/utilities";
import Popuptip_chat from "../UI/Popuptip_chat";
const link = createHttpLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
  credentials: "include",
});
const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const URL_ = "https://chatserver.uhsoka.com/graphql";
const client = io(URL_, { autoConnect: false });


class BulkMessages extends Component {
    constructor(props){
        super(props)
        this.state={
            selectState:this.props.selectState,
            countState:0,
            itemIndices:[],
            messageType:[false,false],
            selectAll:false,
            username:null,
            message:null,
            checkMessage:true,
            checkFile:false,
            iMO:false,
            errorMsg:"",
            id:null,
            price:false,
            isaActive:false,
            duration:null,
            mimeType:null,
            media:false

        }
    }
    cleanUp = ()=>{
      let  prevMedia = this.state.media
      URL.revokeObjectURL(prevMedia)
      this.setState({file1:null,media:null,type:"",price:0, isActive:false})
    }
    onChangeFileHandler = (event)=>{
      if(this.state.media && this.state.file1){
        this.cleanUp()
      }
        let type = ""
        let mimes ={     "video/mp4":"video",
        "video/avi":"video",
        "video/ogg":"video",
        "video/mov":"video",
        "video/wmg":"video",
        "video/3gp":"video",
        "video/ts":"video",
        "video/webm":"video",
        "video/m4v":"video",
        "video/webp":"video"
        // "audio/mpeg":"video",
      }
      if(!event.target.files[0]) return
      if(mimes[event.target.files[0].type]){
        type = mimes[event.target.files[0].type]
      //   console.log(type,"TYPEEEEEEEEEEEEEEEEEEEEEEE")
      }
        let file = new FileReader()
        file.readAsArrayBuffer(event.target.files[0]);
        //file.readAsDataURL(event.target.files[0])
        // file.onloadend = ()=>{
        
        //     this.setState({media:file.result,type:type,file1:event.target.files[0]})
        //     console.log(file.result.type,"TYPE GOES HERE,file.result.mime",file.result)
        // }

        file.onload =(e)=>{
            // The file reader gives us an ArrayBuffer:
      let buffer = e.target.result;
  // We have to convert the buffer to a blob:
     let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
  // The blob gives us a URL to the video file:
     let url = URL.createObjectURL(videoBlob);
      // video.src = url;
      this.setState({media:url,type:type,message:event.target.files[0]})
        }
    }
    setFileDetails=(details)=>{
      this.setState({duration:details.duration,mimeType:details.mimeType})
    }
    setOpenMaster = ()=>{
      this.setState({isActive:true})
    }
    getmessageId = async (id) =>{
      return apolloClient.query({
        query: gql`
        query messageID {
          getmessageId(userId:${id})
        }
      `,
        
      }).then((result)=>{
        console.log(result.data.getmessageId)
        //this.setState({friends:this.state.friends.concat(result.data.getSubscribers)})

        ///oldie chamnge me let db_id = (result.data.getmessageId !== [])?result.data.getmessageId:null
        let db_id = (result.data.getmessageId.length != 0)?result.data.getmessageId:null
       // console.log(db_id,"result from query")
        this.setState({id:db_id[0],session:db_id[1]})
        return db_id[1]
      }).catch(err=>{console.log(err)});
    }
    meQuery = () => {
      const {rcpt_db_id} = this.state
      return apolloClient
        .query({
          query: gql`
            query myQuery {
              me {
                name
                id
              }
            }
          `,
        })
        .then(async (result) => {
          let name = result.data.me.name;
           let param = result.data.me.id
           this.setState({personal:param})
          ///getmessageid
          //const sessionID = localStorage.getItem("sessionID");
          const sessionID = await this.getmessageId(param)
          
          // console.log(sessionID,"return type undetermined,this is first called,could be either null or the id itself")
          if (sessionID) {
            // console.log(name,"the names here foo")
            client.auth = { name };
            client.auth = { sessionID };
            client.connect();
          } else {
            //console.log(name, "using the else conditional");
            client.auth = { name };
            //client.auth = {target}
            client.connect();
          }
  
          client.on("session", ({ sessionID, userID}) => {
            //this.setState({id:userID})
            // attach the session ID to the next reconnection attempts
            console.log("reconnection attempt read",sessionID,userID,name);
            //this.setState({id:userID})
            client.auth = { name };
            client.auth = { sessionID };
           
            //client.auth = {target}
            client.connect();
            // store it in the localStorage
            //const oldSession = localStorage.getItem("sessionID");
            const oldSession = this.state.session 
            console.log(oldSession,"this is the oldSession id reconnection attempt")//get
            if (!oldSession) {
              //createmessageId(userid)
            //localStorage.setItem("sessionID", sessionID);
            this.createmessageId(param,userID,sessionID)
          }//store it in db
  
            // save the ID of the user
            client.userID = userID;
            console.log("USER ID NULL WHY",userID)
            
            //createmessageid
            console.log(userID, sessionID, "session middleware");
          });
          client.on("connect_error", (err) => {
            if (err.message === "invalid username") {
              console.log("invalid username");
            }
          });
         
          this.setState({username:name//friends:[result.data.me]
        });
        }).catch(err=>{console.log(err)});
    };
    componentWillUnmount(){
      client.disconnect()
    }
    componentDidUpdate(prevprops){
      console.log()
      if(prevprops.selectState == this.props.selectState){
       return
      }
     this.setState({selectState:this.props.selectState,itemIndices:[]})
    }
    closeModal=()=>{
      this.setState({iMO:false,message:null})
    }
    componentDidMount(){
      console.log(this.state.selectState,this.props.listUsers,"la knight yeah")
      client.on("bulkmessage sent",({success,id})=>{
        if(id == this.state.id){
          this.createError("success")
          this.setState({price:false})
        }
        
        // this.setState({message:null})
      })
     this.meQuery()
    }
    selectAll=()=>{
        this.setState({selectState:this.props.listUsers.map(a=>true),})
    }
    handleOnChange = (position,user) => {
        // if(this.state.countState == 1){
        //     this.setState({selectAll:true})
        // }
        console.log( this.state.selectState,this.props.listUsers,this.props.selectState)
        let state
        let count = 0
        let itemIndicesCopy = this.state.itemIndices
        
        let usrs = this.props.listUsers
        let selections = this.state.selectState
        const updatedCheckedState = selections.map((item, index) =>{
           
         if(index === position ){
    
         state = !item
         if(state){
            count++
            itemIndicesCopy = itemIndicesCopy.concat([user.messageId])
            console.log(itemIndicesCopy)
            return state
          }
          console.log(itemIndicesCopy)
          itemIndicesCopy = itemIndicesCopy.filter((ftr) => ftr !== user.messageId)
          return state
         }

         else if(index !== position){
            if(item){
              count++
              
              
              return item
            }
           
            
            return item
           }
          
        }
         
    
        );
        console.log(count,updatedCheckedState,itemIndicesCopy,this.props.listUsers,this.state.selectState)
        this.setState({selectState:updatedCheckedState,countState:count,itemIndices:itemIndicesCopy})
    

      }
      createError = (message)=>{
        this.setState({errorMsg:message,iMO:true})
       
      }

  checkMessage=(type)=>{
    
    if(type == "text"){
      if(this.state.checkMessage){
        this.cleanUp()
        this.setState({checkMessage:false,checkFile:true})
        return
      }
      this.cleanUp()
      this.setState({checkMessage:true,checkFile:false})
      return
    }
    if(this.state.checkFile){
      
      this.setState({checkFile:false,checkMessage:true})
      return
    }
    
    this.setState({checkFile:true,checkMessage:false})
  }
      messageHandler=(event)=>{
        this.setState({message:event.target.value})
      }
      priceHandler=(event)=>{
        this.setState({price:event.target.value})
      }
      fileHandler = async (event) =>{
        this.setState({message:event.target.files[0]})
      }
      bulkMessage=(users)=>{
        console.log(users)
        if(this.state.media && users.length < 1){
          this.createError("no file selected")
          return
        }
        console.log("us",users,this.props.listUsers)

        if(this.state.message && users.length > 0){
          let date = new Date(Date.now())
          let dateFmt = date.toDateString() + " " + date.toTimeString()
          let content = this.state.message
          let name = (typeof content !== 'object')?"":content.name
          let price = this.state.price
        
            //console.log({message,to,name,dateFmt})
            client.emit("private bulkmessage", {content,name,dateFmt,users,price});
          
         // this.createError("success")
        }
       
        this.cleanUp()
      }

    render() {

        if(!this.props.showBulkMessages) return null
        return (
            <div className="">
<p style={{borderRight: "0px", padding: "0px"}} className="logo">Bulk Messages</p>
<div style={{display:"flex"}}>
<div><button onClick={()=>this.selectAll()}>select All</button></div>
<div style={{position:"relative",paddingTop:"7px",alignItems:"center",left:"100px"}}>
<div className="row" >
                <div className="toggle-button-cove">
                  <div className="button-cove">
                    <div className="button r" id="button-1">
                      <input
                         checked={this.state.checkMessage}
                         onChange={()=>this.checkMessage("text")}
                        type="checkbox"
                        className="checkbox"
                      />

                      <div className="knobs"></div>
                      <div className="layer"></div>
                 
                    </div>
                   
                  </div>
                </div>
              </div>
              </div>
</div>




<div style={{paddingTop:"12px"}}>
  <ul>
 
  {this.state.media && <div style={{height:"100px",width:"70px",position:"relative",left:"70px"}}> <PostImagePreview fileObject={this.state.message} setOpenMaster={this.setOpenMaster} setFileDetails={this.setFileDetails} type={this.state.type} postSrc={this.state.media} alt="profilepic" className="list2-item-image"/></div>}
  {!this.state.checkFile &&<input onChange={(event)=>this.messageHandler(event)}  className="search-field" type="text" autoComplete="off" name="query" placeholder="Enter Message"/>
}

  </ul>
  <ul>
  {!this.state.checkMessage && <input  type="file" multiple accept="image/*,audio/*,video/*" name="multimedia" onChange={(event)=>{this.onChangeFileHandler(event)}}/>
}
{!this.state.checkMessage &&<input onChange={(event)=>this.priceHandler(event)}   type="number" placeholder="price" />
}
  </ul>


</div>



            <div
              className="list2-wrapper"
              ng-app="app"
              ng-controller="MainCtrl as ctrl"
            >{this.state.mimeType || this.state.message && <button onClick={()=>this.bulkMessage(this.state.itemIndices)}>send message</button>}


                  <div style={{position:"relative"}}>
                              {<i style={{fontSize:"13px"}}onClick={()=> this.props.toggleBulkMessages()} className="fa fa-times" aria-hidden="true"></i>}
                              </div>
                              <ul className="list2">

                              <li ng-repeat="user in ctrl.users" style={{display:"block",backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
                              <div style={{position:"relative"}}>
                                <Popup3 isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>
                                  </div>
                              { this.props.listUsers.map((dat,i)=>(<div>
                                
                                <section class="panel">
<div class="user-info">
	<div class="avatar-wrapper">
		<img class="avatar" src="assets/server_icons/sop.png" alt=""/>
		<div class="status-holder">
			<div class="user-status-icon"></div>
		</div>
	</div>
	<div class="name-tag-container">
		<h1 class="username tipper-boi" data-tip="Click to copy username">{dat.name}</h1>
		<div class="roller">
			<p class="status-emoji">👨‍💻</p>
			<p class="tag">#8100</p>
		</div>
	</div>
</div>
<div class="button-container">
	{/* <button class="switcher tipper-boi" data-tip="Unmute">
		<svg aria-hidden="false" width="20" height="20" viewBox="0 0 24 24">
			<path d="M6.7 11H5C5 12.19 5.34 13.3 5.9 14.28L7.13 13.05C6.86 12.43 6.7 11.74 6.7 11Z" fill="currentColor"></path>
			<path d="M9.01 11.085C9.015 11.1125 9.02 11.14 9.02 11.17L15 5.18V5C15 3.34 13.66 2 12 2C10.34 2 9 3.34 9 5V11C9 11.03 9.005 11.0575 9.01 11.085Z" fill="currentColor"></path>
			<path d="M11.7237 16.0927L10.9632 16.8531L10.2533 17.5688C10.4978 17.633 10.747 17.6839 11 17.72V22H13V17.72C16.28 17.23 19 14.41 19 11H17.3C17.3 14 14.76 16.1 12 16.1C11.9076 16.1 11.8155 16.0975 11.7237 16.0927Z" fill="currentColor"></path>
			<path d="M21 4.27L19.73 3L3 19.73L4.27 21L8.46 16.82L9.69 15.58L11.35 13.92L14.99 10.28L21 4.27Z" class="strikethrough-1n4ekb" fill="red"></path>
		</svg>
	</button> */}
	{/* <button class="switcher tipper-boi" data-tip="Deafen">
		<svg width="20" height="20" viewBox="0 0 24 24">
			<path d="M12 2.00305C6.486 2.00305 2 6.48805 2 12.0031V20.0031C2 21.1071 2.895 22.0031 4 22.0031H6C7.104 22.0031 8 21.1071 8 20.0031V17.0031C8 15.8991 7.104 15.0031 6 15.0031H4V12.0031C4 7.59105 7.589 4.00305 12 4.00305C16.411 4.00305 20 7.59105 20 12.0031V15.0031H18C16.896 15.0031 16 15.8991 16 17.0031V20.0031C16 21.1071 16.896 22.0031 18 22.0031H20C21.104 22.0031 22 21.1071 22 20.0031V12.0031C22 6.48805 17.514 2.00305 12 2.00305Z" fill="currentColor"></path>
		</svg>
	</button> */}
	{/* <button class="switcher tipper-boi" data-tip="User Settings">
		<svg aria-hidden="false" width="20" height="20" viewBox="0 0 24 24">
			<path fill="currentColor" fill-rule="evenodd" clip-rule="evenodd" d="M19.738 10H22V14H19.739C19.498 14.931 19.1 15.798 18.565 16.564L20 18L18 20L16.565 18.564C15.797 19.099 14.932 19.498 14 19.738V22H10V19.738C9.069 19.498 8.203 19.099 7.436 18.564L6 20L4 18L5.436 16.564C4.901 15.799 4.502 14.932 4.262 14H2V10H4.262C4.502 9.068 4.9 8.202 5.436 7.436L4 6L6 4L7.436 5.436C8.202 4.9 9.068 4.502 10 4.262V2H14V4.261C14.932 4.502 15.797 4.9 16.565 5.435L18 3.999L20 5.999L18.564 7.436C19.099 8.202 19.498 9.069 19.738 10ZM12 16C14.2091 16 16 14.2091 16 12C16 9.79086 14.2091 8 12 8C9.79086 8 8 9.79086 8 12C8 14.2091 9.79086 16 12 16Z"></path>
		</svg>
	</button> */}
    <input className="switcher tipper-boi"
          type="checkbox"
          id="topping"
          name="bulkSelect"
          value="pi"
          checked={this.state.selectState[i]}
          onChange={()=>this.handleOnChange(i,dat)}
        />
</div>
</section>

</div>))}

                                </li>
                              </ul>
                              </div>
                
            </div>
        );
    }
}

export default BulkMessages;