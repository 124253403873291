import routes from "../storage_apollo_routes";
import PostlinkDropdown from "./PostlinkDropdown";
import DeletionNotice from "./DeletionNotice";
import React, { Component, useEffect, useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import Favorites from "./Favorites";
import PostImage from "./PostImage";
import "./Styles/Postcard.css"
import Popup3 from "./Popup3";
import TagControllerPost from "../Controllers/TagControllerPost";
import TagControllerAdd from "../Controllers/TagControllerAdd";
import Alerts from "../Controllers/Alerts2";
import SkeletonPost from "./SkeletonPost";
import { useEventTracker } from '../Controllers/EventTrackerContext';
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
  updateQuery,
} from "@apollo/client";
import { offsetLimitPagination } from "@apollo/client/utilities";
import BulkPPV from "./BulkPPV";
import TextPost from "./TextPost";
const authLink_1 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getPosts_: offsetLimitPagination(),
          //  keyArgs:false,
          //  merge(existing=[],incoming){
          //    return [...existing,...incoming]
          //  },
        },
      },
    },
  }),
  link:authLink_1.concat(link),
});

const fetch_1 = gql`
query getPostsEarnings{
  getEarnings{
    
      amount
      postID
    
  }
}
`

const fetch = gql`
  query Posts($id: Int!, $limit: Int!, $offset: Int!) {
    getPosts_(id: $id, limit: $limit, offset: $offset) {

      tags{
        name
        id
        
      }
      favorited
      count
      commentscount
      album{
        id
        Posts{
          caption
          price
          file
          id
          isAudio
          thumbnail
          UserId
          duration
          preview
          title
        }
        id
      }
      post {
        caption
        price
        file
        id
        isAudio
        thumbnail
        UserId
        duration
        preview
        title
      }
    }
  }
`;
/**
 * controlls the scrollback after returning to the Posts page
 */
let currentView = false;

/**
 * Renders a list of the Users Posts
 */
const Posts_tags_replaced = (props) => {
  // posts: [],
  // offset:0,
  // limit:8,
  // profile:null,
  // phohtp:null,
  // display:true,
  // user:this.props.location.user,
  // isModalOpen: false ,
  // results: null,
  //    loading: false,
  //    body:'',
  // console.log(user)

  //const [currentView,setSelect] = useState(false)
  const [ DeletionNoticeActive,setDeletionNotice] = useState({popup:false,id:null})
  const trackEvent = useEventTracker();
  const [postLinkDropdown,setPostLinkDropdown] = useState(false)
  const [posts, setPosts] = useState([]);
  const [e,setError] = useState(false)
  const [openState,setOpen] = useState(false)
  const [offset, setOffset] = useState(0);
  const [isModalOpen, setModalOpen] = useState(false);
  const [results, setResults] = useState(null);
  const [body, setBody] = useState("");
  const [loading, setLoading] = useState(false);
  const [isl, setIsl] = useState(true);
  const [limit, setLimit] = useState(8);
  const [profile, setProfile] = useState(null);
  const [display, setDisplay] = useState(true);
  const [prevDeleted, setNext] = useState(null);
  const [postRefs, setElRefs] = useState([]);
  const [postRefs_1, setElRefs_1] = useState([]);
  const [currentActive,setActive] =  useState(false)
  const [showTag,setShowTag] = useState(false)
  const [tags,setTags] = useState([])
  const [displayPreview,toggleDisplayPreview]  = useState(false)
  const [linkClicked,setLinkActive] = useState(null)
  const [earnings,setEarnings] = useState(null)
  const [refinedEarnings,setRefinedEarnings] = useState({})

  // const location = useLocation()
  // const {userid} = location.state

const onCloseLinkDropdown = ()=>{
  setPostLinkDropdown(false)
  setLinkActive(null)
}

const onCloseDeletionNotice = ()=>{
  setDeletionNotice({popup:false,id:null})

}

  const trimCaptions=(original)=>{
  let og = original.split(" ")
  let length = og.filter((a)=>a!="\n").length
  if(length >= 9){
    let concat = ""
    for(let i = 0;i<7;i++){
      concat = og[i] + " "+ concat
    }
   concat = concat  + "..."
   return concat
  }
  return original
  }

  const [user, setUser] = useState(
    props.location.user || parseInt(localStorage.getItem("userID"))
  );
  const { fetchMore, data, error } = useQuery(fetch, {
    variables: {
      offset: offset,
      limit: limit,
      id: parseInt(user) || parseInt(localStorage.getItem("userID")),
    },
    client: client,
  });

 const  changeActive =(post)=>{
  
    if(post.post.id == currentActive){
setActive(null)
setTags([])
setShowTag(!showTag)
    }
    else{
      setTags(post.tags)
      setActive(post.post.id)
      setShowTag(!showTag)
    }
  }

// const checkActive =(id)=>{
  
//   return id == currentActive
// }

const calculatePostEarnings = (transactions)=>{
  //Refine
  let final = {}
  for(let t in transactions){
   
if(final[transactions[t]]){
 let sum = final[transactions[t].postID] + transactions[t].amount
final[transactions[t].postID] = parseInt((sum*100))/100
}
else{
  final[transactions[t].postID] =  parseInt((transactions[t].amount*100))/100
}
  }
  console.log(final)
  setRefinedEarnings(final)
}

  const getEarnings = ()=>{
    client.query({
      query:fetch_1
    }).then((results)=>{
  
      setEarnings(results.data.getEarnings)
      
      calculatePostEarnings(results.data.getEarnings)
    }).catch((error)=>{
      console.log(error)
    })
  }

  const queryApi = () => {
    //infinite scroll post data offset limit fetchmore
    // const {user,offset,limit} = this.state
    setLoading(true)
    let useParam = user;
    console.log(user, useParam, posts, currentView);
    client
      .query({
        query: gql`
      query myQuery {
        getPosts_(id:${useParam},offset:${offset},limit:${limit}) {
          tags{
            name
            id
            
          }
          album{
            id
            Posts{
              caption
              price
              file
              id
              isAudio
              thumbnail
              UserId
              duration
              preview
              title
            }
            id
          }
          favorited
          count
          commentscount
          post{
            caption
            price
            file
            id
            isAudio
            thumbnail
            UserId
            duration
            preview
            title
          }
          
        }
      }
    `,
      })
      .then((result) => {
        //console.log(result)
        //console.log("state changed")
        // this.setState({posts:result.data.getPosts_,limit:(result.data.getPosts_.length*2),offset:result.data.getPosts_.length})
        if (posts.length > 0) {
          console.log("TESTING CACHE");
          setLoading(false)
          return;
        }

        setPosts(result.data.getPosts_);
setLoading(false)
        setElRefs(result.data.getPosts_.map((x) => React.createRef(null)));
        setIsl(false);

        //   setElRefs_1((postRefs_1) =>
        //   Array(result.data.getPosts_.length)
        //     .fill()
        //     .map((_, i) => postRefs_1[i] || React.createRef()),
        // );

        // listRef.current = listRef.current.slice(0, result.data.getPosts_.length)
        // setLimit((result.data.getPosts_.length*2))
        // setOffset(result.data.getPosts_.length)
      })
      .catch((err) => {
        console.log(err);
        //this.setState({ redirec: !this.state.redirect });
      });

    //GET PROFILE DATA
    client
      .query({
        query: gql`
          query myQuery2 {
            profile {
              name
              description
              photo
              email
            }
          }
        `,
      })
      .then((result) => {
        //console.log(result.data.me.id)
        //this.setState({profile:result.data.profile,photo:result.data.profile.photo})
        setProfile(result.data.profile);
        let email = result.data.profile;
        console.log(email);
        // if(localStorage.getItem("userID") !== id){
        //   localStorage.setItem("userID",id)
        // }
      })
      .catch((err) => {
        console.log(err);

        //this.setState({ redirect: !this.state.redirect });
      });
  };

  const child = React.createRef();

  const [val, setVal] = useState(child);

  useEffect(() => {
   getEarnings()
    queryApi();
    if (currentView && postRefs[currentView]) {
      console.log(currentView, `${currentView}`);
      //element.scrollIntoView()
      // postRefs[currentView].current.scrollIntoView()
      getcurrentRef();
      console.log("went to the post", currentView);
    }
  }, [isl]);

  /**
   * Sets the currunt post to scrollback to when navigating back to Posts
   *   * @param {integer} id 
   */
  const beforeRedirect = (id,event) => {
    //setElRefs(posts.map(x => React.createRef(null)));
    trackEvent(event,"LinkRedirect",id)
    console.log("elrefss called", postRefs, postRefs_1);
    console.log("currentview:", id, currentView);
    currentView = id;
  };


  const addTag= ()=>{

  }
  /**
   * Scrolls the post into view
   */
  const getcurrentRef = () => {
    console.log("elrefss called", postRefs, postRefs_1);
    console.log("currentview:", currentView, postRefs[0].current);
    setTimeout(() => {
      console.log(postRefs[currentView].current, "current");
      postRefs[currentView].current.scrollIntoView();
    }, 100);
  };
  const scrollTo = (id) => {
    // let element = document.getElementById(id)
    // element.scrollIntoView()
  };

  /**
   * Infinite Scroll loads more posts when bottom is reached
   */
  const HandleScroll = async () => {
    //   const {limit,offset,user,posts} = this.state
    if(loading) return
    setVal(val);

    //706 == bottm of page(more or less) child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight
    const bottom = child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight; //child.current.clientHeight
    console.log(
      bottom,
      child.current.scrollHeight,
      child.current.scrollTop,
      child.current.clientHeight
    );
    if (bottom) {
      await fetchMore({
        variables: {
          offset: posts.length,
          limit: posts.length * 2,
          id: user || parseInt(localStorage.getItem("userID")),
        },
      });
      setLoading(true)
      if (data) {
        setLoading(false)
        console.log(prevDeleted);
        if (prevDeleted !== null) {
          const filtered = data.getPosts_.filter(
            (ftr) => ftr.post.id !== prevDeleted
          );
          setPosts(filtered);
          console.log(
            "PREVIOUS DELETED WAS SUCCESSFULLY CALLED NIGGA UCK THE CACHE"
          );
        } else {
          setPosts(data.getPosts_);
          console.log("ELSE WAS CALED INSTEAD");
        }

        setLimit(data.getPosts_.length * 2);
        setOffset(data.getPosts_.length);
      }
      // console.log(offset,limit)
      // client
      // .query({
      //   query: fetch,
      //   variables:{offset:offset,limit:limit,id:user || localStorage.getItem("userID")},
      // })
      // .then((result)=>{
      //   this.setState({posts:this.state.posts.concat(result.data.getPosts_),limit:(result.data.getPosts_.length*2),offset:result.data.getPosts_.length})
      // }).catch(err=>console.log(err))
    }
  };

  /**
   * Adds a new comment to the cache 
   * @param {integer} prevcount 
   * @param {integer} id 
   */
  const newComment = (prevcount, id) => {
    const filteredComments = posts.map((mp) => {
      //mp.post.id !== id

      if (mp.post.id == id) {
        // console.log(id,mutcount,favorited)
        let newpost = { ...mp, commentscount: prevcount + 1 };
        mp = newpost;
        console.log(newpost);
      }
      return mp;

      return mp;
    });
    client.cache.updateQuery({ query: fetch }, (data) => ({
      getPosts_: data.getPosts_.map((todo) => {
        if (todo.post.id == id) {
          let newpost = { ...todo, commentscount: prevcount + 1 };
          todo = newpost;
        }
        return todo;
      }),
    }));
    setPosts(filteredComments);
  };

  const updateTagCache = (tag,id)=>{
    const filteredTags = posts.map((mp) => {
      //mp.post.id !== id

      if (mp.post.id == id) {
       
        let newpost = { ...mp, tags:tag };
        mp = newpost;
        console.log(newpost);
      }

      return mp;

     
    });

      console.log(tags)
      client.cache.updateQuery({ query: fetch }, (data) => ({
        getPosts_: data.getPosts_.map((todo) => {
       //   console.log(todo.tags,tag[t],"weeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeeee")
          if (todo.post.id == id) {
            let newpost = { ...todo, tags:tag};
            todo = newpost;
        
          }
        
          return todo;
        }),
      }));
    
//     let log = tag.concat(tags)
//  console.log(log,tag,tags,prev,id)
    setTags(tag)
    setPosts(filteredTags)
  }

  /**
   * Passed as a callback to the post component to change the favorites cache
   * @param {integer} id 
   * @param {integer} mutcount 
   * @param {boolean} favorited 
   */
  const changefav = (id, mutcount, favorited) => {
    const filteredPosts = posts.map((mp) => {
   

      if (mp.post.id == id) {
        console.log(id, mutcount, favorited);
        let newpost = { ...mp, favorited: favorited, count: mutcount };
        mp = newpost;
        
      }

      return mp;

      return mp;
    });
    console.log(filteredPosts);

    client.cache.updateQuery({ query: fetch }, (data) => ({
      getPosts_: data.getPosts_.map((todo) => {
        if (todo.post.id == id) {
          let newpost = { ...todo, favorited: favorited, count: mutcount };
          todo = newpost;
        }
        
        return todo;
      }),
    }));
    setPosts(filteredPosts);
  };
  /**
   * Deletes a post from the cache
   * @param {integer} param 
   */
  const cacheDelete = (param) => {
    client.cache.updateQuery({ query: fetch }, (data) => ({
      getPosts_: data.getPosts_.filter((todo) => todo.post.id !== param),
    }));
  };
  /**
   * Deletes a post from the Database
   * @param {integer} id 
   */
  const deletePost = (id) => {
    const filteredPosts = posts.filter((ftr) => ftr.post.id !== id);
    client
      .mutate({
        mutation: gql`
        mutation deletePost {
          deletePost(id:${id})
        }
      `,
      })
      .then((result) => {
        console.log(result);
        //this.setState({posts:filteredPosts})
        setPosts(filteredPosts);
        cacheDelete(id);
        setNext(id);
        setError("Post Deleted!")
        setOpen(!openState)
        
        console.log("deleted");
      })
      .catch((err) => {
        console.log(err);
        setError("An Error Occured!")
        setOpen(!openState)
       
        //his.setState({ redirect: !this.state.redirect });
      });
  };

  //       const {posts,profile,photo} = this.state
  return (
    <div
      onScroll={HandleScroll}
      style={{
        "overflow-y": "scroll",
        height: "90rem",
        "border-radius": "5px",
        paddingTop:"22px"
      }}
      ref={child}
    >
     
      <main>
     
        <div className="container ">
      <div className='boxxxx'> 
      
      
       {/* <TagControllerAdd updateTagCache={updateTagCache} tags={tags} postId={currentActive} showTag={showTag}/> */}
       
       {DeletionNoticeActive.popup && <DeletionNotice isOpen={DeletionNoticeActive.popup } onClose={onCloseDeletionNotice} postID={DeletionNoticeActive.id} Delete={deletePost}/>}
       {postLinkDropdown && <PostlinkDropdown onClose={onCloseLinkDropdown} isOpen={postLinkDropdown} postID={postLinkDropdown} />}
       </div>
          <div className="gallery">
          {loading && <SkeletonPost/>}
          <Popup3 OTP={false} isOpen={openState} error={e} onClose={() => {
          setOpen(false)
          
          }}></Popup3>
          
            {!loading && posts.map((post, i) => (
                 
              <div
              
                ref={postRefs[i]}
                key={post.post.id}
                className="gallery-item"
              >
               
                {/* <img className="gallery-image"
                     
                     src={`storage/${post.post.thumbnail == ""?post.post.file:post.post.thumbnail}`}></img> */}
                    
                <PostImage
                displayHandler={(post.post.thumbnail == "")?()=>{}:toggleDisplayPreview}
                isVideoPreview={displayPreview == post.post.id}
                previewSrc={post.post.preview}
                postId={post.post.id}
                  postSrc={
                    post.post.thumbnail == ""
                      ? post.post.file
                      : post.post.thumbnail
                  }
                />

                <div className="gallery-item-info">
                  <ul>
                  {  <div style={{paddingTop:"1px",display:"block"}} className="gallery-item-check"> 
                  <div>
                  <i style={{color:linkClicked==i?"#86a79f":"#ffff"}} onClick={()=>{
                    setPostLinkDropdown(post.post.id)
                    setLinkActive(i)
                    
                }
                    } class="fa fa-link" aria-hidden="true"></i>
                  </div>
                  {/* <div> */}
                    {/* <i style={{fontSize:"20px"}} className="fa fa-money"></i> */}
                   {/* <span style={{paddingLeft:"3px",fontSize:"17px"}}>GHC {refinedEarnings[post.post.id]?refinedEarnings[post.post.id]:0}</span>  */}
                   <div
      style={{ paddingTop: "1px",position:"relative" }}
      className="gallery-item-discount tooltip"
    >
      {" "}
      <div class="tooltip">
        <i class="tooltiptext" aria-hidden="true">
         your post made {refinedEarnings[post.post.id]?refinedEarnings[post.post.id]:0} cedis
     {/* <h2 aria-hidden="true">GHC {refinedEarnings[post.post.id]?refinedEarnings[post.post.id]:0}</h2> */}
    
        </i>
      </div>{" "}
      <div
        className="discount skeleton-loader"
        style={{
          width: "8rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
          
        }}
      >
        GHC {refinedEarnings[post.post.id]?refinedEarnings[post.post.id]:0}
      </div>
    {/* </div> */}
                  </div>
                 
                   </div>}


        
                    {/* <button onClick={()=>getcurrentRef()}>test</button> */}
                    <div className="gallery-item-type">
                      <div onClick={() => {setDeletionNotice({popup:true,id:post.post.id})}}>
                        <i className="fa fa-trash-o" aria-hidden="true"></i>
                      </div>
                    </div>
                    {post.post.duration && <div onClick={()=>{
                      // toggleDisplayPreview(false)
                      if(displayPreview !== post.post.id){
                        toggleDisplayPreview(post.post.id)
                      }
                      else{
                        toggleDisplayPreview(false)
                      }
                  
                      
                      }} style={{opacity:"0.5",color:"orange",fontSize:"14px",position:"relative",textAlign:"center",justifyContent:"center"}} className="gallery-item-duration">
                      <div>
                      <div style={{backgroundColor:"black", borderBottom:"solid",
                        borderBottomColor:"brown",borderRadius:"12px",display:"flex",height:"25px"}} className="duration_opaque">
                      {(displayPreview == post.post.id)?<i style={{fontSize:"11px",paddingLeft:"5px",paddingRight:"5px",paddingTop:"5px"}}class="fa fa-stop" aria-hidden="true"></i>:<i style={{fontSize:"11px",paddingRight:"5px",paddingLeft:"5px",paddingTop:"5px"}} class="fa fa-play-circle" aria-hidden="true"></i>}
                      <p>{post.post.duration}</p></div>

                      </div>
                     </div>}
                    {/* change link to onclick */}
                    <Favorites
                      changefav={changefav}
                      queryApi={queryApi}
                      post={post.post.id}
                      posts={posts}
                      postCount={post.count}
                      favorited={post.favorited ? true : false}
                    />
                    <li className="gallery-item-comments">
                      <span className="visually-hidden">Comments:</span>
                      <div>
                        <Link
                          onClick={(e) => beforeRedirect(i,e)}
                          //paras={{changefav:changefav,}}
                          to={{
                            pathname: `/post/${post.post.id}`,
                            //state:{changefav},
                            duration:post.post.duration,
                            album:post.album.Posts.length > 1?post.album.Posts:false,
                            profile: profile,
                            id: post.post.id,
                            UserId:post.post.UserId,
                            src: post.post.file,
                              changefav:changefav,
                              preview:post.post.thumbnail,
                              thumbnail:post.post.preview,
                              isAudio:post.post.isAudio,
                            count: post.count,
                            isPhoto: post.post.thumbnail == "" ? true : false,
                            favorited: post.favorited ? true : false,
                            caption: post.post.caption,
                            title:post.post.title

                          }}
                        >
                          <i
                            className="fa fa-comments-o"
                            aria-hidden="true"
                          ></i>
                        </Link>{" "}
                      </div>
                      <Link
                        onClick={(e) => beforeRedirect(i,e)}
                        to={{
                          pathname: `/post/${post.post.id}`,
                          profile: profile,
                           changefav:changefav,
                           duration:post.post.duration,
                           preview:post.post.thumbnail,
                           thumbnail:post.post.preview,
                          UserId:post.post.UserId,
                          isAudio:post.post.isAudio,
                          album:post.album.Posts.length > 1?post.album.Posts:false,
                          id: post.post.id,
                          src: post.post.file,
                          count: post.count,
                          isPhoto: post.post.thumbnail == "" ? true : false,
                          favorited: post.favorited ? true : false,
                          caption: post.post.caption,
                          title:post.post.title
                        }}
                      >{`${post.commentscount}`}</Link>
                    </li>
                  </ul>
                  {true && (
                    <div
                      className="textos"
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        top: "4.3rem",
                        fontSize: "15px",
                       
                      }}
                    >
                      <p>{trimCaptions(post.post.caption)}</p>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default Posts_tags_replaced;
