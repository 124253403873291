import React, { Component, useEffect, useRef, useState,forwardRef } from 'react'
import Loader from '../UI/Loader';
import useDragger from './useDragger';
import WaveSurfer from 'wavesurfer.js'
import "../UI/Styles/AudioPlayer.css"
import audio from "./TheFatRat - Upwind [Chapter 4].m4a"

const AudioPlayer = forwardRef((props,ref) =>{
    //const [loading, setLoading] = useState(true);
    // width:"12px",height:"12px"
    const [audiomap,setAudiomap] = useState(props.audiomap)
    const [currentAudio,setCurrentAudio] = useState(props.audio)
    const [loaded,setLoaded] = useState(false)
    const [I,setI] = useState(0)
    let playPause = useRef()
    let current = useRef()
    let duration = useRef()
    let nextAudio = useRef()
    let previousAudio = useRef()



    const next =()=>{
      let current = I
      setCurrentAudio(audiomap[current+1].file)
      setI(current+1)
    }
    const changeThumbnail = ()=>{
      console.log(ref)
      if(!ref.current) return
      ref.current.click()
    }

    const waveHandler = ()=>{

    }
    useEffect(()=>{
           let wavesurfer = WaveSurfer.create({
      container: "#wave",
      waveColor: "#cdedff",
      progressColor: "#1AAFFF",
      height: 48,
      scrollParent: false,
      // url:"./TheFatRat - Upwind [Chapter 4].m4a"
  });
   let current_ = I
    
        const timeCalculator = function (value) {
        let second = Math.floor(value % 60);
       let  minute = Math.floor((value / 60) % 60);
        
        if (second < 10) {
            second = "0" + second;
        }
    
        return minute + ":" + second;
    };



  if(!playPause.current) return

  if(props.audio){
    console.log("re-init")

  
  
    console.log("yo yo", playPause,props.audio,"aidio",audio)
  if(props.isEditor){
    console.log("isEditor")
    wavesurfer.loadBlob(props.audio,props.preview);
  }
  else{
    console.log("isEditor",props.preview)
    wavesurfer.load(`http://localhost:3000/storage/${currentAudio}`);
  }
   
  
  //play and pause a player
  playPause.current.addEventListener("click", function (e) {
   
      wavesurfer.playPause();

  });
  if(audiomap){
    nextAudio.current.addEventListener("click", function  (e){
  
       
      // setCurrentAudio(audiomap[current+1].file)
      // setI(current+1)
      console.log(current_+1,audiomap.length)
      if(audiomap[current_+1]){
        
        current_ = current_+ 1
        if(props.isEditor){
          wavesurfer.loadBlob(audiomap[current_])
          return
        }
         wavesurfer.load(`http://localhost:3000/storage/${audiomap[current_].file}`)
      }

//next()
  })

  previousAudio.current.addEventListener("click", function  (e){
  
       
    // setCurrentAudio(audiomap[current+1].file)
    // setI(current+1)
    console.log(current_+1,audiomap.length)
    if(audiomap[current_-1]){
   
      current_ = current_ - 1
      if(props.isEditor){
        wavesurfer.loadBlob(audiomap[current_])
        return
      }
       wavesurfer.load(`http://localhost:3000/storage/${audiomap[current_].file}`)
    }

//next()
})
  }

  
  
  //load audio duration on load
  wavesurfer.on("ready", function (e) {
    console.log("ready")
      duration.current.textContent = timeCalculator(wavesurfer.getDuration());
  });
  
  //get updated current time on play
  wavesurfer.on("audioprocess", function (e) {
      current.current.textContent = timeCalculator(wavesurfer.getCurrentTime());
  });
  
  //change play button to pause on plying
  wavesurfer.on("play", function (e) {
      playPause.current.classList.remove("fa-play");
      playPause.current.classList.add("fa-pause");
  });
  
  //change pause button to play on pause
  wavesurfer.on("pause", function (e) {
      playPause.current.classList.add("fa-play");
      playPause.current.classList.remove("fa-pause");
  });
  }

    },[I])

  const loadAudio = async ()=>{
    // wavesurfer = await WaveSurfer.create({
    //   container: "#wave",
    //   waveColor: "#cdedff",
    //   progressColor: "#1AAFFF",
    //   height: 48,
    //   scrollParent: false
  // });
  }

useDragger("box")
    return <main style={{display:"grid",placeItems:"center"}}>
    <div  style={{position:"relative",border:"1px",width:"120px",height:"120px"}}className="box_master">
        <div style={{backgroundColor:"black",position:"absolute",top:"0",left:"0",cursor:"pointer",color:"hotpink",}} id="box">
        <section>
      <div class="player">
        <div onClick={()=>{
          if(props.isEditor){
changeThumbnail()
        }}}  class="thumb">
          <img src={(props.isEditor)?`${props.thumbnail}`:`/storage/${props.thumbnail}`} alt="" />
        </div>
        <div class="info">
          <div class="detail">
            <div style={{color:"#e1dcab"}} class="title">
              
              {
              props.details?props.details:props.title}
              <div class="time">
                <span ref={current} id="current">0:00</span> /
                <span ref={duration} id="duration">0:00</span>
              </div>
            </div>
            <div style={{display:"flex",justifyContent:"space-between"}}class="control">
              {/* <i class="fi-rr-play" id="playPause"></i> */}
              <i  ref={playPause} class="fa fa-play" aria-hidden="true"></i>
              {audiomap && <i className="fa fa-step-forward" ref={nextAudio} ></i>}
              {audiomap && <i className="fa fa-step-backward" ref={previousAudio} ></i>}
            </div>
          </div>
          <div style={{maxWidth:"200px"}} id="wave"></div>
        </div>
      </div>
    </section>
        </div>
    </div>
    </main>

  })

  export default AudioPlayer

  