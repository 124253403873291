import React, { Component } from 'react';

class Media extends Component {
    constructor(props){
        super(props)
        this.state = {
            media:"",
            type:""
        }
    }
    componentDidMount(){
        let mimes ={     "video/mp4":"video/mp4",
        "video/avi":"video",
        "video/ogg":"video",
        "video/mov":"video",
        "video/wmg":"video",
        "video/3gp":"video",
        "video/ts":"video",
        "video/webm":"video",
        // "audio/mpeg":"video",
      }
      let type = "image"
      console.log(this.props)
        console.log(this.props.src.content instanceof ArrayBuffer,this.props.src.type,this.props.content)
        if(mimes[this.props.src.type]){
          type = mimes[this.props.src.type]
        //   console.log(type,"TYPEEEEEEEEEEEEEEEEEEEEEEE")
        }

        const mediatype = (this.props.src.content instanceof ArrayBuffer)?new Blob([this.props.src.content]):this.props.src
        let file = new FileReader()
        file.readAsDataURL(mediatype)
        file.onloadend = ()=>{
        
            this.setState({media:file.result})
            console.log(file.result.type,"TYPE GOES HERE,file.result.mime",file.result)
        }
        this.setState({media:file.result,type:type})
    }
    render() {
        const {media,type} = this.state

        return (
            <div className="embed-content-image">
               {type == "video"?
               <div ><video width="100%" height="100%" controls>
               <source src={media}  type="video/mp4">
               </source>
             </video></div>: <img src={media}/>}
            </div>
        );
    }
}

export default Media;