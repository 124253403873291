import "./UI/Styles/Register_final.css"
import React,{useState,useEffect} from "react"
import qs from 'qs'
import Loader from "./UI/Loader";
import { Redirect } from "react-router-dom";
import { setContext } from "@apollo/client/link/context";
import Popup3 from "./UI/Popup3";
import routes from "./storage_apollo_routes";
//import Upload4 from "./UI/Upload4";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const authLink_1 = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
      headers: {
        "X-Pinggy-No-Screen": "true",
      },
    };
  });
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink_1.concat(link),
  });
let login_mutation =   gql`
mutation logIn($name: String!, $password: String!) {
  logIn(name: $name, password: $password) {
    id
  }
}
`;
let register_mutation = gql`
mutation registration($name: String!, $password: String!,$email:String!,$mtn_number:String!,$referrer:String) {
  createnewUserOnboarding(name: $name, password: $password,email:$email,mtn_number:$mtn_number,referrer:$referrer) {
    id
    name
  }
}
`
const Register = (props) =>{
    const [name,setName]  = useState(null)
    const [error,setError] = useState(false)
    const [email,setEmail] = useState(null)
    const [password,setPassword] = useState(null)
    const [isOpen,setIsOpen] = useState(false)
    const [loginLoader,setLoginLoader] = useState(false)
    const [errorState,setErrorOpen] = useState(false)
    const [mtn_number,setMTNNumber] = useState(null)
    const  [stages,setStages] = useState({1:false,2:false,3:false,4:false})
    const [current,setCurrent] = useState(0)
    const [stepsNumber,setStepsNumber] = useState(2)
    const [stepPercent,setStepPercent] = useState(0)
    
    const referrer_id = null
    //useState( qs.parse(location.search , { ignoreQueryPrefix: true }).__referrerid ?qs.parse(location.search , { ignoreQueryPrefix: true }).__referrerid:null)


    const closeError = ()=>{
        setErrorOpen(false)
        setError(false)
    }

   const register = () => {
        // const {referrer_id} = this.state

        const rgStr = register_mutation;
        setIsOpen(true)
        client
          .mutate({
            mutation: rgStr,
            variables: { name:name, password:password,email:"##",mtn_number:"##",referrer:referrer_id},
          })
          .then((result) => {
            console.log(result);
            if(result.data.createnewUserOnboarding){
            //   this.setState({ redirect: !this.state.redirect });
                // setCurrent(current+1)
                // checkIndex(1)
                setIsOpen(false)
                login()
            }
            // this.setState({iMO:true,errorMsg:"something went wrong"})
          })
          .catch((err) => {
            console.log("catch", err);
            // this.setState({iMO:true,errorMsg:"something went wrong"})
            setIsOpen(false)
            setError("An error Occured. Please check your input.")
            setErrorOpen(true)
            
          });
      }

    const login = ()=>{
        setLoginLoader(true)
        client
        .mutate({
          mutation: login_mutation,
          variables: { name: name, password: password },
        })
        .then((result) => {
            setLoginLoader(false)
          console.log(result.data.logIn.id);
        
          localStorage.setItem("userID", result.data.logIn.id);
         // return <Redirect to={{pathname:"homepage",search:"?__firstTimer=true"}}/>
         (window.location.href = "/homepage?__firstTimer=true");
          
        //   this.setState({ redirect: !this.state.redirect });
        })
        .catch((err) => {
        setError("An error Occured")
          setErrorOpen(true)
         
          console.log("catch", err);
        });
    }
    const checkIndex = (i)=>{
        let state = stages[i]
        //console.log(stages,stages[4])
        let n = {...stages}
        n[i] = true
        console.log({...stages,i:!state})
        setStages(n)
    }

const unCheckIndex = (i)=>{
    let state = stages[i]
    //console.log(stages,stages[4])
    let n = {...stages}
    n[i] = false
    console.log({...stages,i:!state})
    setStages(n)
}
return (<div className="screen-body">
   <Popup3 onClose={closeError} isOpen={errorState} error={error}/>
<div className="screen-1">

    {/* <svg class="logo" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" version="1.1" width="300" height="300" viewbox="0 0 640 480" xmlSpace="preserve"><g transform="matrix(3.31 0 0 3.31 320.4 240.4)"><circle
     style={{stroke: "rgb(0,0,0)", strokeWidth: "0", strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4", fill: "rgb(61,71,133)", fillRule: "nonzero", opacity: "1"}} cx="0" cy="0" r="40"></circle></g><g transform="matrix(0.98 0 0 0.98 268.7 213.7)"><circle
      style={{stroke: "rgb(0,0,0)", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4", fill: "rgb(255,255,255)", fillRule: "nonzero", opacity: "1"}} cx="0" cy="0" r="40"></circle></g><g transform="matrix(1.01 0 0 1.01 362.9 210.9)"><circle
     style={{stroke: "rgb(0,0,0)", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4",fill: "rgb(255,255,255)", fillRrule: "nonzero", opacity: "1"}} cx="0" cy="0" r="40"></circle></g><g transform="matrix(0.92 0 0 0.92 318.5 286.5)"><circle
      style={{stroke: "rgb(0,0,0)", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4", fill: "rgb(255,255,255)", fillRule: "nonzero", opacity: "1"}} cx="0" cy="0" r="40"></circle></g><g transform="matrix(0.16 -0.12 0.49 0.66 290.57 243.57)"><polygon style={{stroke: "rgb(0,0,0)", strokeWidth: "0", strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: "4", fill: "rgb(255,255,255)", fillRule: "nonzero", opacity: "1"}} points="-50,-50 -50,50 50,50 50,-50 "></polygon></g><g transform="matrix(0.16 0.1 -0.44 0.69 342.03 248.34)">
        <polygon style={{stroke: "rgb(0,0,0)", strokeWidth: 0, strokeDasharray: "none", strokeLinecap: "butt", strokeDashoffset: "0", strokeLinejoin: "miter", strokeMiterlimit: 4, fill: "rgb(255,255,255)", fillRule: "nonzero", opacity: "1"}}
         vectorEffect="non-scaling-stroke" points="-50,-50 -50,50 50,50 50,-50 "></polygon></g>
    </svg> */}
    
    <div
        class="email"><label for="email">Name</label>
        <div class="sec-2">
            <ion-icon name="mail-outline"></ion-icon><input type="email" value={name}  onChange={(e)=>setName(e.target.value) } placeholder="Username" /></div>
</div>
<div class="password"><label for="password">Password</label>
    <div class="sec-2">
        <ion-icon name="lock-closed-outline"></ion-icon><input class="pas" onChange={(e)=>setPassword(e.target.value)} type="password"  placeholder="············" />
        <ion-icon class="show-hide" name="eye-outline"></ion-icon>
    </div>
</div><button onClick={()=> register()}class="login">{(loginLoader||isOpen)?<Loader isOpen={loginLoader||isOpen} />:"Register & Login "}</button>
{/* <div class="footer"><span>Sign up</span><span>Forgot Password?</span></div> */}
</div>

</div>)
}

export default Register