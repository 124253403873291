import routes from '../storage_apollo_routes';
import React, { Component, useEffect, useRef, useState } from 'react'
import "./Styles/Discount.css"
import Discount from './Discount';
import Payperview from './Payperview';
import Loader from './Loader';
import PostImage from './PostImage';
import { Link,useLocation } from 'react-router-dom'
import { setContext } from '@apollo/client/link/context';
import Favorites from './Favorites';
//import "./Styles/Postcard.css"
import BulkPPV from './BulkPPV';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    gql,
    useQuery
  } from "@apollo/client";
  import { offsetLimitPagination } from '@apollo/client/utilities';
import { NoSchemaIntrospectionCustomRule } from 'graphql';
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(
      {typePolicies:{
        Query:{
          fields:{
            getFeed:offsetLimitPagination(),
              //  keyArgs:false,
              //  merge(existing=[],incoming){
              //    return [...existing,...incoming]
              //  },
              
          }
        }
      }}
    ),
    link:authLink.concat(link),
  });

  const fetch = gql`
  query Posts( $limit: Int!, $offset:Int!) {
    getFeed(limit:$limit, offset:$offset) {
      favorited
      count
      commentscount
      post{
        caption
        price
        file
        id
        thumbnail
        UserId
        preview
        duration
        isAudio
        Discount{
          expires
          price
          createdAt
        }
      }
    
    }
  }
`;
const fetch_recommendations = gql`
query PostRecommendations{
  getRecommendations{
    favorited
    count
    commentscount
    post{
      caption
      price
      file
      id
      thumbnail
      UserId
      preview
      duration
      isAudio
      Discount{
        expires
        price
        createdAt
      }
    }
    album{
      id
      Posts{
        caption
        price
        file
        id
        isAudio
        thumbnail
        UserId
        duration
        preview
        title
      }
    }
  }
}
`;


let currentView = false
const Feed = (props) => {

  // posts: [],
  // offset:0,
  // limit:8,
  // profile:null,
  // phohtp:null,
  // display:true,
  // user:this.props.location.user,
  // isModalOpen: false ,
  // results: null,
  //    loading: false,
  //    body:'',
  // console.log(user)
//const [loaderState,setLoaderState] = useState(false)
const [posts,setPosts] = useState([])
const [forYou,setForYou] = useState(false)
const [offset,setOffset] = useState(0)
const [isModalOpen,setModalOpen] = useState(false)
const [results,setResults] = useState(null)
const [body,setBody] = useState('')
const [loading,setLoading] = useState(false)
const [limit,setLimit] = useState(8)
const [profile,setProfile] = useState(null)
const [display,setDisplay] = useState(false)
const [prevDeleted,setNext] = useState(null)
const [selected,setSelected] = useState(null)
const [discount,setDiscount] = useState(null)
const [isl,setIsl] = useState(true)
const [postRefs,setElRefs] = useState([])
const [amount,setAmount] = useState(null)
const [postRefs_1,setElRefs_1] = useState([])
const [selections,setSelections] = useState([])
const [bundleCount,setBundleCount] = useState(0)
const [totalPrice,setTotalPrice] = useState(0)
const [itemIndices,setItemIndices] = useState([])
const [displayPreview,toggleDisplayPreview]  = useState(false)
const [activeClass,setActiveClass] = useState("Subscriptions")

// const location = useLocation()
// const {userid} = location.state

const [user,setUser] = useState(props.location.user || parseInt(localStorage.getItem("userID")))

const {fetchMore,data,error} = useQuery(fetch,{
  variables:{offset:offset,limit:limit,id:parseInt(user) || parseInt(localStorage.getItem("userID"))},
  client:client
})

const child = React.createRef();
const [val,setVal] = useState(child)
const queryApiRecommendations =()=>{
  let useParam = user
  console.log(user,useParam)
  client
  .query({
    query: gql`
      query myQueryRecommendations {
        getRecommendations{
          favorited
          count
          commentscount
          post{
            caption
            price
            file
            id
            thumbnail
            UserId
            preview
            duration
            isAudio
            Discount{
              expires
              price
              createdAt
            }
          }
          album{
            id
            Posts{
              caption
              price
              file
              id
              isAudio
              thumbnail
              UserId
              duration
              preview
              title
            }
          }
        }
      }
    `,
  })
  .then((result) => {//console.log(result)
    //console.log("state changed")
    if(posts.length > 0){
      console.log("TESTING CACHE")
      setPosts(result.data.getRecommendations)
      return
     }
   // this.setState({posts:result.data.getFeed,limit:(result.data.getFeed.length*2),offset:result.data.getFeed.length})
    setPosts(result.data.getRecommendations)
    setSelections(new Array(result.data.getRecommendations.length).fill(false))
    setElRefs(result.data.getRecommendations.map(x => React.createRef(null)));
    setIsl(false)
   // setLimit((result.data.getFeed.length*2))
   // setOffset(result.data.getFeed.length)
})
  .catch((err) => {
    console.log(err);
    //this.setState({ redirec: !this.state.redirect });
  });


}
const getTimeLeft = (dateString) =>{
  let d2= new Date(dateString)
let d1 = Date.now()

let dif= Math.abs(d2-d1);
let d = Math.round((dif/(1000 * 3600 * 24)))
//bundle or regular subscription
// let days_left = 30 - d
// console.log(d)
return d
}

const calculateExpiry = (createdAt,expiry)=>{
let timeLeft = getTimeLeft(createdAt)
console.log(timeLeft >= expiry,expiry,timeLeft,"brehhhhhhhhhhhhhhhhhhh")
  return timeLeft  >= expiry
}
const queryApi = () =>{
  //infinite scroll post data offset limit fetchmore
 // const {user,offset,limit} = this.state
  let useParam = user
  console.log(user,useParam)
  client
  .query({
    query: gql`
      query myQuery {
        getFeed(offset:${offset},limit:${limit}) {
          favorited
          count
          commentscount
          post{
            caption
            price
            file
            id
            thumbnail
            UserId
            preview
            duration
            isAudio
            Discount{
              expires
              createdAt
              price
            }
          }
          
        }
      }
    `,
  })
  .then((result) => {//console.log(result)
    //console.log("state changed")
    if(posts.length > 0){
      console.log("TESTING CACHE")
      setPosts(result.data.getFeed)
      return
     }
   // this.setState({posts:result.data.getFeed,limit:(result.data.getFeed.length*2),offset:result.data.getFeed.length})
    setPosts(result.data.getFeed)
    setSelections(new Array(result.data.getFeed.length).fill(false))
    setElRefs(result.data.getFeed.map(x => React.createRef(null)));
    setIsl(false)
   // setLimit((result.data.getFeed.length*2))
   // setOffset(result.data.getFeed.length)
})
  .catch((err) => {
    console.log(err);
    //this.setState({ redirec: !this.state.redirect });
  });





  //GET PROFILE DATA
  client.query({
    query: gql`
      query myQuery2 {
        profile {
          name
          description
          photo
          email
        }
      }
    `,
  })
  .then((result) =>{
    //console.log(result.data.me.id)
    //this.setState({profile:result.data.profile,photo:result.data.profile.photo})
    setProfile(result.data.profile)
    let email = result.data.profile
    console.log(email)
    // if(localStorage.getItem("userID") !== id){
    //   localStorage.setItem("userID",id)
    // }
  }) 
  .catch((err) => {
    console.log(err);

    //this.setState({ redirect: !this.state.redirect });
    
  });
}


useEffect(()=>{
  if(forYou){
queryApiRecommendations()
  }
  else{
    queryApi()
  }
 
  if(currentView && postRefs[currentView]){
     
    console.log(currentView,`${currentView}`)
    //element.scrollIntoView()
    // postRefs[currentView].current.scrollIntoView()
    getcurrentRef()
    console.log("went to the post",currentView)
  
  }
 
},[isl])

const beforeRedirect = (id) =>{
  //setElRefs(posts.map(x => React.createRef(null)));
     console.log("elrefss called",postRefs)
  console.log("currentview:",id,currentView)
    currentView = id
  }
  
  const getcurrentRef = ()=>{
       console.log("elrefss called",postRefs,postRefs_1)
  console.log("currentview:",currentView,postRefs[currentView].current)
  //postRefs[currentView].current.scrollIntoView()
  setTimeout(()=>{
    console.log(postRefs[currentView].current,"animal")
    postRefs[currentView].current.scrollIntoView()},100)
  
  }

const modifyPost_Bulk = (indices)=>{
  // for(i in indices){
  //   modifyPost(indices[i])
  // }
  console.log(indices)
  return window.location.href = "/feed"
}

const modifyPost = async (id)=>{
   let pst = await  client
  .query({
    query: gql`
      query Single {
        getSinglePost(id:${id}) {
            caption
            price
            file
            id
            thumbnail
            UserId
            duration
            preview
            isAudio
          
        }
      }
    `,
  }).then((result)=>{
    console.log("li durk aha")
    return result.data.getSinglePost
  }).catch((error   
  )=>{
    console.log(error)
    return false
  })

  const filteredPosts = posts.map(mp =>{ 
    //mp.post.id !== id
    if(pst){
     
      if(mp.post.id == id){
        console.log(pst,mp)
        let newpost = {...mp,post:pst}
         mp = newpost
         console.log(newpost)
      }
       return mp
    }
     return mp
  });
  client.cache.updateQuery( {query:fetch} , (data) => ({
    getFeed: data.getFeed.map((todo) =>{
      if(todo.post.id == id){
        let newpost = ({ ...todo, post:pst})
         todo = newpost
      }
      return todo
    } )
  }));
  console.log(filteredPosts)
  setPosts(filteredPosts)
}
const changefav = (id,mutcount,favorited)=>{
  const filteredPosts = posts.map(mp =>{ 
    //mp.post.id !== id
    
     
      if(mp.post.id == id){
        console.log(id,mutcount,favorited)
        let newpost = {...mp,favorited:favorited,count:mutcount}
         mp = newpost
         console.log(newpost)
      }
       return mp
  
     return mp
  });

   console.log(filteredPosts)

   client.cache.updateQuery( {query:fetch} , (data) => ({
    getFeed: data.getFeed.map((todo) =>{
      if(todo.post.id == id){
        let newpost = ({ ...todo, favorited:favorited,count:mutcount})
         todo = newpost
      }
      return todo
    } )
  }));
  setPosts(filteredPosts)
}

const HandleScroll = async () =>{
  //   const {limit,offset,user,posts} = this.state
    setVal(val)
    

   
    const bottom = child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight
   // console.log(bottom,child.current.scrollHeight,child.current.scrollTop,child.current.clientHeight)
   if(bottom){
     //setLoaderState(true)
     await fetchMore({
      variables:{offset:posts.length,limit:posts.length*2,id:user || parseInt(localStorage.getItem("userID"))}
    })
    console.log((posts.length*2),(posts.length))
   if(data){
    //console.log(prevDeleted)
    // if(prevDeleted !== null){
    //   const filtered = data.getFeed.filter(ftr => ftr.post.id !== prevDeleted);
    //   setPosts(filtered)

    // }
    
      //setnewArray()
      let scrolledposts = new Array(data.getFeed.length).fill(false)
      let concat = [...selections,...scrolledposts]
      setSelections(concat)
      //console.log(concat)
      setPosts(data.getFeed)
     
     
      
      console.log("ELSE WAS CALED INSTEAD")
    
    console.log((data.getFeed.length*2),(data.getFeed.length))
     setLimit((data.getFeed.length*2))
    setOffset(data.getFeed.length)
   }
    // console.log(offset,limit)
    // client
    // .query({
    //   query: fetch,
    //   variables:{offset:offset,limit:limit,id:user || localStorage.getItem("userID")},
    // })
    // .then((result)=>{
    //   this.setState({posts:this.state.posts.concat(result.data.getFeed),limit:(result.data.getFeed.length*2),offset:result.data.getFeed.length})
    // }).catch(err=>console.log(err))
    
    
   }
  }

  const handleOnChange = (position,post) => {
    let count = 0
    let total = 0
    let list = []
    let state
    const updatedCheckedState = selections.map((item, index) =>{
      
      
      //setcount(count)
     
     //let state = index === position ? !item : item
     if(index === position ){

      state = !item
      if(state){
        count++
        total = totalPrice + parseInt(post.price)
        list = itemIndices.concat([post.id])
        //console.log("positive",1,total)
        return state
      }
      total = totalPrice - parseInt(post.price)
      list = itemIndices.filter(ftr => ftr !== post.id)
      //console.log("negative",1,total)
      return state
     }
     else if(index !== position){
      if(item){
        count++
        
        
        return item
      }
     
      
      return item
     }
      
    }
     

    );
    console.log(count,"SCARED",total,list)
    setItemIndices(list)
    setBundleCount(count)
    setTotalPrice(total)
    setSelections(updatedCheckedState);
  }
  
const togglePost = (id,price,discount) =>{
  setSelected(id)
  setDiscount(discount)
 setAmount(price)
  setDisplay(!display)
  // client
  // .query({
  //   query: gql`
  //     query myQuery {
  //       getFeed(offset:${offset},limit:${limit}) {
  //         favorited
  //         count
  //         commentscount
  //         post{
  //           caption
  //           price
  //           file
  //           id
  //           thumbnail
  //         }
          
  //       }
  //     }
  //   `,
  // })
  //get thepayment
  
 }





//       const {posts,profile,photo} = this.state
    return (
      <div onScroll={HandleScroll} style={{"overflow-y":"scroll",height:"90rem","border-radius":"5px"}} ref={child}>
      <main >
       
      <Payperview discount={discount} onClose={togglePost} modify={modifyPost} id={selected} price={amount} isOpen={display}/>
      {!display &&<BulkPPV totalPrice={totalPrice} itemIndices={itemIndices} modifyPost_Bulk={modifyPost_Bulk} toggle={display} checkState={bundleCount}/>}
      <div className="container"  >
      <div class="c__ example-one"><div class="title"></div><header class="example-one-header scroll"><span onClick={()=>{
       // console.log(forYou)
        // queryApi()
         queryApiRecommendations()
        setForYou(true)
        setActiveClass("For You")
      }
            } className={`${activeClass=="For You"?"activeClass logo":"logo"}`} style={{cursor: "pointer"}}>For You</span><span onClick={()=>{
              console.log(forYou)
              // queryApiRecommendations()
              queryApi()
              setForYou(false)
              setActiveClass("Subscriptions")
            }
                  } className={`${activeClass=="Subscriptions"?"activeClass logo":"logo"}`} style={{cursor: "pointer"}}>Subscriptions</span></header></div>
      <div className="gallery">
    
      {posts.map((post,i) => ( 
        <div ref={postRefs[i]} key={post.post.id} className="gallery-item" >
{/*           
                                  <img className="gallery-image"
                     
                     src={`storage/${post.post.thumbnail == ""?post.post.file:post.post.thumbnail}`}></img> */}
                        <PostImage
                displayHandler={(post.post.thumbnail == "")?()=>{}:toggleDisplayPreview}
                isVideoPreview={displayPreview == post.post.id}
                previewSrc={post.post.preview}
                postId={post.post.id}
                  postSrc={
                    post.post.thumbnail == ""
                      ? post.post.file
                      : post.post.thumbnail
                  }
                />
      
   
<div className="gallery-item-info">
{post.post.Discount && <Discount timeElapsed={getTimeLeft} price={post.post.price} calculateExpiry={calculateExpiry} discount={post.post.Discount}/>}<ul>
  {post.post.file == "pngsus.png" &&<div className="gallery-item-check">   <input
          type="checkbox"
          id="topping"
          name="bulkSelect"
          value="pi"
          checked={selections[i]}
          onChange={()=>handleOnChange(i,post.post)}
        /></div>}
<div className="gallery-item-type">

<div onClick={()=>(post.post.file != "pngsus.png")?"":togglePost(post.post.id,post.post.price,post.post.Discount)}>{post.post.thumbnail == ""?<i className="fa fa-picture-o" aria-hidden="true"></i>:
(post.post.isAudio||post.post.isAudio)?<i className="fa fa-music" aria-hidden="true"></i>:<i  className="fa fa-video-camera" aria-hidden="true"></i>
}</div>
</div> 
{post.post.duration && <div onClick={()=>{
                      // toggleDisplayPreview(false)
                      if(displayPreview !== post.post.id){
                        toggleDisplayPreview(post.post.id)
                      }
                      else{
                        toggleDisplayPreview(false)
                      }
                  
                      
                      }} style={{opacity:"0.5",color:"orange",fontSize:"14px",position:"relative",textAlign:"center",justifyContent:"center"}} className="gallery-item-duration">
                      <div style={{backgroundColor:"black",borderRadius:"12px"}} className="duration_opaque"><p>{post.post.duration}</p></div></div>}
{/* change link to onclick */}
<Favorites changefav={changefav} queryApi={queryApi} post={post.post.id} postCount={post.count} favorited={post.favorited? true:false}/>
  <li className="gallery-item-comments"><span className="visually-hidden">Comments:</span><div><Link onClick={()=>beforeRedirect(i)} to={{pathname:`/post/${post.post.id}`,profile:profile,preview:post.post.thumbnail, preview:post.post.thumbnail,
                              thumbnail:post.post.preview,
                              isAudio:post.post.isAudio,duration:post.post.duration, changefav:changefav, UserId:post.post.UserId,id: post.post.id,caption:post.post.caption,
  src:post.post.file,count:post.count,modify:modifyPost,isPhoto:(post.post.thumbnail == "")?true:false, favorited:post.favorited? true:false}}><i className="fa fa-comments-o" aria-hidden="true"></i></Link> </div>
  <Link onClick={()=>beforeRedirect(i)} to={{pathname:`/post/${post.post.id}`, preview:post.post.thumbnail,
                              thumbnail:post.post.preview,
                              isAudio:post.post.isAudio,profile:profile,duration:post.post.duration, changefav:changefav,preview:post.post.thumbnail, UserId:post.post.UserId,id: post.post.id,caption:post.post.caption,
  src:post.post.file,count:post.count,modify:modifyPost,isPhoto:(post.post.thumbnail == "")?true:false, favorited:post.favorited? true:false}}>{`${post.commentscount}`}</Link></li>
</ul>
{true &&<div className="textos" style={{position:"absolute",textAlign:"center",top:"4.3rem",fontSize:"15px"}}>
  <p>{post.post.caption}</p>
</div>}
</div>
      
        </div>
      

        
      
      ))}
      {/* <div>
      <Loader isOpen={loaderState}/>
      </div> */}
      </div>
      
      
      </div>
      

   

      </main>
      </div>
    )
  }


export default Feed



