import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import routes from '../storage_apollo_routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { setContext } from '@apollo/client/link/context';
import { CopyToClipboard } from 'react-copy-to-clipboard';
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
    //  "Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class DeletionNotice extends Component {
  constructor(props){
    super(props);
  this.state={

 
     
  
  }
    // this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    // this.submitnumber = this.submitnumber.bind(this)
  }


  
    






    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        //this.setState({copied:false})
        this.props.onClose()
      }
    }
   
    
    render() {
     
      if (!this.props.isOpen )
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div style={{backgroundColor:"#d74a46"}} className="cenceph_pure_black">
       {/* {!this.props.OTP && <p><h1 style={{fontSize:"18px",fontWeight:"2000"}}>{this.props.error}</h1></p>} */}
        
        <p  onClick={()=>{this.props.Delete(this.props.postID)}} ><h1 style={{color:"#2176bd",fontWeight:"800",fontSize:"23px",fontWeight:"2000",cursor:"pointer"}}>clicking this will delete the file</h1> <br/><span style={{color:"#4a7ea3"}}></span></p>
        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div style={{display:"block"}} className="wrapp">
      <span style={{color:"#4a7ea3",fontSize:"17px"}}>

      <p style={{color:"#ffff"}}>are you sure?</p>
      </span>
        

  
  
  
  
  
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default DeletionNotice;