import { methodOf } from 'lodash';
import routes from '../storage_apollo_routes';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3'
import Loader from './Loader';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
 // defaultOptions:defaultOptions
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

class Payperview extends Component {
  constructor(props){
    super(props);
  this.state={
   contact:"",
    completed:false,
    open:this.props.isOpen,
    errorMsg:"",
    iMO:false,
    loaderState:false,
    message:null,
    price:null,
    useToken:false
  
  }
    //this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    //this.setPrice = this.setPrice.bind(this)
  }


  componentDidMount(){

    
  }

getTimeLeft = (dateString) =>{
    let d2= new Date(dateString)
  let d1 = Date.now()
  
  let dif= Math.abs(d2-d1);
  let d = Math.round((dif/(1000 * 3600 * 24)))
  //bundle or regular subscription
  // let days_left = 30 - d
  // console.log(d)
  return d
  }
  
calculateExpiry = (createdAt,expiry)=>{
  let timeLeft = this.getTimeLeft(createdAt)
  // console.log(timeLeft >= expiry,expiry,timeLeft,"brehhhhhhhhhhhhhhhhhhh")
    return timeLeft  >= expiry
  }



  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  payView = (id)=>{

    const {contact,message,price} = this.state
    let msg = (!message)?"":message
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    console.log(pattern)
    if(!pattern){
      return 
    }
    this.setState({loaderState:true})
    client
  .query({
    query: gql`
      query Tips {
        payPerView(id:${id},contact:"${contact}",useTokens:${this.state.useToken})
      }
    `,
  }).then((result)=>{
    console.log(result.data.payPerView)
    if(result.data.payPerView == "SUCCESSFUL"){
      this.props.modify(id)
      this.setState({completed:true,loaderState:false,contact:""})
      toast( <div style={{display:"flex"}}>
    
       <div style={{backgroundColor:"#fbf7f7", borderBottom:"solid",
                    borderBottomColor:"e3acac",borderRadius:"12px",display:"flex",height:"25px",width:"40px"}} className="duration_opaque">
                  {<i style={{fontSize:"11px",paddingLeft:"5px",paddingRight:"5px",paddingTop:"5px"}} className="fa fa-trash"></i>}
                  Post Unlocked
                  </div>
               
 </div>,{
position:"top-right",
autoClose:5000,
hideProgressBar:false,
newestOnTop:false,
closeOnClick:true,
rtl:false,
pauseOnFocusLoss:true,
draggable:false,
pauseOnHover:true,
theme:"light"})
      //
       }
       else{
        
        // this.props.updateProfileParams(amount)
        
        this.createError(result.data.payPerView)
        //modify post clear it after testing
        
        this.setState({loaderState:false,contact:""})
       }

  }).catch((err)=>{
    console.log(err)
    this.createError(`Invalid Input,${err}`)
    this.setState({loaderState:false,contact:""})
  })

  //clear apollo cache

  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}
// numberchosen = (event)=>{
//   this.setState({price:event.target.value})
// }

closeModal() {
  this.setState({iMO:false,errorMsg:null})
  
  //this.update();
}


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
        this.setState({loaderState:false,errorMsg:null})
      }
    }
   
    
    render() {
     const {completed,amount,errorMsg,price} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div className="cenceph_pure_black">
       
        
        {!completed && <p> Unlock for Ghc {this.props.price}  </p>}
        {this.props.discount && !completed && <p>Discount Active: {`${!this.calculateExpiry(this.props.discount.createdAt,this.props.discount.expires)}`}</p>}
        {completed && <p> Successfully unlocked</p>}

        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
          <Popup3 isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
          <Loader isOpen={this.state.loaderState}/>

      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />  */}
      {!completed && <input onChange={()=>this.setState({useToken:!this.state.useToken})} value={this.state.useToken} type="checkbox"/>}
     {!completed && <input onChange={(e)=>this.contactChosen(e)} value={this.state.contact} placeholder="eg. 23355XXXXXXX" type="text" />}
      {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.payView(this.props.id)}>pay</button>
  
  
  
  
  </div>}
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default Payperview;