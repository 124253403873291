//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import routes from '../storage_apollo_routes';
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import Alertslist from '../UI/Alertslist';
import SkeletonAlerts from '../UI/SkeletonAlerts';
// const client = new ApolloClient({
//   cache: new InMemoryCache({
//     typePolicies: {
//       Query: {
//         fields: {
//           getPosts_: offsetLimitPagination(),
//           //  keyArgs:false,
//           //  merge(existing=[],incoming){
//           //    return [...existing,...incoming]
//           //  },
//         },
//       },
//     },
//   }),
//   link,
// });

const ath = localStorage.getItem("userID")

const httpLink = new HttpLink({
  uri: routes.apollo,
});

const wsLink = new GraphQLWsLink(createClient({
  url: routes.apollo_ws,
  connectionParams: {
    authentication: localStorage.getItem("userID"),
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const ALERT_QUERY = gql`
  query Alertlist($id:Int!){
    getAlerts(id:$id){
      type
      message
      id
      createdAt
      replyId
      postId
      commentId
      hasViewed
    }
  }
`;

const ALERTS = gql`
  subscription Alerted($toUser:Int!) {
    alertAdded(toUser:$toUser){
      type
      id
      message
     
    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache()
});

const Alerts = (props)=>{

  console.log(ath,localStorage.getItem("userID"))


// const []
const [alerts,setAlerts] = useState([])
// const { data, loading } = useSubscription(
//   ALERTS,{ variables: { toUser:parseInt(ath) },client:client });

/**
 * @typedef Alert
 * @prop {string} createAt
 * @prop {integer} type
 * @prop {integer} id
 *@prop {string} message
 */
/**
 * Gets all relevant user data
 */
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }

    const {subscribeToMore,error,loading,...result} = useQuery(
      ALERT_QUERY,
      { variables: {id:parseInt(ath)} ,client:client}
    );
    return (<>
    {loading || error  && <SkeletonAlerts/>}
    {!error && <Alertslist {...result}
    {...result}
    subscribeToNewComments={() =>
      subscribeToMore({
        document: ALERTS,
        // fetchPolicy:"",
        // nextFetchPolicy:"",
        variables: {  toUser:parseInt(ath) },
        updateQuery: (prev, { subscriptionData }) => {
          if (!subscriptionData.data) return prev;
          const newFeedItem = subscriptionData.data.alertAdded;
          // console.log("ABCDEFGHIJKLMNOP",prev)

          return Object.assign({}, prev, {
            getAlerts:  [newFeedItem, ...prev.getAlerts]
          
          });
        }
      })
    }
    />}</>);
  

// useEffect(()=>{

//   if(data){
//     console.log(data)
//     props.append(data)
//     //setAlerts(alerts.concat([data]))
    
//   }
//   console.log("alerts",data)

// },[])



// const alert =()=> {

  

  // setAlerts(prevdata =>[...prevdata,data])


  
// }

}
export default Alerts