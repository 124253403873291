import routes from '../storage_apollo_routes';
import React, { Component } from 'react';
import Popup3 from './Popup3';
import Loader from './Loader';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import Profiledetail from './Profiledetail';
import ProfileDetail2 from './ProfileDetail2';
import { setContext } from '@apollo/client/link/context';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    
    return {
      headers: {
        //"Apollo-Require-Preflight": "true",
        "X-Pinggy-No-Screen":"true"
      },
    };
  });
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink.concat(link),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

class Payment extends Component {
   constructor(props){
    super(props)
    this.state={
        bundles:[],
        response:"",
        contact:"",
        loaderState:false,
        isModalOpen:false,
        errorMsg:"",
        nextPage:null,
        selected:"",
        profilestat:null,
        price:1,
        errorMsg:"",
        togglePopup:false

    }
   }


   calculatePercentageOff = (param,basePrice,time)=>{
    let bundlePrice = param/time
    let unitPrice = basePrice/30
    let percentage = 100*(1-(bundlePrice/unitPrice))
    console.log(bundlePrice,unitPrice,basePrice,percentage,time,param)
   return <p>({Math.round(percentage*10)/10}% off)</p>
  }

  createError = (message)=>{
    //console.log(message)
    this.setState({
      errorMsg:"Check Your Funds!",
      isModalOpen:true,
      loaderState:false
    })
  }
   subScribe = (id)=>{
    const {contact} = this.state
    console.log(contact)
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(!pattern){
      return 
    }
    this.setState({loaderState:true})
    client
    .mutate({
      mutation: gql`
        mutation SubToUser {
          toggleBundle(id:${id},contact:"${contact}")
        }
      `,
    }).then((result)=>{
      if(result.data.toggleBundle == "SUCCESSFUL"){
        this.setState({response:result.toggleBundle,loaderState:false,nextPage:true})
        //redirect to profile/"id"
      }
      else{
        console.log("PAYMENT UNSUCCESSFULL")
        this.setState({isModalOpen:true,errorMsg:result.data.toggleBundle,loaderState:false})
      }
    }).catch(error=>{
      this.createError(error)
      //console.log(error)
    })
   }

   contactChosen = (event,id)=>{
    this.setState({contact:event.target.value,selected:id});
    
}
profileRedirect = () =>{

}
closeModal = ()=> {
      
  this.setState({box:!this.state.box})
  this.setState({ isModalOpen: false })
  
  //this.update();
}

getProfileStat = async()=>{
  let result = await client.query({
    query:gql`
    query Stats{
       getProfileStats(id:${this.props.location.prevstate.id}){
        socials{
          name
          link
        }
      subscribers
      profile{
        id
        name
        email
        description
        photo
        price
        UserId
      }
      posts
    }
  }`
  }).then((res)=>{
    //console.log(res,res.data.getProfileStats.profile.price,"@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
    this.setState({profilestat:res.data.getProfileStats,price:res.data.getProfileStats.profile.price})
  }).catch(err=>console.log(err))
}
   getBundles = async (id)=>{
    let result =  await client
      .query({
        query: gql`
          query Bundles {
            getAllBundles(id:${this.props.location.prevstate.id}) {
              endDate
              price
              id
            }
          }
        `,
      }).then((r)=>{
        console.log(r.data.getAllBundles)
        this.setState({bundles:r.data.getAllBundles})
        
      })
      .catch((err)=>{
        console.log(err,"err")
      })
     
   }
   componentDidMount(){
    //console.log("BEEEEETLEJUUiCE")
    if(!this.props.location.prevstate ) return window.location.href = "/posts"
 let id = this.props.location.prevstate.id 
 let subbed = this.props.location.prevstate.subbed
 this.getBundles(this.props.location.id)
 this.getProfileStat()
   //console.log(this.props.location.id,this.props.location.prevstate,"b is here")
   
   }
    render() {
        const {bundles,errorMsg,loaderState,nextPage,selected,profilestat} = this.state
        return (
            <div className='container' >
                {profilestat && <ProfileDetail2 price={this.state.price} profileStat={profilestat}/>  } 
               
            <div class="containere">
<div class="row justify-content-center">
    {bundles.map(bundle =>(<div  key={bundle.id} class="col-lg-4 col-md-6 col-sm-12">
        <div class="carde">
            <div class="overlay"></div>
            <div class="content">
                <h4>Duration: {bundle.endDate} Days</h4>
                <h6>Price: GHC {bundle.price}</h6>
                <h6> {this.calculatePercentageOff(bundle.price,this.state.price,bundle.endDate)}</h6>
                <div style={{"position":"relative","left":"99px"}}> {bundle.id==selected && <Loader isOpen={loaderState}/>}</div>
               
                <input onChange={(e)=>this.contactChosen(e,bundle.id)}  type="text" />
            </div>
            <div class="fav">
            {!nextPage && <button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.subScribe(bundle.id)}>pay</button>}
            {nextPage && <Link to={{pathname:`/profile/${this.props.location.prevstate.id}`, subbed:true,id: this.props.location.prevstate.id,
  }}> <button className="wpc-btn wpc-btn-default-rounded">proceed</button></Link>}
            </div>
        </div>
        </div>)
  
         
        )}
        </div>
        </div>
        <Popup3 isOpen={this.state.isModalOpen} error={errorMsg} onClose={() => this.closeModal()}>
           
            
          </Popup3>
        
       </div>
        
        );
    }
}

export default Payment;