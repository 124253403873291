import React, { Component } from 'react';
import Popup5 from './Popup5';
import "./Styles/Profiledetail.css"
import routes from '../storage_apollo_routes';
class ProfileDetail2 extends Component {
    constructor(props){
        super(props)
        this.state={
          isOpen:false,
          iMO:false,
          errorMsg:"",
          bundles:"is-active",
          help:""
        }
    }

    closeModal() {
      
      //this.setState({box:!this.state.box})
      this.setState({ isOpen: false ,iMO:false})
      
      //this.update();
    }

    checkHelp =()=>{
      this.setState({bundle:"",help:"is-active"})
         }

         checkBundle=()=>{
          this.setState({help:"",bundle:"is-active"})
         }
    mapSocial = (name)=>{
        let svgMap = {
            twitter:"fa fa-twitter",
            whatsapp:"fa fa-whatsapp",
            facebook:"fa fa-facebook",
            snapchat:"fa-snapchat-ghost",
            instagram:"fa fa-instagram"
        }

        return svgMap[name]
    }

open =()=>{
  this.setState({isOpen:!this.state.isOpen})
}
    componentDidMount(){
      console.log(this.props)
    }
    render() {
        return (
            <div>
                <div className="container">
                <Popup5 id={this.props.profileStat.profile.id}  userID={this.props.profileStat.profile.UserId}onClose={() => this.closeModal()} isOpen={this.state.isOpen}/>
                <div class="pdcard" data-state="#about">

  <div class="pdcard-header">
    <div class="pdcard-cover" style={{backgroundImage: "url('https://images.unsplash.com/photo-1549068106-b024baf5062d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80')"}}></div>
    <img class="pdcard-avatar" src={`${routes.storage_1}${this.props.profileStat.profile.photo}`} alt="avatar" />
    <h1 class="pdcard-fullname">{this.props.profileStat.profile.name}</h1>
    <div style={{paddingTop:"11px"}}>
    <h2 class="pdcard-jobtitle"></h2>
    </div>
    
  </div>
  <div class="pdcard-main">
    <div class="pdcard-section is-active" id="about">
      <div class="pdcard-content">
        <div class="pdcard-subtitle">ABOUT</div>
        <p class="pdcard-desc">{this.props.profileStat.profile.description}
        </p>
      </div>
      {this.props.profileStat.socials.length >=1 && <div class="pdcard-social">
        
        {this.props.profileStat.socials.map((social)=><a href={`${social.link}`}>
        <i className={`${this.mapSocial(social.name)}`} aria-hidden="true"></i></a>)}
       
      </div>}
    </div>
    <div class={`pdcard-section ${this.state.help}`} id="experience">
      <div class="pdcard-content">
        <div class="pdcard-subtitle">REQUESTS AND SUBSCRIPTIONS</div>
        <div class="pdcard-timeline">
        <div class="pdcard-item" data-year="1">
            <div class="pdcard-item-title">Request a <span>Video/Photo</span></div>
            <div class="pdcard-item-desc">Requests cost GHC X.XX</div>
          </div>
          <div class="pdcard-item" data-year="2">
            <div class="pdcard-item-title">What are  <span>Requests?</span></div>
            <div class="pdcard-item-desc">You can ask for customized videos from your favorite creator for a price</div>
          </div>
          <div class="pdcard-item" data-year="3">
            <div class="pdcard-item-title">What's a 30-day <span>Subscription?</span></div>
            <div class="pdcard-item-desc">Subscribe to a creator for 30 days only to get more benefits from your favorite creators.</div>
          </div>
      
          {/*<div class="pdcard-item" data-year="4">
            <div class="pdcard-item-title">Full-Stack Developer at <span>CodePen</span></div>
            <div class="pdcard-item-desc">Responsible for the encomposing brand expreience.</div>
          </div> */}
        </div>
      </div>
    </div>
    <div class={`pdcard-section ${this.state.bundles}`} id="contact">
      <div class="pdcard-content ">
        <div class="pdcard-subtitle">CONTACT</div>
        <div class="pdcard-contact-wrapper">
          <div class="pdcard-contact">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M21 10c0 7-9 13-9 13s-9-6-9-13a9 9 0 0118 0z" />
              <circle cx="12" cy="10" r="3" /></svg>
            A 30-day Subscription costs GHC {`${this.props.price}`}
          </div>
          {/* <div class="pdcard-contact">
            <svg xmlns="http://www.w3.org/2000/svg" viewbox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M22 16.92v3a2 2 0 01-2.18 2 19.79 19.79 0 01-8.63-3.07 19.5 19.5 0 01-6-6 19.79 19.79 0 01-3.07-8.67A2 2 0 014.11 2h3a2 2 0 012 1.72 12.84 12.84 0 00.7 2.81 2 2 0 01-.45 2.11L8.09 9.91a16 16 0 006 6l1.27-1.27a2 2 0 012.11-.45 12.84 12.84 0 002.81.7A2 2 0 0122 16.92z" /></svg>(269) 756-9809</div> */}
          <div class="pdcard-contact">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
              <path d="M4 4h16c1.1 0 2 .9 2 2v12c0 1.1-.9 2-2 2H4c-1.1 0-2-.9-2-2V6c0-1.1.9-2 2-2z" />
              <path d="M22 6l-10 7L2 6" /></svg>
              {this.props.profileStat.profile.email}
          </div>
          <button  onClick={()=>this.open()} class="contact-me">SUBSCRIBE</button>
        </div>
      </div>
    </div>
    <div class="pdcard-buttons">
      <button data-section="#about" onClick={()=>this.checkBundle()} class={this.state.bundles}>CONTENT REQUEST</button>
      <button onClick={()=>this.checkHelp()} class={this.state.help} data-section="#experience">HELP</button>
      <button data-section="#contact">CONTACT</button>
    </div>
  </div>
</div>
                </div>
            </div>
        );
    }
}

export default ProfileDetail2;