import React ,{useState,useEffect} from "react"
import { Link } from "react-router-dom";
import Upload4 from "./UI/Upload4"
import routes from "./storage_apollo_routes";
import { setContext } from "@apollo/client/link/context";
import Loader from "./UI/Loader";
import FirstPay from "./UI/FirstPay";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    
    return {
      headers: {
        "Apollo-Require-Preflight": "true",
        "X-Pinggy-No-Screen":"true"
      },
    };
  });
  const link = createHttpLink({
    uri:routes.apollo,
    
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink.concat(link),
  });

  const client_2 = new ApolloClient({
    cache: new InMemoryCache(),
    link:authLink.concat(link),
    defaultOptions : {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });

  const visit = gql`
  mutation{
    pageVisit
   
    
  }
  
  `
  const checkRequestStatus = gql`
  query CheckStatus($secret:String!){
checkRequestStatus(secret:$secret){
  rejected
  createdAt
  status
  finalLink
}
  }
  `


const Landing = () =>{
  const [uploaded,setUploaded] = useState(false)
  const [firstPayHandlerStatusComplete,setStatusComplete] = useState(false)
  const [errorMsg,setErrorMsg] = useState("")
  const [iMO,setiMO] = useState(true)
  const [secret,setSecret] = useState(false)
  const [requests,setRequests] = useState(null)
  const [loaderState,setLoaderState] = useState(false)
useEffect(()=>{
 visitCount()
},[])

const closeModal=()=>{
  this.setState({iMO:false,errorMsg:""})
}
const calculateExpiry = (dateString)=>{
  let d2= new Date(dateString)
let d1 = Date.now()

let dif= Math.abs(d2-d1);
let d = Math.round((dif/(1000 * 3600 * 24)))
//bundle or regular subscription
// let days_left = 30 - d
// console.log(d)
return d < 7
}

const checkRequestStatusFn = async ()=>{
  
  if(!secret)return 
  setLoaderState(true)
  await client_2.query({
    query:checkRequestStatus,
    variables:{
      secret:secret
    }
}).then((r)=>{
setRequests(r.data.checkRequestStatus)
setLoaderState(false)
window.scrollTo({top:document.body.scrollHeight,behavior:"smooth"})

}).catch((e)=>{
  setLoaderState(false)
  console.log(e)
})

}
const visitCount = async ()=>{
await client.mutate({
    mutation:visit
}).then((r)=>{
    console.log(r)
}).catch((e)=>{
    console.log(e)
})
}
return <div className="wrapperRegister"> 
{<FirstPay onComplete={()=>{
  setStatusComplete(true)
  setUploaded(false)
  }} isOpen={uploaded} FirstPay={errorMsg} onClose={() => closeModal()}/>}
    <Upload4 firstPayHandlerStatusComplete={firstPayHandlerStatusComplete} setUploaded={setUploaded}/>
    <div className="container">
      <p style={{fontSize: "16px", fontWeight: "600"}}className="tooltiptext">Or ...</p>
      <Link to={{pathname:"/login"}}><button style={{fontSize: "19px", fontWeight: "600"}}className='wpc-btn wpc-btn-default-rounded tilt-shake'>Login</button></Link>
      <h2 style={{fontSize: "21px", fontWeight: "600"}}> 
        What's Uhsoka? <br/>
        <span style={{color:"#ffff",backgroundColor:"blue",fontSize: "20px", fontWeight: "600"}}>Got a video or photo you want to sell? Post it here,share the link, see who's bought and how many are interested...</span>
        <br/><a href="https://chat.whatsapp.com/FVQAt3HP91v2Z9Bh1utzR8"><span  style={{fontSize:"12px"}}> i'd really like to hear from you</span></a>
      </h2>
      <div style={{width:"340px"}}>
        <p>Check Request Status</p>
        <h2 style={{fontSize: "21px", fontWeight: "600"}}> 
        Already made a Request? <br/>
        <span style={{color:"#ffff",backgroundColor:"blue",fontSize: "20px", fontWeight: "600"}}>Use the Secret you typed to check the status of the request.Example: uhsoka</span>
        <br/>
        {/* <a href="https://chat.whatsapp.com/FVQAt3HP91v2Z9Bh1utzR8"><span  style={{fontSize:"12px"}}> i'd really like to hear from you</span></a> */}
      </h2>
        <div class="input-container">
        { <input style={{color:"#2b2626",border:"2px solid #cf4848"}}className="input_e"onChange={(e)=>{setSecret(e.target.value)}}  type="text" />}
              <label style={{text:"#392a2a"}}for="">{(false)?"":"Enter Secret"}</label>
              <span>Secret</span>
            </div>
            <button onClick={()=>checkRequestStatusFn()} style={{fontSize: "19px", fontWeight: "600"}}className='wpc-btn wpc-btn-default-rounded tilt-shake'>
              {(loaderState)?
              <Loader isOpen={loaderState}/>:
              "Check"}
              </button>
            
            {requests && requests.map((request)=>(
              <div style={{paddingTop:"12px"}} className="container">
              




              <a href={request.finalLink}> <div class="parking-spot">
                        <div class="status-icon">
                            <i class="fa fa-gift"></i>
                        </div>
                        <div class="spot-info">
                            <div class="spot-header">
                                <div class="spot-name">{secret}</div>
                                <div style={{backgroundColor:(request.rejected)?"#df4541":"#71d3d3"}} class="payment-badge">
                                    <i class="">
                                    </i>
                                    { (request.rejected)?"Rejected":
               <div>{(request.status)?<div><a href={request.finalLink}>Complete</a></div>:
               (calculateExpiry(request.createdAt))?<div>Pending</div>:
               <div>Expired</div>}</div>}
                                </div>
                            </div>
                            <div class="spot-details">
                                {/* <div class="spot-detail">
                                    <i class="fas">
                                    </i>
                                    {
                                      spot.spots > 0
                                        ? `${spot.spots} spots`
                                        : "Full"
                                    }
                                </div>
                                <div class="spot-detail">
                                    <i class="fas fa-clock"></i>
                                    ${spot.hours}
                                </div> */}
                            </div>
                        </div>
                    </div></a>






              </div>
            ))}
      </div>
      </div> 

</div>
}


// style={{display: "inline-flex",
// alignItems: "center",
// position:"absolute",
// top:"0",
// left:"0",
// bottom:"0",
// right:"0",
// margin: "auto",
// //overflowX: "hidden",
// maxWidth:"900px",
// // display:"flex",
// // minHeight: "100vh",
// justifyContent: "center"}}>
//     <div className="wrapperRegister">
//     <div class="card_landing-wrapper">
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}} class="card_landing-inner">
//   <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       UPLOAD
//     </div>

//   </div>
// </div>
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}} class="card_landing-inner">
//   <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       SHARE
//     </div>
//   </div>
// </div>
// <div class="card_landing">
//   <div style={{fontSize:"2rem"}}class="card_landing-inner">
//     <div style={{justifyContent:"center",alignItems:"center",display:"flex"}}>
//       PROFIT
//     </div>
//   </div>
// </div>
// {/* <div class="card_landing">
//   <div class="card_landing-inner"></div>
// </div> */}
// </div>
//     </div>

export default Landing