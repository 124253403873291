import PostImage from './PostImage';
import routes from '../storage_apollo_routes';
import PostImageRounded from './PostImageRounded'
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import ContributionsPopup from './ContributionsPopup';
import HomeOptions from '../Controllers/HomeOptions';
import Lists from '../Controllers/Lists';
import UnreadsSingle from '../Controllers/UnreadsSingle';
import BulkMessages from '../Controllers/BulkMessages';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });

  const fetch = gql `
  query myQuery {
    getSubstats{
    
      subscribers{
          id
          photo
          name
          email
          messageId
          unreads{
          message
          from
          UserId
        }
          user_profile{
            createdAt
            
          }
        }

        subscriptions{
          id
          name
          photo
          email
          unreads{
            message
            from
            type
            UserId
          }
          UserId
          user_profile{
            createdAt
            
            
          }
        }
      
      
    }
  }`

class Stats extends Component {
    constructor(props){
        super(props)
        this.state={
            open:false,
            subscribers:[],
            subscriptions:[],
            subscriberArr:null,
            subscriptionArr:null,
            displayTrans:false,
            transactions:[],
            selectedTrans:null,
            statData:{},
            displayLists:false,
            showBulkMessages:false
        }
    }
beforeRedirect =(index,type)=>{

  if(type =="subscriptions"){
    client.cache.updateQuery({ query: fetch }, (data) => ({
      getSubstats:{
        subscriptions: data.getSubstats.subscriptions.map((todo,i) => {
        console.log(todo,i)
        if (index == todo.id) {
         let newtodo = {...todo,unreads:[]}
         console.log(todo,newtodo)
          todo = newtodo
        }
        return todo;
      }),
    subscribers:data.getSubstats.subscribers
    }
    }));
    return 
  }
  client.cache.updateQuery({ query: fetch }, (data) => ({
    getSubstats:{
      subscribers: data.getSubstats.subscribers.map((todo,i) => {
        if (index == todo.id) {
         
          let newtodo = {...todo,unreads:[]}
          console.log(todo,newtodo)
          todo = newtodo
        }
        return todo;
      }),
      subscriptions:data.getSubstats.subscriptions
    }
  }));

}
    changeActive = (index,type)=>{
      let prevState 
      if(type ==  "subscription"){
      prevState = this.state.subscriptionArr
        prevState[index] = true
        this.setState({subscriptionArr:prevState})
        return
      }
 
      prevState = this.state.subscriberArr
      prevState[index] = true
      this.setState({subscriberArr:prevState})
    
    }

 getContributions = (id)=>{
  this.setState({selectedTrans:id})
  client
  .query({
    query: gql`
      query myQ {
        getContributions(id:${id}){
          from
          amount
          type
        }
      }`}).then((result)=>{
        console.log(result.data.getContributions,"CHATO")
        this.setState({transactions:result.data.getContributions,displayTrans:true})
        }).catch((err)=>{
          console.log(err)
        })
 }
    getTimeLeft = (dateString) =>{
      let d2= new Date(dateString)
let d1 = Date.now()

  let dif= Math.abs(d2-d1);
  let d = Math.round((dif/(1000 * 3600 * 24)))
  //bundle or regular subscription
  // let days_left = 30 - d
// console.log(d)
   return d
    }

    storeStatData=(data,id)=>{
      let newState = this.state.statData

    if(newState[id]){
      return
    }
    newState[id] = data
    console.log(newState)
    this.setState({statData:newState})
    }

    getSubStats = ()=>{
        client
        .query({
          query: gql`
            query myQuery {
              getSubstats{
              
                subscribers{
                    id
                    photo
                    name
                    email
                    subbed
                    messageId
                    unreads{
                    message
                    from
                    UserId
                  }
                    user_profile{
                      createdAt
                      
                    }
                  }

                  subscriptions{
                    id
                    name
                    photo
                    email
                    unreads{
                      message
                      from
                      type
                      UserId
                    }
                    UserId
                    user_profile{
                      createdAt
                      
                      
                    }
                  }
                
                
              }
            }
          `,
        }).then((results)=>{
            console.log(results,"CHATO")
            this.setState({subscribers:results.data.getSubstats.subscribers,subscriptions:results.data.getSubstats.subscriptions,
              subscriberArr:results.data.getSubstats.subscribers.map(r=>r.unreads && r.unreads.length > 0),
              subscriptionArr:results.data.getSubstats.subscriptions.map(r=>r.unreads && r.unreads.length > 0)})
        }).catch((error)=>{
            console.log(error)
        })
    } 
    toggleBulkMessages =()=>{
      this.setState({showBulkMessages:!this.state.showBulkMessages})
    }
  
    componentDidMount(){
        this.getSubStats()
     
    }
	// calculatePercentageOff = (param,basePrice,time)=>{
	// 	let bundlePrice = param/time
	// 	let unitPrice = basePrice/30
	// 	let percentage = 100*(1-(bundlePrice/unitPrice))
	// 	console.log(bundlePrice,unitPrice,percentage,time,param)
	//    return <p>({Math.round(percentage*10)/10}% off)</p>
	//   }

  openLists = ()=>{
    this.setState({displayLists:!this.state.displayLists})
  
  }


	deleteBundle(id){
		this.props.delete(id)
	}
    // componentWillReceiveProps(props) {
    //     this.setState({open:props.toggle,bundle_data:props.bdl})
    //     console.log("I WAS CALLED",props.toggle,props.bdl)
    //     console.log(this.state.open,this.state.bundle_data,"STATAAAAA")
    //     // return the new state as object, do not call .setState()
    //     return { 
    //         open: props.toggle,
    //         bundle_data: props.bdl

    //     };
    
    // }
    render() {

   const {open,subscribers,subscriptions} = this.state
        if (this.props.toggle == null)
            return null
        
    

        return (
            <div className='container'>
            
                            <div className="list2-wrapper" ng-app="app" ng-controller="MainCtrl as ctrl">
                               
            <ul className="list2">
              {/* <li class="list2-item">
                <div>
                  <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/488320/profile/profile-80.jpg" class="list2-item-image"/>
                </div>
                <div class="list2-item-content">
                  <h4>Hitesh Kumar</h4>
                  <p>@hk-skit</p>
                </div>
              </li> */}
              {/* <p>Subscribers</p> */}
              <HomeOptions toggleSettings={this.props.toggleSettings} earningsGraphShow={this.props.earningsGraphShow}onClose={this.props.onClose} toggleBulkMessages={this.toggleBulkMessages} toggleDeposit={this.props.toggleDeposit} listController={this.openLists}/>
              {this.state.displayLists &&<Lists subs={this.state.subscribers} displayLists={this.state.displayLists}/>}
              {this.state.subscribers.length > 1 && this.state.showBulkMessages &&    <BulkMessages selectState={subscribers.map(a=>false)} toggleBulkMessages={this.toggleBulkMessages} listUsers={subscribers}showBulkMessages={this.state.showBulkMessages}/>}
             
              <div style={{maxHeight:"300px",overflowY:"auto"}}>{!this.state.displayLists && subscribers.map((dat,i)=>(<div key={dat.name}>

                <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3)),url(storage/${dat.photo})`}} className="list2-item">
             
                <div style={{height:"100px",width:"70px",position:"relative"}}>
                {/* <span style={{
                    position: "absolute",
                    right:"-0px",
                    borderRadius:"100%",
                    height:"17px",
                    width:"17px",
                    top:"1px",
                    //subbed 
                    background:"blue",
                    textAlign: "center",
                    padding:"5px 10px"}} className="notify-badge"></span> */}
                    <UnreadsSingle index={i} type={"subscribers"}changeActive={this.changeActive} unreadMessage={this.state.subscriberArr[i]} id={dat.id}/>
                <Link to={{pathname:`/profile/${dat.id}`, subbed:dat.subbed,id: dat.id,
  }}><PostImage postSrc={dat.photo}
 alt="profilepic" className="list2-item-image"/></Link>
                </div>
               
                <div className="list2-item-content">
                <Link onClick={() => this.beforeRedirect(dat.id,"subscribers")}to={{pathname:`/chatwith/${dat.id}`,to_id:dat.id,profile_data:null}}><h4>{dat.name}</h4></Link>
                  
                  <p>{dat.email}</p>
                    
                  
                  <div style={{opacity:"0.5",color:"orange",fontSize:"14px",position:"relative",textAlign:"center",justifyContent:"center"}} >
                  <p>{this.getTimeLeft(dat.user_profile.createdAt)} days elapsed</p>
                  </div>
                  <ul style={{cursor:"pointer"}} className="stat_ul">
                  <li onClick={()=>this.getContributions(dat.id)} className="stat_email">more</li>
                    </ul>
                  {/* <p style={{cursor:"pointer"}}onClick={()=>this.getContributions(dat.id)}>more</p> */}
                 
                </div>
               { this.state.selectedTrans == dat.id  &&<div style={{position:"relative",right:"0px"}}className="list2-item-content">
                <ContributionsPopup storeStatData={this.storeStatData} currentid={dat.id} displayTrans={this.state.displayTrans} transactions={this.state.transactions}/>
               
                
                </div>}
                
              </li>
</div>))}</div>
              {/* <p>Subscriptions</p> */}
             
                 <div style={{maxHeight:"300px",overflowY:"auto"}}> { !this.state.displayLists && 
                 <div><p style={{borderRight:"0",padding:"0"}} className="logo">Subscriptions</p>
                 { subscriptions.map((dat,j)=>(<li ng-repeat="user in ctrl.users" key={dat.email} style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3)),url(https://storage.googleapis.com/uhs_bucket_master/${dat.photo})`}} class="list2-item">
                <div style={{height:"100px",width:"70px",position:"relative"}}>
                <UnreadsSingle index={j} type={"subscription"}changeActive={this.changeActive} unreadMessage={this.state.subscriptionArr[j]} id={dat.UserId}/>
                  <Link to={{pathname:`/profile/${dat.UserId}`, subbed:true,id: dat.UserId,
  }}><PostImage postSrc={dat.photo} alt="profilepic" className="list2-item-image"/></Link>
                </div>
                <div className="list2-item-content">
                <Link  onClick={() => this.beforeRedirect(dat.id,"subscriptions")} to={{pathname:`/chatwith/${dat.UserId}`,to_id:dat.UserId,profile_data:null}}> <h4>{dat.name}</h4></Link>
                
                  <p>{dat.email}</p>
                
                  <div style={{opacity:"0.5",color:"orange",fontSize:"14px",position:"relative",textAlign:"center",justifyContent:"center"}} >
                  <p>{this.getTimeLeft(dat.user_profile.createdAt)} days elapsed</p>
                  </div>
                 
                </div>
              </li>
              ))}</div>}</div>
            </ul>
          
          </div>
                </div>

        );
    }
}

export default Stats;