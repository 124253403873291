import React, { Component } from 'react';
import  {Line}   from 'react-chartjs-2'
// import 'chartjs-adapter-date-fns';
// import "./Styles/Earningsgraph.css"
// import  Chart from 'chart.js/auto'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend,
    Filler,
    
  } from 'chart.js';
  
// ChartJS.register(
//     CategoryScale,
//     LinearScale,
//     PointElement,
//     LineElement,
//     Title,
//     TimeScale,
//     Tooltip,
//     Legend,
//     Filler,
//   );

  const UsageStats = ({config}) =>{
    console.log(config.data,config.options)
return (<div style={{"height":"500px",width:"500px"}}>
    <Line data={config.data} options={config.options} />
</div>)
  }

  export default UsageStats