import React, { Component } from "react";
import routes from "./storage_apollo_routes";
import Frm1 from "./Frm1";
import {Redirect} from "react-router-dom";
import { Formik, Form } from "formik";
//gcloud http://34.125.72.16:4000/ local = "https://5483-154-161-33-98.ngrok-free.app/graphql"
import Popup3 from "./UI/Popup3";
import "./UI/Styles/Register.css"
import Loader from "./UI/Loader";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink_1 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
  uri:routes.apollo,
  
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink_1.concat(link),
});

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      isOpen:false,
      iMO:false,
      errorMsg:""
    };
  }

  register(val) {
    this.setState({isOpen:true})
    const logIn = gql`
      mutation logIn($name: String!, $password: String!) {
        logIn(name: $name, password: $password) {
          id
        }
      }
    `;
    client
      .mutate({
        mutation: logIn,
        variables: { name: val.name, password: val.password },
      })
      .then((result) => {
        console.log(result.data.logIn.id);
       this.setState({isOpen:false})
        localStorage.setItem("userID", result.data.logIn.id);
        
        this.setState({ redirect: !this.state.redirect });
      })
      .catch((err) => {
        this.setState({isOpen:false})
        console.log("catch", err);
      });
  }

  onClose=()=>{
    this.setState({isOpen:false})
    this.setState({iMO:true,errorMsg:"An error occured during registration"})
  }

  closeModal=()=>{
    this.setState({iMO:false})
  }
  componentDidMount() {
    // const link = createHttpLink({
    //   uri: 'http://localhost:4000',
    //   credentials: 'include'
    // });
    // const client = new ApolloClient({
    //   cache: new InMemoryCache(),
    //   link
    // });
    //   client
    // .query({
    //   query: gql`
    //     query myQuery {
    //     me{
    //     name
    //     password
    //     }
    //     }
    //   `
    // })
    // .then(result => console.log(result));
    //   const logIn = gql`
    //   mutation logIn($name: String!, $password: String!) {
    //     logIn(name: $name, password: $password) {
    //       id
    //     }
    //   }
    // `;
    //   client
    //   .mutate({
    //    mutation: logIn,
    //    variables: { name:"ANDREW",password:"ANDREW" }
    //   }).then((result) => {
    //    console.log(result);
    //   })
    //   .catch(err => {
    //    console.log("catch", err);
    //   });
  }
  render() {

    if (this.state.redirect === true) {
       (window.location.href = "/homepage");
      // return <Redirect to={{ pathname: "/posts" }} />;
    }

    return (
      // <div>
        
      //   <section>
      //     <div className="container">
      //       <h2>LOGIN HERE</h2>
      //       <Formik
      //         initialValues={{
      //           name: "",
      //           password: "",
      //         }}
      //         onSubmit={(values) => {
      //           this.register(values);
      //         }}
      //       >
      //         {(formik) => (
      //           //console.log(formik)
      //           <Form>
      //             <Frm1 label="name" name="name" type="text" />
      //             <Frm1 label="password" name="password" type="password" />
      //             <button type="submit">log in</button>
      //           </Form>
      //         )}
      //       </Formik>
      //     </div>
      //   </section>
      // </div>
      <div>
               <div className="wrapperLogin fadeInDown">
  <div id="formContent">
    
    <h2 className="active"> Sign In </h2>
    {/* <h2 className="inactive underlineHover"> <a className="underlineHover" onClick={()=>{this.toggleType()}}>Sales</a></h2> */}

    
    <div className="fadeIn first">
    </div>
    <Popup3 isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>
    
    {/* <form onSubmit={this.handleSubmit}>
      <input type="text" id="login" className="fadeIn second" name="login" value={""} onChange={this.handleChangeemail} placeholder="email"/>
      <input type="password" id="password" className="fadeIn third" name="login"value={""} onChange={this.handleChangepassword} placeholder="password"/>
      <input type="submit" className="fadeIn fourth" value="Log In"/>
    </form> */}
                <Formik
              initialValues={{
                name: "",
                password: "",
              }}
              onSubmit={(values) => {
                this.register(values);
              }}
            >
              {(formik) => (
                //console.log(formik)
                <Form>
                  <Frm1 label="name" name="name" type="text" />
                  <Frm1 label="password" name="password" type="password" />
                  <button className="submit" type="submit">log in</button>
                </Form>
              )}
            </Formik>

   <Loader isOpen={this.state.isOpen} onClose={this.onClose}/>
    {/* <div id="formFooter">
      <a className="underlineHover" onClick={()=>{}}>Forgot Password?</a>
    </div> */}

  </div>
</div>
            </div>
    );
  }
}

export default SignUp;
