import routes from '../storage_apollo_routes';
import React, { Component, useEffect, useRef, useState } from 'react'
import Payperview from './Payperview';
import Loader from './Loader';
import { Link,useLocation } from 'react-router-dom'
import Favorites from './Favorites';
import { usePrevious } from "@uidotdev/usehooks";
import "./Styles/Postcard.css"
import BulkPPV from './BulkPPV';
import TagControllerPost from '../Controllers/TagControllerPost';
import PostImage_2 from './PostImage_2';
import Graph from '../Controllers/Graph';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    gql,
    useQuery
  } from "@apollo/client";
  import { offsetLimitPagination } from '@apollo/client/utilities';
import { NoSchemaIntrospectionCustomRule } from 'graphql';
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(
      {typePolicies:{
        Query:{
          fields:{
            getPosts:offsetLimitPagination(),
              //  keyArgs:false,
              //  merge(existing=[],incoming){
              //    return [...existing,...incoming]
              //  },
              
          }
        }
      }}
    ),
    link,
  });

  const fetch = gql`
  query Posts( $id:Int!, $limit: Int!, $offset:Int!) {
    getPosts(id:$id limit:$limit, offset:$offset) {
      favorited
      count
      commentscount
      tags
      post{
        caption
        price
        file
        id
        UserId
        thumbnail
        duration
        preview
      }
    
    }
  }
`;



const Profile2 = (props) => {

  // posts: [],
  // offset:0,
  // limit:8,
  // profile:null,
  // phohtp:null,
  // display:true,
  // user:this.props.location.user,
  // isModalOpen: false ,
  // results: null,
  //    loading: false,
  //    body:'',
  // console.log(user)
//const [loaderState,setLoaderState] = useState(false)
const [posts,setPosts] = useState([])
const [offset,setOffset] = useState(0)
const [isModalOpen,setModalOpen] = useState(false)
const [results,setResults] = useState(null)
const [body,setBody] = useState('')
const [loading,setLoading] = useState(false)
const [limit,setLimit] = useState(8)
const [profile,setProfile] = useState(null)
const [display,setDisplay] = useState(false)
const [prevDeleted,setNext] = useState(null)
const [selected,setSelected] = useState(null)
const [amount,setAmount] = useState(null)
const [selections,setSelections] = useState([])
const [bundleCount,setBundleCount] = useState(0)
const [totalPrice,setTotalPrice] = useState(0)
const [itemIndices,setItemIndices] = useState([])
const [displayPreview,toggleDisplayPreview]  = useState(false)
const [adjacencyList,setAdjacencyList] = useState({})
// const location = useLocation()
// const {userid} = location.state


const [user,setUser] = useState(props.match.params.id)
//const previousUser = usePrevious(user);
let checkem = client.readQuery({query:fetch,variables:{
    id:user,
    limit:limit,
    offset:offset
    }})
    
if(checkem){
  if(checkem.getPosts[0]){
    if(checkem.getPosts[0].post.UserId != user ){
      console.log(checkem.getPosts[0].post.UserId,user)
      client.cache.reset()
     // queryApi()
  }
  }

   }

const {fetchMore,data,error} = useQuery(fetch,{
  variables:{offset:offset,limit:limit,id:parseInt(user) },
  client:client
})

const child2 = React.createRef();
const [val,setVal] = useState(child2)

//access props from the prev iteration do a diff and 




  useEffect(()=>{
   
  queryApi()
  console.log("updated")
  //client.resetStore()
},[])

const modifyPost = async (id)=>{
   let pst = await  client
  .query({
    query: gql`
      query Single {
        getSinglePost(id:${id}) {
            caption
            price
            file
            id
            thumbnail
            UserId
            preview
            duration
          
        }
      }
    `,
  }).then((result)=>{
    //console.log("li durk aha")
    return result.data.getSinglePost
  }).catch((error   
  )=>{
    console.log(error)
    return false
  })

  const filteredPosts = posts.map(mp =>{ 
    //mp.post.id !== id
    if(pst){
     
      if(mp.post.id == id){
        console.log(pst,mp)
        let newpost = {...mp,post:pst}
         mp = newpost
         console.log(newpost)
      }
       return mp
    }
     return mp
  });
  client.cache.updateQuery( {query:fetch} , (data) => ({
    getPosts: data.getPosts.map((todo) =>{
      if(todo.post.id == id){
        let newpost = ({ ...todo, post:pst})
         todo = newpost
      }
      return todo
    } )
  }));
  console.log(filteredPosts)
  setPosts(filteredPosts)
}
const changefav = (id,mutcount,favorited)=>{
  const filteredPosts = posts.map(mp =>{ 
    //mp.post.id !== id
    
     
      if(mp.post.id == id){
        console.log(id,mutcount,favorited)
        let newpost = {...mp,favorited:favorited,count:mutcount}
         mp = newpost
         console.log(newpost)
      }
       return mp
  
     return mp
  });

   console.log(filteredPosts)

   client.cache.updateQuery( {query:fetch} , (data) => ({
    getPosts: data.getPosts.map((todo) =>{
      if(todo.post.id == id){
        let newpost = ({ ...todo, favorited:favorited,count:mutcount})
         todo = newpost
      }
      return todo
    } )
  }));
  setPosts(filteredPosts)
}

const HandleScroll = async () =>{
  //   const {limit,offset,user,posts} = this.state
    setVal(val)
    

   
    const bottom = child2.current.scrollTop >= child2.current.scrollHeight /2 //child.current.clientHeight
    console.log(bottom,child2.current.scrollHeight,child2.current.scrollTop,child2.current.clientHeight)
   if(bottom){
     //setLoaderState(true)
     await fetchMore({
      variables:{offset:posts.length,limit:posts.length*2,id:parseInt(user)}
    })
    console.log((posts.length*2),(posts.length))
   if(data){
    //console.log(prevDeleted)
    if(prevDeleted !== null){
      const filtered = data.getPosts.filter(ftr => ftr.post.id !== prevDeleted);
      setPosts(filtered)
      console.log("PREVIOUS DELETED WAS SUCCESSFULLY CALLED NIGGA UCK THE CACHE")
    }
    else{
      
      setPosts(data.getPosts)
     
     
      
      console.log("ELSE WAS CALED INSTEAD")
    }
    console.log((data.getPosts.length*2),(data.getPosts.length))
     setLimit((data.getPosts.length*2))
    setOffset(data.getPosts.length)
   }
    // console.log(offset,limit)
    // client
    // .query({
    //   query: fetch,
    //   variables:{offset:offset,limit:limit,id:user || localStorage.getItem("userID")},
    // })
    // .then((result)=>{
    //   this.setState({posts:this.state.posts.concat(result.data.getPosts),limit:(result.data.getPosts.length*2),offset:result.data.getPosts.length})
    // }).catch(err=>console.log(err))
    
    
   }
  }
  //client.resetStore()

const togglePost = (id,price) =>{
    console.log("toggled")
  setSelected(id)
 setAmount(price)
  setDisplay(!display)
  // client
  // .query({
  //   query: gql`
  //     query myQuery {
  //       getPosts(offset:${offset},limit:${limit}) {
  //         favorited
  //         count
  //         commentscount
  //         post{
  //           caption
  //           price
  //           file
  //           id
  //           thumbnail
  //         }
          
  //       }
  //     }
  //   `,
  // })
  //get thepayment
  
 }

 const modifyPost_Bulk = (indices)=>{
  // for(i in indices){
  //   modifyPost(indices[i])
  // }
  console.log(indices)
  return window.location.href = "/feed"
}

const handleOnChange = (position,post) => {
  let count = 0
  let total = 0
  let list = []
  let state
  const updatedCheckedState = selections.map((item, index) =>{
    
    
    //setcount(count)
   
   //let state = index === position ? !item : item
   if(index === position ){

    state = !item
    if(state){
      count++
      total = totalPrice + parseInt(post.price)
      list = itemIndices.concat([post.id])
      //console.log("positive",1,total)
      return state
    }
    total = totalPrice - parseInt(post.price)
    list = itemIndices.filter(ftr => ftr !== post.id)
    //console.log("negative",1,total)
    return state
   }
   else if(index !== position){
    if(item){
      count++
      
      
      return item
    }
   
    
    return item
   }
    
  }
   

  );
  console.log(count,"SCARED",total,list)
  setItemIndices(list)
  setBundleCount(count)
  setTotalPrice(total)
  setSelections(updatedCheckedState);
}

const filterPostsByTags =()=>{

}

const queryApi = () =>{
  //infinite scroll post data offset limit fetchmore
 // const {user,offset,limit} = this.state
  let useParam = user
 // console.log(user,useParam,props)
  client
  .query({
    query: gql`
      query myQuery {
        getPosts(id:${user},offset:${offset},limit:${limit}) {
          favorited
          count
          commentscount
          post{
            caption
            price
            file
            id
            thumbnail
            UserId
            duration
            preview
          }
          
        }
      }
    `,
  })
  .then((result) => {//console.log(result)
    //console.log("state changed")
   // this.setState({posts:result.data.getPosts,limit:(result.data.getPosts.length*2),offset:result.data.getPosts.length})
    setPosts(result.data.getPosts)
   // setLimit((result.data.getPosts.length*2))
   setSelections(new Array(result.data.getPosts.length).fill(false))
   // setOffset(result.data.getPosts.length)
})
  .catch((err) => {
    console.log(err);
    //this.setState({ redirec: !this.state.redirect });
  });





  //GET PROFILE DATA
  client.query({
    query: gql`
      query myQuery2 {
        profile {
          name
          description
          photo
          email
        }
      }
    `,
  })
  .then((result) =>{
    //console.log(result.data.me.id)
    //this.setState({profile:result.data.profile,photo:result.data.profile.photo})
    setProfile(result.data.profile)
    let email = result.data.profile
    console.log(email)
    // if(localStorage.getItem("userID") !== id){
    //   localStorage.setItem("userID",id)
    // }
  }) 
  .catch((err) => {
    console.log(err);

    //this.setState({ redirect: !this.state.redirect });
    
  });
}



//       const {posts,profile,photo} = this.state
    return (
      <div onScroll={HandleScroll} style={{"overflow-y":"scroll",height:"90rem","border-radius":"5px"}} ref={child2}>
      <main >
       
      <Payperview onClose={togglePost} modify={modifyPost} id={selected} price={amount} isOpen={display}/>
      {!display &&<BulkPPV totalPrice={totalPrice} itemIndices={itemIndices} modifyPost_Bulk={modifyPost_Bulk} toggle={display} checkState={bundleCount}/>}
      <div className="container"  >
      <TagControllerPost userId={props.match.params.id}/>
      <div className="gallery">
       
      {posts.map((post,i) => ( 
        <div key={post.post.id} className="gallery-item" >
          
                                  {/* <img className="gallery-image"
                     
                     src={`/storage/${post.post.thumbnail == ""?post.post.file:post.post.thumbnail}`}></img> */}
                         <PostImage_2
                displayHandler={(post.post.thumbnail == "")?()=>{}:toggleDisplayPreview}
                isVideoPreview={displayPreview == post.post.id}
                previewSrc={post.post.preview}
                postId={post.post.id}
                  postSrc={
                    post.post.thumbnail == ""
                      ? post.post.file
                      : post.post.thumbnail
                  }
                />
      
   
<div className="gallery-item-info">

<ul>
{post.post.file == "pngsus.png" &&<div className="gallery-item-check">   <input
          type="checkbox"
          id="topping"
          name="bulkSelect"
          value="pi"
          checked={selections[i]}
          onChange={()=>handleOnChange(i,post.post)}
        /></div>}
<div className="gallery-item-type">

<div onClick={()=>(post.post.file != "pngsus.png")?"":togglePost(post.post.id,post.post.price)}>{post.post.thumbnail == ""?<i className="fa fa-picture-o" aria-hidden="true"></i>:<i  className="fa fa-video-camera" aria-hidden="true"></i>
}</div>
</div> 
{post.post.duration && <div onClick={()=>{
                      // toggleDisplayPreview(false)
                      if(displayPreview !== post.post.id){
                        toggleDisplayPreview(post.post.id)
                      }
                      else{
                        toggleDisplayPreview(false)
                      }
                  
                      
                      }} style={{color:"orange",fontSize:"14px",position:"relative",textAlign:"center",justifyContent:"center"}} className="gallery-item-duration">
                      <div style={{backgroundColor:"black",borderRadius:"12px"}} className="duration_opaque"><p>{post.post.duration}</p></div></div>}
                    {/* change link to onclick */}
{/* change link to onclick */}
<Favorites changefav={changefav} queryApi={queryApi} post={post.post.id} postCount={post.count} favorited={post.favorited? true:false}/>
  <li className="gallery-item-comments"><span className="visually-hidden">Comments:</span><div><Link  to={{pathname:`/post/${post.post.id}`,profile:profile,UserId:post.post.UserId, preview:post.post.thumbnail,id: post.post.id,caption:post.post.caption,
  src:post.post.file,count:post.count,modify:modifyPost,isPhoto:(post.post.thumbnail == "")?true:false, favorited:post.favorited? true:false}}><i className="fa fa-comments-o" aria-hidden="true"></i></Link> </div>{`${post.commentscount}`}</li>
</ul>
{true &&<div className="textos" style={{position:"absolute",textAlign:"center",top:"4.3rem",fontSize:"15px"}}>
  <p>{post.post.caption}</p>
</div>}
</div>
      
        </div>
      

        
      
      ))}
      {/* <div>
      <Loader isOpen={loaderState}/>
      </div> */}
      </div>
      
      
      </div>
      

   

      </main>
      </div>
    )
  }


export default Profile2



