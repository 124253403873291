import React, { Component } from 'react';
// import "../UI/Styles/TagControllerAdd.css"
import Loader from '../UI/Loader';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import Popup3 from '../UI/Popup3';
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
class TagControllerAdd extends Component {
    constructor(props){
        super(props)
        this.state={
tags:[],
newTags:[],
newTagsMDF:[],
isModalOpen:false,
errorMsg:"",
isOpen:false,
isModalOpen:false,
errorMsg:""
        }
    }

    isActive =(id)=>{
      let modified =[ ...this.props.tags,...this.state.newTags]
      for(let tag in modified){
        if(modified[tag].id == id || modified[tag] == id){
         return true
        }
      }
      return false
    }
    addTagToPost=()=>{
      this.setState({isOpen:true})
      client.mutate({
          mutation: gql`
          mutation TagRetrieval($names:[Int],$postId:Int!){
            addNewTags(names:$names,postId:$postId)
          }
        `,
        variables:{
          names:this.state.newTags,
          postId:this.props.postId
        }
          
        }).then((result)=>{
      if(result.data.addNewTags){
        this.setState({isOpen:false})
        let prev = this.props.tags
        this.props.updateTagCache([...prev,...this.state.newTagsMDF],this.props.postId) 
         this.setState({newTags:[],newTagsMDF:[]})

        
      }
      else{
        this.setState({isOpen:false,isModalOpen:true,errorMsg:"an Error Occured"})
      }
        }).catch((error)=>{
          console.log(error)
          this.setState({isOpen:false,isModalOpen:true,errorMsg:"an Error Occured"})
        })
        // this.setState({newTags:[],newTagsMDF:[]})
  }


  // componentWillUnmount(){
  //   this.setState({newTags:[],newTagsMDF:[]})
  // }
  // componentDidUpdate(prevprops){
  //   console.log(prevprops,prevprops.postId)
  //    if(prevprops.postId == this.props.postId){
  //     return
  //    }
  //   this.setState({newTags:[],newTagsMDF:[]})
  // }

    getTags=()=>{
        client.query({
            query: gql`
            query TagRetrieval{
              getUserTags{
               name
               id
              }
            }
          `,
            
          }).then((result)=>{
         this.setState({tags:result.data.getUserTags})
          }).catch((error)=>{
            console.log(error)
          })
    }
    closeModal=()=>{
      this.setState({isModalOpen:false,errorMsg:""})
    }
remove=(name)=>{

  client.mutate({
    mutation: gql`
    mutation deleteTag($name:String!){
        deleteTag(name:$name)
      }
    
  `,
  variables:{
    name:name
  }
    
  }).then((result)=>{
 this.setState({tags:this.state.tags.filter(ftr=>ftr.name != name),newTags:this.state.newTags.filter(ftr=>ftr != name)})
  }).catch((error)=>{
    console.log(error)
  })
}
    componentDidMount(){
     // this.setState({tags:this.props.tags})
        this.getTags()
    }

    newTags=(name)=>{

    }
    saveNewTags=()=>{
      this.addTagToPost()

    //   client.mutate({
    //     mutation: gql`
    //     mutation createNewTags($names:[String]){
    //         createNewTags(names:$names)
    //       }
        
    //   `,
    //   variables:{
    //     names:this.state.newTags
    //   }
        
    //   }).then((result)=>{
    //  this.setState({isOpen:false,isModalOpen:true,errorMsg:"saved successfully"})
    //   }).catch((error)=>{
    //     console.log(error)
    //   })
    }
    addTag=(tag)=>{
        // if (e.key == "Enter") {
        //     let tag = e.target.value.replace(/\s+/g, " ");
        //     if (tag.length > 1 ) {
        //       if (this.state.tags.length < 10) {
        //         tag.split(",").forEach((tag) => {
        //           //tags.push(tag);
        //           //this.createTag();
        //           this.setState({tags:this.state.tags.concat([{name:tag}]),newTags:this.state.newTags.concat([tag])})
        //         });
        //       }
        //     }
        this.setState({newTags:this.state.newTags.concat([tag.id]),newTagsMDF:this.state.newTagsMDF.concat([tag])})
            // e.target.value = "";
          
    }
    render() {
        if(!this.props.showTag) return null       
         return (
            <div  style={{ position:"fixed",zIndex:"1"}}>
                <link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.0/css/thinline.css"/>
              
                { this.state.isModalOpen && <Popup3 isOpen={this.state.isModalOpen} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>}
<div  class="wrapperTagAdd">
  <div class="tagTitle">
 
    {/* <img src="tag.svg" alt="icon"/> */}
    {/* <h2>Tags</h2> */}
  </div>
  <div class="tagContent">
    {/* <p>Press enter after each tag</p> */}
    <ul style={{border:"0"}}>{this.state.tags.length > 0 && this.state.tags.map(tag=>(
        <li style={{background:(this.isActive(tag.id)?"#95d3e1":"#f2f2f2")}} className="uit uitit-multiply" onClick={()=>this.addTag(tag)}>{tag.name}</li>
    ))
        }
        {/* <input onKeyUp={(event)=>this.addTag(event)} type="text" spellcheck="false"/>*/}
        </ul> 
  </div>
  <div class="details">
    {/* <p><span>{10 - this.state.tags.length}</span> tags are remaining</p> */}
     <button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.saveNewTags()}>Save</button> 
    <div style={{position:"relative"}}>{ this.state.isModalOpen && <Popup3 isOpen={this.state.isModalOpen} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>}</div> 
    <Loader isOpen={this.state.isOpen}/>
  </div>
</div>
        </div>    
        );
    }
}

export default TagControllerAdd;