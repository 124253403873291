import React, { Component } from 'react';

class ListSearch extends Component {
    constructor(props){
        super(props)
        this.state = {
            resultsProper:null,
            firstDisplay:this.props.resultsProper,
            resultsFiltered:[],
            selectState:this.props.resultsProper.map(a=>false),
            countState:0,
            itemIndices:[]
        }
        this.ref_array = this.props.resultsProper.map(a => React.createRef())
        
    }

    incrementalSearch = ()=>{
      //
    }
   handleOnChange = (position,user) => {
        let state
        let count = 0
        let itemIndicesCopy = this.state.itemIndices
        console.log(itemIndicesCopy)
        let usrs = this.props.resultsProper
        let selections = this.state.selectState
        const updatedCheckedState = selections.map((item, index) =>{
           
         if(index === position ){
    
         state = !item
         if(state){
            count++
            itemIndicesCopy = itemIndicesCopy.concat([user])
            console.log(itemIndicesCopy)
            return state
          }
          console.log(itemIndicesCopy)
          itemIndicesCopy = itemIndicesCopy.filter(ftr => ftr !== user)
          return state
         }

         else if(index !== position){
            if(item){
              count++
              
              
              return item
            }
           
            
            return item
           }
          
        }
         
    
        );
        console.log(count,updatedCheckedState,itemIndicesCopy)
        this.setState({selectState:updatedCheckedState,countState:count,itemIndices:itemIndicesCopy})
    

      }

      


    searchFilter =(list)=>{
      //where name == input/ contains
    let newState = list.map(a=>false)
    console.log(list)
    
    this.setState({firstDisplay:list,selectState:newState,itemIndices:[],countState:0})
 
 
 
 
     }

      onChange = (event)=>{
        console.log(event.target.value)
        let searchStateHandle = this.searchFilter
 
         let data = this.props.resultsProper
         let keyupStack = [];
          keyupStack.push(1);
          
          setTimeout(function () {
            keyupStack.pop();
        
            if (keyupStack.length === 0) {
        
              let buf = '.*' + event.target.value.replace(/(.)/g, "$1.*");
              let reg = new RegExp(buf);
              
              let filteredLists = data.filter(function (d) {
                return reg.test(d.name);
              });
              searchStateHandle(filteredLists);
            }
          }, 300);
        
      }


    addUsers_list = (id)=>{
        this.props.addUsers(this.state.itemIndices)
        this.setState({selectState:this.props.resultsProper.map(a=>false),countState:0,itemIndices:[]})
    }
    render() {
        if(!this.props.displayListSearch) return null
        return (
            <div>
                                        
              <p style={{color:"orange"}}>Add to {this.props.selectedName}</p>
              <div style={{position:"relative"}}>
                          {<i style={{fontSize:"13px"}}onClick={()=> this.props.closeListSearch()} className="fa fa-times" aria-hidden="true"></i>}
                          </div>
                  <input onKeyUp={(event)=>this.onChange(event)}  className="search-field" type="text" autoComplete="off" name="query" placeholder="Search Subscribers"/>
                  {this.state.countState >= 1 && <button onClick={()=>this.addUsers_list()}>add user</button>}
                <div style={{maxHeight:"90px",overflowY:"auto"}}>{this.state.firstDisplay.map((res,i)=>(<div ref={this.ref_array[i]}key={res.name}>

                    <p>{res.name}</p>
                    <input
          type="checkbox"
          id="topping"
          name="bulkSelect"
          value="pi"
          checked={this.state.selectState[i]}
          onChange={()=>this.handleOnChange(i,res.id)}
        />
                </div>))}</div>
            </div>
        );
    }
}

export default ListSearch;