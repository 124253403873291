import React from "react";
import "./Styles/Skeleton.css";
function SkeletonAlerts() {
    return (<div className="dialogue-container">
     
    <div className='container'>
    <div  className="c__ example-one">
      <div className="title"></div>
      <header  className="example-one-header scroll">
        <span style={{cursor:"pointer"}}  className="logo skeleton-loader"></span>
        <nav className="vertical-align-middle">
          <span className="homepage_nav-item skeleton-loader"></span>
          <span className="homepage_nav-item skeleton-loader"></span>
          <span className="homepage_nav-item skeleton-loader" ></span>
          <span className="homepage_nav-item skeleton-loader"></span>
          <span className="homepage_nav-item skeleton-loader"></span>
        </nav>
      </header>
    </div>
    {Array(8)
        .fill()
        .map((item,index)=> 
        <div class="dialog-box skeleton-loader" style={{borderBottom: "3px solid rgba(84, 75, 27, 0.1)"}}>
            <div class="background-blur"></div>
            <div class="header"><div class="background-blur"></div>
            <div class="contents"><div class="leftAlert" style={{fontSize: "22px"}}><a > 
            <i className="fa skeleton-loader"></i></a> </div><div class="rightAlert skeleton-loader"></div>
            </div>
            </div>
            <div style={{borderRadius:"20px"}}class="contents main-content skeleton-loader">
                <strong></strong><br/></div></div> )}
    </div> </div> );
}

export default SkeletonAlerts;