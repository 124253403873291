import  {Component, PureComponent, createRef, Fragment} from 'react';
import "../UI/Styles/Imageresize.css"
import SelectAndCrop from './SelectAndCrop';


// loader component
const Loader = () => (
    <div className="loader">
        <span/>
        <span/>
        <span/>
    </div>
);

// crop image component
class CropImage extends Component {
    imageObject = new Image();

    componentDidMount() {
        const {imageSrc}     = this.props;
        this.imageObject.src = imageSrc;
        this.imageObject.setAttribute("crossOrigin", "anonymous");
    }

    getImagePortion = (imgObject, newWidth, newHeight, startX, startY) => {
        // setIsCropped => a method to set a flag whether image is cropped or not
        const {setIsCropped, getCroppedImg, isFlippedHor, isFlippedVer} = this.props,
              cropCanvas                                                = document.createElement("canvas"),
              cropCanvasContext                                         = cropCanvas.getContext("2d"),
              originalImage                                             = document.createElement('canvas'),
              originalImageContext                                      = originalImage.getContext('2d'),
              imageWidth                                                = imgObject.width,
              imageHeight                                               = imgObject.height,
              scaleH                                                    = isFlippedHor ? -1 : 1, // Set horizontal scale to -1 if flip horizontal
              scaleV                                                    = isFlippedVer ? -1 : 1, // Set verical scale to -1 if flip vertical
              posX                                                      = isFlippedHor ? imageWidth * -1 : 0, // Set x position to -100% if flip horizontal
              posY                                                      = isFlippedVer ? imageHeight * -1 : 0; // Set y position to -100% if flip vertical
        let scale                                                       = 1;
        cropCanvas.width                                                = newWidth;
        cropCanvas.height                                               = newHeight;

        if (imageWidth > document.documentElement.offsetWidth) {
            scale = imageWidth / document.documentElement.offsetWidth;
        }

        // canvas of original image
        originalImage.width  = imageWidth;
        originalImage.height = imageHeight;
        originalImageContext.drawImage(imgObject, 0, 0);

        originalImageContext.save(); // Save the current state
        originalImageContext.scale(scaleH, scaleV); // Set scale to flip the image
        originalImageContext.drawImage(imgObject, posX, posY, imageWidth, imageHeight); // draw the image
        originalImageContext.restore(); // Restore the last saved state

        cropCanvasContext.drawImage(
            originalImage,
            startX * scale,
            startY * scale,
            newWidth * scale,
            newHeight * scale,
            0,
            0,
            newWidth,
            newHeight
        );
        const croppedSrc = cropCanvas.toDataURL();

        setIsCropped(true);
        getCroppedImg(croppedSrc);
    };

    render() {
        const {width, height, x, y} = this.props;

        return (
            <div className="crop-image-component-wrapper">
                <button
                    className="btn btn-danger"
                    onClick={() =>
                        this.getImagePortion(this.imageObject, width, height, x, y)
                    }
                >
                    Crop
                </button>
            </div>
        );
    }
}

// SelectAndCropControls component


class ImageResize extends Component {
    state = {
        fileInputImage : "",
        isError        : false,
        croppedImgSrc  : "",
        croppedImgSrc2 : "",
        croppedImgSrc3 : "",
        croppedImgSrc4 : ""
    };
    
    form = createRef();

    onSelectImage = event => {
        const file = event.target.files[0];
        if (
            file.type === "image/jpeg" ||
            file.type === "image/jpg" ||
            file.type === "image/png"
        ) {
            this.setState({
                fileInputImage : URL.createObjectURL(file),
                isError        : false
            });
        } else {
            this.setState({isError : true});
        }
    };

    removeImg = () => {
        this.form.current.reset();
        this.setState({fileInputImage : ""});
    };

    getCroppedImg = croppedSrc => {
        this.setState({croppedImgSrc : croppedSrc});
    };

    getCroppedImgSecond = croppedSrc => {
        this.setState({croppedImgSrc2 : croppedSrc});
    };

    getCroppedImgThird = croppedSrc => {
        this.setState({croppedImgSrc3 : croppedSrc});
    };

    getCroppedImgForth = croppedSrc => {
        this.setState({croppedImgSrc4 : croppedSrc});
    };

    render() {
        const {fileInputImage, isError} = this.state;
        return (
            <div className="container">
            <div className="container__">
                {/* SelectAndCrop properties
      - image => image to resize and crop
      - ratioWidth => required width ratio
      - ratioHeight => required height ratio
      - getCroppedImg => a method which should be used to get b64 of the cropped part of the image so that you can use it to send it to the server
       Notes:
        * if you don't want ratio resizing don't pass (ratioWidth, ratioHeight) to this component
        * Examples of popular ratios:
            a- 1 => ratioWidth: 1, ratioHeight: 1 (results a square)
            b- 16:9 => ratioWidth: 16, ratioHeight: 9
            c- 16:10 => ratioWidth: 16, ratioHeight: 10
            d- 3:2 => ratioWidth: 3, ratioHeight: 2
            e- 4:3 => ratioWidth: 4, ratioHeight: 3
    */}
                <h3 className="title">Example with file input and user controls</h3>
                <form ref={this.form}>
                    <input
                        type="file"
                        multiple={false}
                        accept="image/png, image/jpeg"
                        disabled={fileInputImage}
                        onChange={this.onSelectImage}
                    />
                </form>
                {fileInputImage && (
                    <Fragment>
                        <button
                            className="btn btn-danger remove-image"
                            onClick={this.removeImg}
                        >
                            Remove image
                        </button>
                        <SelectAndCrop
                            getCroppedImg={this.getCroppedImg}
                            image={fileInputImage}
                            allowUserControls={true}
                        />
                    </Fragment>
                )}
                {isError && (
                    <p className="error-message">
                        Please select an image type of: (.png, .jpg, .jpeg).
                    </p>
                )}
                <hr/>
                <h3 className="title">Example with user controls</h3>
                <SelectAndCrop
                    image="https://picsum.photos/id/64/300/300"
                    allowUserControls={true}
                    getCroppedImg={this.getCroppedImgSecond}
                />
                <hr/>
                <h3 className="title">
                    Example without user controls and with ratio (16:9)
                </h3>
                <SelectAndCrop
                    image="https://picsum.photos/id/234/300/300"
                    ratioWidth={16}
                    ratioHeight={9}
                    getCroppedImg={this.getCroppedImgThird}
                />
                <hr/>
                <h3 className="title">
                    Example without user controls and without ratio
                </h3>
                <SelectAndCrop
                    image="https://picsum.photos/id/790/300/300"
                    getCroppedImg={this.getCroppedImgForth}
                />
            </div>
            </div>
        );
    }
}

export default ImageResize
