import React ,{useState,useRef, useEffect} from 'react'
import "../UI/Styles/Lecropper.css"
import PostImagePreviewCrop from '../UI/PostImagePreviewCrop';
import Loader from '../UI/Loader';
const LeCropper = (props)=>{
/*global document:false, window:false*/
// используйте картинку из своего домена. иначе получите ошибку No 'Access-Control-Allow-Origin' header is present on the requested resource.
// const [cropComponent,setCropComponent] = useState()
// const [event_state,setEventState] = useState({})
// const [ratio,setRatio] = useState(1.0)
// const [MINHEIGHT,setMinHeight] =useState(50)
//     const [MINWIDTH,setMinWidth] =useState(50)
//     const [CROPWIDTH,setCropWidth] =useState(50)
//     const [CROPHEIGHT,setCropHeight] =useState(200)
//     const [keyZoomValue,setKZV] = useState(0)
//     const [resize_canvas,setRCV] = useState(null)
//     const [cropTop,setCropTop] =useState(0)
    const [processedImage,setProcessedImage] = useState(null)
    const [processedImageURL,setURL] = useState(null)


const center = useRef()
const image_target = React.createRef()
const co_ords = useRef({
    container_width:0,
    container_height:0,
    container_left:0,
    container_top:0,
    mouse_x:0,
    mouse_y:0
})

const imageGenerateHandler = (imageBuffer)=>{
    let url = imageBuffer
    ///URL.createObjectURL(imageBuffer);
    setProcessedImage(imageBuffer)
    setURL(url)
center.current.scrollIntoView();
}

const dataURItoBlob =(dataURI)=> {
    // convert base64/URLEncoded data component to raw binary data held in a string
    var byteString;
    if (dataURI.split(',')[0].indexOf('base64') >= 0)
        byteString = atob(dataURI.split(',')[1]);
    else
        byteString = unescape(dataURI.split(',')[1]);
    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    // write the bytes of the string to a typed array
    var ia = new Uint8Array(byteString.length);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {type:mimeString});
    }
const uploadHandler =()=>{
    //new File(dataURItoBlob(processedImage),props.fileName,{type:"image/png"})
    // props.upload(dataURItoBlob(processedImage))
    props.upload(new File([dataURItoBlob(processedImage)],props.fileName,{type:"image/png"}))
}

useEffect(()=>{
    var cropComponent
    , container
    , crop_img
    , event_state = {}
    , ratio = 1.0
    , keyZoomValue = 4.0
    // , MINWIDTH = 50
    // , MINHEIGHT = 50
    , MINWIDTH = 50
    , MINHEIGHT = 50
    , CROPWIDTH = 200
    , CROPHEIGHT = 200
    , cropLeft = 0
    , cropTop = 0
    , cropWidth = 0
    , cropHeight = 0
    , resize_canvas = null;

    if(!image_target.current) return
        var wraper, left, top;

        
        if (image_target.current.dataset.isCrop) {
           // console.log("ALREADY CROPPED")
        }
        
        //console.log("YZMSAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA")
        image_target.current.dataset.isCrop = 'true';
        image_target.current.classList.add('crop-blur');
        image_target.current.draggable = false;
        
        crop_img = new Image();
          crop_img.crossOrigin = image_target.current.crossOrigin;  
                    crop_img.src = image_target.current.src;
        crop_img.draggable = false;
                    
        resize_canvas = document.createElement('canvas');
        //styles added
   
        cropComponent = document.createElement('div');
        center.current.appendChild(cropComponent)
        cropComponent.classList.add('crop-component');
        
        container = document.createElement('div');
        container.classList.add('cropper_overlay');
        //styles added
        container.style.width ="200px"
        container.style.height ="200px"
        
        cropComponent.appendChild(container);
        // wraper = image_target.current.parentNode;
        wraper = center.current
        wraper.appendChild(cropComponent);
        cropComponent.appendChild(crop_img);
        cropComponent.appendChild(image_target.current);
        container.appendChild(crop_img);
        
        left = image_target.current.offsetWidth / 2 - CROPWIDTH / 2;
        top = image_target.current.offsetHeight / 2 - CROPHEIGHT / 2;
        console.log("INIT",top,left)
        
        updateCropImage(left, top);
        //addHandlers();
        container.addEventListener('mousedown', startMoving, false);
container.addEventListener('touchstart', startMoving, false);
container.addEventListener('wheel', resizing, false);

document.addEventListener('keypress', keyHandler, false);
// document.querySelector('.btn-crop').addEventListener('click', openCropCanvasImg);
document.querySelector('.wpc-btn').addEventListener('click', openCropCanvasImg);   
    // const image_target = document.querySelector('.crop-image')



function crop() {
    cropWidth = crop_img.width * ratio;
    cropHeight = crop_img.height * ratio;
    
    resize_canvas.width = CROPWIDTH;
    resize_canvas.height = CROPHEIGHT;
    
    var ctx = resize_canvas.getContext('2d');
    ctx.drawImage(crop_img,
            cropLeft, cropTop,
            cropWidth, cropHeight
    );
    }
    
    function openCropCanvasImg() {
    crop();
            
            try {
                var base64Img = resize_canvas.toDataURL('image/png', 1.0);
                console.log(base64Img)
                imageGenerateHandler(base64Img)
    //window.open(base64Img);
            } catch(e) {
                alert(e);
            } finally {
                // removeHandlers();
            }
    
    }

// if (image_current) {
// init();
// } 
// else {
// image_target.onload = function () {
//     init();
// };
// }

function removeHandlers() {
container.removeEventListener('mousedown', startMoving);
container.removeEventListener('touchstart', startMoving);
container.removeEventListener('wheel', resizing);

document.removeEventListener('mouseup', endMoving);
document.removeEventListener('touchend', endMoving);
document.removeEventListener('mousemove', moving);
document.removeEventListener('touchmove', moving);
document.removeEventListener('keypress', keyHandler);
}

function addHandlers() {
container.addEventListener('mousedown', startMoving, false);
container.addEventListener('touchstart', startMoving, false);
container.addEventListener('wheel', resizing, false);

// document.addEventListener('keypress', keyHandler, false);
document.querySelector('.wpc-btn wpc-btn-default-rounded').addEventListener('click', openCropCanvasImg);
}



function updateCropSize(width, height) {
container.style.width = width + 'px';
container.style.height = height + 'px';
}

function updateCropImage(left, top) {
cropLeft = -left * ratio;
cropTop = -top * ratio;
left = -left + 'px';
top = -top + 'px';

crop_img.style.top = top;
crop_img.style.left = left;
}

function updateContainer(left, top) {
    console.log(top,top + (CROPWIDTH / 2) + 'px')
top = top + (CROPWIDTH / 2) + 'px';
left = left + (CROPHEIGHT / 2) + 'px';

container.style.top = top;
container.style.left = left;
}

// Save the initial event details and container state
function saveEventState(e) {
co_ords.current.container_width = container.offsetWidth
co_ords.current.container_height = container.offsetHeight
co_ords.current.container_left = container.offsetLeft
co_ords.current.container_top = container.offsetTop
co_ords.current.mouse_x = (e.clientX || e.pageX || e.touches && e.touches[0].clientX) + window.scrollX;
co_ords.current.mouse_y = (e.clientY || e.pageY || e.touches && e.touches[0].clientY) + window.scrollY;

event_state.container_width = container.offsetWidth;
event_state.container_height = container.offsetHeight;

event_state.container_left = container.offsetLeft;
event_state.container_top = container.offsetTop;

event_state.mouse_x = (e.clientX || e.pageX || e.touches && e.touches[0].clientX) + window.scrollX;
event_state.mouse_y = (e.clientY || e.pageY || e.touches && e.touches[0].clientY) + window.scrollY;
}

function imgZoom(zoom) {
            zoom = zoom * Math.PI * 2
var newWidth = Math.floor(container.clientWidth + zoom)
        , newHeight = Math.floor(container.clientHeight + zoom)
        , w = crop_img.clientWidth
        , h = crop_img.clientHeight
        , left
        , top
        , right
        , bottom;

if (newWidth < MINWIDTH) {
    return;
} else if (newWidth > w) {
    return;
}

left = container.offsetLeft - (zoom / 2);
top = container.offsetTop - (zoom / 2);
right = left + newWidth;
bottom = top + newHeight;

if (left < 0) {
    left = 0;
}
if (top < 0) {
    top = 0;
}
if (right > w) {
    return;
}
if (bottom > h) {
    return;
}

ratio = CROPWIDTH / newWidth;

updateCropSize(newWidth, newWidth);
updateCropImage(left, top);
updateContainer(left, top);
crop();
}

function keyHandler(e) {
//e.preventDefault();

switch (String.fromCharCode(e.charCode)) {
    case '+' :
        imgZoom(keyZoomValue);
        break;
    case '-' :
        imgZoom(-keyZoomValue);
        break;
}
}

function resizing(e) {
//e.preventDefault();
imgZoom(e.deltaY > 0 ? 1 : -1);
}

function startMoving(e) {
e.preventDefault();
e.stopPropagation();

saveEventState(e);

document.addEventListener('mousemove', moving);
document.addEventListener('touchmove', moving);
document.addEventListener('mouseup', endMoving);
document.addEventListener('touchend', endMoving);
}

function endMoving(e) {
e.preventDefault();

   // isClicked.current = false
    co_ords.current.container_left = container.offsetLeft
    co_ords.current.container_top = container.offsetTop



document.removeEventListener('mouseup', endMoving);
document.removeEventListener('touchend', endMoving);
document.removeEventListener('mousemove', moving);
document.removeEventListener('touchmove', moving);
}

function moving(e) {
var curuntTouch = {}
        , left
        , top
        , w
        , h;

//e.preventDefault();
e.stopPropagation();

// curuntTouch.x = e.pageX || e.touches && e.touches[0].pageX;
// curuntTouch.y = e.pageY || e.touches && e.touches[0].pageY;
//
curuntTouch.x = e.clientX || e.touches && e.touches[0].clientX;
curuntTouch.y = e.clientY || e.touches && e.touches[0].clientY;
// left = curuntTouch.x - (event_state.mouse_x - event_state.container_left);
// top = curuntTouch.y - (event_state.mouse_y - event_state.container_top);
left = curuntTouch.x - (co_ords.current.mouse_x - co_ords.current.container_left);
top = curuntTouch.y - (co_ords.current.mouse_y - co_ords.current.container_top);
console.log("top",curuntTouch.y - (co_ords.current.mouse_y - co_ords.current.container_top))
console.log("left",curuntTouch.x - (co_ords.current.mouse_x - co_ords.current.container_left))
console.log("width",container.offsetWidth)
console.log("height",container.offsetHeight)
console.log("container_top",co_ords.current.container_top)
w = container.offsetWidth;
h = container.offsetHeight;

if (left < 0) {
    left = 0;
} else if (left > crop_img.offsetWidth - w) {
    left = crop_img.offsetWidth - w;
}
if (top < 0) {
    top = 0;
} else if (top > crop_img.offsetHeight - h) {
    top = crop_img.offsetHeight - h;
}

updateCropImage(left, top);
updateContainer(left, top);
}

return removeHandlers

},[])
       


if(props.complete){
    return null
}


return <div style={{margin:"0"}} classNAme="container">
    {/* <div  ref={center} class="center__">
    <img ref={image_target} crossorigin="Anonymous" src={props.postSrc} class="crop-image" alt=""/>
</div> */}



<div className="container">
      
      <div
        className="list2-wrapper"
        ng-app="app"
        ng-controller="MainCtrl as ctrl" 
      >
            <div style={{position:"relative"}}>
            <ul className="list2">
            <div  ref={center} class="center__">
    <img ref={image_target} crossorigin="Anonymous" src={props.postSrc} class="crop-image" alt=""/>
</div>
            <div style={{ maxHeight: "250px", overflowY: "auto" }}>
<p style={{borderRight:"0",padding:"0"}} className="logo">
Preview
{processedImage && <div  className="pic" style={{position:"relative"}}> 
 <PostImagePreviewCrop fileObject={processedImage} setOpenMaster={()=>{}} setFileDetails={()=>{}} type={"image"} postSrc={processedImageURL} alt="profilepic" className="list2-item-image"/>

</div>}

</p>
</div>
<button class="wpc-btn wpc-btn-default-rounded">Crop</button>
{!props.loader && <button onClick={()=>uploadHandler()} class="wpc-btn wpc-btn-default-rounded">Upload</button>}
{props.loader && <Loader onClose={()=>{}} isOpen={props.loader}/>}
</ul>
    </div>
    </div>
</div>




</div>

// return <div class="crop-component">
//     <div class="overlay" style={{top: `${0}px`, left: `${0}px`}}>
//     <img crossorigin="anonymous" src="http://raw.githubusercontent.com/qertis/Crop-Image-PureJS/master/image.jpg" draggable="false" style={{top: `${0}px`, left: `${0}px`}}/>
//     </div>
//     <img crossorigin="Anonymous" src="//raw.githubusercontent.com/qertis/Crop-Image-PureJS/master/image.jpg" class="crop-image crop-blur" alt="" data-is-crop="true" draggable="false"/>
// </div>





}

export default LeCropper