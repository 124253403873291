//graphql Subscriptions
import React, { Component, useEffect, useRef, useState } from 'react'
import routes from '../storage_apollo_routes';
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery, resetApolloContext} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { ToastContainer, toast,Bounce } from 'react-toastify';
import '../UI/Styles/ReactToastify.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import ComposeFormReply from '../UI/ComposeFormReply';
import { v4 } from 'uuid';

const ath = localStorage.getItem("userID")


const httpLink = new HttpLink({
  uri: routes.apollo,
});

const wsLink = new GraphQLWsLink(createClient({
  url: routes.apollo_ws,
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);

const ALERTS = gql`
  subscription Alerted($toUser:Int!) {
    alertAdded(toUser:$toUser){
      type
      id
      message
      postId
      commentId
    }
  }
`;

const client = new ApolloClient({
  link: splitLink,
  cache: new InMemoryCache(),
  defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});


const Alerts2 = (props)=>{
//   let renders = 0
//   console.log(ath,renders)




const [alerts,setAlerts] = useState([])
const { data, loading} = useSubscription(
  ALERTS,{ variables: { toUser:parseInt(ath) },client:client });
  //toast.configure();
  const me = ()=>{
    client
    .query({
      query: gql`
        query myQuery {
          me {
            name
            id
          }
        }
      `,
    }).then((result)=>{console.log(result)}).catch((err)=>{
      console.log(err)
    })
  }
const mapType = (param)=>{
  let types = {
    message:"fa-message",
    favorited:"fa-heart",
    payment:"fa-usd",
    withrawal:"fa-check",
    comment:"fa-reply"
  }
  return types[param]
}
// useEffect(()=>{

//   if(data){
//     console.log(data)
//     props.append(data)
//     //setAlerts(alerts.concat([data]))
    
//   }
//   console.log("alerts",data)

// },[]





  

  // setAlerts(prevdata =>[...prevdata,data])
if(data){
//   renders++
console.log(data)
  //props.increment()
    //props.append(data)
    if(data.alertAdded.type == 'comment'){
      toast( <div style={{display:"flex"}}>
      <Link to="/alerts">
       <div style={{backgroundColor:"#fbf7f7", borderBottom:"solid",
                    borderBottomColor:"e3acac",borderRadius:"12px",display:"flex",height:"25px",width:"40px"}} className="duration_opaque">
                  {<i style={{fontSize:"11px",paddingLeft:"5px",paddingRight:"5px",paddingTop:"5px"}} className={`fa ${mapType(data.alertAdded.type)}`}></i>}
                  </div>
               
    </Link> <div style={{paddingLeft:"11px"}}>{data.alertAdded.message}
    <ComposeFormReply action = {()=>{}} referenceID={data.alertAdded.commentId} id={data.alertAdded.postId}/></div></div>,{containerId:`1${data.alertAdded.type}:data.${v4()}`,
position:"top-right",
autoClose:5000,
hideProgressBar:false,
newestOnTop:false,
closeOnClick:data.alertAdded.type !== 'comment',
rtl:false,
pauseOnFocusLoss:true,
draggable:false,
pauseOnHover:true,
theme:"light"})
    }
    if(data.achievementUnlocked){
      return
    }
else{
  toast( <div style={{display:"flex"}}>
  <Link to="/alerts">
   <div style={{backgroundColor:"#fbf7f7", borderBottom:"solid",
                borderBottomColor:"e3acac",borderRadius:"12px",display:"flex",height:"25px",width:"40px"}} className="duration_opaque">
              {<i style={{fontSize:"11px",paddingLeft:"5px",paddingRight:"5px",paddingTop:"5px"}} className={`fa ${mapType(data.alertAdded.type)}`}></i>}
              </div>
           
</Link> <div style={{paddingLeft:"11px"}}>{data.alertAdded.message}
</div></div>,{containerId:`1${data.alertAdded.type}:data.${v4()}`,
position:"top-right",
autoClose:5000,
hideProgressBar:false,
newestOnTop:false,
closeOnClick:data.alertAdded.type !== 'comment',
rtl:false,
pauseOnFocusLoss:true,
draggable:false,
pauseOnHover:true,
theme:"light"})
}
   
    
  

  

         

}
else {
  return <small className="notification-badge">
  {/* {data && data.alertAdded.message}  */}
  
  </small>

}

  
// }

}
export default Alerts2