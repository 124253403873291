import React,{ useState,useRef ,useEffect,useCallback} from "react";
import { useEventTracker } from '../Controllers/EventTrackerContext';
import Searchv2 from "./Searchv2";
import debounce from "lodash-es/debounce";
import Suggestions from "./Suggestions";
import routes from "../storage_apollo_routes";
import { setContext } from "@apollo/client/link/context";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const authLink = setContext((_, { headers }) => {

    
    return {
      headers: {
        "X-Pinggy-No-Screen":"true"
      },
    };
  });
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
const client = new ApolloClient({
    link:authLink.concat(link),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "no-cache",
        errorPolicy: "ignore",
      },
      query: {
        fetchPolicy: "no-cache",
        errorPolicy: "all",
      },
    },
  });
  const client_ = new ApolloClient({
    link,
    cache: new InMemoryCache(),
  });

const fetch = gql`
query Posts{
  getSimilarlyLiked{   
    post {
      id
      price
    preview
    caption
    UserId
    isAudio
    Discount{
      price
      expires
      createdAt
    }
    preview
    duration
    file
    thumbnail
  }
  count
  commentscount
  favorited
  }
}

`
const q1 = gql`
  query Q1($params: String) {
    searchUsers_(params: $params) {
      id
      preview
      caption
      vector_score
      preview
      duration
      file
      thumbnail
    }
  }
`;
const q2 = gql`
query Q2($params:String) {
  searchUsers(params: $pqarams ) {
     user{
         name
          id
         email
        isVerified
        mtn_number
        user_profile{
         id
         photo
               }
      }    
    count
    subbed

}
}
`;

const SearchPage = () => {
  const trackEvent = useEventTracker();
  const [body, setBody] = useState("");
  const [results, setResults] = useState(null);
  const [posts, setPosts] = useState(null);
  const [fee, setFee] = useState("");
  const [loading_, setLoading_] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loading_s, setLoading_s] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const abortController = React.createRef();

  const handleChange = async (event) => {
    
    search_debounced(search, event.target.value);
    
    setBody(event.target.value )
  };
  const search = async (bod, controller) => {
    let cancel;

    // const { body } = this.state;

firstCall(controller,bod)
secondCall(controller,bod)
  
    // console.log(bod)
   
  };

 const search_debounced = useCallback(debounce((search, input) => {
    const controller = new window.AbortController();
    abortController.current = controller;
    search(input, controller);
  }, 530),[]);

  const abortLatest = () => {
    abortController.current && abortController.current.abort();
  };

 const clear = () => {

    setBody("")
    setResults(null)
  };
  const firstCall = (controller,bod)=>{
   setLoading(true)
    client
    .query({
      query: gql`
            query {
              searchUsers(params: "${bod}" ) {
                
                  user{
                     name
                                      id
                                      email
                                      isVerified
                                      mtn_number
                                      user_profile{
                                        id
                                        photo
                                      }
                  }
                  
                count
                subbed
                 
             
            }
          }
          `,
      options: {
        context: { fetchOptions: { signal: controller.signal } },
      },
    })
    .then((result) => {
      //console.log(result)
      console.log(
        "state changed",
        // result.data.searchUsers.users,
        result.data.searchUsers
      );
    //   this.setState({
    //     results: result.data.searchUsers,
    //     //posts: result.data.searchUsers.posts,
    //     loading: false,
    //   });
   trackEvent({},"SearchComponent",{searchTerm:bod,userID:localStorage.getItem("userID")})
      setLoading(false)
      setResults(result.data.searchUsers)
    })
    .catch((err) => {
      console.log(err);
      //this.setState({ redirec: !this.state.redirect });
    });
  }
  const secondCall = (controller,bod)=>{
    
    setLoading_(true)
    
    client
    .query({
      query: gql`
            query {
              searchUsers_(params: "${bod}" ) {
             
                  
                    id
                    preview
                    caption
                    vector_score
                    preview
                    duration
                    file
                    thumbnail
                  } 
             
            
          }
          `,
          
      options: {
        context: { fetchOptions: { signal: controller.signal } },
      },
    })
    .then((result) => {
      //console.log(result)
      console.log(
        "state changed",
        
        result.data.searchUsers_
      );

      setPosts(result.data.searchUsers_)
      setLoading_(false)
    })
    .catch((err) => {
      console.log(err);
      //this.setState({ redirec: !this.state.redirect });
    });
  }
const  modifyPost = async (id)=>{
    let pst = await  client
   .query({
     query: gql`
       query Single {
         getSinglePost(id:${id}) {
             caption
             price
             file
             id
             thumbnail
             UserId
             duration
             preview
             isAudio
           
         }
       }
     `,
   }).then((result)=>{
     console.log("li durk aha")
     return result.data.getSinglePost
   }).catch((error   
   )=>{
     console.log(error)
     return false
   })
 
   
   const filteredPosts = suggestions.posts.map(mp =>{ 
     //mp.post.id !== id
     if(pst){
      
       if(mp.post.id == id){
         console.log(pst,mp)
         let newpost = {...mp,post:pst}
          mp = newpost
          console.log(newpost)
       }
        return mp
     }
      return mp
   });
   client_.cache.updateQuery( {query:fetch} , (data) => ({
     getSimilarlyLiked: data.getSimilarlyLiked.map((todo) =>{
       if(todo.post.id == id){
         let newpost = ({ ...todo, post:pst})
          todo = newpost
       }
       return todo
     } )
   }));
   //console.log(filteredPosts)
   //this.setState({suggestions:filteredPosts})
   setSuggestions(filteredPosts)
 }
  const getSuggestions = ()=>{
    client_
    .query({
      query: fetch,
    })
    .then((result) => {
setSuggestions(result.data.getSimilarlyLiked)
setLoading_s(false)
    })
    .catch((err) => {
      console.log(err);
      //this.setState({ redirec: !this.state.redirect });
    });
  }
  const changefav = (id,mutcount,favorited)=>{
    const filteredPosts = suggestions.map(mp =>{ 
      //mp.post.id !== id
      
       
        if(mp.post.id == id){
          console.log(id,mutcount,favorited)
          let newpost = {...mp,favorited:favorited,count:mutcount}
           mp = newpost
           console.log(newpost)
        }
         return mp
    
       return mp
    });
  
     console.log(filteredPosts)
  
     client_.cache.updateQuery( {query:fetch} , (data) => {
      //console.log()
      return({
      getSimilarlyLiked: data.getSimilarlyLiked.map((todo) =>{
        if(todo.post.id == id){
          let newpost = ({ ...todo, favorited:favorited,count:mutcount})
           todo = newpost
        }
        return todo
      } )
    })});
    //this.setState({suggestions:filteredPosts})
    setSuggestions(filteredPosts)
  }
useEffect(()=>{
getSuggestions()
},[])


  return (
    <div>
      <div className="container">
        <div
          class="list2-wrapper"
          ng-app="app"
          ng-controller="MainCtrl as ctrl"
        >
          <ul className="list2">
            <i
              className="fa fa-window-close"
              onClick={() => {
                clear();
              }}
            ></i>
            <input
              onChange={(e) => {
                abortLatest();
                handleChange(e);
              }}
              value={body}
              className="search-field"
              type="text"
              autoComplete="off"
              name="query"
              placeholder="Search"
            />
            <div className="search-container">
              <div className="dropdown">
                <Searchv2
                  loading={loading}
                  loading_={loading_}
                  fee={fee}
                  posts={posts}
                  results={results}
                />
                {/* <Searchv2 results={results} fee={fee}/> */}
              </div>

              <div className="search-container-box">
                <div className="search-results"></div>
              </div>
            </div>
          </ul>
        </div>
        <Suggestions
          modifyPost={modifyPost}
          changefav={changefav}
          posts={suggestions}
          loading={loading_s}
        />
      </div>
    </div>
  );
};

export default SearchPage;
