import React, {Component,useEffect,useState} from 'react';
//import apiClient from './api';
import ReactDOM from 'react-dom';
import Loader from './Loader';
import './Styles/ComposeFormReply.css'

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri:"https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});
/**
 * @callback PostCallback
 */

/**
 * @typedef Props
 * @prop {integer} id - The id of the post to comment
 * @prop {PostCallback} action -A callback passed from the { @link Post } component that refreshes the current comments
 */

/**
 * @typedef State
 * 
 */


/**
 * Renders a Form Field that handles replying to a comment
 */

//mport Dashboard from './Dashboard';
class  ComposeFormReply extends Component {
     constructor(props){
       super(props);
     this.state={
           body:'',
           pending:false,
           
           
           
           
     }
     this.handleChange = this.handleChange.bind(this)
     this.handleSubmit =this.handleSubmit.bind(this)
     }


  
/**
 * Adds a reply to the database
 * @param {} event 
 */
  async handleSubmit(event){
    event.preventDefault()
    const post_id = this.props.id
    const comment_id = this.props.referenceID
    const { body}= this.state
    const action =this.props.action;
    console.log("the post id isssssss",post_id)
    const mutation = gql`
    mutation createComment(
      $text: String!
       $PostId:Int!
       $CommentId:Int
     
    ) {
      createComment(
        PostId: $PostId
        text:$text
        CommentId:$CommentId
        
      ) {
        text
        UserId
        PostId
        id
        createdAt
      }
    }
  `;
  this.setState({pending:true})
  client
    .mutate({
      mutation: mutation,
      variables: {
        text:body,
        PostId: post_id,
        CommentId:comment_id
        
      },
    })
    .then((result) => {
      console.log(result);
      this.setState({body:'',pending:false})
      action(result.data.createComment,comment_id);
    })
    .catch((err) => {
      console.log("catch", err);
    });
   

   console.log(post_id)
     
    //   axios.post('/api/addcoment', {
    //     bodi:{post_id,body },
    //    headers: {"Content-Type":"application/json"}


    // }).then(response => {
      
    //   this.setState({body:''})
       
    //   })
      
     
  }











/**
 * 
 * @param {} event - Sets the state for the comment content 
 */
  handleChange(event){
    this.setState({body:event.target.value});
   
     
    }

  render(){ const{body} =this.state
  
  if (this.props.displayComment == false)
  return null
  return (
    <form onSubmit={this.handleSubmit} className="compose-form-reply">
      <div className="compose-form-reply-container">
        
        <textarea
            required='required'
          className="compose-form-reply-textarea"
          placeholder="What's happening?"
          value={body}
          onChange={this.handleChange}
        />
      </div>
      <button   className="compose-form-reply-submit">{(this.state.pending?<Loader isOpen={this.state.pending}/>:"comment")}</button>
    </form>
  );
}}

export default ComposeFormReply;