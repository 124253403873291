import React, { Component } from "react";
import ReferralInputField from "../Controllers/ReferralInputField";
import Loader from "./Loader";
import "./Styles/ReferralPopup.css"
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

class ReferralPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      complete:false,
      loader:false
    };
  }
close = ()=>{
    this.props.closeReferral()
}


  generateId = () => {
    this.setState({loader:true})
    client
      .mutate({
        mutation: gql`
          mutation Referral {
            createReferralId {
              referral_id
            }
          }
        `,
      })
      .then((result) => {
        this.setState({ id: result.data.createReferralId.referral_id,complete:true,loader:false});
      })
      .catch((error) => {
        console.log(error);
        this.setState({loader:false})
      });
  };
  render() {
    if (!this.props.displayReferral) return null;
    return (
      <div className="boxxxx">
        <div className="cenceph_pure_black">
          <p> Get Referral Link </p>
          <div className="tooltip">
            ?
            <i className="tooltiptext">
              Earn 5% of the earnings of users of this link
            </i>
          </div>
          <i onClick={()=> this.close()} className="fa fa-times" aria-hidden="true"></i>
          <div className="wrapp">
            <div className="cont">
                <Loader isOpen={this.state.loader}/>
                <ReferralInputField referral_id={this.state.id}/>
              {!this.state.complete &&<button
                className="wpc-btn wpc-btn-default-rounded"
                onClick={() => this.generateId(this.props.id)}
              >
                generate
              </button>}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralPopup;
