import React, { Component, useEffect, useRef, useState ,forwardRef} from 'react'
import Loader from './Loader';
 const PostImagePreview = forwardRef((props, ref) =>{
    //const [loading, setLoading] = useState(true);
    const child = React.createRef();
    const children = ref

    const [val, setVal] = useState(child);
    const [openState,setOpen] = useState(true)
    const [durations,setDurations] = useState([])
    const [mimeTypes,setMimeTypes] = useState([])
    const [hasRun,setHasRun] = useState(false)








      
    



    const onLoadedHandler = async (i,isImage)=>{
      
      //let type = await getMimeType(props.fileObject[i],i,isImage)
      props.setOpenMaster()
      setOpen(false)
    
    }

    console.log(props.length)
  
    return <div style={{display:"flex"}} > {Object.keys(props.fileObject).map((i,j)=><div style={{ width:"50px",height: "70px",
            margin: "10px"}} >
      <Loader isOpen={openState}/>
     
     {(props.type[j] == "video")? <video ref={children[j]} onLoadedData={() => {
            //runDuration()
             onLoadedHandler(j,false)}} style={{display: openState ? "none" : "block"}} muted={true} autoPlay={true} width="100%" height="100%" controls>
            <source src={props.postSrc[j]}  type="video/mp4">
            </source>
          </video>: <img ref={children[j]}
        style={{display: openState ? "none" : "block"}}
         className="gallery-image"
         src={props.postSrc[j]}
         
         onLoad={() => {
          //runDuration()
          onLoadedHandler(j,true)

            }} />
         }
    </div>)
    

          }</div>
    ;
  })

  export default PostImagePreview
  