import React, { Component, useEffect, useRef, useState } from 'react'
import Loader from './Loader';
import routes from '../storage_apollo_routes';
/**
 * @typedef PostImageProps
 * @prop {string} previewSrc
 * @prop {string} postSrc the image location(Cloud Storage in production)
 * @prop {boolean} isVideoPreview checks if a preview video for the image exists
 */
/**
 * 
 * @param {PostImageProps} props 
 * 
 */
function PostImage(props) {
    //const [loading, setLoading] = useState(true);
   
    const [openState,setOpen] = useState(true)
    const [openStatePreview,setOpenPrev] = useState(true)
  if(props.isVideoPreview){
   
    return <>
    <div style={{alignItems:"center",justifyContent:"center",positon:"relative"}}>
    <Loader  onClose={()=>{}}  isOpen={openStatePreview}/>
    </div>
    <video   autoPlay muted loop onLoadedData={() => {
      setOpenPrev(false)}} style={{display: openStatePreview ? "none" : "block"}} width="100%" height="100%" controls={false}>
     <source src={`${routes.storage_2}${props.previewSrc}`}  type="video/mp4">
     </source>
   </video>
  
   </>
  }
    return <>
     <Loader onClose={()=>{}} isOpen={openState}/>
     
          <img 
//           onMouseOver={ ()=>{if(props.displayHandler){
//             console.log("yeah")
// props.displayHandler(props.postId)
//           } }}
//           onTouchStart={ ()=>{if(props.displayHandler){
//             console.log("yeah")
// props.displayHandler(props.postId)
//           } }}
      
           style={{display: openState ? "none" : "block",}}
            className="gallery-image"
            src={`${routes.storage_2}${props.postSrc}`}
            
            onLoad={() => {
                setOpen(false)}} alt="unavailable" />
      
    </>;
  }

  export default PostImage
  