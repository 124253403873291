import React, { Component, useEffect, useRef, useState } from 'react'
import routes from '../storage_apollo_routes';
import Loader from './Loader';
import PropTypes from 'prop-types';
import { ApolloClient, InMemoryCache,useSubscription,gql,useQuery} from '@apollo/client';
import { split, HttpLink } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { Link } from 'react-router-dom'
import './Styles/Alertslist.css'
const ath = localStorage.getItem("userID")
const rejectRequestMutation = gql`
mutation Reject($alertId:Int!,$requestId:Int!){
 rejectRequest(alertId:$alertId,requestId:$requestId)
}
`
const addRequestVideoMutation = gql`
mutation Fulfill($alertId:Int!,$requestId:Int!,$link:String!){
  addRequestVideo(alertId:$alertId,requestId:$requestId,link:$link)
}
`
const httpLink = new HttpLink({
  uri: routes.apollo,
});

const wsLink = new GraphQLWsLink(createClient({
  url: routes.apollo_ws,
  connectionParams: {
    authentication: ath,
  },

}));
const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === 'OperationDefinition' &&
      definition.operation === 'subscription'
    );
  },
  wsLink,
  httpLink,
);
const client = new ApolloClient({
    link: splitLink,
    cache: new InMemoryCache()
  });

  /**
   * @
   */
/**
 * 
 * @param {Alert} result
 * 
 */
const  Alertslist = React.forwardRef(({fetchMore,data,subscribeToNewComments,...result},ref) =>{
console.log("HHHHHHHHHHHHHHHHHHHH",result,data)
  const [limit,setLimit] = useState(8)
  const [addVideoClicked,setAddVideoClicked] = useState(false)
  const [rejectRequestClicked,setRejectRequestClicked] = useState(false)
  const [offset,setOffset] = useState(0)
const [active,setActive] = useState("all")
const [selectedRequest,setSelectedRequest] = useState(null)
const [videoLink,setVideoLink] = useState("")
const child = React.createRef()

const HandleScroll = async () => {
  //   const {limit,offset,user,posts} = this.state
  // if(loading) return
  // setVal(val);

  //706 == bottm of page(more or less) child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight
  const bottom = child.current.scrollTop >= child.current.scrollHeight /2 /child.current.clientHeight; //child.current.clientHeight
  console.log(
    bottom,
    child.current.scrollHeight,
    child.current.scrollTop,
    child.current.clientHeight
  );
  if (bottom) {
    await fetchMore({
      variables: {
        offset: offset,
        limit: limit,
        id: parseInt(localStorage.getItem("userID")),
      },
    });
    if (data) {
      setLimit(data.getAlerts.length * 2);
      setOffset(data.getAlerts.length);
    }
  }
};

    useEffect(() => subscribeToNewComments(limit,offset), []);

   /** 
    * Returns a string indicating the viewstate of an Alert
    * @param {integer} alert.hasViewed - The viewState of the alert.
    */
    const viewState = (param) =>{
     return (param)?"-viewed":""
    }
//rejected or expired(issue date- currentDate >= 7 )
    const rejectRequest = async(alertId,requestId)=>{
      setRejectRequestClicked(true)
await client.mutate({
  mutation:rejectRequestMutation,
  variables:{
alertId:alertId,
requestId:requestId
  }

}).then((r)=>{
  console.log(r)
  setRejectRequestClicked(false)
  window.location.reload()
}).catch((e)=>{
  console.log(e)
  setRejectRequestClicked(false)
})
    }

    const addRequestVideo = async(alertId,requestId)=>{
      if(videoLink == "") return
      setAddVideoClicked(true)
      await client.mutate({
        mutation:addRequestVideoMutation,
        variables:{
      alertId:alertId,
      requestId:requestId,
      link:videoLink
        }
      
      }).then((r)=>{
        setAddVideoClicked(false)
        console.log(r.data.addRequestVideo)
        window.location.reload()
      }).catch((e)=>{
        console.log(e)
        setAddVideoClicked(false)
      })
          }

       /** 
    * Returns a string indicating the type of an Alert
    * @param {integer} alert.type - The type an Alert.
    */
    const mapType = (param)=>{
      console.log("called alertlist subscribetoMore")
      let types = {
        message:" fa-message",
        favorited:" fa-heart",
        payment:"fa-usd",
        tip:"fa-usd",
        withrawal:" fa-check",
        comment:"fa-reply",
        referral:"fa-user-o",
        payperview:"fa-eye",
        subscription:"fa-check",
        request:"fa-gift"
      }
      return types[param]
    }

           /** 
    * Returns a string indicating the type of an Alert
    * @param {string} alert.type - The type of an Alert.
    * @param {integer} alert.postId - The postId of an Alert.
    * @param {integer} alert.commentId - The postId of an Alert.
    */
     const getPath = (type,post,param) =>{
      if(type == "comment"){
        return `/post/${post}`
      }
      if(type == "favorited"){
        return `/post/${post}`
      }
      return ""
     }

     /** 
      * Changes the viewState of an Alert
      * @param {integer} alert.id -The id of an Alert
     */
     const toggleAlert =(id)=>{

    client
    .mutate({
      mutation: gql`
        mutation modifyAlert {
        toggleAlert(id:${id})
        }
      `,
    })
    .then((result) => {

      this.setState({comments:result.data.toggleAlert})

})
    .catch((err) => {
      console.log(err);

    });
     }
/**
 * Deletes an Alert from the database
 * @param {integer} alert.id - The id of an Alert.
 */
    const removeAlert = (id)=>{
       let fn = ()=>  subscribeToNewComments()
        client
        .mutate({
          mutation: gql`
          mutation {
            deleteAlert(id:${id})
          }
        `,
        }).then((res)=>{
       console.log(res)
       if(res.data.deleteAlert == true){
          return window.location.href = "/alerts"

       }
    
    }).catch((err)=>{
          console.log(err)
        })
   
     
    }
    const filterByType = (type)=>{
// let typeMap ={
//   "likes":true,
//   "replies":true,
//   "referral":true,
//   "tips":true,
//   "all":true
// }
setActive(type)
console.log("yooo")
    }

     return <div       style={{
      "overflow-y": "scroll",
      height: "70rem",
      "border-radius": "5px",
      paddingTop:"22px"
    }} onScroll={HandleScroll} ref={child}    className="dialogue-container">
     
<div className='container'>
<div  className="c__ example-one">
  <div className="title"></div>
  <header  className="example-one-header scroll">
    <span style={{cursor:"pointer"}} onClick={()=>filterByType("all")} className="logo">All</span>
    <nav className="vertical-align-middle">
    <span onClick={()=>filterByType("request")}className="homepage_nav-item">requests</span>
      <span onClick={()=>filterByType("referral")}className="homepage_nav-item">referrals</span>
      <span onClick={()=>filterByType("tip")}className="homepage_nav-item">tips</span>
      <span className="homepage_nav-item" onClick={()=>filterByType("favorited")}>likes</span>
      <span onClick={()=>filterByType("comment")}className="homepage_nav-item">replies</span>
      <span onClick={()=>filterByType("subscription")}className="homepage_nav-item">subscription</span>
      
    </nav>
  </header>
</div>
{data && data.getAlerts.map(res =>  {
 if(active == "all") return (<div style={{borderBottom:"3px solid rgb(84 75 27 / 10%)"}} key={res.id} className="dialog-box">
 <div className={`background-blur${viewState(res.hasViewed)}`}></div>
 <div className="header">
   <div className={`background-blur${viewState(res.hasViewed)}`}></div>
   <div className="contents">
   <div  style={{fontSize: "22px"}} className="leftAlert">
    <Link onClick={()=>toggleAlert(res.id)} to={{pathname:getPath(res.type,res.postId,res.commentId),highlight_comment:res.commentId,reply:res.replyId}}> <i className={`fa ${mapType(res.type)}`}></i></Link> {res.message}
   </div>
 
   <div className="rightAlert">
   {res.createdAt}
   </div>
   </div>
 </div>
 
 <div class="contents main-content">
<div>
{(res.type == "request" &&  res.rejected == null) && res.status == false  && <div className="compose-form-replyy">
      {/* <div className="compose-form-reply-container">
        
        <textarea
        style={{background:"#dfdfed"}}
           // required='required'
          className="compose-form-reply-textarea"
          placeholder="eg. https://uhsoka.com/Otpost/..."
          value={res.id == selectedRequest? videoLink:null}
          onChange={(e)=>{
          setSelectedRequest(res.id)
          setVideoLink(e.target.value)
            console.log(res.rejected)}
          }
        />
      </div> */}
              <div className="input-container textarea">{true && 
        <textarea  
        placeholder="eg. https://uhsoka.com/Otpost/..."
        value={res.id == selectedRequest? videoLink:null}
        onChange={(e)=>{
        setSelectedRequest(res.id)
        setVideoLink(e.target.value)
          console.log(res.rejected)}
        }
        name="message" class="input"></textarea>
      
        }
        
        </div>
      <button onClick={()=>{
        setSelectedRequest(res.id)
        addRequestVideo(res.id,res.requestId)}}  className="compose-form-reply-submit">{(addVideoClicked && res.id == selectedRequest?<Loader isOpen={addVideoClicked}/>:"Paste content link")}</button>
      <button onClick={()=>{
        setSelectedRequest(res.id)
        rejectRequest(res.id,res.requestId)}} style={{color:"#ffff",backgroundColor:"red"}}  className="compose-form-reply-submit">{(rejectRequestClicked && res.id == selectedRequest?<Loader isOpen={rejectRequestClicked}/>:"Reject request")}</button>
    </div>}
</div>
   <strong>
     {/* E */}
     {res.status?    <div
        className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "7rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
          backgroundColor:"#48db4e"
        }}
      >
      fulfilled
      </div>:null}
      {res.rejected?    <div
        className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "7rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
        }}
      >
      cancelled
      </div>:null}
   </strong>
   <br/>
   {/* O */}
 </div>
 </div>)
 if(res.type == active) return (<div style={{borderBottom:"3px solid rgb(84 75 27 / 10%)"}} key={res.id} className="dialog-box">
<div className={`background-blur${viewState(res.hasViewed)}`}></div>
<div className="header">
  <div className={`background-blur${viewState(res.hasViewed)}`}></div>
  <div className="contents">
  <div  style={{fontSize: "22px"}} className="leftAlert">
   <Link onClick={()=>toggleAlert(res.id)} to={{pathname:getPath(res.type,res.postId,res.commentId),highlight_comment:res.commentId,reply:res.replyId}}> <i className={`fa ${mapType(res.type)}`}></i></Link> {res.message}
  </div>

  <div className="rightAlert">
  {res.createdAt}
  </div>
  </div>
</div>
<div class="contents main-content">
<div>
{(res.type == "request" && res.rejected == null) && res.status == false  && <div  className="compose-form-replyy">
      <div className="compose-form-reply-container">
        
        <textarea
        style={{background:"#dfdfed"}}
            //required='required'
          className="compose-form-reply-textarea"
          placeholder="eg. https://uhsoka.com/Otpost/..."
          value={res.id == selectedRequest? videoLink:null}
          onChange={(e)=>{
          setSelectedRequest(res.id)
          setVideoLink(e.target.value)
            console.log(res.rejected)}
          }
        />
      </div>
      <button  onClick={()=>{
        setSelectedRequest(res.id)
        addRequestVideo(res.id,res.requestId)
        
        }} className="compose-form-reply-submit">{(addVideoClicked && res.id == selectedRequest?<Loader isOpen={addVideoClicked}/>:"Paste content link")}</button>
      <button onClick={()=>{
        setSelectedRequest(res.id)
        rejectRequest(res.id,res.requestId)}} style={{color:"#ffff",backgroundColor:"red"}}  className="compose-form-reply-submit">{(rejectRequestClicked && res.id == selectedRequest?<Loader isOpen={rejectRequestClicked}/>:"Reject request")}</button>
    </div>}
</div>
  <strong>
    {/* E */}
    {res.status?    <div
        className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "7rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
          backgroundColor:"#48db4e"
        }}
      >
      fulfilled
      </div>:null}
      {res.rejected?    <div
        className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "7rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
        }}
      >
      cancelled
      </div>:null}
  </strong>
  <br/>
  {/* O */}
</div>
</div>)})}
</div>
</div>
  
})

export default Alertslist;
//<div
// className="dropdown"><div className='conta'>{result.data && result.data.getAlerts.map(res => (<div> <div  className="result"><div className="fav">
// {/* <i className="fa fa-trash-o"onClick={()=>removeAlert(res.id)} aria-hidden="true" ></i> */}
// </div><p>{res.message}</p></div></div>))}    
// </div></div>
//     </div>