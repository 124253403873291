import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SocketClient from './socketClient';

class Chatwith extends Component {
    constructor(props) {
        super(props);
       this.state ={
        to:this.props.location.to_id
       }

    }


    componentDidMount() {
   //console.log("here nigga")
    }


    render() {
        const {to} = this.state
        return (
            <div>
<SocketClient toParam={to}></SocketClient>
            </div>
        );
    }
}



export default Chatwith;