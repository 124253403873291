import ChatUI from "../UI/ChatUI";
import React, { Component } from "react";
import { io } from "socket.io-client";
import OnlineIndicator from "../UI/OnlineIndicator";
import {v4 as uuid} from 'uuid'
import SocketClientComment from "./SocketClientComment";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { resultKeyNameFromField } from "@apollo/client/utilities";
import Popuptip_chat from "../UI/Popuptip_chat";
import PPVchat from "../UI/PPVchat";
const link = createHttpLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
  credentials: "include",
});
const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});

const URL = "http://localhost:5000";
const client = io(URL, { autoConnect: false });

class SocketClient extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socketInstance: "",
      ppvmessage:null,
      msgsid:"",
      username: "",
      id:"",
      session:"",
      recipient:"",
      rcpt:"",
      price:null,
      ppvindex:null,
      users: [],
      friends:[],
      rcpt_db_id:this.props.toParam,
      messages:[],
      personal:"",
      stored:"",
      PPVchat:false,
      tipPopup:false,
      onlineUsers:{},
      online:null,
      activate:false,
      typeStatus:false
    };
  }

clearUnread=(id)=>{
  apolloClient.mutate({
    mutation:gql`
    mutation {
      deleteUnreads(id:${id})
    }`
  })
}

  addTip = ()=>{
    this.setState({tipPopup:!this.state.tipPopup})
    window.scrollTo(0, 0)
  }

  buyPost =(price,index,message)=>{
    this.setState({PPVchat:true,amount:price,ppvindex:index,ppvmessage:message})
    window.scrollTo(0, 0)
  }

  createUnread =async  (type,content,id) =>{
    console.log(id,type,content)
    apolloClient.mutate({
      mutation:gql`
      mutation {
        createUnreadMessage(id:${id},type:"${type}",content:"${content}")
      } `
    }).then((response)=>{
      console.log(response.data.createUnreadMessage)
    }).catch((err)=>{
      console.log(err)
    })
  }

  resaveSession = ()=>{
    apolloClient.mutate({
      mutation: gql`
      mutation resaveSesh {
        resaveSession
      }
    `,
      
    }).then(response=>console.log(response)).catch(err=>console.log(err))
  }
createmessageId = (id,messageid,sessionid)=>{
     return apolloClient.query({
    query: gql`
    query messageID {
      createmessageId(userId:${id},messageId:"${messageid}",sessionId:"${sessionid}"){
        messageId
        sessionId
      }
    }
  `,
    
  }).then((result)=>{
    this.setState({id:result.data.createmessageId.messageId,session:result.data.createmessageId.sessionId})
  })
}
  getmessageId = async (id) =>{
    return apolloClient.query({
      query: gql`
      query messageID {
        getmessageId(userId:${id})
      }
    `,
      
    }).then((result)=>{
      console.log(result.data.getmessageId)
      //this.setState({friends:this.state.friends.concat(result.data.getSubscribers)})
      let db_id = (result.data.getmessageId && result.data.getmessageId.length !== 0)?result.data.getmessageId:null
      console.log(db_id,"result from query")
      this.setState({id:db_id[0],session:db_id[1]})
      return db_id[1]
    }).catch(err=>{console.log(err)});
  }

  fileHandler = async (event) =>{
    const {users,id,rcpt,rcpt_db_id} = this.state
    let index = this.state.messages.length
    let date = new Date(Date.now())
    let dateFmt = date.toDateString() + " " + date.toTimeString()
    let content = event.target.files[0] 
    console.log(content.type)
    //hardcoded to
    let to = (rcpt !== "")?rcpt:await this.getRecipient(rcpt_db_id)
    if(!this.state.online){
      //create a new unread
      this.createUnread("message",content,rcpt_db_id)
     }
    //let to = users[1].userid
    let name = content.name
    let price =(this.state.price && this.state.price > 0)?this.state.price:null
    client.emit("private message", {content,to,name,dateFmt,price,index});
    //remove this
    // content  = {path:`../public/chat/${name}`}
    let from = id
    this.setState({ 
    socketInstance: "" ,
    recipient:to, 
    id:id,
    //price:null
   // messages:this.state.messages.concat([{content,from,dateFmt}])
  });
   // console.log("message emitted",content.name);
   
  }

  filterUsersByFriends = (user) => {
    const {friends} = this.state
    for(let i=0;i<friends.length;i++){
      if(user.username === friends[i].name){
        return true
      }
    }
     return false
  };

getRecipient = async (id) =>{
  return apolloClient.query({
    query: gql`
    query messageID {
      getmessageId(userId:${id})
    }
  `,
    
  }).then((result)=>{
   console.log(result.data.getmessageId[0],"get recipient was called")
   let recipient_id = result.data.getmessageId[0]
   this.setState({rcpt:recipient_id})
   return recipient_id
  }).catch((error)=> console.log(error))
 
}
closeTip = () =>{
this.setState({tipPopup:!this.state.tipPopup})
}
closePPV = () =>{
  this.setState({PPVchat:!this.state.PPVchat})
  }
getRecipient1 = async (id) =>{
  return apolloClient.query({
    query: gql`
    query messageID {
      getmessageId(userId:${id})
    }
  `,
    
  }).then((result)=>{
   console.log(result.data.getmessageId[0],"get recipient was called")
   let recipient_id = result.data.getmessageId[0]
   //this.setState({rcpt:recipient_id})
   return recipient_id
  }).catch((error)=> console.log(error))
 
}
  meQuery = () => {
    const {rcpt_db_id} = this.state
    return apolloClient
      .query({
        query: gql`
          query myQuery {
            me {
              name
              id
            }
          }
        `,
      })
      .then(async (result) => {
        let name = result.data.me.name;
         let param = result.data.me.id
         this.setState({personal:param})
        ///getmessageid
        //const sessionID = localStorage.getItem("sessionID");
        const sessionID = await this.getmessageId(param)
        
        // console.log(sessionID,"return type undetermined,this is first called,could be either null or the id itself")
        if (sessionID) {
          // console.log(name,"the names here foo")
          client.auth = { name };
          client.auth = { sessionID };
          client.connect();
        } else {
          //console.log(name, "using the else conditional");
          client.auth = { name };
          //client.auth = {target}
          client.connect();
        }

        client.on("session", ({ sessionID, userID}) => {
          //this.setState({id:userID})
          // attach the session ID to the next reconnection attempts
          console.log("reconnection attempt read",sessionID,userID,name);
          //this.setState({id:userID})
          client.auth = { name };
          client.auth = { sessionID };
         
          //client.auth = {target}
          client.connect();
          // store it in the localStorage
          //const oldSession = localStorage.getItem("sessionID");
          const oldSession = this.state.session 
          console.log(oldSession,"this is the oldSession id reconnection attempt")//get
          if (!oldSession) {
            //createmessageId(userid)
          //localStorage.setItem("sessionID", sessionID);
          this.createmessageId(param,userID,sessionID)
        }//store it in db

          // save the ID of the user
          client.userID = userID;
          console.log("USER ID NULL WHY",userID)
          
          //createmessageid
          console.log(userID, sessionID, "session middleware");
        });
        client.on("connect_error", (err) => {
          if (err.message === "invalid username") {
            console.log("invalid username");
          }
        });
       
        this.setState({username:name,//friends:[result.data.me]
      });
      }).catch(err=>{console.log(err)});
  };

  onChangeHandler = (event) => {
    this.setState({ socketInstance: event.target.value });
  };

  onChangeHandler_typing = async (event) =>{
    console.log(event)
    const {rcpt,rcpt_db_id} = this.state
    let to = (rcpt !== "")?rcpt:await this.getRecipient(rcpt_db_id)
   if(this.state.activate){
    return
   }
   else{

   this.setState({activate:true})
client.emit("typing_event",{user_typing:true,to})
   setTimeout(()=>{
this.setState({activate:false})
   },2000)
   }

  }

  typeStatus = () =>{
//onchange handler
console.log(this.state.typeStatus,"typestatus")

if(this.state.typeStatus){
  return
}
this.setState({typeStatus:true})
setTimeout(()=>{
this.setState({typeStatus:false})
},2000)
  }
 
 
  emitMsg = async () => {
    const { socketInstance,users,id,rcpt_db_id,rcpt} = this.state;
    let content = socketInstance 
    //heavy refactoring needed
    let date = new Date(Date.now())
    let dateFmt = date.toDateString() + " " + date.toTimeString()
    console.log(dateFmt)
   //console.log(to,id)
  

    //to to too
  
    //pass mesageeid as prop and call get mesageID
    let to = (rcpt !== "")?rcpt:await this.getRecipient(rcpt_db_id)
    
    console.log(to,"THIS IS THE RECIPIENT",this.state.onlineUsers[this.state.rcpt])
    if(!this.state.online){
      //create a new unread
      this.createUnread("message",content,rcpt_db_id)
     }
    // if(!users[1]){
    //   console.log("No User Online")
    //   return
    // }

    client.emit("private message", {content,to,dateFmt});
    let index = this.state.messages.length
    let from = id
    this.setState({ 
    socketInstance: "" ,
    recipient:to, 
    messages:this.state.messages.concat([{content,from,dateFmt,index,to}])});
    console.log("message emitted",{content,from,to,index});
  };

  emitMsg_tip = (msg) =>{
    this.setState({socketInstance:`Tipped ${msg} cedis`})
    this.emitMsg()
   }


 

  // created() {
  //   const sessionID = localStorage.getItem("sessionID");

  //   if (sessionID) {
  //     //this.usernameAlreadySelected = true;
  //     client.auth = { sessionID };
  //     client.connect();
  //   }
  //   // ...
  // }

  componentWillUnmount(){
    client.disconnect()
    //console.log("disconnected")
  }

  deleteMessage=(index,i,to)=>{
    console.log(to)
    let hash = uuid() 
    client.emit("delete message",{index:i,hash,to})
    let filteredMessages = this.state.messages
    this.setState({messages:filteredMessages.filter((msg,index)=>{
      
     // msg.index = msg.index-1
      return index != i})})
  }
  modify=(index,modified)=>{
    console.log(index,modified)
    client.emit("modify",{index,modified})
   }
  onlineStatus = async () =>{
    let call = this.state.rcpt
    let to = (call !== "")?call:await this.getRecipient(this.state.rcpt_db_id)
   
    if(this.state.onlineUsers[to]){
      console.log("METHOD",this.state.onlineUsers[to])
       this.setState({online:true})
       return
    }
    this.setState({online:false})
    console.log("METHOD",this.state.onlineUsers[to]) 
  }
  priceHandler = (event)=>{
    this.setState({price:event.target.value})
  }
  componentDidMount() {
    const {rcpt,rcpt_db_id,id,personal,stored} = this.state
  
    this.meQuery();
    
this.clearUnread(this.state.rcpt_db_id)

    client.on("emitted",({content,from,index,price,to})=>{
     // console.log(price,index)
      let idx =  this.state.messages.length 
      
     // console.log("message received",content)
      // let msgs = (content[0])?content:[{content,from}]
      // let index = (this.state.messages[this.state.messages.length-1].index)+1
      this.setState({typeStatus:false,messages:this.state.messages.concat([{content,from,index:idx,price,to}])})

      //console.log("message received",content)
  })
// client.on("user connected",(data)=>{
// console.log("USER CONNECTED YOOOOOOOOOOOO",data)
// })

client.on("modify success",({mdf_2,index,from})=>{
   
    let messages = this.state.messages
    for(let i in messages){
    if(messages[i].index == index){
    messages[i] = mdf_2
    console.log(index,from,mdf_2)
    }
   
  }
console.log("ratatatattatatattttttttttttttttttttttttttttttttttttttttttttttttttttt")
  this.setState({messages:messages})
   


})
client.on("users",(data)=>{
  console.log(data,"USERS CURRENTLY ONLINE")
  this.setState({onlineUsers:data})
  this.onlineStatus()
})
client.on("msgrcvd",async ({content,sender})=>{
  let to = (stored == "")?await this.getRecipient1(localStorage.getItem("userID")):stored
  this.setState({stored:to})
  console.log(to,sender)
  if(`cid-${to}` == sender){
    let date = new Date(Date.now())
    let dateFmt = date.toDateString() + " " + date.toTimeString()
  this.setState({typeStatus:false,messages:this.state.messages.concat([{content,sender,dateFmt}])});
  }

})

client.on("activate_typing",(data)=>{
  this.typeStatus()
})

client.on("message deleted",({index,deleted})=>{

  let messages = this.state.messages.filter((msg,i)=> i !== index)
  this.setState({messages:messages})

  
})

  client.on("msgs",async(msgs)=>{
console.log(msgs)
    let t = (rcpt !== "")?rcpt:await this.getRecipient(rcpt_db_id)

    console.log("msgs babbby",msgs,t,rcpt,rcpt_db_id)
    const messagesPerUser = new Map();
    let filteredmsgs =  []
    if(msgs.length > 0){
      filteredmsgs = msgs.filter((msg,i)=>{
        const {to,from} = msg
        msg["index"] = i
        return to == t || from == t
      })
  //     msgs.forEach((message) => {
  //        const { from, to } = message;
  //          if(to == t){
  //             console.log(from,to,"FRom,to")
  //    const otherUser = "ddabd129-8873-4c42-b3b4-3e0d5183c7e6" === from ? to : from;
  //    if (messagesPerUser.has(otherUser)) {
  //      messagesPerUser.get(otherUser).push(message);
  //    } else {
  //      messagesPerUser.set(otherUser, [message]);
  //    }
  //          }
   
  //  });
  this.setState({messages:filteredmsgs})
     
    }
    console.log(filteredmsgs)
    // this.setState({messages:this.state.messages.concat(filteredmsgs)})
    
  })
    // client.on("users", (users) => {
    //   users.forEach((user) => {
    //     user.self = user.userID === client.id;
    //   });

      
    //    let pushUsers = users.sort((a, b) => {
    //       if (a.self) return -1;
    //       if (b.self) return 1;
    //       if (a.username < b.username) return -1;
    //       return a.username > b.username ? 1 : 0;
    //     });

    //   pushUsers.forEach((puser) => {
    //     if(!this.filterUsersByFriends(puser)){
    //      console.log("screened",puser)
    //      return
    //     }
        
    //     for (let i = 0; i < this.state.users.length; i++) {
    //       if (this.state.users[i].userid === puser.userid) {
    //         console.log(puser, "has connected");
    //         return;
    //       }
    //     }

    //     this.setState({ users: this.state.users.concat([puser]) });
    //     console.log(this.state.users, "user state");
    //   });
    // });

    client.on("user connected", (user) => {
      //console.log(user, user.username)
      // if(!this.filterUsersByFriends(user)){
      //   console.log(user.username)
      //   return
      //  }
      // for (let i = 0; i < this.state.users.length; i++) {
      //   if (this.state.users[i].userid === user.userid) {
      //     console.log(user, "users have re-established online status");
      //     return;
      //   }
      // }

      let old_state = this.state.onlineUsers
       old_state[user.userid] = user
      //log to console
      this.setState({onlineUsers:old_state})
      console.log(this.state.onlineUsers, "user state this.state.users");
    });

    client.on("disconnection_handler",(param)=>{
      let old_state = this.state.onlineUsers
      old_state[param] = false
     //log to console
     this.setState({onlineUsers:old_state})
     console.log(this.state.onlineUsers, "disconnection handler was called");
    })


   

    // client.on('connect',()=>{
    //         console.log("connected to server")
    //     })
    //    //console.log(`client instance: ${io}`)
  }

  render() {
    const {socketInstance,username,messages,id,recipient,rcpt_db_id,online,rcpt} = this.state;
    return (
      <div>
      <div className="newuimenu">
                <div className="uimenu">
                <div className="uiMenuWrapper"> 
                
                     <div className="conversation">
                     <img className="conversationIMG"/>
                         <span className="conversationName">uhsoka</span>
                         
                         
                     </div>
                </div>
                    </div>
                    <div className="container">
                        <div className="b">chat online indicator{this.state.onlineUsers[this.state.rcpt] && <OnlineIndicator onlineStatus={this.state.online}/>}
                        <div className="chatBoxTop">
                        <ChatUI buyPost={this.buyPost} deleteMessage={this.deleteMessage} activate={this.state.typeStatus} messages={messages} name={username} to={recipient} id={id} rcpt={rcpt_db_id}/>
                        
                        </div>
                        <div className="chatBoxBottom">
                          {/* <textarea classname="chatMessageInput"></textarea>
                          <button className="chatSubmitButton"> send</button> */}
   
                        </div>
                        
                        </div>
                        <div className="cdparent">
            <div className="cd">
            <div classname="inputa">
         {/* <input
         onKeyUp={(event)=>this.onChangeHandler_typing(event)}
          onChange={(event) => this.onChangeHandler(event)}
          className="input"
          value={socketInstance}
          name="clientInput"
          type="text"
        ></input> */}
        
        
        {/* tip with input tag and button for tip */}
        
        {/* <button onClick={() => this.emitMsg()}>Emit Text</button> */}
        <div>
        <Popuptip_chat emitmsg={this.emitMsg_tip} onClose={this.closeTip} id={rcpt_db_id}  isOpen={this.state.tipPopup}/>
        <PPVchat ppvmessage={this.state.ppvmessage} ppvindex={this.state.ppvindex} isOpen={this.state.PPVchat} price={this.state.amount} id={rcpt_db_id} modify={this.modify} onClose={this.closePPV}/>
        </div>
        <SocketClientComment price={this.state.price} priceHandler={this.priceHandler} socketInstance={socketInstance} fileHandler={this.fileHandler} emitMsg={this.emitMsg} onChangeHandler_typing={this.onChangeHandler_typing} onChangeHandler={this.onChangeHandler}/>
        
        {/* <button onClick={() => this.addTip()}>Tip</button> */}
        </div>
                    {/* <div className="uiMenuOnlineWrapper">
                      <input type="file"  multiple accept="image/*,audio/*,video/*" name="multimedia" onChange={(event)=>{this.fileHandler(event)}}/>
                    </div> */}
                    </div>
                    </div>
                    </div>
                   
            </div>
    
            </div>

    );
  }
}

export default SocketClient;