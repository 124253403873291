import React, { Component } from 'react';
import Loader from '../UI/Loader';
import CascadingProfiles from './CascadingProfiles';
import ListSearch from './ListSearch';
import List from './List';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
  

class Lists extends Component {
    constructor(props){
        super(props)
        this.state = {
            lists:[],
            hover:false,
            displayForm:false,
            newListName:null,
            isOpen:false,
            displayListSearch:false,
            selectedId:null,
            name:null,
            openList:false,
            activeListUsers:[]

        }
this.listRef = React.createRef()

    }

    addUsers =(userArray)=>{
       
        const bulk = gql`
        mutation bulkAdd($items:[Int],$listId:Int!) {
            addListItems(items:$items,listId:$listId)
        }
      `;
      client
        .mutate({
          mutation: bulk,
          variables: {
            listId:this.state.selectedId,
            items:userArray,
          },
        })
        .then((result) => {
            if(result.data.addListItems){
console.log(result)
this.setState({displayListSearch:false,selectedId:null})
            }
          
       
        })
        .catch((err) => {
          console.log("catch", err);
      
        });

        setTimeout(()=>{
          this.getLists()
        },1000)


    }

    hideControls = (name) =>{
      let banList = {
        Referrals:false,
        Bookmarked:false
      }

      if(banList[name]) return banList[name]

      return true
    }

    createNewList = ()=>{
        this.setState({isOpen:true})
        client.mutate({
            mutation: gql`
            mutation NewList{
              createNewList(name:"${this.state.newListName}"){
                id
                name
                createdAt
              }
            }
          `,
            
          }).then((result)=>{
            this.setState({lists:this.state.lists.concat([result.data.createNewList]),displayForm:false,newListName:null,isOpen:false})
            console.log(result.data.createNewList)

          }).catch((error)=>{
            console.log(error)
          })

    }

    nameChosen =(event)=>{
        this.setState({newListName:event.target.value})
    }

    showListSearch = (id,name)=>{
        this.setState({displayListSearch:true,selectedId:id,name:name})
    }
    closeListSearch = () =>{
      this.setState({displayListSearch:false})
    }

    deleteList = (id)=>{
      client.mutate({
        mutation: gql`
        mutation DeList{
           deleteList(id:${id})
        }
      `,
        
      }).then((res)=>{
       if(res){
        this.getLists()

       }

      }).catch((error)=>{
      console.log(error)
      })

    }

    getFormField=()=>{
        this.setState({displayForm:!this.state.displayForm,newListName:null})

    }

    // getUsers =(id)

    getLists = ()=>{
        // getAllLists():[List]
        client.query({
            query: gql`
            query ListQuery{
              getAllLists{
                id
                name
                
                createdAt
                users{
                  name
                  messageId
                  id
                  email
                  subbed
                  user_profile{
                    UserId
                    
                  }
                }
              }
            }
          `,
            
          }).then((result)=>{
            this.setState({lists:result.data.getAllLists})
            console.log(result.data.getAllLists)

          }).catch((error)=>{
            console.log(error)
          })
    }
    removeUser = (id,user)=>{
      let prevState = this.state.lists
      for(let i in prevState){
      
        if(prevState[i].id == id){
          
          prevState[i].users = prevState[i].users.filter(usr=>usr.id != user)
          
          this.setState({lists:prevState,activeListUsers:prevState[i].users})
        }
      }
      
    }
closeList=()=>{
  this.setState({activeListUsers:null,openList:false,activeListId:null})
}
    openListProp = (users,id)=>{
      console.log("slop")
      //this.setState({activeListUsers:null})
      this.setState({activeListUsers:users,openList:true,activeListId:id})
      console.log(users)
      this.listRef.current.scrollIntoView()
    }
    componentDidMount(){
        this.getLists()

    }
    render() {
        const {lists} = this.state
        // if(!this.state.displayLists) return null
        return (
            <div>
             <div ref={this.listRef}>{this.state.activeListUsers &&<List closeList={this.closeList} modifyListUsers={this.removeUser} activeListId={this.state.activeListId} openList={this.state.openList} listUsers={this.state.activeListUsers}/>}</div>

<li ng-repeat="user in ctrl.users" onMouseOver={()=>this.setState({hover:true})} onMouseLeave={()=>this.setState({hover:false})} style={{position:"relative",cursor:"pointer",height:"75px",borderBottom:(this.state.hover)? "3px solid rgb(84 75 27 / 10%)" : "1px solid rgba(0, 0, 0, 0.1)"}} className="list2-item">
                          <div style={{    color: "blue",
    width: "7px",
    position: "absolute",
    top: "0px",
    right: "0px"}}>
                          {/* <i onClick={(e)=> this.close(e)} className="fa fa-plus" aria-hidden="true"></i> */}
                          </div>
                <div style={{height:"100px"}}>

                </div>
                <div  className="list2-item-content">
                 <h4 onClick={()=>this.getFormField()}>Create New List</h4>
                 {/* {this.state.isOpen && <div style={{height:"9px"}}><h4><Loader isOpen={this.state.isOpen}/></h4></div>} */}
                 {this.state.displayForm && <input onChange={(e)=>this.nameChosen(e)} value={this.state.newListName} placeholder="enter a name"  type="text" />}
                 {this.state.newListName && <div style={{    color: "blue",
    width: "1px",
    position: "absolute",
    bottom: "9px",
    right: "80px"}}> <button onClick={()=>this.createNewList()} className="wpc-btn wpc-btn-default-rounded">
        create

        </button></div>}
               
                </div>
              </li>
             <div> {<ListSearch getUsers={this.getUsers} closeListSearch={this.closeListSearch} addUsers={this.addUsers} resultsProper={this.props.subs} selectedName={this.state.name} selectedId={this.state.selectedId} displayListSearch={this.state.displayListSearch}/>}</div>
                          <div  >{lists.map(dat=>(<li key={dat.id} ng-repeat="user in ctrl.users" style={{position:"relative",cursor:"pointer"}} className="list2-item">
                          <div style={{    color: "blue",
    width: "10px",
    position: "absolute",
    top: "2px",
    right: "5px"}}>
                          {dat.name != "Referrals" &&<i style={{fontSize:"13px"}}onClick={()=> this.showListSearch(dat.id,dat.name)} className="fa fa-plus" aria-hidden="true"></i>}
                          </div>
                          <div style={{    color: "#ff003b",
    width: "1px",
    position: "absolute",
    cursor:"pointer",
    bottom: "9px",
    right: "22px"}}> {dat.name != "Referrals" && <i style={{fontSize:"13px"}}onClick={()=> this.deleteList(dat.id)} className="fa fa-trash-o" aria-hidden="true"></i>}
        

</div>
                <div style={{height:"100px"}}>
                    <CascadingProfiles users={dat.users}/>

                </div>
                <div className="list2-item-content">
                 <h4 onClick={()=>this.openListProp(dat.users,dat.id)}> {dat.name}</h4>
                  <p>{dat.createdAt}</p>
               
                </div>
              </li>
              ))} </div>
            </div>
        );
    }
}

export default Lists;