import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3'
import Loader from './Loader';
import ReactDOM from 'react-dom';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
}
const link = createHttpLink({
  uri: "https://apolloserver.uhsoka.com/graphql",
  credentials: "include",
 // defaultOptions:defaultOptions
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});

class PPVchat extends Component {
  constructor(props){
    super(props);
  this.state={
   contact:"",
    completed:false,
    open:this.props.isOpen,
    errorMsg:"",
    iMO:false,
    loaderState:false,
    message:null,
    price:null
  
  }
    //this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    //this.setPrice = this.setPrice.bind(this)
  }


  componentDidMount(){

    
  }



  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  payView = (id,price)=>{
console.log(id)
   // const {contact,message,price} = this.state
 
    this.setState({loaderState:true})
    client
  .mutate({
    mutation: gql`
    mutation Unlock {
        PPVchat(id:${id},amount:${price})
      }
    `,
  }).then((result)=>{
    console.log(result.data.PPVchat)
    if(result.data.PPVchat){
      this.props.modify(this.props.ppvindex,this.props.ppvmessage)
      this.setState({completed:true,loaderState:false,contact:""})
      //
       }
       else{
        
        // this.props.updateProfileParams(amount)
        
        this.createError("An error occured")
        //modify post clear it after testing
        
        this.setState({loaderState:false,contact:""})
       }

  }).catch((err)=>{
    console.log(err)
    this.createError(`Invalid Input,${err}`)
    this.setState({loaderState:false,contact:""})
  })

  //clear apollo cache

  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

// contactChosen = (event)=>{
//     this.setState({contact:event.target.value});
    
// }
// numberchosen = (event)=>{
//   this.setState({price:event.target.value})
// }

closeModal() {
  this.setState({iMO:false,errorMsg:null})
  
  //this.update();
}


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
        this.setState({loaderState:false,errorMsg:null})
      }
    }
   
    
    render() {
     const {completed,amount,errorMsg,price} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div className="cenceph">
       
        
        {!completed && <p> Unlock for Ghc {this.props.price}  </p>}
        {completed && <p> Successfully unlocked</p>}

        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
          <Popup3 isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
          <Loader isOpen={this.state.loaderState}/>

      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" />  */}
    
      {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.payView(this.props.id,this.props.price)}>pay</button>
  
  
  
  
  </div>}
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default PPVchat;