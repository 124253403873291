import React,{useState,useEffect,createRef,useRef} from "react"
function useDragger(id){
const elementRef = useRef(null)
const containerRef = useRef(null)
const isClicked = useRef(false)
const co_ords = useRef({
    startX:0,
    startY:0,
    lastX:0,
    lastY:0
})


useEffect(()=>{
// if(!elementRef.current || !containerRef.current){
//     return
// }
const target = document.getElementById(id)
if(!target){
    return new Error("ELEMENT DOESN'T EXIST")
}

// const el = elementRef.current
// const contain = containerRef.current
const contain = target.parentElement
if(!contain){
    return new Error("ELEMENT DOESN'T EXIST")
}


const onMouseDown = (e)=>{
   // console.log("not clicked at all")
isClicked.current = true
co_ords.current.startX = e.clientX
co_ords.current.startY  = e.clientY
}

const onMouseUp = (e)=>{
    isClicked.current = false
    co_ords.current.lastX = target.offsetLeft
    co_ords.current.lastY = target.offsetTop

}



const onMouseMove = (e)=>{
    console.log(isClicked)
if(!isClicked.current){
return
}

console.log("bruv")
const nextX = e.clientX - co_ords.current.startX + co_ords.current.lastX
const nextY = e.clientY - co_ords.current.startY + co_ords.current.lastY
target.style.top = `${nextY}px`
target.style.left = `${nextX}px`
}

const onTouchMove = (e)=>{
    console.log(e.touches[0].clientX,"smoove")
    const nextX = e.touches[0].clientX - co_ords.current.startX + co_ords.current.lastX
const nextY = e.touches[0].clientY - co_ords.current.startY + co_ords.current.lastY
target.style.top = `${nextY}px`
target.style.left = `${nextX}px`
}

const onTouchStart = (e)=>{
    isClicked.current = true
co_ords.current.startX = e.touches[0].clientX
co_ords.current.startY  = e.touches[0].clientY

}

const onTouchEnd = (e)=>{
    isClicked.current = false
    co_ords.current.lastX = target.offsetLeft
    co_ords.current.lastY = target.offsetTop
}
target.addEventListener('mousedown',onMouseDown)
target.addEventListener('mouseup',onMouseUp)
contain.addEventListener('mousemove',onMouseMove)
contain.addEventListener('mouseleave',onMouseUp)
//touchEvents
target.addEventListener('touchstart',onTouchStart)
target.addEventListener('touchend',onTouchEnd)
contain.addEventListener('touchmove',onTouchMove)
contain.addEventListener('touchleave',onTouchEnd)

const cleanUp = ()=>{
    target.removeEventListener('mousedown',onMouseDown)
    target.removeEventListener('mouseup',onMouseUp)
    contain.removeEventListener('mouseup',onMouseMove)
    contain.removeEventListener('mouseleave',onMouseUp)
    //touchEvents
    target.removeEventListener('touchstart',onTouchStart)
target.removeEventListener('touchend',onTouchEnd)
contain.removeEventListener('touchmove',onTouchMove)
contain.removeEventListener('touchleave',onTouchEnd)
}

return cleanUp
},[])

}

export default useDragger