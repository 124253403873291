import React, { Component } from 'react';
import routes from '../storage_apollo_routes';
import AudioPlayer from "../Controllers/AudioPlayer"
import UploadProgress from './uploadProgress';
import  { buildAxiosFetch } from "axios-fetch";
import { uploadFetch } from './uploadFetch';
import axios from 'axios';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  import Loader from "./Loader";
  import { setContext } from "@apollo/client/link/context";
  import PostImagePreview from './PostImagePreview';
  import { createUploadLink } from "apollo-upload-client";
  import Popup3 from './Popup3'
  import Error from './Error';
  const logo = "iooio";
  const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    //const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    
    return {
      headers: {
        "Content-Type": "application/json", 
       "Apollo-Require-Preflight": "true",
        "X-Pinggy-No-Screen":"true",
        
      },
    };
  });
  
  
  const link = createUploadLink({
    uri: routes.apollo,
    fetch: (uri,  options) => {
      if (options.onProgress) {
        // file upload
        console.log(options,"onprogress true")
        return uploadFetch(uri, options,options.onProgress);
      }
      console.log(options,"onprogress= null")
      return fetch(uri, options);
    },
    // buildAxiosFetch(axios, (config, input, init) => ({
    //   ...config,
    //   onUploadProgress: init.onUploadProgress,
    // })),
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(link),
  });


class Upload3 extends Component {
    constructor(props){
        super(props)
        this.state = {
            media:[],
            type:null,
            caption:"",
            file1:null,
            price:0,
            spinState:false,
            errorMsg:"",
            iMO:false,
            //mimetypes:[]
            //durations:[]
            mimeType:[],
            duration:[],
            isActive:false,
            isAudio:false,
            details:null,
            uploadProgress:null,
                    }
              this.thumbInput = React.createRef()      
    }

     getMimeType =  (file,isImage) =>{

  
        
       
   return new Promise((resolve,reject)=>{
    if(isImage){
      // props.setFileDetails({duration:null,mimeType:"jpg"})
       // setDurations([null,...durations])
       // setMimeTypes(["jpg",...mimeTypes])
       // props.setOpenMaster()
       // setOpen(false)
        resolve("jpg")
        return
     }
    let retVal = ""
    const reader = new FileReader();
    console.log(file,"resres")
    reader.readAsArrayBuffer(file);
    reader.onloadend = function() {
      const arr = (new Uint8Array(reader.result)).subarray(0, 4);
      let header = "";
      for(let i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      if(header == '1a45dfa3'){

      resolve("webm")
      }
      if(header == '00018') {

      resolve("mp4")
      }
     else if(header == '00020'){

      resolve("mp4")
      }
      else if(header == '0001c'){
        console.log(header)

        resolve("mp4")
      }
    
      else{

        resolve("unsupported")
      }
         
      }
   })

            

            
          };
    setOpenMaster = ()=>{
      this.setState({isActive:true})
    }
    closeModal=()=>{
      this.setState({iMO:false,errorMsg:""})
    }

    setFileDetails=(details)=>{
      console.log(details)
      //this.setState({duration:[details.duration,...this.state.duration],mimeType:[details.mimeType,...this.state.mimeType]})
      this.setState({duration:details.duration,mimeType:details.mimeType})
    }
    priceChosen = (e) => {
        this.setState({ price: e.target.value });
      };
closeLoader = ()=>{
  this.setState({spinState:false})
}
    contactChosen = (e) => {
        console.log(e.target.value,this.state.mimeType);
        this.setState({ caption: e.target.value });
      };

      fileUploadHandler =  async () => {
        //console.log(isAudio,"SPURRIOUS ASDF<GHERAS")
        const { file1, file2, caption, price ,mimeType,duration,refs} = this.state;
        console.log(file1, "fileflelelelel");
        
        if(mimeType == "unsupported"){
          this.setState({spinState:false,iMO:true,errorMsg:"an error occured:Unsupported filetype"})
          return
        }
//caption
//title
        // console.log(file2)
        if (file1) {
          
          let duration_updated = refs.map((ref)=>{
           return ref.current.duration || null
          })
          let mimeUpdated = await Promise.all(refs.map(async(ref,i)=>{
            let isImage = !ref.current.duration?true:false 
            let ans =  await this.getMimeType(file1[i],isImage)
            console.log(ans)
            return  ans
          }))
          console.log(mimeUpdated,duration_updated)
          this.setState({spinState:true})
          const newPost = gql`
            mutation newPost_(
              $title: String!
              $caption: String!
              $image: [Upload]
              $price: Int!
              $duration:[Float]
              $mimeType:[String]
              $isAudio:Boolean!
              $audioThumb:[Upload]
            ) {
              newPost_(
                title: $title
                caption: $caption
                image: $image
                price: $price
                duration:$duration
                mimeType:$mimeType
                isAudio:$isAudio
                audioThumb:$audioThumb
              ) {
                name
              }
            }
          `;
          client
            .mutate({
              mutation: newPost,
              context: {
                fetchOptions: {
                  //useUpload: true,
                  onProgress: (progress => {
                    console.log(progress);
                    this.setState({uploadProgress:progress})
                  }),
                }
              },
              variables: {
                title: "Default",
                caption: caption,
                image: [...file1],
                price: parseInt(price),
                duration:duration_updated,
                mimeType:mimeUpdated,
                isAudio:this.state.isAudio,
                audioThumb:this.state.audioThumb
              },
            })
            .then((result) => {
              console.log(result);
              // this.setState({spinState:false,type:null,
              //   caption:"",
              //   file1:null,
              //   price:0, isActive:false})
              //successmessage
              
              this.setState({isActive:false,spinState:false,uploadProgress:null})
              this.cleanUp()
            })
            .catch((err) => {
              console.log("catch", err);
              this.setState({uploadProgress:null,spinState:false,iMO:true,errorMsg:"an error occured"})
            });
        } else {
          console.log("no file selected");
          this.setState({isActive:false})
        }
      };
cleanUp = ()=>{
  let  prevMedia = this.state.media
  for(let i = 0;i< prevMedia.length;i++){
    URL.revokeObjectURL(prevMedia[i])
    // URL.revokeObjectURL(this.state.thumbnail)
  }

  this.setState({file1:null,media:[],type:[],price:0, isActive:false,isAudio:false,thumbnail:null})
}

createFileBlob_ = (fileObject,callback) =>{
 return new Promise((resolve,reject)=>{
  let file = new FileReader()
  file.readAsArrayBuffer(fileObject);
  file.onload =(e)=>{
    // The file reader gives us an ArrayBuffer:
let buffer = e.target.result;
//let videoBlob = new Blob([new Uint8Array(buffer)], { type: fileObject.target.files[0].type});
resolve(buffer)

}

 })


}

createFileBlob = (fileObject,callback) =>{
  let file = new FileReader()
        file.readAsArrayBuffer(fileObject);
        //file.readAsDataURL(event.target.files[0])
        // file.onloadend = ()=>{
        
        //     this.setState({media:file.result,type:type,file1:event.target.files[0]})
        //     console.log(file.result.type,"TYPE GOES HERE,file.result.mime",file.result)
        // }

        file.onload =(e)=>{
            // The file reader gives us an ArrayBuffer:
      let buffer = e.target.result;
      //let videoBlob = new Blob([new Uint8Array(buffer)], { type: fileObject.target.files[0].type});

      callback(buffer)
    
    }

}
convertForAudiomap =(array)=>{
  let objSong = array.map((songSrc)=>{
    return {
      file:songSrc
    }
  })
  return objSong
}
    onChangeFileHandler = async (event)=>{
      console.log(event.target.files.length)
      if(this.state.media && this.state.file1){
        this.cleanUp()
      }
        let type = ""
        let mimes ={     "video/mp4":"video",
        "video/avi":"video",
        "video/ogg":"video",
        "video/mov":"video",
        "video/wmg":"video",
        "video/3gp":"video",
        "video/ts":"video",
        "video/webm":"video",
        "video/m4v":"video",
        "video/webp":"video"
        // "audio/mpeg":"video",
      }

     
      if(!event.target.files[0]) return
      // if(mimes[event.target.files[0].type]){
      //   //type = mimes[event.target.files[0].type]
      //   type = Promise.all([...event.target.files].map((file)=>mimes[file.type]))
      // //   console.log(type,"TYPEEEEEEEEEEEEEEEEEEEEEEE")
      // }
      type = await Promise.all([...event.target.files].map((file)=>{
  console.log(mimes[file.type],file.type)
return mimes[file.type]

}))
      console.log(type)
      let mediaArray = await Promise.all([...event.target.files].map(async(file)=>{
        let buffer = await this.createFileBlob_(file)
        let videoBlob = new Blob([new Uint8Array(buffer)], { type: file.type});
        return URL.createObjectURL(videoBlob)
      }))

console.log(mediaArray)
let refs = Array(event.target.files.length).fill().map(()=>React.createRef())
      this.setState({media:[...this.state.media,...mediaArray],type:type,file1:event.target.files,refs:refs})
    }
    render() {
        return (
            <div className="container">
{/* <LeCropper/> */}
            <div
              className="list2-wrapper"
              ng-app="app"
              ng-controller="MainCtrl as ctrl" 
            >
                    
                  <div style={{position:"relative"}}>
                              {/* {<i style={{fontSize:"13px"}}onClick={()=> this.props.toggleSettings()} className="fa fa-times" aria-hidden="true"></i>} */}
                              </div>
                           
              <ul className="list2">
                <div style={{ maxHeight: "300px", overflowY: "auto" }}>
                  <div >
                  <h2 style={{    fontSize: "21px", fontWeight: "600", marginLeft: "8px"}}>Upload</h2>
                  <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
                  <div style={{display:"flex"}}>{this.state.file1 && <p><h1  style={{fontSize:"16px",fontWeight:"2000"}}>""</h1> files</p> }
                  <p style={{borderRight:"0",padding:"0"}} className="logo">Preview
                  <div style={{height:"100px",width:"200px",position:"relative",left:"70px"}}>
                  {this.state.uploadProgress && <UploadProgress percent={this.state.uploadProgress}/>}
             {this.state.media.length > 0 && !this.state.isAudio &&
                <PostImagePreview ref={this.state.refs} length={this.state.file1.length} fileObject={this.state.file1} setOpenMaster={this.setOpenMaster} setFileDetails={this.setFileDetails} type={this.state.type} postSrc={this.state.media} alt="profilepic" className="list2-item-image"/>
               
             
             }
             {this.state.media.length > 0 && this.state.isAudio &&  <AudioPlayer audiomap={(this.state.file1.length > 1?this.state.file1:null)} ref={this.thumbInput} thumbnail={this.state.thumbnail} isEditor={true} isAudio={this.state.isAudio} details={this.state.details} audio={this.state.media} />}
              <Error isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Error>
                {this.state.spinState && <Loader onClose={this.closeLoader} isOpen={this.state.spinState}/>}
                
             <div
                      className="textos"
                      style={{
                        position: "absolute",
                        textAlign: "center",
                        background:"beige",
                        top: "-3rem",
                        left:"124px",
                        minWidth:"120px",
                        fontSize: "10px",
                      }}
                    >
                      <p>{this.state.caption}</p>
                    
             </div>
         
                </div>
                  </p>
        
                  {/* <SocialLinks/> */}
                  </div>
                 
                  </li>
                 
                                  
                  </div>
                  <div style={{display:"flex"}}>
                 <div style={{fontSize:"20px",paddingRight:"10px"}}>
                 <i class="fa fa-file" aria-hidden="true"></i>
                  </div> 
                  <input
                  style={{maxWidth:"180px",fontSize:"9px"}}
                  ref={this.inputField}
                  type="file"
                  onChange={(event) => {
                    this.onChangeFileHandler(event);
                  }}
                  multiple
                  accept="image/*,video/*"
                  className="copy-link-input"
                />
                
                {this.state.isActive && <button
                  onClick={() => {
                    this.fileUploadHandler();
                  }}
                  type="button"
                  className="copy-link-button"
                >
                  <span className="material-icons">upload</span>
                </button>}
                </div>
                  <div>
                  <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
                  <p style={{borderRight:"0",padding:"0"}} className="logo">Caption</p>

                  <div style={{paddingLeft:"8px"}}>
                  <input onChange={(event)=>this.contactChosen(event)}  className="search-field" type="text" autoComplete="off" name="query" placeholder="Caption"/>
                  </div>
              
                    
    
                  </li>
                 
                                  
                  </div>
                  { this.state.media && this.state.isAudio &&      <div>
                  <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
                  <p style={{borderRight:"0",padding:"0"}} className="logo">Description</p>
                  
                  <div style={{paddingLeft:"8px"}}>
                  <input onChange={(event)=>
                  this.setState({details:event.target.value})}  className="search-field" type="text" autoComplete="off" name="query" placeholder="Caption"/>
                  </div>
              
                    
    
                  </li>
                 
                                  
                  </div>}
                  {this.state.media && this.state.isAudio &&   <div style={{display:"flex"}}>
                  <input
                  style={{display:"none",maxWidth:"180px",fontSize:"9px"}}
                  ref={this.thumbInput}
                  type="file"
                  onChange={(event) => {
                    // this.onChangeFileHandler(event);
                    if(!event.target.files[0]) return
                    this.createFileBlob(event,(buffer)=>{
                      let videoBlob = new Blob([new Uint8Array(buffer)], { type: event.target.files[0].type});
                       let url = URL.createObjectURL(videoBlob);
                  
                        //this.setState({media:url,type:type,file1:event.target.files[0]})
                      this.setState({thumbnail:url,audioThumb:event.target.files[0]})
                    })
                   // this.setState({thumbnail:event.target.files[0]})
                  }}
                  multiple
                  accept="image/*,audio/*,video/*"
                  className="copy-link-input"
                />
                
                {this.state.isActive || this.state.isAudio && <button
                  onClick={() => {
                    this.fileUploadHandler();
                  }}
                  type="button"
                  className="copy-link-button"
                >
                  <span className="material-icons">upload</span>
                </button>}
                </div>}
                  <div>
                  {this.state.media && !this.state.isAudio &&  <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`,display:"block"}} className="list2-item">
                  <p style={{borderRight:"0",padding:"0"}} className="logo">Add Price</p>
                 { this.state.media && <div>
                {" "}
                <input
                  onChange={(e) => this.priceChosen(e)}
                  placeholder="eg. 10"
                  value={this.state.amount}
                  type="number"
                  min="1"
                  max="700"
                />
              </div>}
                  {/* <div style={{maxWidth:"100px"}}><TagController/></div> */}
                  </li>}
                 
                                  
                  </div>
                </div>
              </ul>
            </div>
          </div>
        );
    }
}

export default Upload3;