import routes from "../storage_apollo_routes";
import RealPay from "../Controllers/RealPay";
import { useEffect,useState } from "react";
import "../UI/Styles/Request.css"
import Loader from "./Loader";
import Popup3 from "./Popup3";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });
  const createRequest = gql`
  mutation Request($message:String!,$secret:String!,$amount:Float!,$to:Int!){
    makeRequest(message:$message,secret:$secret,amount:$amount,to:$to){
        status
        amount
        message
    }
  }
  `
const RequestPopup = (props)=>{
    const [completed,setComplete] = useState(false)
    const [loaderState,setLoaderState] = useState(false)
    const [errorMsg,setErrorMsg] = useState(null)
    const [iMO,setIMO] = useState(false)
    const [message,setMessage] = useState(null)
    const [secret,setSecret] = useState(null)
    const [openPopup,setOpenPopup] = useState(false)
    const [contact,setContact] = useState(false)

    const makeRequest = async ()=>{
        setLoaderState(true)
await client.mutate({
    mutation:createRequest,
    variables:{
        message,
        secret,
        amount:1,
        to:props.userID

    }
}).then((r)=>{
    setLoaderState(false)
    setComplete(true)
    console.log(r)
}).catch((e)=>{
    setLoaderState(false)
    console.log(e)
})
    }
if(!props.isOpen)return null
return <div>
            <div className="boxxxx">
        
        <div style={{backgroundColor:"#549785"}} className="cenceph_pure_black">
       
        
        <p style={{fontSize:"18px"}}>  Request</p>
        

        <i onClick={()=> props.onClose()} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
          <Popup3 isOpen={iMO} error={errorMsg} onClose={() => props.closeModal()}></Popup3>
          <Loader isOpen={loaderState}/>
          

      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" /> */}
      
      <div style={{display:"block",fontSize:"15px"}}>
        request message
        <div className="input-container textarea">{!completed && 
        <textarea onChange={(e)=>setMessage(e.target.value)} name="message" class="input"></textarea>
      
        }
          <label style={{color:"#6c757d"}} for="">{(message)?"":"A video where you..."}</label>
        </div>

        <div class="input-container">
        {!completed && <input className="input_e"onChange={(e)=>setSecret(e.target.value)}  type="text" />}
        <label  for="">{(secret)?"":"Secret"}</label>
              <span>Secret</span>
            </div>
        <div class="input-container">
        {!completed && <input className="input_e"onChange={(e)=>setContact(e.target.value)}  type="text" />}
              <label for="">{(contact)?"":"Email"}</label>
              <span>Email</span>
            </div>
            <div style={{position:"relative",top:"0",left:"0",width:"36px"}} class="tooltip">
                 
        {<h2 style={{width:"270px",backgroundColor:"rgb(132 134 229)"}} class="tooltiptext" aria-hidden="true">
If the content creator rejects your request or the request time expires, your deposit will be refunded.<br/>
To check if your request is fulfilled, go to <a href="https://uhsoka.com">uhsoka.com</a> and use the Secret you typed <br/>
A Secret is like a password used to check on the progress of your request...use it wisely <br/>
If your deposit isn't refunded, please reach out to uhsokasolutions@gmail.com or call 0552298878
        </h2>}
        { <div className="skeleton-loader hfour">help</div>}
      </div>
      </div>
      {!completed && secret && message && <div className="cont"><RealPay text={"Request"} contact={contact} price={1} deposit={()=>makeRequest()} className={"wpc-btn wpc-btn-default-rounded"}/>
      {completed && <div>Request Complete</div>}
  

  
  
  </div>}
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> 
</div>
}

export default RequestPopup

