import React,{useEffect,useState,useRef} from "react";
import "../UI/Styles/Playlist.css"
import useDragger from "./useDragger";
 function Playlist(props) {
    console.log(props)
//const songlist = [{id:1,title:"FAT RAT AUDIO_1",file:"4_5922638508228347737.mp3",thumbnail:"GEPFACvW4AAvXmd.jpeg"},{id:1,title:"FAT RAT AUDIO_2",file:"TheFatRat - Upwind [Chapter 4].m4a",thumbnail:"wp11979788.webp"},
//{id:1,title:"FAT RAT AUDIO_3",file:"4_5852809662914626875.mp3",thumbnail:"wp11979788.webp"},{id:4,title:"FAT RAT AUDIO_4",file:"4_5852809662914626888.mp3",thumbnail:"wp11979788.webp"}]
const progresFilled = useRef()
const progres = useRef()
const audioElements = (props.songList.map((x) => React.createRef()));
let audioElement = useRef()
const sliderContext = useRef()
const sliderContent = useRef()
const sliderName = useRef()
const sliderTitle = useRef()
const [selected,setSelected] = useState(0)
const [isPlay,setIsplay] = useState(false)
const [dropdown,setDropdown] = useState(true)
const [index,setIndex] = useState(0)
// const songlist = props.songList
const [songlist,setSongList] = useState(props.songList)
const [isMove,setMove] = useState(false)
const [audiowidth,setWidth] = useState("0%")
const [left,setLeft] = useState(0)

const durationSongs = (i) =>{
    // console.log(i,audioElements[i],audioElements[1])
    // let min = parseInt(audioElements[i].current.duration / 60);
    // if (min < 10) min = "0" + min;
    // let sec = parseInt(audioElements[i].current.duration % 60);
    // if (sec < 10) sec = "0" + sec;
    // const playerSongTime = `${min}:${sec}`;
    // // this.closest(".player__song").querySelector(".player__song-time").append(playerSongTime);
    // return playerSongTime
    return "0:00"
    }

const next = () =>{
//     count = index || count;
// if (count == sliderContentLength) {
// count = count;
// return;
// }
// left = (count + 1) * sliderWidth;
// left = Math.min(left, (sliderContentLength) * sliderWidth);
// sliderContent.style.transform = `translate3d(-${left}%, 0, 0)`;
// count++;
// run();
//selected
if(index+1 < songlist.length ){
   let  left = (index + 1) * 100;
left = Math.min(left, (songlist.length-1) * 100);
sliderContent.current.style.transform = `translate3d(-${left}%, 0, 0)`;
    setIndex(index+1)
    setSelected(index+1)
    selectSong(index+1)
}
}

const prev =()=>{
    if(selected == 0){
        return
   }
   let left = (index - 1) * 100;
left = Math.max(0, left);
sliderContent.current.style.transform = `translate3d(-${left}%, 0, 0)`;
   setIndex(index-1)
   setSelected(index-1)
   selectSong(index-1)


}
const uListener = ()=>{
    // if (index > count) {
    //     next(index - 1);
    //     return;
    //     }
    //     if (index < count) {
    //     back(index + 1);
    //     return;
    //     }
    //     ;
        
}
const slide = (e)=>{
    const currentTime = ( (e.clientX - progres.current.getBoundingClientRect().left) / progres.current.offsetWidth ) * audioElements[selected].current.duration;
    audioElements[selected].current.currentTime = currentTime;
}
const handlePointerMove =(e)=>{
    if(isMove){
        slide(e);
       // setMove(true)
       audioElements[selected].current.muted = true;
    }


}
const handlePointerDown =(e)=>{
    slide(e);
    setMove(true)
}
const handlePointerUp =(e)=>{
    setMove(false);
    audioElements[selected].current.muted = false;
}
const play = (b)=>{
    
// if(isPlay){
//     return
// }
//     item.pause();
// item.currentTime = 0;
//setDropdown(!dropdown)
setIsplay(true)
// audioElements[selected].current.play()
audioElements[b].current.play()
}

const progresUpdate =  () =>{
    const progresFilledWidth = (audioElements[selected].current.currentTime / audioElements[selected].current.duration) * 100 + "%";
    console.log(audiowidth)
    setWidth(progresFilledWidth)
    if (isPlay && audioElements[selected].current.duration == audioElements[selected].current.currentTime) {
    console.log("finished")
    next()
    }

}

const pause = (a)=>{
   // setDropdown(!dropdown)
    setIsplay(false)
// audioElements[selected].current.pause()
audioElements[a].current.pause()

}

const selectSong = (i)=>{
    console.log(i,selected)
    for(let sng in audioElements){
        if(sng != i){
            audioElements[sng].current.pause()
            audioElements[sng].current.currentTime = 0
        //     setSelected(i)
        //    playBackHandler()
            
        }

    }
    setIndex(i)
          setSelected(i)
           playBackHandler(i)
    console.log(i,selected)

}


const playBackHandler = (c)=>{
    for(let sng in audioElements){
        if(sng != c){
            audioElements[sng].current.pause()
            audioElements[sng].current.currentTime = 0
        //     setSelected(i)
        //    playBackHandler()
            
        }

    }
setIsplay(true)
    if(isPlay){
pause(c)
    }
    else{
      //width:"120px",height:"120px"
play(c) 
    }
}
useDragger("box")
    return   <main style={{display:"grid",placeItems:"center",zIndex:"99"}}>
            <div  style={{position:"relative",border:"1px"}}className="box_master">
        <div style={{backgroundColor:"black",position:"absolute",top:"0",left:"0",cursor:"pointer",color:"hotpink",}} id="box">
        <section>
    <div className="dimes" style={{display: "flex",userSelect: "none",
alignItems: "center",justifyContent: "center"}}>
    <div class="player_2">
<div class="player__header">
<div class="player__img player__img--absolute slider">
<button class="player__button player__button--absolute--nw playlist">
<img src="http://physical-authority.surge.sh/imgs/icon/playlist.svg" alt="playlist-icon"/>
</button>
<button onClick={()=>playBackHandler(selected)} class="player__button player__button--absolute--center play">
<img style={{display:(isPlay)?"none":"block"}} src="http://physical-authority.surge.sh/imgs/icon/play.svg" alt="play-icon"/>
<img style={{display:(isPlay)?"block":"none"}} src="http://physical-authority.surge.sh/imgs/icon/pause.svg" alt="pause-icon"/>
</button>
<div ref={sliderContent}class="slider__content">
<img class="img slider__img" src={`/storage/${songlist[selected].thumbnail}`} alt="cover"/>
{/* <img class="img slider__img" src="http://physical-authority.surge.sh/imgs/1.jpg" alt="cover"/> */}
{
/* <img class="img slider__img" src="http://physical-authority.surge.sh/imgs/2.jpg" alt="cover"/>
<img class="img slider__img" src="http://physical-authority.surge.sh/imgs/3.jpg" alt="cover"/>
<img class="img slider__img" src="http://physical-authority.surge.sh/imgs/4.jpg" alt="cover"/>
<img class="img slider__img" src="http://physical-authority.surge.sh/imgs/5.jpg" alt="cover"/>
<img class="img slider__img" src="http://physical-authority.surge.sh/imgs/6.jpg" alt="cover"/>
<img class="img slider__img" src="http://physical-authority.surge.sh/imgs/7.jpg" alt="cover"/> */}
</div>
</div>
<div class="player__controls">
<button  onClick={()=>prev()} class="player__button back">
<img class="img_playlist" src="http://physical-authority.surge.sh/imgs/icon/back.svg" alt="back-icon"/>
</button>
<p ref={sliderContext} class="player__context slider__context">
<strong onClick={()=>{
    console.log(dropdown)
    setDropdown(!dropdown)
}} ref={sliderName} class="slider__name">
{(songlist[selected].title.length > 16)?
<span className="text-wrap">{songlist[selected].title}</span>
:songlist[selected].title}
</strong>
<span ref={sliderTitle} class="player__title slider__title">{(songlist[selected].title.length > 18)?
<span className="text-wrap">{songlist[selected].title}</span>
:songlist[selected].title}</span>
</p>
<button  onClick={()=>next()} class="player__button next">
<img class="img_playlist" src="http://physical-authority.surge.sh/imgs/icon/next.svg" alt="next-icon"/>
</button>
<div ref={progres} onPointerUp={(e)=>handlePointerUp(e)} onPointerMove={(e)=>handlePointerMove(e)}  onPointerDown={(e)=>handlePointerDown(e)} class="progres">
<div style={{width:`${audiowidth}`}} ref={progresFilled} class="progres__filled"></div>
</div>
</div>
</div>
<ul  style={{zIndex:"99",display:(dropdown)?"block":"none"}} class="player__playlist list">
{songlist && songlist.map((song,i)=><li onClick={()=>{
    // for(let sng in audioElements){
    //     if(sng != i){
    //         audioElements[sng].current.pause()
    //         audioElements[sng].current.currentTime = 0
    //     //     setSelected(i)
    //     //    playBackHandler()
            
    //     }

    // }
    //       setSelected(i)
    //        playBackHandler()
    // console.log(i,selected)
    selectSong(i)



}}style={{backgroundColor:(i == selected)?"#d2dfdf":"#ffff"}} class="player__song">
<img class="player__img img" src={`/storage/${song.thumbnail}`} alt="cover"/>
<p class="player__context">
<b class="player__song-name">{song.title}</b>
<span class="flex">
<span class="player__title">Artist</span>
<span class="player__song-time">{durationSongs(i)}</span>
</span>
</p>
<audio ref={audioElements[i]} onTimeUpdate={()=>progresUpdate()} class="audio" src={`/storage/${song.file}`} ></audio>
</li>)}
{/* <li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/2.jpg" alt="cover"/>
<p class="player__context">
<b class="player__song-name">blinding lights</b>
<span class="flex">
<span class="player__title">the weeknd</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/2.mp3"></audio>
</li>
<li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/3.jpg" alt="cover"/>
<p class="player__context">
<b class="player__song-name">джованна</b>
<span class="flex">
<span class="player__title">enrasta</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/3.mp3"></audio>
</li>
<li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/4.jpg" alt="cover"/>
<p class="player__context">
<b class="player__song-name">a man</b>
<span class="flex">
<span class="player__title">travis scott</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/4.mp3"></audio>
</li>
<li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/5.jpg" alt="cover"/>
<p class="player__context ">
<b class="player__song-name">unforgetting</b>
<span class="flex">
<span class="player__title">zaxx</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/5.mp3"></audio>
</li>
<li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/6.jpg" alt="cover"/>
<p class="player__context">
<b class="player__song-name">waharan</b>
<span class="flex">
<span class="player__title">Randall</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/6.mp3"></audio>
</li>
<li class="player__song">
<img class="player__img img" src="http://physical-authority.surge.sh/imgs/7.jpg" alt="cover"/>
<p class="player__context ">
<b class="player__song-name">starlight feat mr gabriel <span class="uppercase">4am</span> remix</b>
<span class="flex">
<span class="player__title">jai wolf</span>
<span class="player__song-time"></span>
</span>
</p>
<audio class="audio" src="http://physical-authority.surge.sh/music/7.mp3"></audio>
</li> */}
</ul>
</div>
</div>
 </section>
 </div>
 </div>
    </main>
    ;
}

export default Playlist;