import {useHistory,Redirect, Switch, BrowserRouter,Route,Link,NavLink} from 'react-router-dom';
import React, { Component } from 'react';
import AlertsSingle from './AlertsSingle';
import {v4 as uuidv4} from 'uuid'

class Minimenu extends Component {
  constructor(props){
    super(props)
    this.state = {
      show:false,
      count:""
    }


  }
  increment = ()=>{
    this.setState({count:this.state.count+1})
  }
  changeShow(){
    this.setState({count:uuidv4()})
  }
    render() {
        return (
            <div>
            
                    <div className="nav">
      <div className="nav__link">
      <Link to={{pathname:'/posts'}}><i className="material-icons nav__icon">person</i></Link>
    <span className="nav__text">Posts</span>
  </div>
  <div className="nav__link ">
    <NavLink activeClassName="-activee" to="/homepage"><i className="material-icons nav__icon">dashboard</i></NavLink>
    <span className="nav__text">Dashboard</span>
  </div>
  <div  className="nav__link nav__link--active">
  <NavLink activeClassName="-activee" to="/alerts"> <i onClick={()=>this.changeShow()}  className="material-icons nav__icon notif">notifications</i>
  {/* <small class="notification-badge">{this.state.count}</small> */}
  <AlertsSingle key={this.state.count} show={this.state.show} increment={this.increment}/>
  </NavLink>
    <span className="nav__text">Alerts
    
    </span>
    
  </div>
  
  
  <div  className="nav__link">
  <Link to={{pathname:"/upload"}}>
    <i className="material-icons nav__icon">file_upload</i></Link>
    <span className="nav__text">Upload</span>
    
  </div>
  <div className="nav__link">
  <Link to={{pathname:"/feed"}}><i className="material-icons nav__icon">feed</i></Link>
    <span className="nav__text">Feed</span>
  </div>
</div>
      
            </div>
        );
    }
}

export default Minimenu;