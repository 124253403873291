 import { useState,React } from "react";
 import "../UI/Styles/StatsControllerList.css"
 import { Link } from "react-router-dom";
 import PostImage from "../UI/PostImage";
 const StatsControllerList = ({transactions,show,postTrans})=>{
    const [highlight,setHighlight] = useState(show)
    if(!show) return null
    return (  
    <div style={{height:"400px",overflowY:"scroll",maxWidth:"200px",position:"absolute",right:"89px"}}className="con">
        <i onClick={()=>setHighlight(false)} class="fa fa-times" aria-hidden="true" style={{"font-size": "13px"}}></i><div className="lists_stat_controller">
	<div class="list_stat_controller-container">
		{/* <h2>Rocco&apos;s Top 5 Albums</h2> */}
		<ol id="1">
			{transactions && transactions.map((t,index)=><li>
                {postTrans[index] && <div style={{height:"97px",width:"70px"}}><PostImage postSrc={postTrans[index].file}/></div>}
                <Link to={{pathname:`/post/${t.postID}`}}>
                This Post 
                </Link>
               <br/>earned GHC {t.amount}</li>)}
			{/* <li>Odelay!<br/>Beck</li>
			<li>Led Zeppelin IV<br/>Led Zeppelin</li>
			<li>Tapestry<br/>Carole King</li>
			<li>Voodoo<br/>D&apos;Angelo</li> */}
		</ol>
	</div>
    </div></div>);
}

export default StatsControllerList;