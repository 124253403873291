import React, {Component,useEffect,useState} from 'react';

class  Loader extends Component {
     constructor(props){
       super(props);
     this.state={
           
           spinning:false
           
           
           
           
     }
     
     }


  componentWillUnmount(){
    //this.props.onClose()
  }

     close(e) {
        e.preventDefault()
    
        if (this.props.onClose) {
          this.props.onClose()
        }
      }
     









  render(){ 
    if (this.props.isOpen === false)
        return null  
    const{body} =this.state
  return (
    <div><div className="loader"></div></div>
  );
}}

export default Loader;