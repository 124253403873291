import React, {Component,useEffect,useState} from 'react';
import AudioPlayer from '../Controllers/AudioPlayer';
import routes from '../storage_apollo_routes';
import Popuptip from './Popuptip';
import PostImage from './PostImage';
import AlbumSlider from './AlbumSlider';
import RealPay from '../Controllers/RealPay';
//import apiClient from './api';
import { Link } from 'react-router-dom'
import ReactDOM from 'react-dom';
import axios from 'axios';
import {useHistory,Redirect, Switch, BrowserRouter,Route} from 'react-router-dom';
//import Dashboard from './Dashboard';
import Composeform from './Composeform';
import Comments from './Comments3';
import Favorites from './Favorites';
import ProfilePic from './ProfilePic';
import VideoPlayer from '../Controllers/VideoPlayer';
import Ads from './AdVid';
import { setContext } from '@apollo/client/link/context';
import OTPPopup from './OTPPopup';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
     // "Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});
const transactions = gql`
query Trans($postID:Int){
  getOTPTransactions(postID:$postID){
    secret
    amount
  }
}`
/**
 * Renders a single Post component with comments
 */
class OTPPost extends Component {
  constructor(props){
    super(props);
    this.state = {
        postId:this.props.location.id,
        ogsrc:null,
        unlocked:false,
        previewActive:false,
        count:this.props.location.count,
        favorited:this.props.location.favorited,
        postsrc:this.props.location.src,
        isPhoto:this.props.location.isPhoto,
        display:false,
        title:this.props.location.title,
        caption:this.props.location.caption,
        profile:this.props.location.profile,
        UserId:this.props.location.UserId,
        isAudio:this.props.location.isAudio,
        someVar: true,
        album:this.props.location.album,
        isOpen:false,
        preview:this.props.location.preview,
        thumbnail:this.props.location.thumbnail,
        user:parseInt(localStorage.getItem("userID")),
        highlighted:false,
        reply:false,
        duration:this.props.location.duration,
        showPopup:false,
        views:null,
      };
     
    this.handler = this.handler.bind(this)
    this.childTwo = React.createRef();
    
    
  }

  /**
   * gets the post data from the Database
   */

  getOTPtransactions = async () =>{
let query = await client.query({
  query:transactions,
  variables:{
  postID:parseInt(this.props.match.params.id)
 }
}).then((r)=>{
  console.log(r.data.getOTPTransactions)
  if(r.data.getOTPTransactions.length > 0){
    let t = this.calculateTotal(r.data.getOTPTransactions)
    this.setState({transactions:r.data.getOTPTransactions,total:t})
    
  }
  this.setState({transactions:r.data.getOTPTransactions,total:0})
}).catch((e)=>{
  console.log(e)
  this.setState({transactions:[],total:0})
})

  }
  getSinglePost = async () =>{
    console.log(this.props.match.params.id)
    let pst = await  client
    .query({
      query: gql`
        query Single {
          getOTPost(id:${this.props.match.params.id}) {
             post{
              caption
              price
              file
              id
              thumbnail
              UserId
              duration
              preview
              isAudio
              title

             } 
             album{
              id
              Posts{
                caption
                price
                file
                id
                isAudio
                thumbnail
                UserId
                duration
                preview
                title
              }
              
            }
             count
             favorited
             views
            
          }
        }
      `,
    }).then((result)=>{
      let photoStatus = (result.data.getOTPost.post.thumbnail == "")?true:false

        let isViewable = !(result.data.getOTPost.post.file == "pngsus.png")
        // console.log(photoStatus,isViewable,api_call.post.file,133)
      let highlighted = (this.props.location.highlight_comment)?this.props.location.highlight_comment:false
      let reply = (this.props.location.reply)?this.props.location.reply:false
      const isPhotoFn = (res)=>{
        if(res.data.getOTPost.post.isAudio){
          return false
        }
        if(!res.data.getOTPost.post.duration){
          return true
        }
        if(res.data.getOTPost.post.preview ==""){
          return true
        }
        if(res.data.getOTPost.post.thumbnail ==""){
          return true
        }
        return false
      }

      //thumbnail == preview == vice v
      this.setState({
        postId:result.data.getOTPost.post.id,
        price:result.data.getOTPost.post.price,
        favorited:result.data.getOTPost.favorited,
        caption:result.data.getOTPost.post.caption,
        duration:result.data.getOTPost.post.duration,
        UserId:result.data.getOTPost.post.UserId,
        preview:result.data.getOTPost.post.thumbnail,
        thumbnail:result.data.getOTPost.post.preview,
        isPhoto:isPhotoFn(result),
        postsrc:result.data.getOTPost.post.file,
        count:result.data.getOTPost.count,
        isAudio:result.data.getOTPost.post.isAudio,
        title:result.data.getOTPost.post.title,
        highlighted:highlighted,
        unlocked:false,
        reply:reply,
        album:result.data.getOTPost.album.Posts.length > 0?result.data.getOTPost.album.Posts:false,
        views:result.data.getOTPost.views,
        
//"https://vmcloudinstancefr-qd5g7ivkga-wm.a.run.app/graphql"

      })
      // this.props.location.isPhoto = (result.data.getOTPost.post.duration||result.data.getOTPost.post.preview||!result.data.getOTPost.post.thumbnail)?false:true
      // this.props.location.src = result.data.getOTPost.post.file
    //return result.data.getOTPost
    }).catch((error   
    )=>{
      console.log(error)
      return false
    })
    //return pst
  }
  
   changefav = (id, mutcount, favorited) => {
    const filteredPosts = this.props.location.posts.map((mp) => {
   

      if (mp.post.id == id) {
        console.log(id, mutcount, favorited);
        let newpost = { ...mp, favorited: favorited, count: mutcount };
        mp = newpost;
        
      }

      return mp;

      return mp;
    });
    console.log(filteredPosts);

    client.cache.updateQuery({ query: fetch }, (data) => ({
      getPosts: data.getPosts.map((todo) => {
        if (todo.post.id == id) {
          let newpost = { ...todo, favorited: favorited, count: mutcount };
          todo = newpost;
        }
        
        return todo;
      }),
    }));
    //setPosts(filteredPosts);
  };
  
showPopup = ()=>{
this.setState({showPopup:!this.state.showPopup})
}

   async componentDidMount(){
    console.log(this.props)
    
      if(!this.props.location.id || this.props.fromSearch){
        let highlighted = (this.props.location.highlight_comment)?this.props.location.highlight_comment:false
        let reply = (this.props.location.reply)?this.props.location.reply:false
          this.getSinglePost()
          this.getOTPtransactions()
        
        // let photoStatus = await (api_call.post.thumbnail == "")?true:false

        // let isViewable = !(api_call.post.file == "pngsus.png")
        // console.log(photoStatus,isViewable,api_call.post.file,133)
//         this.setState({
//           postId:api_call.post.id,
//           favorited:api_call.favorited,
//           caption:api_call.post.caption,
//           duration:api_call.post.duration,
//           UserId:api_call.post.UserId,
//           preview:api_call.post.preview,
//           isPhoto:(api_call.post.duration||api_call.post.preview||!api_call.post.thumbnail)?false:true,
//           postsrc:api_call.post.file,
//           count:api_call.count,
//           highlighted:highlighted,
//           reply:reply
          
// //"https://vmcloudinstancefr-qd5g7ivkga-wm.a.run.app/graphql"

//         })
      }
      console.log(this.state.profile,"profileprops yo",this.props)
      window.scrollTo(0, 0)
      //console.log("mounted",this.state.caption,this.props)
    }
/**
 * Handles rendering the image , video (or locked png if the post hasn't been paid for)
 * @param {boolean} isPhoto a Boolean that represents if the post is a photo
 * @param {string} src the Post name
 *
 */
playPreview = ()=>{
  if(this.state.album){
    this.setState({ogsrc:this.state.album,
      album:(this.state.album.map((r,i)=>{
        if(r.duration ||r.preview){
          console.log(r.preview)
return {...r,file:r.preview
}
        }

        return r

      }))
      ,previewActive:true})
      return
  }
    if(this.state.duration){
console.log(this.state.duration)
this.setState({ogsrc:this.state.postsrc,postsrc:this.state.thumbnail,previewActive:true})

    }
    

}

openModal = ()=>{
  window.scrollTo(0, 0)
    this.setState({isOpen:true})
}
stopPreview = ()=>{
  if(this.state.album){
    // this.setState({
    //   album:this.state.ogsrc,
    //   ogsrc:null
    //   ,previewActive:true})
      return
  }
    this.setState({ogsrc:null,postsrc:this.state.ogsrc,previewActive:false})
}
    mediaStatus = (isPhoto,src) =>{
      console.log(this.state.preview)
      console.log(src,"source")
      console.log(isPhoto)
      if(isPhoto){
        console.log(src,"here")
        return <div><img  src={`/storage/${src}`} ></img></div>
        //<PostImage postSrc={`/${src}`}/>
      }
      else if(!isPhoto && src !=="pngsus.png"){
  return     <Ads isAudio={this.state.isAudio} Src={src} caption={this.state.caption}  preview={this.state.preview} duration={this.state.duration}/>  
      {/* <div ><video width="100%" height="100%" controls>
          <source src={`/storage/${src}`}  type="video/mp4">
          </source>
        </video></div> */}
       
       
      
      }
      else if(!isPhoto && src =="pngsus.png"){
        return  <div><img src={`/storage/${src}`}  ></img></div>
            }

            
    

 
    }

    /**
     * increases the likes count on the Post UI(called from the Favorites component as a callback)
     */


   /**
    * Renders a Tip Popup
    */
   calculateTotal =  (transactions)=>{
  
    let total = 0
    if(transactions.length > 0){
    for(let t in transactions){
     total = parseFloat(transactions[t].amount) + total
    //console.log(parseFloat(transactions[t].dataValues.amount))
    }
    }
    
    return total
    }
  

    complete = (src)=>{
      //for now handle only one image being unlocked
      console.log(src,"373",true)
      this.setState({
        unlocked:true,
        album:(this.state.album.map((r,i)=>{
      
      return {...r,file:src[i]
      }
         
  
        }))
        })
      //this.setState({postsrc:src,unlocked:true})
    }
    /**
     * Closes the Tip Popup
     */

 close =()=>{
    this.setState({isOpen:false})
 }
    handler(param) {
      this.setState({someVar:!this.state.someVar})
      // this.childTwo.current.updateComment(param);
      this.childTwo.current.updateTable(param);
      
    }

    // componentWillUnmount(){
    //  // this.props.scrollTo(this.state.postId)
    // }
        
 

    render(){
      const {postId,postsrc, email,count,someVar,favorited,isPhoto,profile,caption,isAudio,UserId,views}= this.state

    return (<div className="post">
      {/* <AudioPlayer  ref={this.thumbInput} thumbnail={this.state.thumbnail} isEditor={true} isAudio={this.state.isAudio} details={this.state.details} audio={this.state.media} /> */}
            {this.state.isAudio && <AudioPlayer audiomap={this.state.album} title={this.state.title} thumbnail={this.state.thumbnail} audio={this.state.postsrc} isEditor={false}/>}
         
        <blockquote   className="style1">

<div className="style2">
  <div className="style3">

    
  <div className="style4">
    <Link   to={{pathname: `/userdat/${this.state.UserId}`,}}> 
    <ProfilePic id={UserId}/>
    </Link>
 
    </div>

    <div className="style5">
      <p className= "style6">postcard</p>
       <div className="style7"></div>
    </div>

    
    {/* <div onClick={()=>this.showPopup()} className="style8">
      <div className="style9"></div>
      <div className="style9"></div>
      <div className="style9"></div>
    </div> */}
  

  </div>
  
</div>


<div className="style10">

  {this.state.album && <AlbumSlider  mediaStatus={this.mediaStatus} posts={this.state.album}/>}
  {this.state.postsrc && !this.state.album && this.mediaStatus(this.state.isPhoto,this.state.postsrc)}
</div>


<div className="style11">

 
  <div className="style12">
   
  {/* {this.state.showPopup && <div style={{zIndex:"12",position:"absolute"}}><Postdropdown isPhoto={this.state.isPhoto} postID={this.state.postId}/></div> } */}
      <div className="bigger">

        <ul style={{"list-style-type": "none","left":"-0px","margin-top":"1px","position":"relative"
}}>

      
    {this.state.postId &&<Favorites isOTP={true} post={this.state.postId} decreasePostCount={()=>{}} increasePostCount={()=>{} }isPost={true} changefav={this.props.location.changefav} postCount={this.state.count} favorited={true}/> }
    </ul>
      </div>
    
   
    {/* <div className="style16">
    <div className="style17 "></div>
      <div className="style18"></div>
    </div> */}

    <div onClick={()=>{
        if(this.state.unlocked) return
        if(this.state.previewActive){
           this.stopPreview()
        }
        else{
            this.playPreview()
        }
       }} className="style19">
    <div
        className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "8rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
        }}
      >
        {(this.state.unlocked)?"unlocked!":"preview"}
      </div>
    </div>
      {this.state.postId &&<div ><OTPPopup UserId={this.state.UserId} complete={this.complete} price={this.state.price} onClose={()=>{this.close()}}  id={this.state.postId} isOpen={this.state.isOpen}/></div>}
    {!this.state.unlocked && <div className="style22" onClick={()=>{this.openModal()}}>
  
      <ul>
      <i  style={{fontSize:"22px",cursor:"pointer"}} class="fa fa-eye" aria-hidden="true"></i>
      </ul>
     
    </div>}

  </div>
 
  <div className="style26">
    
    <div>
      {this.state.postId && <p className="style27"> <ProfilePic id={this.state.UserId}/>
     
        <span className="style28">   Caption:</span> {`${this.state.caption}`}
        
         {!this.state.unlocked && !isPhoto && !isAudio && <p> Full Video {this.state.duration}<br/> Preview 0:03 </p>}  </p>}
         <p style={{fontSize:"17px",color:"#8d7c7c"}}>{views} Views</p>
         {this.state.UserId == localStorage.getItem("userID") && <div  className="discount skeleton-loader"
        style={{
        cursor:"pointer",
          width: "8rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
        }}> GHC{this.state.total}</div>}
         <a href="https://uhsoka.com"><p style={{fontSize:"17px",color:"#8d7c7c"}}>Upload your own content and register here</p></a>
         <Link
                          
                          //paras={{changefav:changefav,}}
                          to={{
                            pathname: `/request/${this.state.UserId}`,}}><p style={{fontSize:"17px",color:"#8d7c7c"}}>Request a video from this creator</p></Link>
         <div style={{position:"relative",top:"0",left:"0",width:"36px"}} class="tooltip">
                  more
        {<h2 style={{backgroundColor:"rgb(132 134 229)"}} class="tooltiptext" aria-hidden="true">
Requests cost GHC 1 (which is refunded if the creator rejects the request or the request period is expired)
        </h2>}
        { <div className="skeleton-loader hfour">info</div>}
      </div>
    </div>
 
    <div className="style29">
      <div className="style30"><span className="style30a">Comments</span>

      {this.state.postId && <Comments ref={this.childTwo} value={someVar} reply={this.state.reply} highlight={this.state.highlighted} post={this.state.postId}/>}

       </div>
    </div>
  </div>


  

   {/* <div  >{this.state.postId && <Composeform action = {this.handler} id={this.state.postId}/>}</div>  */}
</div>
</blockquote>

      </div>
    );
    }
}

export default OTPPost;