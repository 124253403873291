import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import routes from '../storage_apollo_routes';
import { setContext } from '@apollo/client/link/context';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});
/**
 * @typedef FavoritesProps
 * @prop {boolean} isFavorited is the post already favorited
 * @prop {integer} post the id of the post
 * @prop {integer} count the current favorites count
 */



/**
 * Renders the love icon and handles favorites for posts
 * @param {FavoritesProps} state
 */
class Favorites extends Component{
  
        state = {
          post:this.props.post,
          isFavorited:this.props.favorited,
          count:this.props.postCount
        };
       
         
      

      
/**
 * Adds or removes a favorite from the database
 * @param {integer} post 
 * @param {integer} count 
 */
  favorite = (post,count)=> {
    if(this.props.isOTP) return
    let mutcount = count
    client
    .mutate({
      mutation: gql`
        mutation togglePost {
          togglePost(id:${post}){
            id
          }
        }
      `,
    })
    .then((result) => {console.log(result)
     //this.setState({isFavorited:!this.state.isFavorited})
     if(this.props.favorited){
      if(this.props.isPost){
        this.props.decreasePostCount()
      }
      console.log(this.state.isFavorited,!this.state.isFavorited)
      this.props.changefav(post,(mutcount-1),!this.props.favorited)
      //this.setState({count:mutcount-1})
      
     }
     else{
      console.log(this.props.favorited,true)
      if(this.props.isPost){
        this.props.increasePostCount()
      }
      this.props.changefav(post,(mutcount+1),!this.props.favorited)
      //this.setState({count:mutcount+1})
      

     }
    
    
     })
    .catch((err) => {
      console.log(err);
      this.setState({ redirect: !this.state.redirect });

    });
    // this.props.queryApi()
}




render(){
  const {post,isFavorited,count}= this.state
return (
  <li className="gallery-item-likes"><span className="visually-hidden">Likes:</span><div><div onClick={()=>{this.favorite(this.props.post,this.props.postCount)}}><i   className = {this.props.favorited?"fa fa-heart":"fa fa-heart-o"} aria-hidden="true"></i></div></div> {this.props.postCount}</li> 
)

  // <div><div onClick={()=>{this.favorite(post)}}><i   className = {isFavorited?"fa fa-heart":"fa fa-heart-o"} aria-hidden="true"></i></div></div>
  /* <li className="gallery-item-likes"><span className="visually-hidden">Likes:</span><div><div onClick={()=>{this.favorite(post)}}><i   className = {isFavorited?"fa fa-heart":"fa fa-heart-o"} aria-hidden="true"></i></div></div> {count}</li> */


  
}





























}
export default Favorites;