import React, { Component } from 'react';
import "./Styles/OnlineIndicator.css"

class OnlineIndicator extends Component {
    render() {
        return (
            <div className='indicator'>
                <i class="fa fa-dot-circle-o" aria-hidden="true">
                <div className="indicatortext">user is online</div>
                </i>

            </div>
        );
    }
}

export default OnlineIndicator;