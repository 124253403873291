import  {tv} from 'ima-wrapper';
import React, { useState,useEffect } from 'react';
//import VideoPlayer  from "./Controllers/VideoPlayer"
import routes from '../storage_apollo_routes';
import TypingIndicator from './TypingIndicator';

const Ads = (props)=>{
const [adsLoaded,setAdsLoaded] = useState(false)
const [videoLoaded,setVideoLoaded] = useState(false)
const [adSliderColor,setAdSliderColor] = useState(false)
const [IMAloaded,setIMAloaded] = useState(false)
const [intervalTimer,setIntervalTimer] = useState(0)
const [adContainer,setAdcontainer] = useState(null)
const [duration,setDuration] = useState("0:00")
const [videoSliderRailPercentage,setVSRP] = useState(0)
const [videoSliderBufferPercentage,setVSBP] = useState(0)
const [audioSliderRailPercentage,setASRP] = useState(30)
const [audioSliderBufferPercentage,setASBP] = useState(30)
const [audioSlider,setAudioSlider] = useState(.30)
const [muteOpen,setMOpen] = useState(true)
const [muteClose,setMClose] = useState(false)
const [fullscreenOpen,setFOpen] = useState(true)
const [fullscreenExit,setFExit] = useState(false)

const [time,setTime] = useState("0:00")
 const [openState,setOpen] = useState(true)
 //const [isFullScreen,setFullScreen] = useState(false)
 const [removeBlack,toggleBlack] = useState(false)
 const [showPlay,setShowPlay] = useState(true)
 const [spin,setSpin]  = useState(false)
 const [reset,setReset] = useState(false)
 

 const vidPlayer = React.createRef();
 const videoElement = React.createRef()
 const videoSlider = React.createRef()
 const adContainerRef = React.createRef()

 useEffect(()=>{
console.log("props changed videoSrc:",props.Src)
videoElement.current.load()
 },[props.Src])

//  const getViews = (id)=>{
//   ///redi
//  }

//  const recordView =(currentTime)=>{
//   //
//   let videoPercentage = 0.30*videoElement.current.duration
//   if(videoPercentage <= currentTime){
//     //generateFingerPrintHash
//     //start redisClient add view record
//   }
//  }

const initializeIMA = ()=>{
return
  setAdSliderColor(true)
  setIMAloaded(true)
  const _container = adContainerRef
const adsManager = new tv.IMAWrapper(document.getElementById("ad-container"),   document.getElementById("videoElement"), (error) => {
    if(error) {
        console.log(error);
        return;
    }
});

console.log(videoElement.current)

adsManager.addEventListener('AdsManagerLoaded', function() {
  // Get height and width of your video element
  let width =   document.getElementById("videoElement").clientWidth
  let height = document.getElementById("videoElement").clientHeight
  let viewMode = 'normal'; // fullscreen
  // Init
  try {
      adsManager.init(width, height, viewMode);
  } catch (adError) {
      // Play your context without ads, if an error occurs
  }
});
// AdError
adsManager.addEventListener('AdError', function(adError) {
  if(adsManager) {
      // Removes ad assets loaded at runtime that need to be properly removed at the time of ad completion
      // and stops the ad and all tracking.
      adsManager.abort();
  }
  // ... 
});
// AdLoaded
adsManager.addEventListener('AdLoaded', function(adEvent) {
  // Ad loaded, awaiting start
  // Check if ad type is linear
  if(adEvent.isLinear()) {
      try {
          // Start ad
          adsManager.start();
      } catch (adError) {
          // Play video content without ads in case of error
      }
  } else {
      // Ad is not linear
  }
});
// AdStarted
adsManager.addEventListener('AdStarted', function() {
  // Pause your video content
  document.getElementById("videoElement").pause()
});
// ...
// AdDurationChange
// AdSizeChange
// AdImpression
// AdVideoStart
// AdVideoFirstQuartile
// AdVideoMidpoint
// AdVideoThirdQuartile
// AdVideoComplete
// AdPaused
// AdPlaying
// AdStopped
// AdSkipped
// AdClickThru
// ...
// AllAdsCompleted
adsManager.addEventListener('AllAdsCompleted', function() {
  // Play your video content
 let p_req = document.getElementById("videoElement").play();
 p_req.then((_)=>{
  setAdSliderColor(false)
 }).catch(err=>{
  setAdSliderColor(false)
  console.log(err)
})
 

});


// VAST tag url
let vastUrl = 'https://pubads.g.doubleclick.net/gampad/ads?' +
'iu=/21775744923/external/single_ad_samples&sz=640x480&' +
'cust_params=sample_ct%3Dlinear&ciu_szs=300x250%2C728x90&' +
'gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator=';

// Request Ads

adsManager.requestAds(vastUrl);





}

 const requestAds = ()=>{

}
 const onAdsManagerLoaded = ()=>{

 }

 const onAdEvent =()=>{

 }

 const onAdError = ()=>{

 }


 const isFullScreen = ()=>{
   var full_screen_element =
   document.fullscreenElement ||
   document.webkitFullscreenElement ||
   document.mozFullScreenElement ||
   document.msFullscreenElement ||
   null;

 if (full_screen_element === null) return false;
 else return true;
 }
//preview image from props
const  timeFormat =(d)=> {
const duration = Math.floor(d);
const h = Math.floor(duration / 3600);
const m = Math.floor((duration - h * 3600) / 60);
const s = duration % 60;
const H = h === 0 ? '' : `${h}:`;
const M = m < 10 ? `0${m}:` : `${m}:`;
const S = s < 10 ? `0${s}` : `${s}`;
return H + M + S;
}
const loading = ()=>{
// console.log(videoElement.current.readyState)

if (videoElement.current.readyState === 4) {
setSpin(false);
console.log("YEAHHH")
 play();
} else {

 setSpin(true)
 pause()
}
}

const voiceSliderRange = (event)=>{
var range = event.target.value
console.log(range)
//(localStorage.setItem(this.id,(this).val()));
// video_voice_buffer.css("width", range * 100 + "%");
setASBP(range * 100 )
//audioSliderBufferPercentage,setASBP
videoElement.current.volume = range;
setAudioSlider(range)
if (range == 0) {
 audioOn();
} else {
 audioOff();
}
}

const Fullscreen =(element)=>{
if (element.requestFullscreen) element.requestFullscreen();
else if (element.mozRequestFullScreen) element.mozRequestFullScreen();
else if (element.webkitRequestFullscreen)
 element.webkitRequestFullscreen();
else if (element.msRequestFullscreen) element.msRequestFullscreen();
// full_screen_open.hide();
setFExit(true)
setFOpen(false)
//full_screen_exit.show();
}

const updatePlayer = ()=>{

var percentage = (videoElement.current.currentTime / videoElement.current.duration) * 100;
//console.log(percentage)
setVSRP(percentage);
setVSBP( percentage - 1)
setTime(timeFormat(videoElement.current.currentTime))
//video_count_time.text(getFormatedTime());
//video_count_fulltime.text(getFormatedFullTime());
}
const skip = (event)=>{
var mouseX = event.pageX - videoSlider.current.getBoundingClientRect().left,
width = videoSlider.current.offsetWidth;
console.log(width,videoSlider.current.getBoundingClientRect().left)
videoElement.current.currentTime = (mouseX / width) * videoElement.current.duration;

updatePlayer();
}

const onEndHandler = ()=>{
setReset(true)
}
 const onLoadedHandler = ()=>{
   //toggleBlack(false)
   setVideoLoaded(!videoLoaded)
 }

 const exitFullScreen = ()=>{

   if (document.exitFullscreen) document.exitFullscreen();
   else if (document.mozCancelFullScreen) document.mozCancelFullScreen();
   else if (document.webkitExitFullscreen) document.webkitExitFullscreen();
   else if (document.msExitFullscreen) document.msExitFullscreen();
   setFExit(false)
   setFOpen(true)
   // full_screen_open.show();
   // full_screen_exit.hide();
   
 }

 const secondsToStringFormat = ()=>{
   
 }

 const playMaster = async ()=>{
  if(!IMAloaded){
    await initializeIMA()
  }
 
  // $(video_preview).hide();
   
   //setShowPlay(false)
   let p_req = document.getElementById("videoElement").play()
   //videoElement.current.play()
if(p_req !== undefined){
p_req.then((_)=>{
 setShowPlay(false)
 toggleBlack(true)
}).catch(err=>console.log(err))

 }
}

 const pause = async ()=>{
   setShowPlay(true)
  await  videoElement.current.pause()
   //video_control_pause.hide();
   //video_control_play.show();
 }

 const play = async ()=>{
   if(reset){
     setReset(false)
   }
   
let p_req = videoElement.current.play()
if(p_req !== undefined){
p_req.then((_)=>{
 setShowPlay(false)
}).catch(err=>console.log(err))
}

 }

const audioOn = ()=>{
 videoElement.current.muted = true;
 // $(video_voice_on).hide();
 setMClose(false)
 setMOpen(true)
 
 // $(video_voice_off).show();
}

const audioOff = ()=>{
 videoElement.current.muted = false;
 // $(video_voice_on).hide();
 setMClose(true)
 setMOpen(false)
}

 return <div className={`container ${(props.isAlbum)?"slide-images":""}`}>




 <div  ref={vidPlayer} class="video">

<div class="video-preview" style={{backgroundImage:`url(${routes.storage_1}${props.preview})`,display:`${(removeBlack)?"none":"flex"}`}} >
 {!props.isAudio && videoLoaded && <button style={{display:(removeBlack)?"none":"flex"}} class="video-start-btn" onClick={()=>{playMaster()}}>
   <svg width="60" height="60" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
     <path d="M256,0C114.833,0,0,114.844,0,256s114.833,256,256,256s256-114.844,256-256S397.167,0,256,0z M357.771,264.969
         l-149.333,96c-1.75,1.135-3.771,1.698-5.771,1.698c-1.75,0-3.521-0.438-5.104-1.302C194.125,359.49,192,355.906,192,352V160
         c0-3.906,2.125-7.49,5.563-9.365c3.375-1.854,7.604-1.74,10.875,0.396l149.333,96c3.042,1.958,4.896,5.344,4.896,8.969
         S360.813,263.01,357.771,264.969z" />
   </svg>
 </button>}
 {!videoLoaded && <div className="video-start-btn">
  <TypingIndicator/>
  </div>}
</div>
<div class="video-top"></div>
{ <div style={{display:(spin)?"flex":"none"}} class="video-loading">
 <div class="video-loading-spinner"></div>

</div>}
{<div style={{display:(reset)?"flex":"none"}}class="video-reset">
 <button onClick={()=>{
   play()
   setReset(false)}} class="video-reset-btn">Watch again</button>

</div>}
{!props.isAudio && <div class="video-player-controls">
 <div class="video-slider">
   <div ref={videoSlider} onClick={(event)=>{skip(event)}} class="video-slider-container">
     <div class="video-slider-bg">
       <div style={{width:`${videoSliderRailPercentage}%`,background:`${(adSliderColor)?"#d7be0c":"#ffffff"}`}}class="video-slider-rail"></div>
       <div style={{left:`${videoSliderBufferPercentage}%`}}class="video-slider-buffer"></div>
     </div>
   </div>

 </div>
 <div class="row">
   <div class="wrap">
     <div class="video-control">
       <button onClick={()=>{
         if(!showPlay){
           pause()
         }
         else{
           play()
         }
       }} class="video-control-btn">
         <svg style={{display:(showPlay)?"flex":"none"}} class="video-control-play" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" shapeRendering="geometricPrecision">
           <polygon points="5 3 19 12 5 21 5 3" fill="currentColor"></polygon>
         </svg>
         <svg style={{display:(showPlay)?"none":"flex"}} class="video-control-pause" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" shapeRendering="geometricPrecision">
           <rect x="6" y="4" width="4" height="16" fill="currentColor"></rect>
           <rect x="14" y="4" width="4" height="16" fill="currentColor"></rect>
         </svg>

       </button>
     </div>
     <div class="video-views">{props.caption}</div>
   </div>
   <div class="wrap">
     <div class="video-counts"><span class="video-count-time">{time}</span><span class="video-count-line">/</span><span class="video-count-fulltime">{props.duration}</span></div>
     <div class="video-voice">

       <div class="video-voice-slider">
         <div class="video-voice-slider-rail" role="slider">
           <div style={{width:`${audioSliderBufferPercentage}%`}} class="video-voice-slider-buffer"></div>
           <input class="video-voice-slider-range" onKeyUp={()=>{}} onChange={(event)=>{voiceSliderRange(event)}} type="range" min="0" max="1" value={audioSlider} step="0.01" />
         </div>
       </div>

       <button onClick={
         ()=>{
           if (videoElement.current.muted === false) {
             audioOn();
           } else {
             audioOff();
           }
         }
       }class="video-voice-btn">
         <svg style={{display:(muteOpen)?"flex":"none"}} class="video-voice-on" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision">
           <path d="M11 5L6 9H2v6h4l5 4V5z"></path>
           <path d="M19.07 4.93a10 10 0 010 14.14M15.54 8.46a5 5 0 010 7.07"></path>
         </svg>
         <svg style={{display:(muteClose)?"flex":"none"}}class="video-voice-off" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision">
           <path d="M11 5L6 9H2v6h4l5 4V5z"></path>
           <path d="M23 9l-6 6"></path>
           <path d="M17 9l6 6"></path>
         </svg>
       </button>

     </div>
     <div class="full-screen">
       <button onClick={()=>{
           if (isFullScreen()) exitFullScreen();
           else Fullscreen(vidPlayer.current)
         }} class="full-screen-btn">
         <svg class="full-screen-open" style={{display:(fullscreenOpen)?"flex":"none"}} viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision">
           <path d="M15 3h6v6"></path>
           <path d="M9 21H3v-6"></path>
           <path d="M21 3l-7 7"></path>
           <path d="M3 21l7-7"></path>
         </svg>

         <svg style={{display:(fullscreenExit)?"flex":"none"}} class="full-screen-exit" viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" fill="none" shapeRendering="geometricPrecision">
           <path d="M4 14h6v6"></path>
           <path d="M20 10h-6V4"></path>
           <path d="M14 10l7-7"></path>
           <path d="M3 21l7-7"></path>
         </svg>

       </button>

     </div>
   </div>
 </div>
</div>}

<div  class="video-player">
  
 <video id="videoElement"ref={ videoElement} style={{width:props.isAlbum?"350px":"100%"}} onTimeUpdate={()=>{updatePlayer()}} onEnded={()=>{onEndHandler()}} onProgress={()=>{loading()}} onLoadedData={() => {
             onLoadedHandler()}}   muted tabIndex="-1" data-video="" allowFullScreen={false} controlsList="nodownload">
   <source src={`${routes.storage_1}${props.Src}`} type="video/mp4"/>
 </video>
 <div ref={adContainerRef} id="ad-container"></div>
</div>
<div class="video-contextMenu">
 <button class="video-contextMenu-btn">Copy Video Adress</button>
</div>
</div>
   
 </div>;




}

export default Ads