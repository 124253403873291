import routes from "../storage_apollo_routes";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
  updateQuery,
} from "@apollo/client";
import CopyToClipboard from "react-copy-to-clipboard";
import "./Styles/Postdropdown.css" 
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import PostImagePreview from './PostImagePreview';
import Loader from "./Loader";
import { useState,useEffect } from "react";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "Apollo-Require-Preflight": "true",
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const link_1 = createUploadLink({
  uri:routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
});
const client_1 = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(link_1),
});


const Postdropdown = (props)=>{
  const [transactions,setTransactions] = useState([])
  const [isl,setIsl] = useState(false)
  const [displayForm,setDisplayForm] = useState(false)
  const [target,setPostTarget] = useState(null)
  const [total,setTotal] = useState(false)
  const [showPreview,setShowPreview] = useState(false)
  const [media,setMedia] = useState(null)
  const [file1,setFile1] = useState(null)
  const [type,setFileType] = useState(null)
  const [duration,setDuration] = useState(null)
  const [mimeType,setMimeType] = useState(null)
  const [isActive,setActive] = useState(false)
  useEffect(() => {
getPostEarnings()
  }, [isl]);

  const setTarget = async ()=>{
    // if(!target){
    //   return
    // }
    setIsl(true)
    await  client
    .mutate({
      mutation: gql`
        mutation Target {
          setPostTarget(id:${props.postID},target:${target}) 
        }
      `,
    }).then((result)=>{
      setPostTarget(result.data.setPostTarget)
      setIsl(false)
    }).catch((error)=>{
console.log(error)
setIsl(false)
    })
    //
    console.log("poo shiesty")
  }

  const alertPost = async ()=>{
    await  client
  .mutate({
    mutation: gql`
      mutation Alert {
        submitApproval(payload:${props.postID})
      }
    `,
  }).then((result)=>{
    console.log(result)
  }).catch((error)=>{
    console.log(error)
  })
  }
  const getTotal = ()=>{
    let sum = 0
    for(let t in transactions){
sum = transactions[t].amount + sum
    }
    setTotal(sum)
  }

  const cleanUp = ()=>{

  }

 const setOpenMaster = ()=>{
    // this.setState({isActive:true})
    setActive(true)
  }
  const setFileDetails=(details)=>{
    
    setDuration(details.duration)
    setMimeType(details.mimeType)
  }
  const onChangeFileHandler = (event)=>{
    if(media && file1){
      cleanUp()
    }
      let type = ""
      let mimes ={     "video/mp4":"video",
      "video/avi":"video",
      "video/ogg":"video",
      "video/mov":"video",
      "video/wmg":"video",
      "video/3gp":"video",
      "video/ts":"video",
      "video/webm":"video",
      "video/m4v":"video",
      "video/webp":"video"
      // "audio/mpeg":"video",
    }
    if(!event.target.files[0]) return
    if(mimes[event.target.files[0].type]){
      type = mimes[event.target.files[0].type]
    //   console.log(type,"TYPEEEEEEEEEEEEEEEEEEEEEEE")
    }
      let file = new FileReader()
      file.readAsArrayBuffer(event.target.files[0]);
      //file.readAsDataURL(event.target.files[0])
      // file.onloadend = ()=>{
      
      //     this.setState({media:file.result,type:type,file1:event.target.files[0]})
      //     console.log(file.result.type,"TYPE GOES HERE,file.result.mime",file.result)
      // }

      file.onload =(e)=>{
          // The file reader gives us an ArrayBuffer:
    let buffer = e.target.result;
// We have to convert the buffer to a blob:
   let videoBlob = new Blob([new Uint8Array(buffer)], { type: 'video/mp4' });
// The blob gives us a URL to the video file:
   let url = URL.createObjectURL(videoBlob);
    // video.src = url;
    //this.setState({media:url,type:type,file1:event.target.files[0]})
    setMedia(url)
    setFile1(event.target.files[0])
    setFileType(type)
      }
    }
    const setPreviewVideo = async()=>{
      if(!file1) return
      await  client_1
      .mutate({
        mutation: gql`
          mutation PreviewVideo($newVideo:[Upload],$videoId:Int!){
            changePreviewVideo(newVideo:$newVideo,videoId:$videoId) 
          }
        `,
      variables:{newVideo:[file1],videoId:props.postID}}).then((result)=>{
console.log(result)
      }).catch((error)=>{
        console.log(error)
      })
    }

const getPostEarnings=async()=>{
  await  client
  .query({
    query: gql`
      query Earnings {
        getPostEarnings(id:${props.postID}) {
          from
          amount
          createdAt
          type
          
        }
      }
    `,
  }).then((result)=>{
    console.log(result.data.getPostEarnings)
    setTransactions(result.data.getPostEarnings)
  }).catch((error)=>{
    console.log(error)
  })
}
return <div>
 

<div class="swanky)">

  <div class="swanky_wrapper">
    <input id="Dashboard" name="radio" type="radio"></input>
    <label style={{display:"flex"}} for="Dashboard">
      {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/dash.png"/> */}
      {/* <span>Stats</span> */}
      {/* <div class="lil_arrow"></div> */}
      {/* <div class="bar"></div>
      <div class="swanky_wrapper__content">
        <ul>
          <li onClick={()=>getTotal()}>Total {(total)?`GHC ${total}`:""}</li>
          <li style={{display:"block"}} onClick={()=>setDisplayForm(true)}>Set Target
          {displayForm && <div><input onChange={(e)=>{setPostTarget(e.target.value)}}  placeholder="enter target amount"  type="number" /></div>}
          {displayForm && <div style={{    color: "blue",
    width: "1px",
    position: "absolute",
    bottom: "9px",
    right: "80px"}}> {!isl && <i onClick={()=>setTarget()}class="fa fa-check-square" aria-hidden="true"></i>}
    <Loader isOpen={isl}/>
    </div>}
          </li>
          <li>Tippers</li>
        </ul>
      </div> */}
    </label>
    {/* <input id="Sales" name="radio" type="radio"></input>
    <label for="Sales">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/del.png"/>
      <span>Sales</span>
      <div class="lil_arrow"></div>
      <div class="bar"></div>
      <div class="swanky_wrapper__content">
        <ul>
          <li>New Sales</li>
          <li>Expired Sales</li>
          <li>Sales Reports</li>
          <li>Deliveries</li>
        </ul>
      </div>
    </label> */}
    <input id="Messages" name="radio" type="radio"></input>
    <label for="Messages">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/mess.png"/>
      <span>One Time Link</span>
      <div class="lil_arrow"></div>
      <div class="bar"></div>
      <div class="swanky_wrapper__content">
       { <ul>
         {/* <li>One Time Link</li>
         <li onClick={()=>{alertPost()}}>Report Post</li> */}
         
         <li>Get One Time Link
          {/* <CopyToClipboard text={`https://uhsoka.com/Otpost/${props.postID}`}><button onClick={()=>{}}>Copy One-Time-Payment Link</button></CopyToClipboard> */}
         {/* {media && <div style={{height:"100px",width:"70px",position:"relative",left:"70px"}}> <PostImagePreview fileObject={file1} setOpenMaster={setOpenMaster} setFileDetails={setFileDetails} type={type} postSrc={media} alt="profilepic" className="list2-item-image"/></div>} */}
         {/* <input
                  style={{maxWidth:"180px",fontSize:"9px"}}
                  
                  type="file"
                  onChange={(event) => {
                    onChangeFileHandler(event);
                  }}
                  multiple
                  accept="video/*"
                  className="copy-link-input"
                /> */}
                {/* <button onClick={()=>setPreviewVideo()}>change</button> */}
         </li>
         
        </ul>}
      </div>
    </label>
    {/* <input id="Users" name="radio" type="radio"></input>
    <label for="Users">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/users.png"/>
      <span>Users</span>
      <div class="lil_arrow"></div>
      <div class="bar"></div>
      <div class="swanky_wrapper__content">
        <ul>
          <li>New User</li>
          <li>User Groups</li>
          <li>Permissions</li>
          <li>Passwords</li>
        </ul>
      </div>
    </label> */}
    {/* <input id="Settings" radio="radio" type="radio"></input>
    <label for="Settings">
      <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/set.png"/>
      <span>Settings</span>
      <div class="lil_arrow"></div>
      <div class="swanky_wrapper__content">
        <ul>
          <li>Databases</li>
          <li>Design</li>
          <li>Change User</li>
          <li>Log Out</li>
        </ul>
      </div>
    </label> */}
  </div>

</div>

{/* <div class="love">
  <p>Made with <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/217233/love_copy.png" /> by <a href='https://www.jamiecoulter.co.uk' target='_blank'> Jcoulterdesign </a></p>
</div> */}
</div>

}

export default Postdropdown