import React, { Component } from 'react';
/**
 * @typedef Bundle
 * @prop {string} endDate
 * @prop {float} price
 * @prop {integer} id
 */
/**
 * @callback deleteCallBack
 * Displays a Bundle Controller
 * @param {Array.<Bundle>} bdl - an Array of Bundle Objects
 * @param {boolean} toggle - Boolean that handles the display of the component
 * @param {integer} price - the price of a user Subscription/ user.price
 * @param {deleteCallBack} delete - A callback that handles bundle deletion
 */

class Bundlecard extends Component {
    constructor(props){
        super(props)
        this.state={
            open:false,
            bundle_data:[]
        }
    }
	/**
	 * Calculates the percentage off
	 * @param {number} param - The price of the new bundle
	 * @param {decimal} basePrice - The price of the user's subscription (user.price)
	 * @param {integer} time - The duration of the new bundle
	 * @returns {JSX} jsx with calculated percentage
	 */
	calculatePercentageOff = (param,basePrice,time)=>{
		let bundlePrice = param/time
		let unitPrice = basePrice/30
		let percentage = 100*(1-(bundlePrice/unitPrice))
		console.log(bundlePrice,unitPrice,percentage,time,param)
	   return <p>({Math.round(percentage*10)/10}% off)</p>
	  }
/**
	 * Deletes a bundle from the database 
	 * @param {number} param - The id of the bundle

	 */
	deleteBundle(id){
		this.props.delete(id)
	}

    render() {

   const {open,bundle_data} = this.state
        if (this.props.toggle == null)
            return null
        
    

        return (
            <div className='container'>
                <div class="containere">
	<div class="row justify-content-center">
		{this.props.bdl.map(bundle =>(<div  key={bundle.id} class="col-lg-4 col-md-6 col-sm-12">
			<div class="carde">
				<div class="overlay_"></div>
				<div class="content">
					<h4>Duration: {bundle.endDate} Days</h4>
					<h6>Price: GHC {bundle.price}</h6>
					<h6> {this.calculatePercentageOff(bundle.price,this.props.price,bundle.endDate)}</h6>
				</div>
				<div class="fav">
					<i onClick={()=>this.deleteBundle(bundle.id)} class="fa fa-times"></i>
				</div>
			</div>
            </div>)
      
             
            )}
		
	
		{/* <div class="col-lg-4 col-md-6 col-sm-12">
			<div class="carde">
				<div class="overlay"></div>
				<div class="content">
					<h4>UI DESIGN COURSE {`${open}`} </h4>
					<h6>3 Hrs | 13 Sections</h6>
				</div>
				<div class="fav">
					<i class="far fa-heart"></i>
				</div>
			</div>
		</div> */}
		{/* <div class="col-lg-4 col-md-6 col-sm-12">
			<div class="card">
				<div class="overlay"></div>
				<div class="content">
					<h4>UI DESIGN COURSE</h4>
					<h6>3 Hrs | 13 Sections</h6>
				</div>
				<div class="fav">
					<i class="far fa-heart"></i>
				</div>
			</div>
		</div> */}
		{/* <div class="col-lg-4 col-md-6 col-sm-12">
			<div class="card">
				<div class="overlay"></div>
				<div class="content">
					<h4>UI DESIGN COURSE</h4>
					<h6>3 Hrs | 13 Sections</h6>
				</div>
				<div class="fav">
					<i class="far fa-heart"></i>
				</div>
			</div>
		</div> */}
	</div>
</div>
            </div>
        );
    }
}

export default Bundlecard;