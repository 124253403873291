import React, { Component } from 'react';
import Loader from './Loader';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
  
class SocialLinkForm extends Component {
    constructor(props){
        super(props)
        this.state ={
            site:"",
            loaderState:false
        }
    }
onChangeHandler = (event)=>{
    this.setState({site:event.target.value})
}

setLink =()=>{
  if(this.state.site !== ""){
  this.setState({loaderState:true})
    client
    .mutate({
      mutation: gql`
        mutation newSocial {
          createSocial(name:"${this.props.name}",link:"${this.state.site}"){
            name
            link
          }
                      }`}).then((results)=>{
                        console.log(results)
                        this.props.modifiedLinkHandler(results.data.createSocial)
                        this.setState({loaderState:false,site:""})
                      }).catch((error)=>{
                        console.log(error)
                      })
                    }
}
    render() {
        return (
            <div>
                            <div>
                <div style={{width:"140px"}}className="copy-link">
  <input onChange={(event)=>this.onChangeHandler(event)}ref={this.inputField}type="text" className="copy-link-input" placeholder={(this.props.link)?`${this.props.link}`:`https://www.${this.props.name}.com/`} value={`${this.state.site}`} />
  <button onClick={()=>{this.setLink()}} type="button" className="copy-link-button">
    {!this.state.loaderState && <span className="">add</span>}
    {this.state.loaderState && <span>
      <Loader isOpen={this.state.loaderState}/>
    </span>}
  </button>
</div>
            </div>
            </div>
        );
    }
}

export default SocialLinkForm;