import React, { Component } from 'react';
import "../UI/Styles/socketClientComment.css"

class SocketClientComment extends Component {
	constructor(props){
		super(props)
		this.state ={
        isFile:false,
		isText:true,
		file:null
		}
	}
setTextHandler=(event)=>{
	if(!this.state.isText){
		this.setState({isFile:false,isText:true,file:null})
	}
	this.props.onChangeHandler(event)
	
}
	setFileHandler =(event)=>{
		console.log(event.target.value)
		this.setState({isFile:true,isText:false,file:event})
	}
	setPrice =(event)=>{
		this.props.priceHandler(event)
	}
	handleSend = ()=>{
		//if(!this.props.socketInstance) return
		if(this.state.isFile){
			console.log(this.state.file)
			this.props.fileHandler(this.state.file)
		}
		if(this.state.isText){
			this.props.emitMsg()
		}
			
		
		
		this.setState({file:null,isText:true,isFile:false})
	}
	
	render() {
        return (
            <div style={{maxWidth:"300px"}}>
                	<div class="writing">
		<input value={this.props.socketInstance} onChange={(event)=>this.setTextHandler(event)} onKeyUp={(event)=>this.props.onChangeHandler_typing(event)} class="textarea" autofocus spellcheck="false">
			{/* <p>Hi <a class="tagged-user">@Joe</a></p> */}
		</input>
		<div class="footer">
			<div class="text-format">
				<div >
				<input onChange={(event)=>this.props.priceHandler(event)}   type="number" placeholder="price" />
				{/* <i class="fa fa-file-image-o" aria-hidden="true"></i> */}
				{ <input type="file"  style={{fontSize:"9px",  padding: "0px"}} className="copy-link-input"  multiple accept="image/*,audio/*,video/*" name="multimedia"  onChange={(event)=>this.setFileHandler(event)}></input>}
				
			
				</div>
		
				{/* <button class="btn_chat"><i class="ri-italic"></i></button>
				<button class="btn_chat"><i class="ri-underline"></i></button>
				<button class="btn_chat"><i class="ri-list-unordered"></i></button> */}
			</div>
			<div class="group-button">
				<button class="btn_chat"><i class="ri-at-line"></i></button>
				<button onClick={()=>this.handleSend()} class="btn_chat primary">Send</button>
			</div>
		</div>
	</div>
            </div>
        );
    }
}

export default SocketClientComment;