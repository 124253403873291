import Popup5 from "./Popup5";
import routes from "../storage_apollo_routes";
import { useEffect,useState } from "react";
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import RequestPopup from "./RequestPopup";
  const link = createHttpLink({
    uri: routes.apollo,
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
  });
  const getCreatorRequest = gql`
  query getProfileDetails($id:Int){
    getCreatorProfile(id:$id){
        id
        photo
        name
        description
        email
    }
  }
  `
const Request = (props)=>{
    const [profile,setProfile] = useState(null)
    const [isOpen,setOpen] = useState(false)
    const [help,setHelp] = useState(false)

    useEffect(()=>{
        console.log(props.match.params.id)
getRequestCreator(props.match.params.id)
    },[])

    const getRequestCreator = async (id)=>{
        await client.query({
            query:getCreatorRequest,
            variables:{id:parseInt(id)}
        }).then((r)=>{
            console.log(r)
            setProfile(r.data.getCreatorProfile)
        }).catch((e)=>{
            console.log(e)
        })
    }
    if(!profile) return null
  return   (
        
        <div>
            <div className="container">
            <RequestPopup id={profile.id}  userID={parseInt(props.match.params.id)} onClose={() => {setOpen(false)}} isOpen={isOpen}/>
            <div class="pdcard" data-state="#about">

<div class="pdcard-header">
<div class="pdcard-cover" style={{backgroundImage: "url('https://images.unsplash.com/photo-1549068106-b024baf5062d?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=934&q=80')"}}></div>
<img class="pdcard-avatar" src={`${routes.storage_1}${profile.photo}`} alt="avatar" />
<h1 class="pdcard-fullname">{profile.name}</h1>
<div style={{paddingTop:"11px"}}>
<h2 class="pdcard-jobtitle"></h2>
</div>

</div>
<div class="pdcard-main">
<div class="pdcard-section is-active" id="about">
  <div class="pdcard-content">
    <div class="pdcard-subtitle">ABOUT</div>
    <p class="pdcard-desc">{profile.description}
    </p>
  </div>
  {/* {props.profileStat.socials.length >=1 && <div class="pdcard-social">
    
    {props.profileStat.socials.map((social)=><a href={`${social.link}`}>
    <i className={`${this.mapSocial(social.name)}`} aria-hidden="true"></i></a>)}
   
  </div>} */}
</div>
<div class={`pdcard-section ${help}`} id="experience">
  <div class="pdcard-content">
    <div class="pdcard-subtitle">REQUESTS</div>
    <div class="pdcard-timeline">
    <div class="pdcard-item" data-year="1">
        <div class="pdcard-item-title">Request a <span>Video/Photo</span></div>
        <div class="pdcard-item-desc">Requests cost GHC X.XX</div>
      </div>
      <div class="pdcard-item" data-year="2">
        <div class="pdcard-item-title">What are  <span>Requests?</span></div>
        <div class="pdcard-item-desc">You can ask for customized videos from your favorite creator for a price</div>
      </div>
  
      {/*<div class="pdcard-item" data-year="4">
        <div class="pdcard-item-title">Full-Stack Developer at <span>CodePen</span></div>
        <div class="pdcard-item-desc">Responsible for the encomposing brand expreience.</div>
      </div> */}
    </div>
  </div>
</div>

<div class="pdcard-buttons">
  <button onClick={()=>{setOpen(true)}} data-section="#about" class="request">CONTENT REQUEST</button>
  <button  class={help} data-section="#experience">HELP</button>
  <button data-section="#contact">CONTACT</button>
</div>
</div>
</div>
            </div>
        </div>
    );
}

export default Request