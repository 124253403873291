import React, { Component } from "react";
import Popup3 from "./Popup3";
import Loader from "./Loader";
import "./Styles/BulkPPV.css";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
  credentials: "include",
  // defaultOptions:defaultOptions
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
    query: {
      fetchPolicy: "no-cache",
      errorPolicy: "all",
    },
  },
});


/**
 *  * @callback modifyPost_BulkCallback
 */
/**
 * A popup that handles bulk pay-per-views

 * @param {float} totalPrice - Sum of selected items
 * @param {Array.<float>} itemIndices - Array of each item index
 * @param {modifyPost_BulkCallback }  modifyPost_Bulk- a callback that handles a successful bulkPayment request
 * @param {boolean} toggle - handles the display state of the popup
 * @param {integer} checkState - keeps count of selected Posts
 */
class BulkPPV extends Component {
  constructor(props) {
    super(props);
    this.state = {
      contact: "",
      loaderState: false,
      errorMsg: "",
      iMO: false,
      useTokens:false
    };
  }
  /**
   * Sets the state for phone number
   * @param {*} event
   */
  contactChosen = (event) => {
    this.setState({ contact: event.target.value });
  };

  bulkPayment = (indices, total) => {
    const { contact } = this.state;
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact);
   
    if (!pattern) {
      return;
    }
    this.setState({ loaderState: true });
    const bulk = gql`
      mutation bulkPayment($total: Int!, $contact: String!, $ids: [Int!],$useTokens:Boolean!) {
        bulkPayment(total: $total, contact: $contact, ids: $ids,useTokens:$useTokens)
      }
    `;
    client
      .mutate({
        mutation: bulk,
        variables: {
          contact: contact,
          total: total,
          ids: indices,
          useTokens:this.state.useTokens
        },
      })
      .then((result) => {
        console.log(result.data.bulkPayment);
        if (result.data.bulkPayment == "SUCCESSFUL") {
          this.props.modifyPost_Bulk(indices);
          this.setState({ loaderState: false });
        } else {
          this.createError("An Error Occurred");
          this.setState({ loaderState: false });
        }
      })
      .catch((err) => {
        console.log("catch", err);
        this.setState({ loaderState: false });
        console.log(err);
        this.createError("An Error Occurred");
      });
  };

  /**
   * Closes the popup
   */
  closeModal() {
    this.setState({ iMO: false, errorMsg: null });
  }

  /**
   * Generates an error pop-up 
   * @param {string} message - The error message to be displayed 
   */
  createError = (message) => {
    this.setState({ errorMsg: message, iMO: true });
    //console.log("message")
  };

  componentDidMount() {
    console.log(this.props.checkState, this.props.toggle, "TOOGLLEEERR");
  }
  render() {
    const { errorMsg } = this.state;
    if (this.props.checkState == 0) return null;

    return (
      <div className="boxxxx">
        <div className="cenceph_pure_black">
          {this.props.checkState} posts Selected
          <p>Total:Ghc {this.props.totalPrice}</p>
          <div className="tooltip">
            ?
            <i className="tooltiptext">
              Only MTN numbers in the format 233XXXXXXXXX allowed
            </i>
          </div>
          <Popup3
            isOpen={this.state.iMO}
            error={errorMsg}
            onClose={() => this.closeModal()}
          ></Popup3>
          <Loader isOpen={this.state.loaderState} />
          <input onChange={()=>this.setState({useTokens:!this.state.useTokens})} value={this.state.useTokens} type="checkbox" />
          <input
            onChange={(e) => this.contactChosen(e)}
            placeholder="eg. 233XXXXXXXXX"
            value={this.state.contact}
            type="text"
          />
          <div className="cont">
            <button
              className="wpc-btn wpc-btn-default-rounded"
              onClick={() =>
                this.bulkPayment(this.props.itemIndices, this.props.totalPrice)
              }
            >
              pay
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default BulkPPV;
