import ReactDOM from 'react-dom';
//import { methodOf } from 'lodash';
import Homepage from '../UI/Homepage';
import React ,{Component,Navlink,useEffect,useState} from 'react';
import {useHistory,Redirect, Switch, BrowserRouter,Route,Link,NavLink} from 'react-router-dom';
import Alerts from './Alerts';
import routes from '../storage_apollo_routes';
//import Upload from './Upload';
// import Notification from './Notification';
import { setContext } from '@apollo/client/link/context';
//import { search } from './utils'
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
// const client = new ApolloClient({
//   cache: new InMemoryCache(),
//   link,
// });
const authLink_1 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const client = new ApolloClient({
  link:authLink_1.concat(link),
  cache: new InMemoryCache(),
   defaultOptions : {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
  })

// import Echo from 'laravel-echo';
//window._ = require('lodash');

//const CancelToken = axios.CancelToken

export class Dashboard extends Component{
 constructor(props){
    
        super(props);
      
        this.state = {
          serverres:'',
          userid:this.props.id,
          credentials:[],
          body:"",
          results: [],
         loading: false,
         fee:"",
         alerts:[],
         currentlocation:"",
         isVerified:false
         


        };
        this.handleChange = this.handleChange.bind(this)
        
      }

      appendAlerts = (newAlert)=>{
        console.log(newAlert)
        this.setState({alerts:this.state.alerts.concat([newAlert])})
      }
      
      clear = () =>{
        this.setState({body:'',
        results:null})
      }
      

      search = async bod => {
         
        
        let cancel 
       
        const {body}= this.state
         
        


          this.setState({ loading: true });
          console.log(bod)
          client
          .query({
            query: gql`
              query {
                searchUsers(params: "${bod}" ) {
                user {
                name
                id
                photo
                email
                mtn_number
              }
               subbed
                  
                }
              }
            `,
          })
          .then((result) => {//console.log(result)
            //console.log("state changed")
            this.setState({results:result.data.searchUsers})
      
      })
          .catch((err) => {
            console.log(err);
            //this.setState({ redirec: !this.state.redirect });
          });
      //      axios.post(
      //       '/api/search', {
      //         query:body ,
      //        headers: {"Content-Type":"application/json"},
      //        cancelToken: new CancelToken(c => cancel = c)
      
      
      //     }
      //     ).then(response =>{
      //       this.setState({ results:response.data,
      //       fee:response.data.fee});
      //       this.setState({ loading:false});
      //     }).catch(e =>{
      //       if(axios.isCancel(e))return
      //     })


          

          
          
        
          
        
        
        
        
         
      // return () =>cancel()   
         
      }
    
    
    
    
    
    
    
    
    
    
    
    
      handleChange = async event =>{
        this.search(event.target.value);
        this.setState({body:event.target.value});
       
         
        }















       componentWillUnmount(){
        this.setState({results:null})
       }



        componentDidMount() {
          client
            .query({
              query: gql`
                query myQuery {
                  me {
                    name
                    id
                    isVerified
                  }
                }
              `,
            })
            .then((result) =>{
              console.log(result.data.me.id)
              let id = result.data.me.id
              this.setState({
                isVerified:result.data.me.isVerified

              })
              if(localStorage.getItem("userID") !== id){
                localStorage.setItem("userID",id)
              }
            }) 
            .catch((err) => {
              console.log(err);

              this.setState({ redirect: !this.state.redirect });
            });
        }


    
  render(){
   
    const {userid,credentials,body,results,fee} = this.state
      return(
       
      <div>
        

      {/* <div> <Homepage/></div> */}


        {/* <div className="navigation">
<div className="logo">
  <a className="no-underline" href="#">
   
  </a>
</div> */}
{/* <div className="navigation-search-container">
  <i  className="fa fa-search" onClick={()=>{this.clear()}}></i>
  <input onChange={this.handleChange} value={body} className="search-field" type="text" autoComplete="off" name="query" placeholder="Search"/>
  <div className="search-container">
  <div className="dropdown">

  <Searchresults fee={fee} results={results}/>
  {/* <Searchv2 results={results} fee={fee}/> */}
  {/*</div>
  
    <div  className="search-container-box">
      <div className="search-results">

      </div>
    </div>
  </div>
</div> */}
{/* <div className="navigation-icons">
  
  
  <a href="https://instagram.com/mimoudix" className="navigation-link">
    <i className="fa fa-user-circle"></i>
  </a>
  
</div> */}

{/* </div> */}


  














        
  
      
      
<div className="profilecard">
      <div className="cardheader">
         <div className="pic">
        <img src="joker_03.jpg"></img>
         </div>
        
        <div className="name">{credentials.name}</div>
        <div className="desc"></div>
        
        <Link to={{pathname:'/posts',user:userid}} state={{userid:userid}}>  <div  className="contact-btn">Posts</div></Link>
        <Link to={{pathname:"/upload", user:userid }}>  <div  className="contact-btn">Upload</div></Link>
        <Link to={{pathname:"/homepage", user:userid }}>  <div  className="contact-btn">Home</div></Link>
        <Link to="/alerts">  <div  className="contact-btn">Alerts</div></Link>
        <Link to="/feed">  <div  className="contact-btn">Feed</div></Link>
        <Link to="/search">  <div  className="contact-btn">Search</div></Link>

         </div> 
         <div className="card-footer">
            <div className="numbers"> 
            <div className="n">
            {/* <Notification userid={userid}/> */}

</div>
                
                
                
            </div>
         </div><div className="cont">
       
              </div>
      </div>




     








    
      
     
      <div className="n">
      <div className="search-container">
      
      </div>
      </div>
      
      
      </div>)
  }

}


export default Dashboard;
