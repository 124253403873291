import "./Styles/Discount.css"
function Discount(props) {
    //percentage off original price,new price ,expiry date
    const calculatePercentageOff = ()=>{
      //use dummy price
      if(!props.price || props.price == 0){
        return "undefined"

      }
      let original_price = props.price
      let new_price = props.discount.price
      let percent_off = ((original_price-new_price)/original_price) * 100
      return Math.round(percent_off)

    }
    if(props.calculateExpiry(props.discount.createdAt,props.discount.expires)) return
  return (
    <div
      style={{ paddingTop: "1px" }}
      className="gallery-item-discount tooltip"
    >
      {" "}
      <div class="tooltip">
        <i class="tooltiptext" aria-hidden="true">
        Limited  Offer -
     <h2 aria-hidden="true">{`${calculatePercentageOff()}% off`} </h2>
     expires in {props.discount.expires-props.timeElapsed(props.discount.createdAt)} days
        </i>
      </div>{" "}
      <div
        className="discount skeleton-loader"
        style={{
          width: "8rem",
          textAlign: "center",
          borderRadius: "4px",
          display: "inline-block",
          height: "25px",
        }}
      >
        discount
      </div>
    </div>
  );
}

export default Discount;
