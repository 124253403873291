import React, { Component } from "react";
import SocialLinks from "./SocialLinks";
import Loader from "./Loader";
import TagController from "../Controllers/TagController";
import Popup4 from "./Popup4";
import Popup3 from "./Popup3";
import routes from "../storage_apollo_routes";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const authLink_1 = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      "X-Pinggy-No-Screen": "true",
    },
  };
});
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink_1.concat(link),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  }
});
/**
 * Renders the settings in the Homepage
 */
class Settings extends Component {
  constructor(props) {
    super(props);
    this.state={
      text:"",
      displayLoader:false,
      displayLoader_music:false,
      iMO:false,
      errorMsg:""
    }
  }
  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }
  startMusic=async()=>{
    this.setState({displayLoader_music:true})
    await client.query({
      query:gql`
      query Audio {
        startPlayer
      }
      `
    }).then((result)=>{
      if(result){
        /// 
         this.setState({displayLoader_music:false})
      }
    }).catch((error)=>{
console.log(error)
this.setState({displayLoader_music:false})
    })
  }

  handleChange=(event)=>{

this.setState({text:event.target.value})
  }
  closeModal=()=>{
    this.setState({iMO:false})
  }
  submitRequest = async ()=>{
    await  client
    .mutate({
      mutation: gql`
        mutation Alert {
          submitApproval(payload:${null})
        }
      `,
    }).then((result)=>{
      if(result.data.submitApproval){
        this.createError("Request Submitted,check alerts for update...")
        //popup
      }
      console.log(result)
    }).catch((error)=>{
      console.log(error)
      this.createError("Error Occured")

    })
  }
  handleChangeVerification = (event)=>{

this.setState({idVerification:event.target.value})
  }

  uploadCredentials = ()=>{
    //graphql call here
  }

  changeDescription=()=>{
    

    if(this.state.text != ""){
      this.setState({displayLoader:true})
      client
      .mutate({
        mutation: gql`
      mutation Description{
        changeDescription(text:"${this.state.text}")
                        }`}).then((results)=>{
                          this.props.changeDescription(results.data.changeDescription)
                          this.setState({sites:results.data.changeDescription,displayLoader:false})
                        }).catch((error)=>{
                          console.log(error)
                        })
    }
     

  }

  render() {
    if (!this.props.showSettings) return null;
    return (
      <div className="container">
      
        <div
          className="list2-wrapper"
          ng-app="app"
          ng-controller="MainCtrl as ctrl" 
        >
              <div style={{position:"relative"}}>
                          {<div style={{left:"-3em",position:"relative"}}><i style={{fontSize:"13px"}}onClick={()=> this.props.toggleSettings()} className="fa fa-times" aria-hidden="true"></i></div>}
                          </div>
          <ul className="list2">
            <div style={{ maxHeight: "300px", overflowY: "auto" }}>
              <div>
              <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
              <div>
              <p style={{borderRight:"0",padding:"0"}} className="logo"> Social Links</p>
              <SocialLinks/>
              </div>
             
              </li>
             
                              
              </div>
              <div>
              <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
              <p style={{borderRight:"0",padding:"0"}} className="logo">Description</p>
              <div style={{paddingLeft:"8px"}}>
              <input onChange={(event)=>this.handleChange(event)}  className="search-field" type="text" autoComplete="off" name="query" placeholder="Add Description"/>
              </div>
            <button className="stat-btn-hover color-2" onClick={()=>this.changeDescription()}>
                {  !this.state.displayLoader && <span>change</span>}
                {  this.state.displayLoader && <span> <Loader isOpen={this.state.displayLoader} /> </span>}
                </button>
                

              </li>
             
                              
              </div>
              {!this.props.isVerified &&    <div>
              <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
              <p style={{borderRight:"0",padding:"0"}} className="logo">Verification</p>
              <div style={{paddingLeft:"8px"}}>
              <input onChange={(event)=>this.handleChangeVerification(event)}  className="search-field" type="file" autoComplete="off" name="query" placeholder="Upload Identification"/>
              </div>
            <button className="stat-btn-hover color-2" onClick={()=>this.submitRequest()}>
                {  !this.state.displayLoader && <span>upload</span>}
                {  this.state.displayLoader && <span> <Loader isOpen={this.state.displayLoader} /> </span>}
                </button>
                

              </li>
              <div style={{position:"relative"}}><Popup3 isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3></div>
                              
              </div>}
              <div>
              <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`,display:"block"}} className="list2-item">
              <p style={{borderRight:"0",padding:"0"}} className="logo">Add Content Tags</p>
              <div style={{maxWidth:"100px"}}><TagController/></div>
              </li>
             
                              
              </div>
              <div>
              <li ng-repeat="user in ctrl.users" style={{backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`}} className="list2-item">
              <p style={{borderRight:"0",padding:"0"}} className="logo">PlayList</p>
              <div style={{paddingLeft:"8px"}}>
              
              </div>
            <button className="stat-btn-hover color-2" onClick={()=>this.startMusic()}>
                {  !this.state.displayLoader_music && <span>load player</span>}
                {  this.state.displayLoader_music && <span> <Loader isOpen={this.state.displayLoader_music} /> </span>}
                </button>
                

              </li>
              <div style={{position:"relative"}}><Popup3 isOpen={this.state.iMO} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3></div>
                              
              </div>
              <div>
              <li ng-repeat="user in ctrl.users" style={{paddingTop:"50px",backgroundImage:`linear-gradient(rgba(255,255,255,.4), rgba(255,255,255,.3))`,display:"block"}} className="list2-item">
              <p style={{borderRight:"0",padding:"0"}} className="logo"></p>
              <div style={{maxWidth:"100px"}}><div className="wrapperTag" style={{position:"relative",display:"flex",zIndex:"0"}}><Popup4 onClose={()=>{}} updateProfileParams={this.props.updateProfileParams} createError={this.props.createError} price={this.props.price} isOpen={true}/></div></div>
              </li>
             
                              
              </div>
            </div>
          </ul>
        </div>
      </div>
    );
  }
}

export default Settings;
