import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import Popup3 from './Popup3'
import Loader from './Loader';
import ReactDOM from 'react-dom';
import { setContext } from '@apollo/client/link/context';
import { BrowserRouter, Switch, Route,Link } from 'react-router-dom';
import routes from '../storage_apollo_routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
     // "Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class Popup5 extends Component {
  constructor(props){
    super(props);
  this.state={
   contact:"",
    completed:false,
    open:this.props.isOpen,
    errorMsg:"",
    iMO:false,
    loaderState:false
  
  }
    //this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    //this.setPrice = this.setPrice.bind(this)
  }


  createError = (message)=>{
    this.setState({errorMsg:message,iMO:true})
    console.log("message")
  }


  followUser = (id)=>{
    const {contact} = this.state
    let pattern = /^(([2][3][3])|[0])?([2]|[5])([4]|[5])\d{7}$/.test(contact)
    if(!pattern){
      return 
    }
    this.setState({loaderState:true})
    client
  .mutate({
    mutation: gql`
      mutation Sub {
        followUser(profile_id:${id},contact:"${contact}")
      }
    `,
  }).then((result)=>{
    console.log(result.data.followUser)
    if(result.data.followUser == "SUCCESSFUL"){
      this.setState({completed:true,loaderState:false})
      //
       }
       else{
        
        // this.props.updateProfileParams(amount)
      
        this.createError(result.data.followUser)
        this.setState({loaderState:false})
       }

  }).catch((err)=>{
    this.setState({loaderState:false})
    this.createError("Check your Funds!")
  })
  }






// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

contactChosen = (event)=>{
    this.setState({contact:event.target.value});
    
}

closeModal() {
  this.setState({iMO:false})
  
  //this.update();
}


    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
   
    
    render() {
     const {completed,amount,errorMsg} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div className="cenceph_pure_black">
       
        
        <p> Subscribe for 30 days  </p>
        

        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
          <Popup3 isOpen={this.state.iMO} error={errorMsg} onClose={() => this.closeModal()}></Popup3>
          <Loader isOpen={this.state.loaderState}/>
          {completed && <Link to={{pathname:`/profile/${this.props.id}`, subbed:true,id: this.props.userID,
  }}><button className="wpc-btn wpc-btn-default-rounded"> Continue</button></Link>}
      {/* <input onChange={this.numberchosen}  value={this.state.amount} type="number" min="1" max="700" /> */}
      {!completed && <input onChange={(e)=>this.contactChosen(e)}  type="text" />}
      {!completed && <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.followUser(this.props.id)}> Subscribe</button>
  
  
  
  
  </div>}
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default Popup5;