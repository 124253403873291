import { methodOf } from 'lodash';
import React ,{Component,useEffect,useState} from 'react';
import ReactDOM from 'react-dom';
import Loader from './Loader';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import routes from '../storage_apollo_routes';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  createHttpLink,
  gql,
} from "@apollo/client";
import { setContext } from '@apollo/client/link/context';
const link = createHttpLink({
  uri: routes.apollo,
  credentials: "include",
});
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  //const token = localStorage.getItem('token');
  // return the headers to the context so httpLink can read them
  
  return {
    headers: {
      //"Apollo-Require-Preflight": "true",
      "X-Pinggy-No-Screen":"true"
    },
  };
});
const client = new ApolloClient({
  cache: new InMemoryCache(),
  link:authLink.concat(link),
});

class Popup4 extends Component {
  constructor(props){
    super(props);
  this.state={
    amount:'',
    date:'',
    completed:false,
    open:this.props.isOpen,
    spin:false
  
  }
    this.numberchosen =this.numberchosen.bind(this)
    // this.datechosen =this.datechosen.bind(this)
    this.setPrice = this.setPrice.bind(this)
  }


  submitnumber = async (event)=>{
    event.preventDefault()
    //apollo client createbundle price...  await user.update({ password: password });
    const {amount,date}= this.state
    console.log(amount,date)
    const createBundle = gql`
    mutation newBundle($endDate: Int!, $price: Int!) {
      newBundle(endDate: $endDate, price: $price) {
        price
        endDate
        id
      }
    }
  `;
  if(amount > 700 || date > 365){
    return
  }
 let b = await client
    .mutate({
      mutation: createBundle,
      variables: { endDate: parseInt(date), price:parseInt(amount) },
    })
    .then((result) => {
      console.log(result);
      let bundle = result.data.newBundle

      return bundle
    })
    .catch((err) => {
      console.log("catch", err);
    });
 
    
   
    this.props.updateBundle(b)

    // axios.post('/api/paywall' ,{quantity:amount}).then(response =>{
    //   console.log(response)
    //   this.props.onClose()})
}

setPrice = async ()=>{
const {amount} = this.state
if(amount > 700)return
this.setState({spin:true})
    let call =  await client
     .mutate({
       mutation: gql`
         mutation setPrice {
           subscriptionPrice(price:${parseFloat(amount)})
         }
       `,
     }).then((result)=>{
       if(result == "Error"){
        this.props.createError("Create a Profile first")
       }
       else{
          this.setState({completed:true,spin:false})
         this.props.updateProfileParams(amount)
       }
      //this.setState({})
      
     }).catch((error)=>{
        this.props.createError("Invalid Input")
     })
    }

// close = ()=>{
//   this.setState({amount:"",date:"",open:!this.state.open})
// }

  numberchosen = (event)=>{
    this.setState({amount:event.target.value});
    
}




    close(e) {
      e.preventDefault()
  
      if (this.props.onClose) {
        this.props.onClose()
      }
    }
   
    
    render() {
     const {completed,amount,spin} = this.state
      if (this.props.isOpen == false)
        return null
  
      return (
       
        <div className="boxxxx">
        
        <div className="cenceph_pure_black">
       
        
        <p> Set a monthly fee for subscribers  </p>
        {!completed && <p>Current price {this.props.price}</p>}
        <Loader isOpen={spin}/>
        {completed && <p>Changed price to GHC{amount}</p>}

        <i onClick={(e)=> this.close(e)} className="fa fa-times" aria-hidden="true"></i>
      <div className="wrapp">
          {/* change to create subscription bundle */}
      <input onChange={this.numberchosen} placeholder="GHC"  value={this.state.amount} type="number" min="1" max="700" />

      <div className="cont"><button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.setPrice()}> cedis 30 days</button>
  
  
  
  
  </div>
    
  </div>
  
  
      
      
      
  
  
  
  
  
 
  
  
  
  </div>
  
  
  
  
  
  
  </div> //<div className="bg" onClick={e => this.close(e)}/>
    
      )
    }
  
    
  }
  export default Popup4;