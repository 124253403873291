import React, { Component } from 'react';
import "../UI/Styles/TagController.css"
import Loader from '../UI/Loader';
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    useQuery,
    createHttpLink,
    gql,
  } from "@apollo/client";
import Popup3 from '../UI/Popup3';
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    }
  });
class TagController extends Component {
    constructor(props){
        super(props)
        this.state={
tags:[],
newTags:[],
isModalOpen:false,
errorMsg:"",
isOpen:false
        }
    }

    getTags=()=>{
        client.query({
            query: gql`
            query TagRetrieval{
              getUserTags{
               name
              }
            }
          `,
            
          }).then((result)=>{
         this.setState({tags:result.data.getUserTags})
          }).catch((error)=>{
            console.log(error)
          })
    }
    closeModal=()=>{
      this.setState({isModalOpen:false,errorMsg:""})
    }
remove=(name)=>{

  client.mutate({
    mutation: gql`
    mutation deleteTag($name:String!){
        deleteTag(name:$name)
      }
    
  `,
  variables:{
    name:name
  }
    
  }).then((result)=>{
 this.setState({tags:this.state.tags.filter(ftr=>ftr.name != name),newTags:this.state.newTags.filter(ftr=>ftr != name)})
  }).catch((error)=>{
    console.log(error)
  })
}
    componentDidMount(){
        this.getTags()
    }

    newTags=(name)=>{

    }
    saveNewTags=()=>{
 this.setState({isOpen:true})
      client.mutate({
        mutation: gql`
        mutation createNewTags($names:[String]){
            createNewTags(names:$names)
          }
        
      `,
      variables:{
        names:this.state.newTags
      }
        
      }).then((result)=>{
     this.setState({isOpen:false,isModalOpen:true,errorMsg:"saved successfully"})
      }).catch((error)=>{
        console.log(error)
      })
    }
    addTag=(e)=>{
        if (e.key == "Enter") {
            let tag = e.target.value.replace(/\s+/g, " ");
            if (tag.length > 1 ) {
              if (this.state.tags.length < 9) {
                tag.split(",").forEach((tag) => {
                  //tags.push(tag);
                  //this.createTag();
                  this.setState({tags:this.state.tags.concat([{name:tag}]),newTags:this.state.newTags.concat([tag])})
                });
              }
            }
            e.target.value = "";
          }
    }
    render() {
        return (
            <div>
                <link rel="stylesheet" href="https://unicons.iconscout.com/release/v4.0.0/css/thinline.css"/>
              

<div class="wrapperTag">
  <div class="tagTitle">
  { this.state.isModalOpen && <Popup3 isOpen={this.state.isModalOpen} error={this.state.errorMsg} onClose={() => this.closeModal()}></Popup3>}
    {/* <img src="tag.svg" alt="icon"/> */}
    <h2>Tags</h2>
  </div>
  <div class="tagContent">
    <p>Press enter after each tag</p>
    <ul>{this.state.tags.length > 0 && this.state.tags.map(tag=>(
        <li className="uit uit-multiply" onClick={()=>this.remove(tag.name)}>{tag.name}</li>
    ))
        }
        <input onKeyUp={(event)=>this.addTag(event)} type="text" spellcheck="false"/></ul>
  </div>
  <div class="details">
    <p><span>{9 - this.state.tags.length}</span> tags are remaining</p>
    <button className="wpc-btn wpc-btn-default-rounded" onClick={()=>this.saveNewTags()}>Save</button>
    <Loader isOpen={this.state.isOpen}/>
  </div>
</div>
            </div>
        );
    }
}

export default TagController;