import React from "react";
import "./Styles/Skeleton.css";
function SkeletonLI() {
  return (
    <div>{Array(2)
        .fill()
        .map((item,index)=><li
      ng-repeat="user in ctrl.users"
      class="list2-item"
      style={{"background-image": 'linear-gradient(rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0.3)), url("https://storage.googleapis.com/uhs_bucket_master/undefined");'}}
    >
      <div className="skeleton-loader" style={{borderRadius:"20px",height: "100px", width: "70px", position: "relative"}}>

<div className="skeleton-loader"></div>

      </div>
      <div className="list2-item-content">
        <h4 className="skeleton-loader hfour"></h4>
        <p className="skeleton-loader hfour"></p>
        <i className="skeleton-loader" aria-hidden="true">
          {" "}
          
        </i>
      </div>
    </li>)}</div>
  );
}

export default SkeletonLI;
