import axios from "axios";

//import redisClient from "./redisClient";
//conversion and funnels and speed
//get ip
//fastapi->hashIp and save ()progressive disclosure and measure dropoff for each stage eg hash(IP){timeStamp:date.now()}
//measure new uploads
//measure one-time-link usage
//popups and tooltips for guiding actions

const saveEvent = async ({event,component,state})=>{
 await axios.post("http://localhost:81/saveEvent",{
    user_event:{
      event,component,state
    }
  }).then(()=>console.log("Event Logged Successfully")).catch((error)=>{
    console.log(error)
  })
}

const trackEvent = (event, component, state) => {

    console.log('Event:', event);
    console.log('Component:', component);
    console.log('State:', state);

    saveEvent({event:"SearchClick",component,state})
    //graphql call
  };

  export default trackEvent