import React, { Component, useEffect, useRef, useState } from 'react'
import "../UI/Styles/Statements.css"
import {
    ApolloClient,
    InMemoryCache,
    ApolloProvider,
    createHttpLink,
    gql,
    useQuery,
    updateQuery,
  } from "@apollo/client";
  import { offsetLimitPagination } from "@apollo/client/utilities";
  const link = createHttpLink({
    uri: "https://5483-154-161-33-98.ngrok-free.app/graphql",
    credentials: "include",
  });
  const client = new ApolloClient({
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            getPosts: offsetLimitPagination(),
            //  keyArgs:false,
            //  merge(existing=[],incoming){
            //    return [...existing,...incoming]
            //  },
          },
        },
      },
    }),
    link,
  });

function Statements(props){
    const [transactions,setTransactions] = useState([])
    useEffect(() => {
        getTransactionHistory()
       
      }, [transactions]);
    

const calculateFee = (toFloat)=>{
  let parseMe = parseFloat(toFloat)
 let final =  (3/7*(parseMe))
 return (parseInt(final*100))/100
}    

const calculateTotal = (toFloat)=>{
  let parseMe = parseFloat(toFloat)
 let final =  (parseMe/0.70) 
 return (parseInt(final*100))/100
}
   const getTransactionHistory = ()=>{
        //add post id to transaction
        client.query({
            query: gql`
            query TransactionHistory{
              getTransactionHistory{
               createdAt
               amount
               type
              }
            }
          `,
            
          }).then((result)=>{
            console.log(result.data.getTransactionHistory)
          setTransactions(result.data.getTransactionHistory)
        
          }).catch((error)=>{
            console.log(error)
          })
    }


    return <div class="container">
    {/* <ul class="tabs">
      <li>
        <a id="url-action" class="active" href="javascript:;">Site url</a>
      </li>
      <li>
        <a id="category-action" href="javascript:;">Category</a>
      </li>
      <li>
        <a id="popularity-action" href="javascript:;">Popularity</a>
      </li>
      <li>
        <a id="visits-action" href="javascript:;">Visits</a>
      </li>
    </ul> */}
    <table id="table">
      <colgroup>
        <col/>
        <col id="test"/>
      </colgroup>
      <tr>
        <th>Type</th>
        <th>Amount</th>
        <th>Fee</th>
        <th>Net</th>
        <th>Date</th>
      </tr>
      {transactions && transactions.length > 0 && transactions.map(transaction=>(<tr>
        <td>
          {transaction.type}
        </td>
        <td>
         GHC {calculateTotal(transaction.amount)}
        </td>
        <td>
        GHC {calculateFee(transaction.amount)}
        </td>
        <td>
          GHC {transaction.amount}
        </td>
        <td>
          {transaction.createdAt}
        </td>
      </tr>))}
      {/* <tr>
        <td>
          Amazon
        </td>
        <td>
          https://amazon.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          3/10
        </td>
        <td>
          9 000 000
        </td>
      </tr>
      <tr>
        <td>
          Alibaba
        </td>
        <td>
          https://alibaba.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          9/10
        </td>
        <td>
          7 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Google
        </td>
        <td>
          https://google.com
        </td>
        <td>
          Search
        </td>
        <td>
          5/10
        </td>
        <td>
          1 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Amazon
        </td>
        <td>
          https://amazon.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          3/10
        </td>
        <td>
          9 000 000
        </td>
      </tr>
      <tr>
        <td>
          Alibaba
        </td>
        <td>
          https://alibaba.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          9/10
        </td>
        <td>
          7 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Google
        </td>
        <td>
          https://google.com
        </td>
        <td>
          Search
        </td>
        <td>
          5/10
        </td>
        <td>
          1 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Amazon
        </td>
        <td>
          https://amazon.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          3/10
        </td>
        <td>
          9 000 000
        </td>
      </tr>
      <tr>
        <td>
          Alibaba
        </td>
        <td>
          https://alibaba.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          9/10
        </td>
        <td>
          7 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Google
        </td>
        <td>
          https://google.com
        </td>
        <td>
          Search
        </td>
        <td>
          5/10
        </td>
        <td>
          1 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Amazon
        </td>
        <td>
          https://amazon.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          3/10
        </td>
        <td>
          9 000 000
        </td>
      </tr>
      <tr>
        <td>
          Alibaba
        </td>
        <td>
          https://alibaba.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          9/10
        </td>
        <td>
          7 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Google
        </td>
        <td>
          https://google.com
        </td>
        <td>
          Search
        </td>
        <td>
          5/10
        </td>
        <td>
          1 000 000 000
        </td>
      </tr>
      <tr>
        <td>
          Amazon
        </td>
        <td>
          https://amazon.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          3/10
        </td>
        <td>
          9 000 000
        </td>
      </tr>
      <tr>
        <td>
          Alibaba
        </td>
        <td>
          https://alibaba.com
        </td>
        <td>
          Shopping
        </td>
        <td>
          9/10
        </td>
        <td>
          7 000 000 000
        </td>
      </tr> */}
    </table>
  </div>
   
}

export default Statements