import React from 'react'
import {Formik,useField} from 'formik'

const Frm1 = ({label,...props}) =>{
const [field,meta] = useField(props)
//console.log(field,meta)
return (
<div className="formik_login">
<label htmlFor={field.name}>{label}</label>
<input  className="formText" autoComplete='off'{...field} {...props}></input>




</div>)

}

export default Frm1